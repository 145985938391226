import {DataGridPro, GridColDef, GridValueGetterParams} from "@mui/x-data-grid-pro";
import {format} from "date-fns";
import {Button, DialogActions, DialogTitle, Typography} from "@material-ui/core";
import React, {useState} from "react";
import {Box, Dialog, DialogContent} from "@mui/material";

const columns: (accountCosts)=>GridColDef[] = (accountCosts) => {
    return [
        {headerName: "ID", field: "id", hide: true},
        {
            headerName: "日期",
            field: "date",
            width: 100,
            type: "date",
            valueGetter: (params: GridValueGetterParams) => {
                try {
                    return params.row.date
                        ? format(new Date(params.row.date), "yyyy-MM-dd")
                        : "未指定";
                } catch (e) {
                    return params.row.date;
                }

            },
        },
        {headerName: "花費", field: "cost", valueFormatter: ({value}) => value?.toLocaleString(), type: "number"},
        {headerName: `合計：${accountCosts.reduce((a, c) => a + c.cost, 0).toLocaleString()}`, field: "xxx", width: 150}
    ]
}

export default ({ deposit }) => {
    const [open, setOpen] = useState(false);

    return (<div>
        <Dialog disableEnforceFocus open={open} fullWidth={true} maxWidth={"xs"}>
            <DialogTitle style={{backgroundColor: "#DDD"}}>
                <Typography variant="h4" align="center">
                    每日花費
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Box width={"100%"} style={{ height: "60vh" }}>
                    <DataGridPro
                        columns={columns(deposit.accountCosts)}
                        rows={deposit.accountCosts}
                        hideFooter
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    variant={"contained"}
                    color={"primary"}
                    onClick={()=>setOpen(false)}
                >
                    確定
                </Button>
            </DialogActions>
        </Dialog>

        <Button
            variant={"contained"}
            color={"primary"}
            onClick={() => setOpen(true)}
        >
            每日花費
        </Button>

    </div>)
}