import React, { useCallback, useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
} from "@material-ui/core";
import { Formik } from "formik";
import FormRenderer, { InputType } from "../../common/FormRenderer";
import { format } from "date-fns";
import {
    OrderItemStatus,
    OrderStatus,
    useGetOrderDataQuery,
    useGetUserListQuery,
    useUpdateOrderItemMutation,
} from "../../generated/graphql";
import DefaultQueryHandler from "../../common/DefaultQueryHandler";
import { omit } from "lodash";
import NotificationPopup from "../../common/NotificationPopup";
import CreateView from "../custom/CreateView";
import { selectMapFn } from "../../common/Util";
import Input from "@material-ui/icons/Input";
import {BelongingList, OrderItemTypeList} from "../../common/Constant";
import {FieldTagList, ProductTagList} from "../../common/IndustryConstant";

export default ({ orderItem }) => {
    // const [open, setOpen] = useState(false);
    const [suppliers, setSuppliers] = useState<any>([]);
    const { loading, data, error } = useGetOrderDataQuery();
    const userListQuery = useGetUserListQuery();
    const [updateOrderItem] = useUpdateOrderItemMutation({
        refetchQueries: ["getOrderItem"],
    });
    const onSubmit = useCallback(
        async (updatedOrderItem, { resetForm }, setOpen) => {

            try {
                const query = await updateOrderItem({
                    variables: {
                        orderItem: updatedOrderItem,
                        id: orderItem.id,
                    },
                });
                NotificationPopup.success(`修改完成`);
                return query.data?.updateOrderItem;
            } catch (e) {
                NotificationPopup.error(`修改委刊細項發生問題: ${e.message}`);
                console.error(e);
            } finally {
                resetForm();
                setOpen(false);
            }
        },
        [updateOrderItem]
    );
    return (
        <DefaultQueryHandler
            loading={loading && userListQuery.loading}
            error={error}
        >
            {orderItem.status == OrderItemStatus.Started &&
            <CreateView
                title={"修改委刊細項"}
                // columns={orderItem.order.status == OrderStatus.Draft ? [
                columns={[
                    {
                        name: "productId",
                        label: "商品",
                        type: InputType.select,
                        options: data?.products.map(selectMapFn),
                        onChange: (selectId) => {
                            data?.products.map((product) => {
                                if (product.id == selectId) {
                                    setSuppliers(product.suppliers);
                                }
                            });
                        },
                        value: orderItem.product.id,
                    },
                    {
                        name: "type",
                        label: "操作方式",
                        type: InputType.select,
                        options: OrderItemTypeList,
                        value: orderItem.type,
                    },
                    {
                        name: "totalAmount",
                        label: "購買金額",
                        value: orderItem.totalAmount,
                        type: InputType.number,
                    },
                    {
                        name: "serviceFee",
                        label: "帳戶執行費",
                        value: orderItem.serviceFee,
                        type: InputType.number,
                    },
                    {
                        name: "budget",
                        label: "成本預算",
                        value: orderItem.budget,
                        type: InputType.number,
                    },
                    {
                        name: "assigneeId",
                        label: "專案負責人",
                        type: InputType.select,
                        options: userListQuery.data
                            ? userListQuery.data?.users.map(selectMapFn)
                            : [],
                        value: orderItem.assignee?.id,
                    },
                    {
                        name: "supporterId",
                        label: "協助執行人",
                        type: InputType.select,
                        options: userListQuery.data
                            ? userListQuery.data?.users.map(selectMapFn)
                            : [],
                        value: orderItem.supporter?.id,
                        required: false,
                    },
                    {
                        name: "startDate",
                        label: "開始時間",
                        type: InputType.date,
                        value: format(
                            new Date(orderItem.startDate),
                            "yyyy-MM-dd"
                        ),
                    },
                    {
                        name: "endDate",
                        label: "結束時間",
                        type: InputType.date,
                        value: format(
                            new Date(orderItem.endDate),
                            "yyyy-MM-dd"
                        ),
                    },
                    {
                        name: "description",
                        label: "備註",
                        type: InputType.textarea,
                        value: orderItem.description,
                    },
                    {
                        name: "productTag",
                        label: "產品標籤",
                        type: InputType.select,
                        options: ProductTagList.map((product)=>({value: product.id,label: `${product.id} ${product.name}`
                        })),
                        value: orderItem.productTag,
                    },
                    {
                        name: "fieldTag",
                        label: "場域標籤",
                        type: InputType.select,
                        options: FieldTagList.map((field)=>({value: field.id,label: `${field.id} ${field.name}`
                        })),
                        value: orderItem.fieldTag,
                    },
                    { name: "belonging", label: "歸屬", type: InputType.select,
                        options: BelongingList, value: orderItem.belonging },
                ]}
                // : [
                //     {
                //         name: "assigneeId",
                //         label: "負責人",
                //         type: InputType.select,
                //         options: userListQuery.data ? userListQuery.data?.users.map(selectMapFn) : [],
                //         value: orderItem.assignee?.id,
                //     },
                //     {
                //         name: "startDate",
                //         label: "開始時間",
                //         type: InputType.date,
                //         value: format(new Date(orderItem.startDate), "yyyy-MM-dd"),
                //     },
                //     {
                //         name: "endDate",
                //         label: "結束時間",
                //         type: InputType.date,
                //         value: format(new Date(orderItem.endDate), "yyyy-MM-dd"),
                //     },
                //     { name: "accountId", label: "CID", type: InputType.autoComplete, value: orderItem.accountId, freeSolo: true, isMulti: true },
                // ]}
                onSubmit={onSubmit}
                submitTitle={'修改'}
                queryEntity={"orderItem"}
            />}
        </DefaultQueryHandler>
    );
};
