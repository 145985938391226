import React, { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Page from "../../components/Page";
import {
    useCreateLogMutation,
    useGetProductListQuery,
    useGetSupplierQuery,
    useRemoveSupplierMutation,
    useUpdateSupplierMutation,
} from "../../generated/graphql";
import DefaultQueryHandler from "../../common/DefaultQueryHandler";
import { AppBar, Box, Grid, Tab, Tabs } from "@material-ui/core";
import TextInput from "../../components/Form/TextInput";
import SelectInput from "../../components/Form/SelectInput";
import { isNonNullChain } from "typescript";
import ContactView from "../../layouts/PopupLayout/ContactView";
import DetailView from "../custom/DetailView";
import EditView, {getModifiedValues} from "../custom/EditView";
import NotificationPopup from "../../common/NotificationPopup";
import { InputType } from "../../common/FormRenderer";
import { selectMapFn, useTabIndex } from "../../common/Util";
import TabPanel from "../custom/TabPanel";
import ContactTable from "../contact/ContactTable";
import CreateContactView from "../contact/CreateContactView";
import {useUser} from "../../Auth";
import {getLogQueryMessage} from "../log/LogUtils";
import {omit} from "lodash";

/*
    id
    name
    address
    invoiceTitle
    taxId
    products {
        id
        name
        website
        createdAt
        description
        productOrigin
    }
*/

const getSupplierColumns = (supplier, products) => {
    const supplierColumns = [
        { name: "name", label: "供應商名稱", value: supplier?.name },
        { name: "phone", label: "電話", value: supplier?.phone },
        { name: "address", label: "地址", value: supplier?.address },
        {
            name: "invoiceTitle",
            label: "發票抬頭",
            value: supplier?.invoiceTitle,
        },
        { name: "taxId", label: "統編", value: supplier?.taxId },
        {
            name: "products",
            label: "產品",
            type: InputType.autoComplete,
            options: products ? products.map(selectMapFn) : [],
            isMulti: true,
            value: supplier
                ? supplier.products.map((product) => {
                      return {value: product.id, label: product.name};
                  })
                : [],
        },
        { name: "bankCode", label: "銀行代碼", value: supplier?.bankCode },
        {
            name: "bankAccountNumber",
            label: "銀行帳號",
            value: supplier?.bankAccountNumber,
        },
    ];
    return supplierColumns;
};
export default () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const { data, loading, error } = useGetSupplierQuery({
        variables: {
            id,
        },
    });
    const productListQuery = useGetProductListQuery();
    const [updateSupplier] = useUpdateSupplierMutation();
    const [removeSupplier] = useRemoveSupplierMutation();
    const [tabIndex, setTabIndex] = useTabIndex();
    const user = useUser();
    const [createLog] = useCreateLogMutation();
    const onSubmit = useCallback(
        async (supplier, { resetForm }) => {
            const updatedSupplier = supplier.products ? {...supplier, productIds: supplier.products.map((product)=>product.value)} : supplier;
            try {
                await updateSupplier({
                    variables: {
                        supplier: omit(updatedSupplier, ['products']),
                        id,
                    },
                });
                NotificationPopup.success(`更新完成`);
                await createLog({
                    variables: {
                        log: {
                            entityId: id,
                            userId: user!.id as string,
                            queryAction: "修改供應商",
                            queryEntity: "supplier",
                            queryMessage: `${getLogQueryMessage(getModifiedValues(supplier, data?.supplier, ''), getSupplierColumns(
                                data?.supplier,
                                productListQuery.data?.products
                            ))}`,
                        }
                    }
                });
            } catch (e) {
                NotificationPopup.error(`更新供應商發生問題: ${e.message}`);
                console.error(e);
            } finally {
                // resetForm();
            }
        },
        [updateSupplier, data, productListQuery]
    );
    const onRemove = async () => {
        try {
            await removeSupplier({
                variables: {
                    id: id,
                },
                refetchQueries: ["getProductList"],
            });
            NotificationPopup.success(`刪除完成`);
            navigate(`/app/supplier`);
        } catch (e) {
            NotificationPopup.error(`刪除產品發生問題: ${e.message}`);
            console.error(e);
        }
    };
    return (
        <Page title={"supplier detail page"}>
            <AppBar position="static" color="default">
                <Tabs
                    value={tabIndex}
                    indicatorColor="secondary"
                    textColor="secondary"
                    onChange={(
                        event: React.ChangeEvent<{}>,
                        newValue: number
                    ) => {
                        setTabIndex(newValue);
                    }}
                >
                    <Tab label="供應商" />
                    <Tab label="聯絡人" />
                </Tabs>
            </AppBar>
            <DefaultQueryHandler
                loading={loading || productListQuery.loading}
                error={error}
            >
                <TabPanel value={tabIndex} index={0}>
                    <EditView
                        columns={getSupplierColumns(
                            data?.supplier,
                            productListQuery.data?.products
                        )}
                        onSubmit={onSubmit}
                        onRemove={onRemove}
                    />
                </TabPanel>
                <TabPanel value={tabIndex} index={1}>
                    <CreateContactView supplier={data?.supplier} />
                    <ContactTable contacts={data?.supplier?.contacts} />
                </TabPanel>
            </DefaultQueryHandler>
        </Page>
    );
};
