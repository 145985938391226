import Page from "../../components/Page";
import {Container, Grid} from "@material-ui/core";
import DefaultQueryHandler from "../../common/DefaultQueryHandler";
import PurchaseOrderTable from "../purchaseOrder/PurchaseOrderTable";
import React from "react";
import UpgradeTable from "./UpgradeTable";
import CreateUpgradeButton from "./CreateUpgradeButton";
import {useCustomers} from "../../Auth";


export default () => {
    const customers = useCustomers();
    return (
        <Page title={"purchase order page"}>
            <Container maxWidth={false} style={{padding: "30px"}}>
                <Grid container spacing={2}>
                    <CreateUpgradeButton customers={customers}/>
                    <UpgradeTable/>
                </Grid>
            </Container>
        </Page>
    );
}