import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import React, {useState} from "react";
import {range,map} from 'lodash';
import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles({
    formControl:
    {
        minWidth:"75px"
    }

});

export interface ReportDateInputValue
{
    year:number;
    month:number;
}

export const useReportDate = ()=>
{
    const now = new Date();
    const [reportDate,setReportDate] = useState<ReportDateInputValue>({year:now.getFullYear(),month:now.getMonth() + 1});
    const onChange = ({year,month})=>setReportDate({year,month});
    return {reportDate,onChange};
}


export default ({value,onChange}:{value:ReportDateInputValue,onChange:(value:ReportDateInputValue)=>void})=>
{
    const styles = useStyles();

    return <>
        <FormControl className={styles.formControl}>
            <InputLabel>
                月
            </InputLabel>
            <Select value={value.month} onChange={e => onChange({...value,month:parseInt(e.target.value as string)})}>
                {map(range(1, 13), i => <MenuItem value={i}>
                    {i}
                </MenuItem>)}
            </Select>
        </FormControl>
        <FormControl className={styles.formControl}>
            <InputLabel>
                年
            </InputLabel>
            <Select value={value.year} onChange={e => onChange({...value,year:parseInt(e.target.value as string)})}>
                {map(range(2021, 2027), i => <MenuItem value={i}>
                    {i}
                </MenuItem>)}
            </Select>
        </FormControl>


    </>
}
