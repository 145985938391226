import React, { Fragment, useCallback, useState } from "react";
import DefaultQueryHandler from "../../common/DefaultQueryHandler";
import { InputType } from "../../common/FormRenderer";
import NotificationPopup from "../../common/NotificationPopup";
import {
    CurrencyEnum,
    InvoiceStatus,
    useUpdateInvoiceMutation,
    UpdateInvoiceDto,
    RoleIdEnum, usePushMessageMutation,
} from "../../generated/graphql";
import CreateView from "../custom/CreateView";
import { format } from "date-fns";
import Grid from "@material-ui/core/Grid";
import ConfirmButton from "../../components/ConfirmButton";
import { reduce } from "lodash";
import { HasRole, saveFile } from "../../common/Util";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography,
} from "@material-ui/core";
import { getAccessToken } from "../../Auth";
import {InvoiceStatusList} from "../../common/Constant";

export default ({ invoice }) => {
    const [updateInvoice] = useUpdateInvoiceMutation();
    const [pushMessage] = usePushMessageMutation();
    const [open, setOpen] = useState(false);
    const [date, setDate] = useState(format(new Date(), "yyyy-MM-dd"));

    const onSubmit = useCallback(
        async (updatedInvoice, { resetForm }, setOpen) => {

            updatedInvoice.discountPercentage = updatedInvoice.isDiscount
                ? invoice.order.customer.defaultDiscountPercentage
                : 0;
            if (updatedInvoice.totalAmount) {
                updatedInvoice.totalAmount = parseFloat(
                    updatedInvoice.totalAmount
                );
            }
            if (updatedInvoice.paidAmount) {
                updatedInvoice.paidAmount = parseFloat(
                    updatedInvoice.paidAmount
                );
            }

            let postInvoice = reduce(
                updatedInvoice,
                (acc, value, key) => {
                    if (
                        [
                            "postDate",
                            "invoiceNumber",
                            "status",
                            "totalAmount",
                            "status",
                            "discountPercentage",
                            "paidAmount",
                            "note",
                        ].indexOf(key) > -1
                    ) {
                        return { ...acc, [key]: value };
                    } else {
                        return acc;
                    }
                },
                {}
            );
            try {
                const query = await updateInvoice({
                    variables: {
                        invoice: postInvoice,
                        id: invoice.id,
                    },
                    refetchQueries: ["getInvoice"],
                });
                NotificationPopup.success(`回填完成`);
                return query.data?.updateInvoice;
            } catch (e) {
                NotificationPopup.error(`回填發票發生問題: ${e.message}`);
                console.error(e);
            } finally {
                resetForm();
                setOpen(false);
            }
        },
        [updateInvoice]
    );
    const onChanged = useCallback(async () => {
        try {
            await updateInvoice({
                variables: {
                    invoice: {
                        status:
                            invoice.postDate == null
                                ? InvoiceStatus.Started
                                : invoice.creditDate == null
                                ? InvoiceStatus.Posted
                                : InvoiceStatus.Paid,
                        items: invoice.items?.map((item) => {
                            console.log(item.changedAmount == 0);
                            return {
                                id: item.id,
                                amount: item.changedAmount,
                                changedAmount: 0,
                                delete: item.changedAmount == 0,
                            };
                        }),
                    },
                    id: invoice.id,
                },
                refetchQueries: ["getInvoice"],
            });
            NotificationPopup.success(`異動完成`);
        } catch (e) {
            NotificationPopup.error(`異動發票發生問題: ${e.message}`);
            console.error(e);
        }
    }, [updateInvoice]);
    const updateStatus = useCallback(
        async (updatedInvoice, { resetForm }, setOpen) => {
        try {
            await updateInvoice({
                variables: {
                    invoice: {
                        status: updatedInvoice.status,
                        note: updatedInvoice.note,
                    },
                    id: invoice.id,
                },
                refetchQueries: ["getInvoice"],
            });
            const message = `${invoice.order.assignedSalesUser.name} 委刊單號:${invoice.order.number} ` +
                `發票被退回, 退回原因:${updatedInvoice.note}\n${window.location.origin}/app/order/${invoice.order.id}`;
            await pushMessage({variables: {message}});
        } catch (e) {
            NotificationPopup.error(`退回申請發生問題: ${e.message}`);
            console.error(e);
        }


    }, [updateInvoice]);

    
    return (
        <div style={{ margin: 10 }}>
            <Grid container spacing={2}>
                {invoice.status == InvoiceStatus.Started && (
                    <Grid item>
                        <CreateView
                            title={"回填發票"}
                            columns={[
                                {
                                    name: "postDate",
                                    label: "實際發票日期",
                                    type: InputType.date,
                                    value: format(new Date(), "yyyy-MM-dd"),
                                },
                                {
                                    name: "invoiceNumber",
                                    label: "發票號碼",
                                    pattern: "[A-Z]{2}\\d{8}",
                                    required: true,
                                },
                                {
                                    name: "status",
                                    label: "狀態",
                                    value: InvoiceStatus.Posted,
                                    options: InvoiceStatusList,
                                    type: InputType.hidden,
                                },
                                {
                                    name: "totalAmount",
                                    label: "台幣金額",
                                    type:
                                        invoice.currency == CurrencyEnum.Twd
                                            ? InputType.hidden
                                            : InputType.number,
                                    required:
                                        invoice.currency == CurrencyEnum.Usd,
                                },
                                {
                                    name: "note",
                                    label: "財務註記",
                                    value: invoice.note,
                                },
                            ]}
                            onSubmit={onSubmit}
                            queryEntity={"invoice"}
                        />
                    </Grid>
                )}
                {(invoice.status == InvoiceStatus.Started || invoice.status == InvoiceStatus.Pending) && (
                    <Grid item>
                        <CreateView
                            title={"退回申請"}
                            columns={[
                                {
                                    name: "note",
                                    label: "退回原因",
                                    value: invoice.note,
                                },
                                {
                                    name: "status",
                                    label: "狀態",
                                    value: InvoiceStatus.Canceled,
                                    options: InvoiceStatusList,
                                    type: InputType.hidden,
                                },
                            ]}
                            onSubmit={updateStatus}
                            queryEntity={"invoice"}
                        />
                        {/*<ConfirmButton*/}
                        {/*    onConfirm={() => {*/}
                        {/*        updateStatus(InvoiceStatus.Canceled);*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*    退回申請*/}
                        {/*</ConfirmButton>*/}
                    </Grid>
                )}
                {/*{(invoice.status == InvoiceStatus.Posted ||*/}
                {/*    invoice.status == InvoiceStatus.Paid) && (*/}
                {/*    <Grid item>*/}
                {/*        <CreateView*/}
                {/*            title={"回填收款"}*/}
                {/*            columns={[*/}
                {/*                {*/}
                {/*                    name: "creditDate",*/}
                {/*                    label: "收款日期",*/}
                {/*                    type: InputType.date,*/}
                {/*                    value: format(*/}
                {/*                        new Date(invoice.creditDate as string),*/}
                {/*                        "yyyy-MM-dd"*/}
                {/*                    ),*/}
                {/*                },*/}
                {/*                {*/}
                {/*                    name: "paidAmount",*/}
                {/*                    label: "收款金額",*/}
                {/*                    type: InputType.number,*/}
                {/*                    value: invoice.paidAmount,*/}
                {/*                },*/}
                {/*                {*/}
                {/*                    name: "isDiscount",*/}
                {/*                    label: `現折${invoice.order.customer.defaultDiscountPercentage}%`,*/}
                {/*                    type: invoice.order.customer*/}
                {/*                        .defaultDiscountPercentage*/}
                {/*                        ? InputType.checkbox*/}
                {/*                        : InputType.hidden,*/}
                {/*                },*/}
                {/*                {*/}
                {/*                    name: "status",*/}
                {/*                    label: "狀態",*/}
                {/*                    value: InvoiceStatus.Paid,*/}
                {/*                    type: InputType.hidden,*/}
                {/*                },*/}
                {/*                {*/}
                {/*                    name: "note",*/}
                {/*                    label: "財務註記",*/}
                {/*                    value: invoice.note,*/}
                {/*                },*/}
                {/*            ]}*/}
                {/*            onSubmit={onSubmit}*/}
                {/*        />*/}
                {/*    </Grid>*/}
                {/*)}*/}
                {invoice.status == InvoiceStatus.CancelRequested && (
                    <Grid item>
                        <CreateView
                            title={"作廢發票"}
                            columns={[
                                {
                                    name: "status",
                                    label: "狀態",
                                    value: InvoiceStatus.Canceled,
                                    options: InvoiceStatusList,
                                    type: InputType.hidden,
                                },
                                {
                                    name: "note",
                                    label: "作廢原因",
                                }
                            ]}
                            onSubmit={onSubmit}
                            queryEntity={"invoice"}
                        />
                    </Grid>
                )}
                {invoice.status == InvoiceStatus.Changed && (
                    <HasRole roles={[RoleIdEnum.Finance]}>
                        <Grid item>
                            <ConfirmButton onConfirm={onChanged}
                                           queryEntity={"invoice"}
                                           queryAction={"核准異動"}
                                           entityId={invoice.id}>
                                核准異動
                            </ConfirmButton>
                        </Grid>
                    </HasRole>
                )}
                {invoice.status != InvoiceStatus.Started && (
                    <Grid item>
                        <CreateView
                            title={"修改發票號碼"}
                            columns={[
                                {
                                    name: "postDate",
                                    label: "實際發票日期",
                                    type: InputType.date,
                                    value: format(
                                        new Date(invoice.postDate),
                                        "yyyy-MM-dd"
                                    ),
                                },
                                {
                                    name: "invoiceNumber",
                                    label: "發票號碼",
                                    pattern: "[A-Z]{2}\\d{8}",
                                    value: invoice.invoiceNumber,
                                },
                            ]}
                            onSubmit={onSubmit}
                            queryEntity={"invoice"}
                        />
                    </Grid>
                )}
                <HasRole roles={[RoleIdEnum.Finance, RoleIdEnum.Admin]}>
                    <Grid item>
                        <Button
                            variant={"contained"}
                            color={"primary"}
                            onClick={() => {
                                saveFile(
                                    `/accounting-integration/downloadOne/${invoice?.id}`,
                                    `invoice-${invoice?.id}.xlsx`
                                );
                            }}
                        >
                            下載鼎新excel表
                        </Button>
                    </Grid>
                    <Grid item>
                        <div>
                            <Dialog open={open} fullWidth={true}>
                                <DialogTitle
                                    style={{ backgroundColor: "#DDD" }}
                                >
                                    <Typography variant="h4" align="center">
                                        上傳鼎新
                                    </Typography>
                                </DialogTitle>
                                <DialogContent>
                                    <TextField
                                        label="開立發票日期"
                                        type="date"
                                        key={date}
                                        value={date}
                                        onChange={(e) => {
                                            setDate(e.target.value);
                                        }}
                                    />
                                </DialogContent>
                                <DialogActions
                                    style={{
                                        paddingLeft: 24,
                                        paddingRight: 24,
                                    }}
                                >
                                    <Button
                                        variant={"contained"}
                                        color={"primary"}
                                        onClick={async () => {
                                            setOpen(false);
                                            const res = await fetch(
                                                `${process.env.REACT_APP_BACKEND_URI}/accounting-integration/send/${invoice?.id}?invoiceDate=${date}`,
                                                {
                                                    headers: {
                                                        Authorization: `bearer ${getAccessToken()}`,
                                                    },
                                                }
                                            );
                                            if (res.status !== 200) {
                                                NotificationPopup.error(
                                                    `上傳鼎新excel表遇到問題: ${JSON.stringify(
                                                        await res.json()
                                                    )}`
                                                );
                                            } else {
                                                NotificationPopup.success(
                                                    `上傳鼎新excel表成功`
                                                );
                                            }
                                        }}
                                    >
                                        送出
                                    </Button>
                                    <Button
                                        variant={"contained"}
                                        color={"secondary"}
                                        onClick={() => {
                                            setOpen(false);
                                        }}
                                    >
                                        取消
                                    </Button>
                                </DialogActions>
                            </Dialog>
                            <Button
                                variant={"contained"}
                                color={"primary"}
                                onClick={async () => {
                                    setOpen(true);
                                }}
                            >
                                上傳鼎新
                            </Button>
                        </div>
                    </Grid>
                </HasRole>
            </Grid>
        </div>
    );
};
