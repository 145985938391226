import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TableCell,
    TableRow,
    TextField,
    Typography,
} from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { NumberFormatCustom } from "../../common/FormRenderer";
import NotificationPopup from "../../common/NotificationPopup";
import {
    InvoiceItem,
    InvoiceStatus,
    UpdateInvoiceItemDto, useCreateLogMutation,
    useUpdateInvoiceMutation,
} from "../../generated/graphql";
import {useUser} from "../../Auth";
import {getLogQueryMessage} from "../log/LogUtils";
import {getModifiedValues} from "../custom/EditView";

export default ({ invoice, orderItems }) => {
    const [open, setOpen] = useState(false);
    const [changedItems, setChangedItems] = useState<
        UpdateInvoiceItemDto[] | undefined
    >();
    const [updateInvoice] = useUpdateInvoiceMutation();
    const [itemNameMap, setItemNameMap] = useState<any>();
    const [note, setNote] = useState<string>();
    const user = useUser();
    const [createLog] = useCreateLogMutation();
    useEffect(() => {
        let itemsMap = {};
        let nameMap = {};
        invoice.items?.map((item) => {
            let invoiceItem: UpdateInvoiceItemDto = {
                id: item.id,
                orderItemId: item.orderItem.id,
                amount: item.amount,
                changedAmount: item.changedAmount,
            };
            itemsMap[item.orderItem.id] = invoiceItem;
            nameMap[item.orderItem.id] = item.name;
        });

        orderItems?.map((orderItem) => {
            if (!(orderItem.id in itemsMap)) {
                let invoiceItem: UpdateInvoiceItemDto = {
                    orderItemId: orderItem.id,
                    amount: 0,
                    changedAmount: 0,
                };
                itemsMap[orderItem.id] = invoiceItem;
                nameMap[orderItem.id] = orderItem.product.name;
            }
        });
        setChangedItems(Object.values(itemsMap) as UpdateInvoiceItemDto[]);
        setItemNameMap(nameMap);
    }, []);

    const onSubmit = useCallback(async () => {
        try {
            await updateInvoice({
                variables: {
                    invoice: {
                        status: InvoiceStatus.Changed,
                        items: changedItems,
                        note: note,
                    },
                    id: invoice.id,
                },
                refetchQueries: ["getOrder", "getInvoice", "getInvoiceNotification"],
            });
            NotificationPopup.success(`申請異動完成`);
            await createLog({
                variables: {
                    log: {
                        entityId: invoice.id,
                        userId: user!.id as string,
                        queryAction: "異動發票",
                        queryEntity: "invoice",
                        queryMessage: `異動項目: ${changedItems?.map(
                            (item)=> itemNameMap[item.orderItemId!.toString()]+": "
                                +item.amount+"->"+item.changedAmount).join(', ')}
                            , 異動原因: ${note ? note : ""}`,
                    }
                }
            });
        } catch (e) {
            NotificationPopup.error(`異動發票發生問題: ${e.message}`);
            console.error(e);
        } finally {
            setOpen(false);
        }
    }, [updateInvoice, changedItems, note]);

    let totalAmount = invoice.items?.reduce((a, c) => a + c.amount, 0);
    let totalChangedAmount = changedItems?.reduce(
        (a, c) => a + (c.changedAmount as number),
        0
    );
    return (
        <div>
            <Dialog open={open} fullWidth={true}>
                <DialogTitle style={{ backgroundColor: "#DDD" }}>
                    <Typography variant="h4" align="center">
                        異動發票
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <TextField
                        label="異動原因"
                        onChange={(e) => {
                            setNote(e.target.value);
                        }}
                        style={{ width: "80%" }}
                        defaultValue={invoice.note}
                    ></TextField>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>產品</TableCell>
                        <TableCell>原發票金額</TableCell>
                        <TableCell>異動金額</TableCell>
                    </TableRow>
                    {changedItems?.map((changedItem, index) => {
                        return (
                            <TableRow>
                                <TableCell>
                                    {changedItem.orderItemId}
                                </TableCell>
                                <TableCell>
                                    {
                                        itemNameMap[
                                            changedItem.orderItemId!.toString()
                                        ]
                                    }
                                </TableCell>
                                <TableCell>{changedItem.amount}</TableCell>
                                <TableCell>
                                    <TextField
                                        value={changedItem.changedAmount}
                                        onChange={(e) => {
                                            setChangedItems(
                                                changedItems?.map((item) => {
                                                    if (
                                                        item.orderItemId ==
                                                        changedItem.orderItemId
                                                    ) {
                                                        item.changedAmount =
                                                            parseInt(
                                                                e.target.value
                                                            );
                                                    }
                                                    return item;
                                                })
                                            );
                                        }}
                                        InputProps={{
                                            inputComponent:
                                                NumberFormatCustom as any,
                                        }}
                                    />
                                </TableCell>
                            </TableRow>
                        );
                    })}

                    <TableRow>
                        <TableCell>總和</TableCell>
                        <TableCell>{totalAmount}</TableCell>
                        <TableCell>{totalChangedAmount}</TableCell>
                    </TableRow>
                    <DialogActions
                        style={{ paddingLeft: 24, paddingRight: 24 }}
                    >
                        <Button
                            variant={"contained"}
                            color={"primary"}
                            onClick={() => {
                                if (totalAmount != totalChangedAmount) {
                                    NotificationPopup.error(
                                        `異動金額總和必須和原金額相同`
                                    );
                                } else {
                                    onSubmit();
                                }
                            }}
                        >
                            確定
                        </Button>
                        <Button
                            variant={"contained"}
                            color={"secondary"}
                            onClick={() => {
                                setOpen(false);
                            }}
                        >
                            取消
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
            <Button
                variant={"contained"}
                color={"primary"}
                onClick={() => setOpen(true)}
            >
                異動
            </Button>
        </div>
    );
};
