import DetailView from "../custom/DetailView";
import {
    CustomerTypeEnum,
    OrderItem,
    RoleIdEnum,
    useGetPurchaseOrderQuery,
    useGetReceivedPaymentQuery, useRemoveReceivedPaymentMutation
} from "../../generated/graphql";
import {useParams} from "react-router";
import DefaultQueryHandler from "../../common/DefaultQueryHandler";
import {AppBar, Box, Button, Tab, Tabs} from "@material-ui/core";
import {
    CurrencyMap,
    InvoiceStatusList,
    InvoiceStatusMap,
    PurchaseOrderStatusMap,
    ReceivedPaymentStatusMap
} from "../../common/Constant";
import React, {useCallback} from "react";
import {format} from "date-fns";
import {DataGridLocale} from "../../common/DataGridLocale";
import {dateValueFormatter, HasRole, PasteToClipboard, saveFile, useTabIndex, ViewColumn} from "../../common/Util";
import {DataGridPro, GridColDef, GridValueFormatterParams, GridValueGetterParams} from "@mui/x-data-grid-pro";
import NotificationPopup from "../../common/NotificationPopup";
import {getAccessToken} from "../../Auth";
import Page from "../../components/Page";
import CreateContactView from "../contact/CreateContactView";
import ContactTable from "../contact/ContactTable";
import TabPanel from "../custom/TabPanel";
import LinkInvoicesButton from "./LinkInvoicesButton";
import ConfirmButton from "../../components/ConfirmButton";
import {useNavigate} from "react-router-dom";


const invoiceColumns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 120, type: "number" },
    { field: "status", headerName: "狀態", width: 200,
        valueFormatter: ({value}) => InvoiceStatusMap[value as string],
        valueOptions: InvoiceStatusList,
        type: "singleSelect" },
    { field: "invoiceNumber", headerName: "發票號碼", width: 200 },
    { field: "postDate", headerName: "開立發票日期", width: 150, valueFormatter: dateValueFormatter, type: "date" },
    ViewColumn('/app/invoice'),
];
const columns = [
    {field: "id", headerName: "ID"},
    {
        field: "status",
        headerName: "狀態",
        valueFormatter: (value) => {
            return ReceivedPaymentStatusMap[value];
        },
    },
    {
        field: "date",
        headerName: "收款日期",
        valueFormatter: (value) => format(new Date(value), "yyyy-MM-dd"),
    },
    {
        field: "amount",
        headerName: "金額",
        valueFormatter: (value) => {
            return value?.toLocaleString();
        },
    },
    {
        field: "customer", headerName: "客戶", valueFormatter: (value) => {
            return value?.name
        }
    },
    {field: "remark", headerName: "匯款備註"},
    {field: "sourceBankCode", headerName: "匯款銀行代碼"},
    {field: "sourceBankAccountNumber", headerName: "匯款帳號"},
    {field: "destinationBankAccountNumber", headerName: "虛擬帳號"},
    {field: "note", headerName: "財務註記"},
];
export default () => {
    const navigate = useNavigate();
    const {id} = useParams();
    const {loading, data, error} = useGetReceivedPaymentQuery({variables: {id}});
    const [tabIndex, setTabIndex] = useTabIndex();
    const [removeReceivedPayment] = useRemoveReceivedPaymentMutation();
    const onRemove = useCallback(async ()=>{
        try {
            await removeReceivedPayment({
                variables: {id},
                refetchQueries: ["getReceivedPaymentList"]
            })
            NotificationPopup.success(`刪除成功`);
            navigate("/app/receivedPayment")
        }
        catch (e) {
            NotificationPopup.error(`發生問題: ${e.message}`);
        }

    }, [removeReceivedPayment])
    return (
        <Page title={"receive payment detail page"}>
            <AppBar position="static" color="default">
                <Tabs
                    value={tabIndex}
                    indicatorColor="secondary"
                    textColor="secondary"
                    onChange={(
                        event: React.ChangeEvent<{}>,
                        newValue: number
                    ) => {
                        setTabIndex(newValue);
                    }}
                >
                    <Tab label="收款單"/>
                    <Tab label="連結發票"/>
                </Tabs>
            </AppBar>

            <DefaultQueryHandler error={error} loading={loading}>
                <TabPanel value={tabIndex} index={0}>
                    <ConfirmButton onConfirm={()=>{
                        onRemove();
                    }}>刪除</ConfirmButton>
                    <DetailView columns={columns} data={data?.receivedPayment}/>
                </TabPanel>
                <TabPanel value={tabIndex} index={1}>
                    <LinkInvoicesButton receivedPayment={data?.receivedPayment}/>
                    <br/>
                    <DataGridPro
                        columns={invoiceColumns}
                        sortModel={[{field:"id",sort:"desc"}]}
                        rows={data?.receivedPayment.invoices!}
                        hideFooter={true}
                        autoHeight={true}
                        localeText={DataGridLocale}
                        onCellClick={PasteToClipboard}
                    />
                </TabPanel>
            </DefaultQueryHandler>
        </Page>
    );
};
