import React, {useCallback} from "react";
import {

    useGetOrderItemReportLazyQuery,
    } from "../../generated/graphql";
import DefaultQueryHandler from "../../common/DefaultQueryHandler";
import {get,find,isArray} from 'lodash';
import {
    Button,
    ButtonGroup,  Grid,


    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,

} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import {formatCurrency, formatDate, saveFile} from "../../common/Util";
import OrderItemCostsTable from "./RealAccountCostsTable";
import {getAccessToken} from "../../Auth";
import {saveAs} from 'file-saver';
import {format} from 'date-fns';
import ReportDateInput, {useReportDate} from "./ReportDateInput";
import DownloadableReports from "./DownloadableReports";
import {OrderItemTypeList} from "../../common/Constant";


const useStyles = makeStyles({
    container:
    {
        margin:"60px"
    },

});

const formatPercentage = val=>
{
    return (val * 100).toFixed(1) + "%";
}

const columns = [


    {"headerName":"委刊單編號","field":"orderNumber"},
    {"headerName":"委刊細項","field":"name"},
    {"headerName":"操作方式","field":"type",format:(val)=>find(OrderItemTypeList,v=>v.value === val)?.label},
    {"headerName":"客戶名稱","field":"customerName"},
    {"headerName":"媒體別","field":"productOrigin"},
    {"headerName":"委刊單走期(起)","field":"startDate",format:formatDate},
    {"headerName":"委刊單走期(迄)","field":"endDate",format:formatDate},
    {"headerName":"委刊單銷售金額","field":"totalAmount",format:formatCurrency},
    {"headerName":"委刊單帳戶執行費","field":"serviceFee",format:formatCurrency},
    {"headerName":"委刊單合計金額","field":"summarizedTotal",format:formatCurrency},
    {"headerName":"委刊單成本(總發稿金額)",field:"budget",format:formatCurrency},
    {"headerName":"委刊單帳戶執行費%","field":"serviceFeePercentage",format:formatPercentage},
    {"headerName":"Markup","field":"markup",format:formatCurrency},
    {"headerName":"Markup %","field":"markupPercentage",format:formatPercentage},

    {"headerName":"案件預估毛利","field":"estimatedProfit",format:formatCurrency,},
    {"headerName":"案件預估毛利率","field":"estimatedProfitPercentage",format:formatPercentage,},
    {"headerName":"案件預估成本率","field":"estimatedCostPercentage",format:formatPercentage,},

    {headerName:"超花案件的實際毛利",field:"overSpendAmount",format:formatCurrency},
    {headerName:"超花案件的實際毛利率",field:"overSpendRatio",format:formatPercentage},
    {headerName:"超花案件的實際成本率",field:"overSpendCostRatio",format:formatPercentage},

    {"headerName":"截至上期為止之實際花費","field":"previousCosts",format:formatCurrency,color:"#acbcdb"},
    {"headerName":"當月成本發生數","field":"currentMonthCosts",format:formatCurrency,color:"#acbcdb"},
    {"headerName":"累計本期成本實際發生數(鎖上期數)","field":"accumulatedCosts",format:formatCurrency,color:"#acbcdb"},


    {"headerName":"截至上月已開立發票金額","field":"previouslyOutstandingAmount",format:formatCurrency,color:"#de9548"},
    {"headerName":"本期開立發票金額","field":"currentMonthOutstandingAmount",format:formatCurrency,color:"#de9548"},
    {"headerName":"累計本期開立發票金額","field":"accumulatedOutstandingAmount",format:formatCurrency,color:"#de9548"},

    {"headerName":"截至上月實際認列收入","field":"previousIncome",format:formatCurrency,},
    {"headerName":"當月應認列收入金額","field":"currentMonthIncome",format:formatCurrency,},
    {"headerName":"累計本期應認列收入金額","field":"accumulatedIncome",format:formatCurrency,},
    {"headerName":"CIDs","field":"accountIds",format:(val:string[])=>isArray(val) && val.join(',')},

]



export default ()=>
{
    const styles = useStyles();
    const {reportDate,onChange} = useReportDate();
    const download = useCallback(async()=>
    {
        const res = await fetch(`${process.env.REACT_APP_BACKEND_URI}/order-items/report?year=${reportDate.year}&month=${reportDate.month}`,
            {
                headers:{
                    Authorization:`Bearer ${getAccessToken()}`
                }
            });
        const blob = await res.blob();
        saveAs(blob,`report-${format(new Date(),"yyyy-MM-dd")}.xlsx`);
    },[reportDate]);
    const [getItems,{loading,data,error}] = useGetOrderItemReportLazyQuery();
    return <div className={styles.container}>
        <OrderItemCostsTable/>
        <Grid container spacing={8} style={{marginTop:"25px"}}>
            <Grid item>
                <ReportDateInput value={reportDate} onChange={onChange}/>
                <ButtonGroup style={{marginTop:"10px"}}>
                    <Button variant={"contained"} color={"primary"} onClick={()=>getItems({variables:{...reportDate}})}>
                        顯示
                    </Button>
                    <Button variant={"contained"} color={"secondary"}  onClick={() => download()}>
                        下載報表
                    </Button>
                    <Button variant={"contained"} color={"secondary"}  onClick={() =>
                        saveFile(`/order-items/order-items-errors?year=${reportDate.year}&month=${reportDate.month}`,
                            `errors-${reportDate.month}-${reportDate.year}.xlsx`)}>
                        下載錯誤報表
                    </Button>
                </ButtonGroup>
            </Grid>
            <Grid item>
                <DownloadableReports />
            </Grid>
        </Grid>



        <DefaultQueryHandler loading={loading} error={error}>
            <Table>
                <TableHead style={{whiteSpace:"nowrap"}}>
                    <TableRow>
                        {columns.map(c=><TableCell key={c.field} style={{backgroundColor:c.color || "inherit"}}>{c.headerName}</TableCell>)}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data?.orderItemReport.map((row)=><TableRow key={row.id}>
                        {columns.map(c=><TableCell style={{whiteSpace:"nowrap",backgroundColor:c.color || "inherit"}} key={`${c.field}-${row.id}`}>
                            {c.format?c.format(get(row,c.field)):get(row,c.field)}
                        </TableCell>)}
                        </TableRow>)}
                </TableBody>
            </Table>
        </DefaultQueryHandler>


    </div>
}
