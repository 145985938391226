import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Input,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@material-ui/core";
import { format } from "date-fns";
import React, { useState } from "react";
import { useGetProductQuery } from "../../generated/graphql";
import UploadFile from "../order/UploadFile";

export default ({ orderItem }) => {
    const [open, setOpen] = useState(false);
    const [supplierId, setSupplierId] = useState<string>();
    const [startDate, setStartDate] = useState<string>(
        format(new Date(orderItem.startDate), "yyyy-MM-dd")
    );
    const [endDate, setEndDate] = useState<string>(
        format(new Date(orderItem.endDate), "yyyy-MM-dd")
    );
    const { data, loading, error } = useGetProductQuery({
        variables: {
            id: orderItem.product.id,
        },
    });
    return (
        <div>
            <Dialog open={open} fullWidth={true}>
                <DialogTitle style={{ backgroundColor: "#DDD" }}>
                    <Typography variant="h4" align="center">
                        上傳發稿
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <FormControl style={{ width: "100%" }}>
                        <InputLabel>供應商</InputLabel>
                        <Select
                            onChange={({ target }) => {
                                setSupplierId(target.value as string);
                            }}
                        >
                            {data?.product.suppliers!.map((supplier) => {
                                return (
                                    <MenuItem
                                        value={supplier.id}
                                        key={supplier.id}
                                    >
                                        {supplier.name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                    <FormControl style={{ width: "100%" }}>
                        <TextField
                            label={"開始時間"}
                            type={"date"}
                            defaultValue={startDate}
                            onChange={({ target }) => {
                                setStartDate(target.value);
                            }}
                        />
                        <TextField
                            label={"結束時間"}
                            type={"date"}
                            defaultValue={endDate}
                            onChange={({ target }) => {
                                setEndDate(target.value);
                            }}
                        />
                    </FormControl>
                    <div style={{ float: "left", marginTop: 10 }}>
                        <UploadFile
                            uriPart={`/deposits/upload?orderItemId=${orderItem.id}&supplierId=${supplierId}&startDate=${startDate}&endDate=${endDate}`}
                            uploadTitle={"上傳發稿檔"}
                            onSuccess={() => {
                                window.location.reload();
                                setOpen(false);
                            }}
                            queryEntity={"orderItem"}
                            entityId={orderItem.id}

                        />
                    </div>
                </DialogContent>

                <DialogActions style={{ paddingLeft: 24, paddingRight: 24 }}>
                    <Button
                        variant={"contained"}
                        color={"secondary"}
                        onClick={() => {
                            console.log(
                                `/deposits/upload?orderItemId=${orderItem.id}&supplierId=${supplierId}startDate=${startDate}&endDate=${endDate}`
                            );
                            setOpen(false);
                        }}
                    >
                        取消
                    </Button>
                </DialogActions>
            </Dialog>
            <Button
                variant={"contained"}
                color={"primary"}
                onClick={() => setOpen(true)}
            >
                上傳發稿
            </Button>
        </div>
    );
};
