import {DescriptionButton} from "../common/Util";
import {DataGridPro} from "@mui/x-data-grid-pro";
import React from "react";

const categories = [
    {
        category: 'Adult_成人',
        subcategories: ['Adult Communities_成人社群',
            'Adult Events_成人活動',
            'Adult Games & Software_成人遊戲與軟體',
            'Adult Image Galleries_成人圖片畫廊',
            'Adult Magazines_成人雜誌',
            'Adult Nightlife & Clubs_成人夜生活與俱樂部',
            'Adult Novelty Items_成人新奇產品',
            'Adult Travel_成人旅行',
            'Adult Video Entertainment_成人視頻娛樂',
            'Adult Websites_成人網站',
            'Erotic Services_色情服務',
            'Porn_色情',
            'Porn Portals & Adult Networks_色情網站入口與成人網',
            'Softcore_色情書刊',],
    },
    {
        category: 'Apparel_服飾',
        subcategories: ['Apparel Accessories_服裝配飾',
            'Clothing_服裝',
            'Dry Cleaning & Alterations_乾洗及改裝',
            'Footwear_鞋類',
            'Jewelry_首飾',
            'Rain Gear_雨具',],
    },
    {
        category: 'Arts & Entertainment_藝術與娛樂',
        subcategories: ['Arts & Entertainment Awards_藝術與娛樂獎', 'Cartoons_卡通',
            'Comics & Graphic Novels_漫畫及圖形小說',
            'Entertainment Industry_娛樂業',
            'Entertainment Media Retailers_娛樂傳媒報導',
            'Event Entertainment_娛樂事件',
            'Events, Shows & Cultural Attractions_活動、表演及文化景點',
            'Fun & Trivia_趣事與益智',
            'Humor & Jokes_搞笑與笑話',
            'Movies & Films_電影',
            'Music & Audio_音樂與音頻',
            'Offbeat_另類的',
            'Sports Entertainment_體育娛樂',
            'TV & Video_電視與影片',
            'Visual Art & Design_視覺藝術與設計',],
    },
    {
        category: 'Beauty & Personal Care_美容及個人護理',
        subcategories: ['AntiAging_抗老化',
            'Body Art_人體藝術',
            'Fashion & Style_時尚風格',
            'Hair Care_頭髮護理',
            'Hygiene & Toiletries_衛生保健與化妝品',
            'Insect Repellent_驅蚊',
            'Lip Care_唇部護理',
            'MakeUp & Cosmetics_化妝品',
            'Nail Care_指甲護理',
            'Oral Care_口腔護理',
            'Perfumes & Fragrances_香水與香氛',
            'Shaving & Grooming_刮鬍&理毛',
            'Skin Care_皮膚護理',
            'Spa & Medical Spa_水療和醫療水療中心',
            'Tanning & Sun Care_曬成褐色&防曬護理',
            'Hair Care/Hair Styling_頭髮造型',
            'Spa & Medical Spa/Cosmetic Procedures/Laser Skin Treatments_皮膚療程',],
    },
    {
        category: 'Business & Industrial_商業與工業',
        subcategories: ['Aerospace & Defense_航空與國防',
            'Agriculture_農業',
            'Building Construction & Maintenance_房屋建造及維修',
            'Business Management_企業管理',
            'Chemical Industry_化學產業',
            'Commercial & Industrial Printing_商業與工業印刷',
            'Energy Industry_能源產業',
            'Fishing Industry_漁業',
            'Food Production_食品生產',
            'Food Service Industry_食品服務業',
            'Import & Export_進出口',
            'Industrial Goods & Manufacturing_工業產品及製造',
            'Janitorial Products & Services_清潔產品及服務',
            'Manufacturing_製造業',
            'Office_辦公室',
            'Retail Trade_零售業',
            'Scientific Equipment & Services_科研設備和服務',
            'Security Equipment & Services_保安設備及服務',
            'Shipping & Packing_送貨及包裝',
            'Veterinary Equipment & Supplies_獸醫設備及用品',
            'Retail Trade/Merchant Services & Payment Systems_付款系統',
            'Business Management/Advertising & Marketing/Internet Marketing_數位行銷公司',
            'Shipping & Packing/Mail & Package Delivery_快遞公司',
            'Building Construction & Maintenance/Masonry/Stone_建設公司',
            'Business Management/Business Opportunities/MLM & Get Rich Quick Opportunities_直銷',
            'Shipping & Packing/Freighting_船貨貨運公司',
            'Energy Industry/Energy Management_能源公司',
            'Design & Engineering/Interior Design_室內設計公司',
            'Building Construction & Maintenance/Building Materials & Supplies_建築用料',],
    },
    {
        category: 'Computers & Consumer Electronics_電腦及消費類電子產品',
        subcategories: ['Car Audio & Video_汽車影音',
            'Computer Accessories_電腦配件',
            'Computer Consulting_電腦諮詢',
            'Computer Hardware_電腦硬體',
            'Computer Manufacturing_電腦製造',
            'Computer Rentals_電腦租賃',
            'Computer Repair_電腦維修',
            'Computer Tech Support_電腦技術幫助',
            'Consumer Electronic Accessories_消費性電子配件',
            'Consumer Electronic Donation_消費性電子捐贈',
            'Consumer Electronic Service & Repair_消費性電子服務及維修',
            'Consumer Electronic Warranty Plans_消費性電子保固計畫',
            'Consumer Electronics_消費類電子產品',
            'Electronic Waste Disposal_電子廢物處置',
            'GPS & Navigation_GPS和導航',
            'Home Audio & Video_家庭音頻和影片',
            'Hosted Data Storage_託管數據存儲',
            'Portable Media Devices_便攜式媒體設備',
            'Radar Detectors_雷達探測器',
            'Software_軟體',
            'Technology News & Publications_科技新聞和出版社',
            'Technology Specs, Reviews & Comparisons_技術規格，評論與比較',
            'Wireless Devices_無線網路設備',
            'Consumer Electronics/Home Audio & Video/Televisions_電視',],
    },
    {
        category: 'Dining & Nightlife_餐飲與夜生活',
        subcategories: ['Dining & Nightlife Reviews, Guides & Listings_餐飲、夜生活評論、指南和目錄',
            'Nightclubs, Bars & Music Clubs_夜店、酒吧與音樂俱樂部',
            'Restaurants_餐廳',
            'Restaurants/Fast Food Restaurants_速食店',],
    },
    {
        category: 'Family & Community_家庭與社區',
        subcategories: ['Baby, Parenting & Family_嬰兒、育兒與家庭',
            'Community Service & Social Organizations_社區服務與社會組織',
            'Domestic & Personal Resources_家庭及個人資源',
            'Faith & Belief_信仰與信念',
            'Romance & Relationships_浪漫與關係',
            'Faith & Belief/Christianity_宗教',
            'Faith & Belief/Astrology & Horoscopes_星座',
            'Faith & Belief/Occult & Paranormal/Psychics & Fortune Telling_算命',
            'Baby, Parenting & Family/Baby/Baby Feeding/Baby Bottlesbaby_餵食相關',
            'Baby, Parenting & Family/Parenting & Family/Child Care/Day Care_托嬰中心',
            'Romance & Relationships/Dating & Marriage Matching/Dating Directories & Sites_相親',],
    },
    {
        category: 'Finance_財經',
        subcategories: ['ATM Sales & Processing_提款機銷售及處理',
            'Accounting & Auditing_會計與審計',
            'Banking_銀行業',
            'Business Finance_商務財經',
            'Business News & Media_商業新聞與媒體',
            'Credit & Lending_信用與貸款',
            'Financial Planning & Management_財務規劃與管理',
            'Grants, Scholarships & Financial Aid_贈款、獎學金和助學金',
            'Insurance_保險',
            'Investing_投資',
            'Money Transfer & Wire Services_匯款及通訊服務',],
    },
    {
        category: 'Food & Groceries_食品雜貨',
        subcategories: ['Beverages_飲料',
            'Food_食物',
            'Household Supplies_家居用品',
            'Online Grocery Shopping & Grocery Delivery_線上購物及送貨',
            'Tobacco_菸草',],
    },
    {
        category: 'Game_遊戲',
        subcategories: ['Game_線上遊戲、手機遊戲',],
    },
    {
        category: 'Health_健康',
        subcategories: ['Biotech & Pharmaceutical_生物及醫藥',
            'Health Care Services_健康管理服務',
            'Health Conditions & Concerns_健康狀況與關注',
            'Medical Devices, Equipment & Supplies_醫療器械、設備及用品',
            'Nutrition & Dieting_營養與節食',
            'Pharmacy_藥局',
            'Professional Medical Resources_專業的醫療資源',
            'Health Care Services/Plastic Surgery_整形',
            'Health Care Services/Hospitals & Health Clinics/Hospitals_診所醫院',
            'Health Conditions & Concerns/Allergy & Sinus/Allergy Medications/Seasonal Allergy & Sinus Medication_過敏',
            'Pharmacy/Vitamins & Supplements_維他命保健品',
            'Health Conditions & Concerns/Infectious Diseases & Viruses/Cold & Flu/Influenza/Flu Vaccines_疫苗',
            'Health Care Services/Hospice & Home Nursing Care_護理之家',
            'Health Care Services/Geriatric & Aging Care Services/Retirement Homes & Senior Living Communities_安養中心',
            'Health Conditions & Concerns/Women\'s Health & OBGYN/Yeast Infections/Yeast Infection Remedies_婦女酵母感染',],
    },
    {
        category: 'Hobbies & Leisure_愛好與休閒',
        subcategories: ['Ancestry & Genealogy_祖先與家譜',
            'Antiques & Collectibles_古董和收藏品',
            'Arts & Crafts_工藝品',
            'Astronomy_天文學',
            'Birding_賞鳥',
            'Camping & Outdoor Recreation_露營及戶外休閒',
            'Cigar & Tobacco Collecting_雪茄和煙草收集',
            'Cooking_烹飪',
            'Gambling_賭博',
            'Gardening_園藝',
            'Pets & Animals_寵物及動物',
            'Photo & Video_照片及影片',
            'Prizes & Competitions_獎項及比賽',
            'Recreational Activity Education_娛樂教育活動',
            'Scale Models & Model Building_比例模型與模型構建',
            'Toys & Games_玩具及遊戲',
            'Wine & Beer Collecting & Brewing_葡萄酒和啤酒收集和釀造',
            'Gardening/Trees, Plants & Shrubs_園藝',
            'Cooking/Cuisines/Asian Cuisine/Chinese Cuisine_烹飪',],
    },
    {
        category: 'Home & Garden_家居與園藝',
        subcategories: ['Bathroom_浴室',
            'Bedding & Linens_就寢用品',
            'Doorbells & Door Knockers_門鈴與門環',
            'Home & Garden Media & Publications_家居與園藝媒體和出版社',
            'Home Appliances_家電',
            'Home Decor & Interior Decorating_家居裝飾及室內裝飾',
            'Home Furniture_家具',
            'Home Heating & Cooling_家中暖氣及冷氣機',
            'Home Improvement & Maintenance_房屋裝修及保養',
            'Home Laundry_洗衣機',
            'Home Safety & Security_房屋安全',
            'Home Storage & Organization_家庭儲藏及組織',
            'Kitchen & Bathroom Cabinets_廚房和浴室櫃',
            'Kitchen & Dining_廚房及餐廳',
            'Lights & Lighting_燈具及照明',
            'Residential Cleaning_住宅清潔',
            'Watch & Clock Repair_鐘錶維修',
            'Water Filters_濾水器',
            'Yard, Garden & Patio_庭院，花園、露台',
            'Home Appliances/Home Appliance Service & Repair_家電維修',
            'Yard, Garden & Patio/Pest & Weed Control_除蟲清潔',
            'Residential Cleaning_居家清潔',
            'Home Decor & Interior Decorating/Candles & Incense_家用芳香劑',],
    },
    {
        category: 'Internet & Telecom_網路及電信',
        subcategories: ['Cable Services_有線電視服務',
            'Calling Cards_電話卡',
            'Internet_網路',
            'Satellite Services_衛星服務',
            'Teleconferencing_電話會議',
            'Telegrams_電報',
            'Telephony_功能性電話',
            'VOIP_網路電話',],
    },
    {
        category: 'Jobs & Education_工作與教育',
        subcategories: ['Alumni & Classmate Resources_校友與同學資源',
            'Education & Training_教育及培訓',
            'Jobs & Careers_工作與招聘訊息',
            'Education & Training/Study Abroad Programs_留學',
            'Education & Training/Language Education_語言補習班',],
    },
    {
        category: 'Law & Government_法律與政府',
        subcategories: ['Census Services_調查服務',
            'Emergency Services_緊急服務',
            'Government_政府',
            'Government Consulting & Contracting_政府諮詢與承包',
            'Law Enforcement & Protective Services_執法和防護服務',
            'Legal_法律',
            'Military_軍事',
            'Politics_政治',
            'Public Services_公共服務',
            'Law Enforcement & Protective Services/Private Investigation_徵信社',
            'Legal/Immigration Law/Work Permits & Work Visas_移民工作簽證',],
    },
    {
        category: 'News, Media & Publications_新聞、媒體和出版社',
        subcategories: ['Apparel Media & Publications_服裝媒體和出版社',
            'Books & Literature_書籍與文學',
            'Celebrities & Entertainment News_名人和娛樂新聞',
            'Health Media & Publications_健康媒體和出版社',
            'Local News, Media & Publications_地區新聞、媒體和出版社',
            'Magazines & Magazine Subscriptions_雜誌及雜誌訂閱',
            'Men\'s Interests Media & Publications_男性嗜好媒體和出版社',
            'Newspapers_報紙',
            'Online Media_網絡媒體',
            'Political News & Media_政治新聞與媒體',
            'Publishing_出版',
            'Reference Materials & Resources_參考資料及資源',
            'Teen Media & Publications_青少年媒體及出版社',
            'Vehicle Media & Publications_汽車媒體和出版社',
            'Weather_天氣',
            'Women\'s Interests Media & Publications_婦女權益媒體和出版社',
            'World News & Media_國際新聞及媒體',],
    },
    {
        category: 'Occasions & Gifts_慶典及禮品',
        subcategories: ['Cards & Greetings_賀卡與祝福',
            'Flower Arrangements_插花',
            'Gifts_禮物',
            'Holidays & Seasonal Events_假日與節慶',
            'Parties & Party Supplies_派對和聚會用品',
            'Singing Telegrams_歌唱電台',
            'Special Occasions_特別活動',
            'Special Occasions/Wedding & Special Occasion Photo & Video_婚攝',
            'Special Occasions/Funerals & Bereavement_禮儀社',
            'Special Occasions/Weddings/Wedding Beauty Services_新秘',
            'Flower Arrangements_花店',
            'Parties & Party Supplies/Paper Plates, Napkins & Disposable Cutlery_派對用品',],
    },
    {
        category: 'Real Estate_房地產',
        subcategories: ['Commercial & Investment Real Estate_商業及投資房地產',
            'Escrow & Real Estate Titling_託管與房地產產權登記',
            'House Plans & Floor Plans_房子平面圖和樓層平面圖',
            'Housing Market & Real Estate Prices_房地產市場與房地產價格',
            'Private Communities_私人社區',
            'Property Development_物產發展',
            'Property Inspections & Appraisals_物業檢查與評估',
            'Property Management_物業管理',
            'Real Estate Agents & Brokerages_房地產代理與經紀公司',
            'Real Estate Auctions_房地產拍賣會',
            'Real Estate Listings_房地產房產',
            'Relocation & Household Moving_搬遷及家庭搬家',
            'Vacation Properties & Second Homes_渡假勝地',],
    },
    {
        category: 'Retailers & General Merchandise_零售及百貨',
        subcategories: ['Coupons & Rebates_優惠券與折扣',
            'Informal Selling & Exchanging_非正統銷售及交換',
            'Rental Services_租賃服務',
            'Shopping Portals & Search Engines_購物管道與搜索引擎',
            'Wholesalers & Liquidators_批發商及清算人',],
    },
    {
        category: 'Sensitive Subjects_敏感主題',
        subcategories: ['Crime & Violence_犯罪與暴力',
            'Recreational Drugs_娛樂性藥物',
            'War & Conflict_戰爭與衝突',],
    },
    {
        category: 'Sports & Fitness_體育運動',
        subcategories: ['Boating & Water Recreation_划船和水上娛樂',
            'Fitness_健身',
            'Sporting Goods_體育用品',
            'Sports_運動',
            'Sports & Fitness Apparel_體育運動服裝',
            'Sports Equipment Rental Services_體育器材租賃服務',
            'Sports Fan Gear & Apparel_體育迷用品及服裝',
            'Sports Instruction & Coaching_體育教學與訓練',
            'Sports News & Media_體育新聞與媒體',
            'Sports Programs & Camps_體育課程和營隊',],
    },
    {
        category: 'Travel & Tourism_旅行和旅遊',
        subcategories: ['Accommodations_住宿',
            'Air Travel_航空旅行',
            'Cruises & Cruise Services_郵輪及郵輪服務',
            'Last Minute Travel_限時優惠旅程',
            'Luggage & Travel Accessories_行李箱及旅行用品',
            'Luggage Services_行李服務',
            'Luxury Travel_豪華旅遊',
            'Specialty Travel_特色旅遊',
            'Tour Operators_旅遊經營商',
            'Tourist Attractions & Destinations_旅遊景點與目的地',
            'Transportation & Excursions_交通運輸和短途遊覽',
            'Travel Booking Services_旅遊預訂服務',
            'Travel Documents_旅行證件',
            'Travel Media & Publications_旅遊媒體和出版物',
            'Vacation Packages_度假套票行程',
            'Transportation & Excursions/Car Rental Services_租車',],
    },
    {
        category: 'Vehicles_交通工具',
        subcategories: ['Boats & Watercraft_船及船舶',
            'Driving Instruction & Driver Education_駕駛訓練及駕駛教育',
            'Motor Vehicles_汽車',
            'Personal Airplanes & Aircraft_私人飛機',
            'Vehicle Auctions_車子拍賣',
            'Vehicle Dealers_汽車經銷商',
            'Vehicle Donation & Removal_車輛捐贈與搬運',
            'Vehicle Emissions_車輛排放',
            'Vehicle History Reports_車輛歷史報告',
            'Vehicle Parts & Accessories_汽車零配件',
            'Vehicle Repair & Maintenance_車輛維修及保養',
            'Vehicle Specs, Reviews & Comparisons_車輛規格、評論與比較',
            'Vehicle Towing_車輛拖吊',
            'Vehicle Warranties & Protection Plans_車輛保固計畫',
            'Vehicle Window Tinting_車窗著色',],
    },
]
let industryDescriptionList:any = [];
let descriptionId = 0;
categories.map((category)=> {
    category.subcategories.map((subcategory, index)=> {
        industryDescriptionList =
            [...industryDescriptionList, {id: descriptionId, col1: (index==0 ? category.category : ''), col2: subcategory}]
        descriptionId++;
    })
})

export const IndustryDescriptionButton = () => {
    return <DescriptionButton>
        <DataGridPro style={{minHeight: 500, maxWidth: 1000}} columns={[
            {field: 'col1', headerName: '產業類別', width: 200},
            {field: 'col2', headerName: '子分類', width: 700},
        ]} rows={industryDescriptionList} hideFooter={true}/></DescriptionButton>
}