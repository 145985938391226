import DetailView from "../custom/DetailView";
import {OrderItem, RoleIdEnum, useGetInvoiceQuery,} from "../../generated/graphql";
import {useParams} from "react-router";
import DefaultQueryHandler from "../../common/DefaultQueryHandler";
import {Box, Button} from "@material-ui/core";
import {CurrencyMap, InvoiceStatusMap} from "../../common/Constant";
import React from "react";
import UpdateInvoiceButton from "./UpdateInvoiceButton";
import {format} from "date-fns";
import {DataGridLocale} from "../../common/DataGridLocale";
import {HasRole, PasteToClipboard, saveFile} from "../../common/Util";
import {DataGridPro} from "@mui/x-data-grid-pro";
import NotificationPopup from "../../common/NotificationPopup";
import {getAccessToken} from "../../Auth";

const columns = [
    { field: "id", headerName: "ID" },
    {
        field: "date",
        headerName: "預期開立發票日期",
        valueFormatter: (value) => format(new Date(value), "yyyy-MM-dd"),
    },
    {
        field: "postDate",
        headerName: "實際發票日期",
        valueFormatter: (value) =>
            value ? format(new Date(value), "yyyy-MM-dd") : "尚未開立發票",
    },
    { field: "createdAt", headerName: "建立時間" },
    {
        field: "order",
        headerName: "專案名稱 - 委刊單號",
        valueFormatter: (value) => {
            return `${value.name} - ${value.number}`;
        },
    },
    {
        field: "status",
        headerName: "狀態",
        valueFormatter: (value) => {
            return InvoiceStatusMap[value];
        },
    },
    {
        field: "creditDate",
        headerName: "收款日期",
        valueFormatter: (value) =>
            value ? format(new Date(value), "yyyy-MM-dd") : "尚未收款",
    },
    { field: "expired", headerName: "逾期", color: "red",
        valueFormatter: (value) => {
            return value ? "已逾期" : "";
        }
    },
    {
        field: "invoiceNumber",
        headerName: "發票號碼",
        valueFormatter: (value) => {
            return value ? value : "尚未開立";
        },
    },
    {
        field: "currency",
        headerName: "幣別",
        valueFormatter: (value) => {
            return CurrencyMap[value];
        },
    },
    {
        field: "discountPercentage",
        headerName: "折讓",
        valueFormatter: (value) => {
            return value ? `${value}%` : "無";
        },
    },
    {
        field: "paidAmount",
        headerName: "收款金額",
        valueFormatter: (value) => {
            return value?.toLocaleString();
        },
    },
    { field: "description", headerName: "發票備註", color: "red"},
    { field: "note", headerName: "財務註記", color: "blue"},
];
export default () => {
    const { id } = useParams();
    const { loading, data, error } = useGetInvoiceQuery({ variables: { id } });
    let total = 0;
    data?.invoice!.items.map((invoiceItem) => {
        total += invoiceItem.amount;
    });
    let concatItems = data?.invoice?.items.concat([
        {
            id: "+",
            orderItem: { id: "總計", totalAmount: 123 },
            name: "未稅",
            amount: total,
            changedAmount: 0,
        },
        {
            id: "-",
            orderItem: { id: "" },
            name: "税",
            amount: Math.round(total * 0.05),
            changedAmount: 0,
        },
        {
            id: "All",
            orderItem: { id: "總計" },
            name: "含稅",
            amount: Math.round(total * 1.05),
            changedAmount: 0,
        },
    ]);
    if (data?.invoice?.totalAmount) {
        concatItems = concatItems?.concat([{
            id: "x",
            orderItem: { id: "", totalAmount: 123 },
            name: "實收台幣金額",
            amount: data?.invoice?.totalAmount,
            changedAmount: 0,
        }])
    }
    return (
        <DefaultQueryHandler error={error} loading={loading}>


            <UpdateInvoiceButton invoice={data?.invoice} />
            
            <DetailView columns={columns} data={data?.invoice} />
            <Box width={652} style={{ margin: 10 }}>
                <DataGridPro
                    autoHeight
                    hideFooter
                    disableExtendRowFullWidth
                    columns={[
                        {
                            field: "id",
                            headerName: "ID",
                            width: 100,
                            hide: true,
                        },
                        {
                            field: "orderItem",
                            headerName: "id",
                            width: 100,
                            valueFormatter: (o) => {
                                return (o.value as OrderItem).id;
                            },
                        },
                        { field: "name", headerName: "產品名稱", width: 250 },
                        {
                            field: "amount",
                            headerName: "金額",
                            width: 150,
                            valueFormatter: (o) => {
                                return o.value?.toLocaleString();
                            },
                            type: "number",
                        },
                        {
                            field: "changedAmount",
                            headerName: "異動金額",
                            width: 150,
                            valueFormatter: (o) => {
                                return o.value?.toLocaleString();
                            },
                            type: "number",
                        },
                    ]}
                    rows={concatItems || []}
                    localeText={DataGridLocale}
                    onCellClick={PasteToClipboard}
                />
            </Box>
        </DefaultQueryHandler>
    );
};
