import {listToMap} from "../../common/Constant";

export const getLogQueryMessage = (queryDTO, columns) => {
    let columnMap = {}
    columns.map((item) => {
        columnMap[item.name] = {
            label: item.label,
            options: item.options,
            isMulti: item.isMulti,
        }
    })
    return Object.keys(queryDTO).map(key=>{
        if (columnMap[key]&&(queryDTO[key]!=undefined)) {
            if (columnMap[key].isMulti) {
                const mapping = listToMap(columnMap[key].options);
                return `${columnMap[key].label}: [${queryDTO[key].map((value)=>mapping[value]).join(', ')}]`
            }
            else {
                return `${columnMap[key].label}: ${columnMap[key].options ? listToMap(columnMap[key].options)[queryDTO[key]] : queryDTO[key]}`
            }
        }
             else
            return null;
    }).filter(x => !!x).join(', ');
}