import {
    Button,
    ButtonGroup,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    TextField
} from "@material-ui/core";
import React, {useState} from "react";
import {format} from "date-fns";
import CreateView from "../custom/CreateView";
import {InputType} from "../../common/FormRenderer";
import {ProductOriginList} from "../../common/Constant";
import {map} from 'lodash';
import {getAccessToken} from "../../Auth";
import {saveAs} from 'file-saver';
import NotificationPopup from "../../common/NotificationPopup";
import {saveFile} from "../../common/Util";

//
export const download = async(toggle,urlPart,filename, showDate=true)=>
{
    const res = await fetch(`${process.env.REACT_APP_BACKEND_URI}/${urlPart}`,
        {
            headers:{
                Authorization:`Bearer ${getAccessToken()}`
            }
        });
    if(res.status === 200)
    {
        const blob = await res.blob();
        const name = `${filename}` + (showDate ? `-${format(new Date(),"yyyy-MM-dd")}` : '') + `.xlsx`;
        saveAs(blob, name);
    }
    else
    {
        const json = await res.json();
        NotificationPopup.error(`下載遇到問題. ${res.statusText} ${JSON.stringify(json)}`);
    }

};

const now = new Date();




export default ()=>
{

    return <div>

        <ButtonGroup disableElevation>
                <CreateView submitTitle={"下載報表"}  title={"下載發票報表"} columns={[
                    { name: "startDate", label: "回填日期開始",type:InputType.date },
                    { name: "endDate", label: "回填日期結束",type:InputType.date },
                    // { name: "productOrigin", label: "媒體別(原廠)",type:InputType.select,options:map(ProductOrigin,(val,key)=>({value:key,label:val})) },
                ]}
                            onSubmit={async(res,helpers,setOpen)=>
                            {
                                await download(()=>setOpen(false),
                                    `reports/invoice-items?startDate=${res.startDate}&endDate=${res.endDate}`,
                                    "invoice-items");
                            }}
                            queryEntity={"report"}
                />

                    <CreateView submitTitle={"下載報表"}  title={"下載委刊細項報表"} columns={[
                        { name: "startDate", label: "走期(起)",type:InputType.date },
                        { name: "endDate", label: "走期(迄)",type:InputType.date },
                        { name: "productOrigin", label: "媒體別(原廠)",type:InputType.select,
                            options:[{value:"all",label:"全選"}].concat(ProductOriginList) },
                    ]}
                                onSubmit={async(res,helpers,setOpen)=>
                                {
                                    await download(()=>setOpen(false),
                                        `reports/order-items?startDate=${res.startDate}&endDate=${res.endDate}&productOrigin=${res.productOrigin==='all'?'':res.productOrigin}`,
                                        "order-items");
                                }}
                                queryEntity={"report"}
                    />

                    <Button color={"primary"} variant={"contained"} onClick={()=>
                        saveFile(`/reports/orders`,
                            "委刊單報表.xlsx")
                    }>
                        下載發稿報表
                    </Button>

                    <CreateView submitTitle={"下載報表"}  title={"下載未開立發票報表"} columns={[
                        // { name: "startDate", label: "回填日期開始",type:InputType.date,value:format(now,'yyyy-MM-dd') },
                        { name: "endDate", label: "走其（迄）日期結束",type:InputType.date },
                    ]}
                                onSubmit={async(res,helpers,setOpen)=>
                                {
                                    await download(()=>setOpen(false),
                                        `reports/order-invoice?endDate=${res.endDate}`,
                                        "未開立發票報表");
                                }}
                                queryEntity={"report"}
                    />

        </ButtonGroup>
        </div>



}
