import { Box } from "@material-ui/core";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { DataGridLocale } from "../../common/DataGridLocale";
import { InputType } from "../../common/FormRenderer";
import NotificationPopup from "../../common/NotificationPopup";
import { PasteToClipboard } from "../../common/Util";
import ConfirmButton from "../../components/ConfirmButton";
import {
    useRemoveContactMutation,
    useUpdateContactMutation,
} from "../../generated/graphql";
import CreateView from "../custom/CreateView";
import {omit} from 'lodash';
import {GridColDef, GridValueFormatterParams, DataGridPro, GridRenderCellParams} from "@mui/x-data-grid-pro";

export default ({ contacts }) => {
    const [updateContact] = useUpdateContactMutation();
    const [removeContact] = useRemoveContactMutation();
    const onSubmit = useCallback(
        async (contact, { resetForm }, setOpen) => {
            try {
                await updateContact({
                    variables: {
                        contact:omit(contact,["id"]),
                        id: contact.id,
                    },
                    refetchQueries: ["getCustomer"],
                });
                NotificationPopup.success(`修改完成`);
                return contact;
            } catch (e) {
                NotificationPopup.error(
                    `Failed to update contact ${e.message}`
                );
                console.error(e);
            } finally {
                resetForm();
                setOpen(false);
            }
        },
        [updateContact]
    );
    const onRemove = (id) => {
        try {
            removeContact({
                variables: {
                    id: id,
                },
                refetchQueries: ["getCustomer"],
            });
            NotificationPopup.success(`刪除完成`);
        } catch (e) {
            NotificationPopup.error(`刪除發生問題: ${e.message}`);
            console.error(e);
        }
    };
    const columns: GridColDef[] = [
        { field: "name", headerName: "姓名", width: 150 },
        { field: "phone", headerName: "電話", width: 150 },
        { field: "email", headerName: "Email", width: 250 },
        {
            field: "test",
            headerName: "動作",
            width: 200,
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <div style={{ display: "flex" }}>
                        <CreateView
                            title={"修改"}
                            columns={[
                                {
                                    name: "id",
                                    label: "ID",
                                    value: params.row.id,
                                    type: InputType.hidden,
                                },
                                {
                                    name: "name",
                                    label: "姓名",
                                    value: params.row.name,
                                },
                                {
                                    name: "phone",
                                    label: "電話",
                                    value: params.row.phone,
                                },
                                {
                                    name: "email",
                                    label: "Email",
                                    value: params.row.email,
                                },
                            ]}
                            onSubmit={onSubmit}
                            submitTitle={"修改"}
                            queryEntity={"contact"}
                        />
                        &ensp;
                        <ConfirmButton
                            onConfirm={() => {
                                onRemove(params.row.id);
                            }}
                            queryEntity={"contact"}
                            queryAction={"刪除聯絡人"}
                            entityId={params.row.id}
                            queryMessage={`名稱: ${params.row.name}`}
                        >
                            刪除
                        </ConfirmButton>
                    </div>
                );
            },
        },
    ];
    return (
        <Box width={"100%"} style={{ marginTop: 20, height: 200 }}>
            <DataGridPro
                columns={columns}
                rows={contacts}
                hideFooter={true}
                autoHeight={true}
                localeText={DataGridLocale}
                onCellClick={PasteToClipboard}
            />
        </Box>
    );
};
