import React, {useCallback} from "react";
import NotificationPopup from "../../common/NotificationPopup";
import {useGetRolesQuery, useUpdateUserMutation} from "../../generated/graphql";
import {InputType} from "../../common/FormRenderer";
import {selectMapFn} from "../../common/Util";
import CreateView from "../custom/CreateView";
import {omit} from "lodash";

export default ({user}) => {
    const rolesQuery = useGetRolesQuery();
    const [updateUser] = useUpdateUserMutation();
    const onSubmit = useCallback(
        async (updatedUser, { resetForm }, setOpen) => {
            try {
                const query = await updateUser({
                    variables: {
                        user: omit(updatedUser,["id"]),
                        id: updatedUser.id,
                    },
                    refetchQueries: ["getUserList"],
                });
                NotificationPopup.success(`修改完成`);
                return query.data?.updateUser;
            } catch (e) {
                NotificationPopup.error(`修改使用者發生問題： ${e.message}`);
                console.error(e);
            }
            finally {
                resetForm();
                setOpen(false);
            }
        },
        [updateUser]
    );
    return (
        <CreateView
            title={"修改使用者"}
            columns={[
                {
                    name: "id",
                    label: "ID",
                    value: user.id,
                    type: InputType.hidden,
                },
                {
                    name: "name",
                    label: "姓名",
                    value: user.name,
                },
                {
                    name: "email",
                    label: "Email",
                    value: user.email,
                },
                {
                    name: "roleIds",
                    label: "角色",
                    type: InputType.select,
                    options: rolesQuery.loading
                        ? []
                        : rolesQuery.data?.roles.map(
                            selectMapFn
                        ),
                    isMulti: true,
                    value: user.roles.map((role) => {
                        return role.id;
                    }),
                },
            ]}
            onSubmit={onSubmit}
            submitTitle={"儲存"}
            queryEntity={"user"}
        />
    )
}