import {Grid} from "@material-ui/core";
import {StatusCell} from "../purchaseOrder/PurchaseOrderStatusView";
import {PurchaseOrderStatusEnum, UpgradeStatus} from "../../generated/graphql";

export default ({upgrade}) => {
    let statusInt = 0;
    let rejectInt = 0;
    if (upgrade.gm != null) {
        statusInt = 4;
    }
    else if (upgrade.manager != null) {
        statusInt = 3;
    }
    else if (upgrade.finance != null) {
        statusInt = 2;
    }
    else if (upgrade.ae != null) {
        statusInt = 1;
    }
    if (upgrade.status == UpgradeStatus.Rejected) {
        rejectInt = statusInt;
        statusInt -= 1;
    }
    return (
        <Grid container style={{marginTop: 20}}>
            <StatusCell name={upgrade.ae?.name} state={"送出申請"} isFinished={statusInt>0}/>
            <StatusCell name={upgrade.finance?.name} state={"財務審核"} isFinished={statusInt>1}/>
            <StatusCell name={upgrade.manager?.name} state={"主管審核"} isFinished={statusInt>2} isRejected={rejectInt==3}/>
            <StatusCell name={upgrade.gm?.name} state={"總經理審核"} isFinished={statusInt>3} isRejected={rejectInt==4}/>
        </Grid>
    )
}