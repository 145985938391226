import { useNavigate } from "react-router";
import { format } from "date-fns";
import {getAccessToken, useUser} from "../Auth";
import { saveAs } from "file-saver";
import React, { useEffect, useState } from "react";
import LinkButton from "../components/LinkButton";
import { useSearchParams } from "react-router-dom";
import {GridCellParams, GridValueFormatterParams, GridActionsCellItem} from '@mui/x-data-grid-pro';
import {Role, RoleIdEnum, User} from "../generated/graphql";
import NotificationPopup from "./NotificationPopup";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton} from "@material-ui/core";
import {AlertCircle, Eye} from "react-feather";
import {find} from "lodash";

export const selectMapFn = ({ id, name }: { id: any; name?: any }) => ({
    value: id,
    label: name,
});
export const labelize = (string: string | null) => {
    if (string == null) {
        return "";
    }
    if (typeof string === "number") {
        return string;
    }
    return capitalizeFirstLetter(
        string.replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, " $1")
    );
};

export const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const Clickable = ({ value, link }) => {
    const navigate = useNavigate();
    return (
        <div onClick={() => navigate(link)} style={{ width: "100%" }}>
            {value}
        </div>
    );
};

export const defaultLinkCellRenderer = (linkPart) => {
    return (params: GridCellParams) => {
        return (
            <Clickable
                value={params.value}
                link={`${linkPart}/${params.row.id}`}
            />
        );
    };
};

export const dateValueFormatter = (params: GridValueFormatterParams) => {

    return params?.value ? format(new Date(params?.value as string), "yyyy-MM-dd") : "";
};
export const datetimeValueFormatter = (params: GridValueFormatterParams) => {
    return format(new Date(params?.value as string), "yyyy-MM-dd HH:mm:SS");
};

export const saveFile = async (uriPart, filename) => {
    const token = await getAccessToken();
    const res = await fetch(`${process.env.REACT_APP_BACKEND_URI}${uriPart}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    if(!res.ok)
    {
        const json = await res.json();
        NotificationPopup.error(`下載遇到問題. ${json.message || JSON.stringify(json)}`);
        return;
    }
    const blob = await res.blob();
    saveAs(blob, filename);
};

export const saveFilePost = async (uriPart, filename,body) => {
    const token = await getAccessToken();
    const res = await fetch(`${process.env.REACT_APP_BACKEND_URI}${uriPart}`, {
        method:"POST",
        body:JSON.stringify(body),
        headers: {
            "Content-Type":"application/json",
            Authorization: `Bearer ${token}`,
        },
    });
    if(!res.ok)
    {
        const json = await res.json();
        NotificationPopup.error(`下載遇到問題. ${json.message || JSON.stringify(json)}`);
        return;
    }
    const blob = await res.blob();
    saveAs(blob, filename);
};

export const previewPdf = async (uriPart, fileName) => {
    const token = await getAccessToken();
    const res = await fetch(`${process.env.REACT_APP_BACKEND_URI}${uriPart}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    const blob = await res.blob();
    const file = new Blob([blob], {
        type: "application/pdf",
    });
    var fileURL = URL.createObjectURL(file);
    window.open(fileURL);
};

const formatter = new Intl.NumberFormat("zh-TW", { maximumFractionDigits: 0 });
export const formatCurrency = (value) => {
    return formatter.format(value);
};

export const formatDate = (date: Date | string): string => {
    if (typeof date === "string") {
        date = new Date(date);
    }
    if (date) {
        return format(date, "yyyy-MM-dd");
    } else {
        return "-";
    }
};

export const ViewColumn = (routePrefix?: null | string) => {
    // const navigate = useNavigate();
    return {
        type: "actions",
        headerName: "動作",
        field: "actions",
        width: 90,
        disableColumnMenu: true,
        disableColumnSelector: false,
        sortable: false,
        getActions: (params) => [
            <LinkButton
                uri={
                    routePrefix
                        ? `${routePrefix}/${params.row.id}`
                        : params.row.id
                }
            >
                <Eye/>
            </LinkButton>,
        ]
    };
};

export const PasteToClipboard = (param: GridCellParams) => {
    console.log(param)
    if (param.value) {
        navigator.clipboard.writeText(param.value.toLocaleString());
    }
};

export const useTabIndex = (): [number, (n: number) => void] => {
    const navigate = useNavigate();
    const [index, setIndex] = useState(0);
    const [searchParams, setSearchParams] = useSearchParams();
    useEffect(() => {
        setIndex(
            searchParams.get("index")
                ? parseInt(searchParams.get("index") as string)
                : 0
        );
    }, []);
    useEffect(() => {
        navigate({
            search: `?index=${index}`,
        }, {replace:true});
    }, [index])
    return [index, setIndex];
};

export const HasRole = ({roles,children}:{roles:RoleIdEnum[],children:any})=>
{
    const user = useUser();
    if (hasRole(user, roles))
    {
        return children;
    }
    else
    {
        return null;
    }
}

export function hasRole(user: Partial<User>|undefined, roles: RoleIdEnum[]) {
    return user && user.roles?.find(({id})=>roles.includes(id))
}

export const DescriptionButton = ({title = undefined, children}: { title?: string | undefined, children: any }) => {
    const [open, setOpen] = useState(false)
    return (<div>
        <Dialog maxWidth={"md"} open={open} fullWidth={true}>
            <DialogTitle>
                {title}
            </DialogTitle>
            <DialogContent>
                {children}
            </DialogContent>
            <DialogActions style={{justifyContent: "center"}}>
                <Button
                    variant={"contained"}
                    color={"primary"}
                    onClick={() => {
                        setOpen(false);
                    }}
                >
                    確認
                </Button>
            </DialogActions>
        </Dialog>

        <IconButton onClick={() => {
            setOpen(true)
        }}>
            <AlertCircle/>
        </IconButton>
    </div>)
}

export const getLevelName = (score) => {
    return find([
        {score: 251, name: "藍鑽級"},
        {score: 201, name: "紅寶石級"},
        {score: 151, name: "藍寶石級"},
        {score: 101, name: "黃寶石級"},
        {score: 1, name: "水晶級"},
    ],(level)=>{
        return score >= level.score;
    })?.name;
}