import React, {useState} from "react";
import {Button, Container, DialogTitle, Grid, Input, InputLabel, Typography, Dialog, DialogContent, FormControl, MenuItem, Select} from "@material-ui/core";
import Page from "../../components/Page";
import CreateOrderView from "./CreateOrderView";
import OrderTable from "./OrderTable";
import {Comparator, InvoiceStatus, OrderStatus, RoleIdEnum, useGetNotificationListQuery} from "../../generated/graphql";
import CreateView from "../custom/CreateView";
import {InputType} from "../../common/FormRenderer";
import {format} from "date-fns";
import {download as downloadReport} from "../report/DownloadableReports";
import {HasRole} from "../../common/Util";
import ImportAccountCostsButton from "../report/ImportAccountCostsButton";

export default () => {
    const notificationListQuery = useGetNotificationListQuery({
        variables: {
            ordersFiltering: [
                {
                    comparator: Comparator.Equals,
                    key: "status",
                    value: OrderStatus.Changed,
                },
            ],
            invoicesFiltering: [
                {
                    comparator: Comparator.Equals,
                    key: "status",
                    value: InvoiceStatus.Changed,
                },
            ],
        },
    });
    return (
        <Page title={"order page"}>
            <Container maxWidth={false} style={{padding: "30px"}}>
                <Grid container spacing={2}>
                    <CreateOrderView/>
                    &nbsp; &nbsp;
                    <HasRole roles={[RoleIdEnum.Admin, RoleIdEnum.Op]}>
                        <ImportAccountCostsButton/>
                    </HasRole>
                </Grid>
                <br/>
                <OrderTable
                    filterButtons={[
                        {
                            label: "已結案",
                            filter: {
                                items: [
                                    {
                                        columnField: "status",
                                        value: OrderStatus.Finished,
                                        operatorValue: "equals",
                                    },
                                ],
                            },
                        },
                        {
                            label: "異動中",
                            filter: {
                                items: [
                                    {
                                        columnField: "status",
                                        value: OrderStatus.Changed,
                                        operatorValue: "equals",
                                    },
                                ],
                            },
                            count: notificationListQuery.data?.orders.total,
                        },
                    ]}
                />
            </Container>
        </Page>
    );
};
