import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import {useUser} from "../Auth";
import {useCreateLogMutation} from "../generated/graphql";

export default ({ children, onConfirm,
                    entityId="0", queryAction='', queryEntity='', queryMessage='' }) => {
    const [open, setOpen] = useState(false);
    const user = useUser();
    const [createLog] = useCreateLogMutation();

    return (
        <div>
            <Dialog open={open} fullWidth={true}>
                <DialogTitle disableTypography={true}>
                    <Typography variant="h4" align="center">
                        {`確定要${children}嗎？`}
                    </Typography>
                </DialogTitle>
                <DialogActions>
                    <Button
                        variant={"contained"}
                        color={"primary"}
                        onClick={async () => {
                            setOpen(false);
                            onConfirm();
                            await createLog({
                                variables: {
                                    log: {
                                        entityId: entityId,
                                        userId: user!.id as string,
                                        queryAction: queryAction,
                                        queryEntity: queryEntity,
                                        queryMessage: queryMessage,
                                    }
                                }
                            });
                        }}
                    >
                        確定
                    </Button>
                    <Button
                        variant={"contained"}
                        color={"secondary"}
                        onClick={() => setOpen(false)}
                    >
                        取消
                    </Button>
                </DialogActions>
            </Dialog>
            <Button
                variant={"contained"}
                color={"secondary"}
                onClick={() => setOpen(true)}
            >
                {children}
            </Button>
        </div>
    );
};
