import { Box, Button } from "@material-ui/core";
import {
    DataGridPro,
    GridColDef,
    GridValueFormatterParams,
    GridCellParams,
    GridValueGetterParams, GridRenderCellParams,
} from "@mui/x-data-grid-pro";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { DataGridLocale } from "../../common/DataGridLocale";
import { PasteToClipboard } from "../../common/Util";
import { Supplier } from "../../generated/graphql";
import ContactView from "../../layouts/PopupLayout/ContactView";

export default ({ orderItem }) => {
    const navigate = useNavigate();
    const columns: GridColDef[] = [
        { field: "id", headerName: "ID", width: 50, type: "number" },
        {
            field: "supplier",
            headerName: "供應商",
            valueFormatter: (o) => {
                return (o.value as Supplier).name;
            },
            width: 150,
        },
        {
            field: "contact.name",
            headerName: "聯絡人",
            renderCell: (params: GridRenderCellParams) => {
                console.log(params);
                return <ContactView contact={params.row.contact} />;
            },
            width: 120,
        },
        { field: "amount", headerName: "付款單金額", width: 150, type: "number" },
        {
            field: "signedDate",
            headerName: "簽約日期",
            width: 150,
            valueGetter: (params: GridValueGetterParams) => {
                return params.row.signedDate
                    ? format(new Date(params.row.signedDate as string), "yyyy-MM-dd")
                    : "未指定";
            },
            type: "date",
        },
        {
            field: "paidDate",
            headerName: "付款日期",
            width: 150,
            valueGetter: (params: GridValueGetterParams) => {
                return params.row.paidDate
                    ? format(new Date(params.row.paidDate as string), "yyyy-MM-dd")
                    : "未指定";
            },
            type: "date",
        },
        {
            headerName: "動作",
            field: "createdAt",
            disableColumnMenu: true,
            sortable: false,
            renderCell: (params: GridCellParams) => {
                return (
                    <Button
                        variant={"contained"}
                        color={"primary"}
                        onClick={() => {
                            navigate(`/app/purchaseOrder/${params.row.id}`);
                        }}
                    >
                        {"查看"}
                    </Button>
                );
            },
        },
    ];
    return (
        <Box width={"100%"} style={{ height: "60vh" }}>
            <DataGridPro
                columns={columns}
                rows={orderItem.purchaseOrders}
                hideFooter={true}
                autoHeight={true}
                localeText={DataGridLocale}
                onCellClick={PasteToClipboard}
            />
        </Box>
    );
};
