import format from "date-fns/format";
import { useCallback, useState } from "react";
import { InputType } from "../../common/FormRenderer";
import NotificationPopup from "../../common/NotificationPopup";
import { selectMapFn } from "../../common/Util";
import { useCreatePurchaseOrderMutation, useGetProductQuery } from "../../generated/graphql";
import CreateView from "../custom/CreateView";


export default ({ orderItem }) => {
    const [createPurchaseOrder] = useCreatePurchaseOrderMutation();
    const { data, loading, error } = useGetProductQuery({
        variables: {
            id: orderItem.product.id,
        },
    });
    const [contacts, setContacts] = useState([]);

    const onSubmit = useCallback(
        async (purchaseOrder, { resetForm }, setOpen) => {
            try {
                const query = await createPurchaseOrder({
                    variables: {
                        purchaseOrder: {
                            orderItemId: orderItem.id,
                            supplierId: purchaseOrder.supplierId,
                            amount: purchaseOrder.amount,
                            signedDate: purchaseOrder.signedDate,
                            paidDate: purchaseOrder.paidDate,
                            contactId: purchaseOrder.contactId,
                            note: purchaseOrder.note,
                        },
                    },
                    refetchQueries: ["getOrder", "getOrderItem"],
                });
                NotificationPopup.success(`建立完成`);
                resetForm();
                return query.data?.createPurchaseOrder;
            } catch (e) {
                NotificationPopup.error(`新增付款單發生問題: ${e.message}`);
                console.error(e);
            } finally {
                setOpen(false);
            }
        }
        ,[createPurchaseOrder]
    );
    return (
        <CreateView
            title={"新增付款單"}
            columns={[
                {
                    name: "supplierId",
                    label: "供應商",
                    type: InputType.autoComplete,
                    onChange: (supplierId) => {
                        data?.product.suppliers!.map((supplier: any) => {
                            if (supplierId == supplier.id) {
                                setContacts(supplier!.contacts)
                            }
                        })
                    },
                    options: data ? data.product.suppliers!.map(selectMapFn) : [],
                    required: true,
                },
                {
                    name: "contactId", 
                    label: "聯絡人",
                    type: InputType.select, 
                    options: contacts.map(selectMapFn),
                },
                { name: "amount", label: "付款單金額", type: InputType.number },
                {
                    name: "signedDate",
                    label: "簽約日期",
                    type: InputType.date,
                    value: format(new Date(), "yyyy-MM-dd"),
                },
                {
                    name: "paidDate",
                    label: "付款日期",
                    type: InputType.date,
                    value: format(new Date(), "yyyy-MM-dd"),
                },
                { name: "note", label: "備註"},
            ]}
            onSubmit={onSubmit}
            queryEntity={"purchaseOrder"}
        />
    );
}
