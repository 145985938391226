import { format } from "date-fns";
import React, { useCallback } from "react";
import { InputType } from "../../common/FormRenderer";
import NotificationPopup from "../../common/NotificationPopup";
import { useUpdateInvoiceMutation } from "../../generated/graphql";
import CreateView from "../custom/CreateView";


export default ({ invoice }) => {
    const [updateInvoice] = useUpdateInvoiceMutation();
    const onSubmit = useCallback(
        async ({ date, description },
            { resetForm },
            setOpen) => {
            try {
                const query = await updateInvoice({
                    variables: {
                        invoice: {
                            date,
                            description,
                        },
                        id: invoice.id,
                    },
                    refetchQueries: ["getOrder"],
                });
                NotificationPopup.success(`修改日期完成`);
                return query.data?.updateInvoice;
            } catch (e) {
                NotificationPopup.error(`修改日期發生問題: ${e.message}`);
                console.error(e);
            } finally {
                resetForm();
                setOpen(false);
            }
        },
        [],
    )
    return (
        <CreateView
            title={"修改"}
            columns={[
                {
                    name: "date",
                    label: "開立發票日期",
                    type: InputType.date,
                    value: format(new Date(invoice.date), "yyyy-MM-dd"),
                },
                {
                    name: "description",
                    label: "發票備註",
                    value: invoice.description,
                }
            ]}
            onSubmit={onSubmit}
            submitTitle={"修改"}
            queryEntity={"invoice"}
        />
    );
};
