import {Button, IconButton} from "@material-ui/core";
import React from "react";
import { useNavigate } from "react-router";
import {Eye} from "react-feather";

export default ({uri,children}) => {
    const navigate = useNavigate();
    return (
        <IconButton
            onClick={() => {
                navigate(uri.toString());
            }}
        >
            {children}
        </IconButton>
    );
};
