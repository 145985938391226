import React, {useCallback, useEffect, useState} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormControl, Grid, InputLabel,
    MenuItem,
    Select,
    TextField
} from "@material-ui/core";
import {
    useCreateRebateMutation,
    useGetProductListQuery,
    useRemoveRebateMutation,
    useUpdateRebateMutation
} from "../../generated/graphql";
import NotificationPopup from "../../common/NotificationPopup";
import {format} from "date-fns";
import {filter, omit, pick} from "lodash";
import ConfirmButton from "../../components/ConfirmButton";
import {OrderItemTypeList} from "../../common/Constant";
import {Autocomplete} from "@material-ui/lab";

const newRebate = {
    startDate: format(new Date(), "yyyy-MM-dd"),
    endDate: format(new Date(), "yyyy-MM-dd"),
    products: [],
    intervals: [{}],
};
export default ({customer, initRebate=null, classes}: any) => {
    const [open, setOpen] = useState(false);
    const defaultRebate = initRebate ?
        {
            type: initRebate.type,
            startDate: format(new Date(initRebate.startDate), "yyyy-MM-dd"),
            endDate: format(new Date(initRebate.endDate), "yyyy-MM-dd"),
            products: initRebate.products,
            intervals: initRebate.intervals.map((interval)=>pick(interval, ["lowerBound", "upperBound", "rate"]))
        }
        : newRebate;
    const [rebate, setRebate] = useState<any>(defaultRebate);
    const { loading, data, error } = useGetProductListQuery();
    const [createRebate] = useCreateRebateMutation();
    const [updateRebate] = useUpdateRebateMutation();
    const [removeRebate] = useRemoveRebateMutation();
    useEffect(()=>
    {
        setRebate(defaultRebate);
    },[initRebate]);
    const onSubmit = useCallback(async (doCreate=false) => {
        try {
            let rebateDto = rebate;
            if ("products" in rebateDto) {
                rebateDto = {...omit(rebate, "products"), productIds: rebateDto.products.map((o)=>o.id)}
            }
            if (doCreate) {
                await createRebate({
                    variables: {
                        rebate: {...rebateDto, customerId: customer.id}
                    },
                    refetchQueries: ["getCustomer"],
                });
                setRebate(defaultRebate);
            }
            else {
                await updateRebate({
                    variables: {
                        id: initRebate.id,
                        rebate: rebateDto,
                    },
                    refetchQueries: ["getCustomer"],
                });
            }
        } catch (e) {
            NotificationPopup.error(`新增退佣發生問題: ${e.message}`);
            console.error(e);
        } finally {
            setOpen(false);
        }
    }, [rebate]);
    const onRemove = useCallback(async () => {
        try {
            await removeRebate({
                variables: {
                    id: initRebate.id,
                },
                refetchQueries: ["getCustomer"],
            });
            NotificationPopup.success(`刪除完成`);
        } catch (e) {
            NotificationPopup.error(`刪除退佣發生問題: ${e.message}`);
            console.error(e);
        } finally {
            setOpen(false);
        }
    }, [])
    const title = initRebate ? "修改" : "新增退佣";
    return (
        <div>
            <Dialog
                open={open}
                fullWidth={true}
                maxWidth={"sm"}
                onClose={() => {
                    setOpen(false);
                }}
            >
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <Grid container alignItems={"center"}>
                        <div style={{width: "100%"}}>
                            {data?.products &&
                            <FormControl style={{width: "100%", margin: 10}}>
                                <Autocomplete
                                    multiple
                                    id="product-autocomplete"
                                    options={data.products}
                                    value={rebate.products}
                                    onChange={(event, newValue) => {
                                        setRebate({...rebate, products: newValue});
                                        event.stopPropagation();
                                    }}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="產品"
                                            style={{ width: "100%", margin: 10 }}
                                        />
                                    )}
                                />
                                {/*<InputLabel>產品</InputLabel>*/}
                                {/*<Select*/}
                                {/*    style={{width: "100%"}}*/}
                                {/*    multiple*/}
                                {/*    label="產品"*/}
                                {/*    value={rebate.productIds}*/}
                                {/*    onChange={(e) => {*/}
                                {/*        console.log(e.target.value)*/}
                                {/*        setRebate({...rebate, productIds: e.target.value});*/}
                                {/*        e.stopPropagation();*/}
                                {/*    }}*/}
                                {/*>*/}
                                {/*    {data.products.map((product) => {*/}
                                {/*        return (*/}
                                {/*            <MenuItem value={product.id} key={product.id}>*/}
                                {/*                {product.name}*/}
                                {/*            </MenuItem>*/}
                                {/*        );*/}
                                {/*    })}*/}
                                {/*</Select>*/}
                            </FormControl>}
                        </div>
                        <div style={{width: "100%"}}>
                            <FormControl style={{width: "100%", margin: 10}}>
                                <InputLabel>操作方式</InputLabel>
                                <Select
                                    style={{width: "100%"}}
                                    label="操作方式"
                                    value={rebate.type}
                                    onChange={(e) => {
                                        setRebate({...rebate, type: e.target.value});
                                        e.stopPropagation();
                                    }}
                                >
                                    {OrderItemTypeList.map(({value, label}) => {
                                        return (
                                            <MenuItem value={value} key={value}>
                                                {label}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </div>
                        <Grid item style={{display: "flex", width: "100%", margin: 10}}>
                            <TextField
                                label="開始日期"
                                type="date"
                                value={rebate.startDate}
                                onChange={(e) => {
                                    setRebate({...rebate, startDate: e.target.value});
                                    e.stopPropagation();
                                }}
                            />
                            <div style={{ flex: "1 0 0" }} />
                            <TextField
                                label="結束日期"
                                type="date"
                                value={rebate.endDate}
                                onChange={(e) => {
                                    setRebate({...rebate, endDate: e.target.value});
                                }}
                            />

                        </Grid>
                        <Grid item style={{display: "flex", width: "100%", margin: 10}} alignItems={"center"}>
                            ***上方有較大優先權***
                            <div style={{ flex: "1 0 0" }} />
                            <Button
                                variant={"contained"}
                                color={"primary"}
                                onClick={()=>{
                                    setRebate({...rebate, intervals: [...rebate.intervals, {}]});
                                }}
                            >
                                新增範圍
                            </Button>
                        </Grid>

                        {rebate.intervals?.map((interval, index)=>{
                            const getTextField = (label, key) => {
                                return <TextField
                                    className={`without-padding`}
                                    variant="outlined"
                                    type={"number"}
                                    label={label}
                                    value={interval[key]}
                                    style={{margin: 10}}
                                    onChange={({target})=>{
                                        setRebate({...rebate, intervals: rebate.intervals?.map((interval, intervalIndex)=>{
                                                const value = parseFloat(target.value);
                                                if (target.value.toString().split(".")[1]?.length >= 2) {
                                                    return interval;
                                                }
                                                else {
                                                    return index == intervalIndex ? {...interval, [key]: value} : interval;
                                                }
                                            })});
                                    }}/>
                            }
                            return <Grid container alignItems={"center"}>
                                {getTextField("最低", "lowerBound")} ~
                                {getTextField("最高", "upperBound")}
                                {getTextField("退佣%數", "rate")}

                                <Button
                                    style={{marginLeft: 50}}
                                    variant={"contained"}
                                    color={"secondary"}
                                    onClick={()=>{
                                        setRebate({...rebate, intervals: filter(rebate.intervals, (interval, intervalIndex)=>index!=intervalIndex)})
                                    }}
                                >
                                    刪除
                                </Button>
                            </Grid>
                        })}
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <ConfirmButton
                        onConfirm={onRemove}
                    >
                        刪除
                    </ConfirmButton>
                    <ConfirmButton
                        onConfirm={async () => {
                            await onSubmit(true);
                        }}
                    >
                        複製
                    </ConfirmButton>
                    <div style={{ flex: "1 0 0" }} />
                    <Button
                        variant={"contained"}
                        color={"primary"}
                        onClick={async () => {
                            await onSubmit(initRebate==null);
                        }}
                    >
                        送出
                    </Button>
                    <Button
                        variant={"contained"}
                        color={"secondary"}
                        onClick={() => {
                            setOpen(false);
                            setRebate(defaultRebate);
                        }}
                    >
                        取消
                    </Button>
                </DialogActions>
            </Dialog>
            <Button
                variant={"contained"}
                color={"secondary"}
                onClick={() => setOpen(true)}
                style={{marginBottom: 15}}
            >
                {title}
            </Button>
        </div>
    )

}