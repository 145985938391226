import {Box, Button} from "@material-ui/core";
import React from "react";
import PaginatedDatagrid, { FilterButton } from "../../components/PaginatedDatagrid";
import { loader } from "graphql.macro";
import {GridCellParams, GridFilterItem} from "@mui/x-data-grid-pro";
import {datetimeValueFormatter, dateValueFormatter} from "../../common/Util";
import {useNavigate} from "react-router-dom";
import {RoutesList} from "../../layouts/DashboardLayout/NavBar";
const GetLogList = loader("./graphql/GetLogList.graphql");

export default ({defaultFilterItems, filterButtons, refetchState}:
                    {defaultFilterItems?: GridFilterItem[], filterButtons?: FilterButton[], refetchState?: any}) => {
    const navigate = useNavigate();
    return (
        <Box width={"100%"}>
            <PaginatedDatagrid
                id={'log'}
                refetchState={refetchState}
                defaultFilterItems={defaultFilterItems}
                filterButtons={filterButtons}
                query={GetLogList}
                ordering={{ field: "id", sort: "desc" }}
                columns={[
                    {
                        headerName: "ID",
                        field: "id",
                        type: "number",
                    },
                    {
                        headerName: "日期時間",
                        field: "createdAt",
                        type: "dateTime",
                        valueFormatter: datetimeValueFormatter,
                    },
                    {
                        headerName: "使用者",
                        field: "user.name",
                    },
                    {
                        headerName: "動作",
                        field: "queryAction",
                    },
                    // {
                    //     headerName: "項目",
                    //     field: "queryEntity",
                    // },
                    {
                        headerName: "項目ID",
                        field: "entityId",
                        type: "number",
                        renderCell: (params: GridCellParams) => {
                            if (RoutesList.includes(`/app/${params.row.queryEntity}`)) {
                                return (
                                    <Button
                                        variant={"contained"}
                                        color={"primary"}
                                        onClick={() => {
                                            navigate(`/app/${params.row.queryEntity}/${params.row.entityId}`);
                                        }}
                                    >
                                        {params.row.entityId}
                                    </Button>
                                );
                            }
                            else {
                                return <>{params.row.entityId}</>
                            }
                        },
                    },
                    {
                        headerName: "細節",
                        field: "queryMessage",
                    },
                ]}
            />
        </Box>
    );
};
