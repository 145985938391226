import {Box, MenuItem, Select} from "@material-ui/core";
import {DataGridLocale} from "../../common/DataGridLocale";
import {dateValueFormatter, PasteToClipboard} from "../../common/Util";
import {DataGridPro, GridCellParams, GridColDef, GridValueGetterParams} from "@mui/x-data-grid-pro";
import React from "react";
import {join} from "lodash";
import {format} from "date-fns";
import UpdateDeposit from "../deposit/UpdateDeposit";
import CreateRebateButton from "./CreateRebateButton";
import {OrderItemTypeMap} from "../../common/Constant";

export default ({customer}) => {
    const columns = [
        { field: "products", headerName: "產品", width: 400, valueGetter: params => join(params.row.products.map((product)=>product.name), ",") },
        {
            field: "type",
            headerName: "操作方式",
            valueGetter: (params: GridValueGetterParams) => {
                return OrderItemTypeMap[params.row.type as string];
            },
        },
        {
            field: "startDate",
            headerName: "開始日期",
            width: 120,
            valueFormatter: dateValueFormatter,
        },
        {
            field: "endDate",
            headerName: "結束日期",
            width: 120,
            valueFormatter: dateValueFormatter,
        },
        {
            field: "actions",
            headerName: "動作",
            disableColumnMenu: true,
            sortable: false,
            renderCell: (params: GridCellParams) => {

                return <CreateRebateButton customer={customer} initRebate={params.row}/>;
            },
        }
        // {
        //     field: "intervals",
        //     headerName: "級距",
        //     renderCell: (param) => {
        //         const intervals = param.row.intervals;
        //         return <div>
        //             {intervals.map((interval)=>{
        //                 return <div>{interval.lowerBound ?? 0} ~ {interval.upperBound ?? "以上"}</div>
        //             })}
        //         </div>
        //     },
        // },
    ];
    return <Box width={"100%"} style={{ marginTop: 20, height: 200 }}>
        <DataGridPro
            columns={columns}
            rows={customer.rebates ?? []}
            hideFooter={true}
            autoHeight={true}
            localeText={DataGridLocale}
            onCellClick={PasteToClipboard}
            rowHeight={100}
        />
    </Box>
}