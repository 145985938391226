import React, {useState} from "react";
import {Button, Dialog, DialogContent} from "@material-ui/core";
import {InputType} from "../../common/FormRenderer";
import {download as downloadReport} from "../report/DownloadableReports";
import CreateView from "../custom/CreateView";
import {useUpdateCustomerMutation} from "../../generated/graphql";
import NotificationPopup from "../../common/NotificationPopup";
import {saveFile} from "../../common/Util";

export default ({ customer }) => {
    const [open, setOpen] = useState(false);
    const [updateCustomer] = useUpdateCustomerMutation();
    return (
        <CreateView
            submitTitle={"下載合約"}
            title={"下載合約"}
            columns={[
                {
                    name: "contractStartDate",
                    label: "走期(起)",
                    type: InputType.date,
                    required: true,
                },
                {
                    name: "contractEndDate",
                    label: "走期(迄)",
                    type: InputType.date,
                    required: true,
                },
                {
                    name: "agent",
                    label: "簽約代表人",
                    required: true,
                }
            ]}
            onSubmit={async (res, helpers, setOpen) => {
                try {
                    await updateCustomer({
                        variables: {
                            customer: {
                                contractStartDate: res.contractStartDate,
                                contractEndDate: res.contractEndDate,
                            },
                            id: customer.id,
                        },
                        refetchQueries: ["getCustomer", "getCustomerList"],
                    });
                    await saveFile(`/customer/contract/${customer.id}?agent=${res.agent}`,
                        `${customer.name}-主約.pdf`
                    );
                }
                catch (e) {
                    NotificationPopup.error(`下載合約發生問題: ${e.message}`);
                    console.error(e);
                }
                finally {
                    setOpen(false);
                }
            }}
            queryEntity={""}
        />
    )
}
