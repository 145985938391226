import React from "react";
import {Container, Grid} from "@material-ui/core";
import Page from "../../components/Page";
import PurchaseOrderTable from "./PurchaseOrderTable";
import {
    Comparator,
    InvoiceStatus,
    OrderStatus,
    PurchaseOrderStatusEnum,
    RoleIdEnum,
    useGetNotificationListQuery, useGetPurchaseOrderNotificationQuery
} from "../../generated/graphql";
import {useUser} from "../../Auth";
import {hasRole} from "../../common/Util";
import DefaultQueryHandler from "../../common/DefaultQueryHandler";

export const getFilterQueryVariables = (purchaseOrderStatus: PurchaseOrderStatusEnum) => {
    return {
        variables: {
            purchaseOrdersFiltering: [
                {
                    comparator: Comparator.Equals,
                    key: "status",
                    value: purchaseOrderStatus,
                }
            ]
        },
    }
}
export default () => {
    const user = useUser();
    let filterButtons: any = [
        {
            label: "草稿",
            filter: {
                items: [
                    {
                        columnField: "status",
                        value: PurchaseOrderStatusEnum.Draft,
                        operatorValue: "equals",
                    },
                ],
            },
        }
    ]
    const managerReviewNotificationListQuery = useGetPurchaseOrderNotificationQuery(
        getFilterQueryVariables(PurchaseOrderStatusEnum.ManagerReview)
    );
    const gmReviewNotificationListQuery = useGetPurchaseOrderNotificationQuery(
        getFilterQueryVariables(PurchaseOrderStatusEnum.GmReview)
    );
    const financeReviewNotificationListQuery = useGetPurchaseOrderNotificationQuery(
        getFilterQueryVariables(PurchaseOrderStatusEnum.FinanceReview)
    );
    const unpaidNotificationListQuery = useGetPurchaseOrderNotificationQuery(
        getFilterQueryVariables(PurchaseOrderStatusEnum.Unpaid)
    );
    if (hasRole(user, [RoleIdEnum.AeManager, RoleIdEnum.AmManager])) {
        filterButtons = filterButtons.concat([{
            label: "等待主管審核",
            filter: {
                items: [
                    {
                        columnField: "status",
                        value: PurchaseOrderStatusEnum.ManagerReview,
                        operatorValue: "equals",
                    },
                ],
            },
            count: managerReviewNotificationListQuery.data?.purchaseOrders.total,
        }])
    }
    if (hasRole(user, [RoleIdEnum.Gm])) {
        filterButtons = filterButtons.concat([{
            label: "等待總經理審核",
            filter: {
                items: [
                    {
                        columnField: "status",
                        value: PurchaseOrderStatusEnum.GmReview,
                        operatorValue: "equals",
                    },
                ],
            },
            count: gmReviewNotificationListQuery.data?.purchaseOrders.total,
        }])
    }
    if (hasRole(user, [RoleIdEnum.Finance])) {
        filterButtons = filterButtons.concat([{
            label: "等待財務審核",
            filter: {
                items: [
                    {
                        columnField: "status",
                        value: PurchaseOrderStatusEnum.FinanceReview,
                        operatorValue: "equals",
                    },
                ],
            },
            count: financeReviewNotificationListQuery.data?.purchaseOrders.total,
        }, {
            label: "待付款",
            filter: {
                items: [
                    {
                        columnField: "status",
                        value: PurchaseOrderStatusEnum.Unpaid,
                        operatorValue: "equals",
                    },
                ],
            },
            count: unpaidNotificationListQuery.data?.purchaseOrders.total,
        }])
    }

    return (
        <Page title={"purchase order page"}>
            <Container maxWidth={false} style={{padding: "30px"}}>
                <Grid container spacing={2}>
                    <DefaultQueryHandler loading={managerReviewNotificationListQuery.loading}
                                         error={managerReviewNotificationListQuery.error}>
                        <PurchaseOrderTable
                            filterButtons={filterButtons}
                        />
                    </DefaultQueryHandler>
                </Grid>
            </Container>
        </Page>
    );
};
