import CreateView from "../../custom/CreateView";
import React, {useCallback, useState} from "react";
import {useCampaignsQuery, useGetAdsAccountsQuery} from "../../../generated/graphql";
import {InputType} from "../../../common/FormRenderer";
import {find} from "lodash";

export default ({onSelectAccount}) => {
    const [selectedAccount, setSelectedAccount] = useState<any>();
    const { data:accounts, loading, error } = useGetAdsAccountsQuery();
    const accountColumns = [
        {
            name: "adsAccount",
            label: "廣告帳戶",
            type: InputType.autoComplete,
            options: accounts?.adsAccounts?.map((account)=>{
                return {value: account.id, label: `${account.id}-${account.name}`};
            }),
            onChange: (d) => {
                setSelectedAccount(find(accounts?.adsAccounts ?? [], (c)=>{
                    return c.id == d;
                }));
            },
        },
    ];
    const onSubmit = useCallback(
        async (account, { resetForm }, setOpen) => {
            onSelectAccount(selectedAccount);
            setOpen(false);
        },[selectedAccount, onSelectAccount]
    );

    return <CreateView
        title={"新增廣告帳戶"}
        columns={accountColumns}
        onSubmit={onSubmit}
        queryEntity={"accountAds"}
    />
};