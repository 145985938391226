import React, {useCallback, useState} from "react";
import Page from "../../components/Page";
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Container, FormControl,
    Grid, InputLabel, MenuItem,
    Select,
    TextField
} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import {CustomerTypeEnum, useGetProductListQuery, useGetUserListQuery} from "../../generated/graphql";
import {saveFile, saveFilePost, selectMapFn} from "../../common/Util";
import NotificationPopup from "../../common/NotificationPopup";
import {map} from "lodash";
import {CustomerTypeList, OrderItemTypeList, ProductOriginList} from "../../common/Constant";

export default () => {
    const productListQuery = useGetProductListQuery();
    const { loading, data, error } = useGetProductListQuery();
    const [reportInput, setReportInput] = useState<any>({})
    const [forecastInput, setForecastInput] = useState({})
    const userListQuery = useGetUserListQuery();
    const inputProps: any = {
        inputProps: {style: {textAlign: "center"}}, style: {margin: 10},
        variant: "outlined",
        onChange: ({target}) => {
            setReportInput({...reportInput, [target.id]: target.value})
        }
    }
    const forecastInputProps: any = {
        onChange: ({target}) => {
            setForecastInput({...forecastInput, [target.id]: target.value})
        }
    }
    const downloadReport = useCallback(async (reportInput) => {
        try {
            const {customerType, durationTo, durationFrom, costTo, costFrom, productOrigins} = reportInput
            await saveFilePost(`/reports/order-items-revenue`,
                "產業報表細項.xlsx", {
                    startDate: durationFrom,
                    endDate: durationTo,
                    amountMin: costFrom,
                    amountMax: costTo,
                    productOrigins,
                    customerType
                });
        } catch (e) {
            NotificationPopup.error(`遇到問題 ${e.message}`);
        }
    }, []);

    const downloadAggregateReport = useCallback(async (reportInput) => {
        try {
            const {customerType, durationTo, durationFrom, costTo, costFrom, productOrigins} = reportInput
            await saveFilePost(`/reports/order-items-revenue-by-industry`,
                "產業加總表.xlsx", {
                    startDate: durationFrom,
                    endDate: durationTo,
                    amountMin: costFrom,
                    amountMax: costTo,
                    productOrigins,
                    customerType
                });
        } catch (e) {
            NotificationPopup.error(`遇到問題 ${e.message}`);
        }
    }, []);
    const downloadForecastReport = useCallback(async (forecastInput, v2=false)=> {
        try {
            const route = v2 ? "/order-items/forecast-order-items-v2" : "/order-items/forecast-order-items";
            const {startDate, endDate, productIds, customerName, customerType, orderItemType
                , productOrigin, assignedSalesUserId, assigneeId} = forecastInput;
            let url = `${route}?startDate=${startDate}&endDate=${endDate}`;
            if (startDate==undefined || endDate==undefined) {
                NotificationPopup.error(`請選擇起迄日期。`);
                return;
            }
            if (productIds?.length>0) {
                url += `&products=${productIds.join(',')}`;
            }
            if (customerName) {
                url += `&customerName=${customerName}`;
            }
            if (customerType) {
                url += `&customerType=${customerType}`;
            }
            if (orderItemType) {
                url += `&orderItemType=${orderItemType}`;
            }
            if (productOrigin) {
                url += `&productOrigin=${productOrigin.toLowerCase()}`;
            }
            if (assignedSalesUserId) {
                url += `&assignedSalesUserId=${assignedSalesUserId}`;
            }
            if (assigneeId) {
                url += `&assigneeId=${assigneeId}`;
            }
            await saveFile(url, `forecast-${startDate}-${endDate}`);
        }
        catch (e) {
            NotificationPopup.error(`遇到問題 ${e.message}`);
        }
    }, [])
    return (
        <Page title={"industry report page"}>
            <Container maxWidth={false} style={{padding: "30px"}}>
                <Box display="inline-block">
                    <Card>
                        <CardHeader title={"產業報表"}/>
                        <CardContent>
                            <FormControl style={{width: "100%"}}>
                                <InputLabel>選擇客戶類型</InputLabel>
                                <Select
                                    label={"選擇客戶類型"}
                                    defaultValue={[]}
                                    onChange={(event) => {
                                        // console.log(event.target.value)
                                        setReportInput({...reportInput, "customerType": event.target.value})
                                    }}
                                >
                                    {CustomerTypeList.map(({value, label}) => {
                                        return <MenuItem value={value}>{label}</MenuItem>
                                    })}
                                    <MenuItem value={"all"}>全部</MenuItem>
                                </Select>
                            </FormControl>
                            <Grid container direction={"column"}>
                                <Grid container direction={"row"} alignItems={"center"}>
                                    花費從
                                    <TextField {...inputProps} id={"costFrom"} type="number"></TextField>
                                    至
                                    <TextField {...inputProps} id={"costTo"} type="number"></TextField>
                                </Grid>

                                <Grid container direction={"row"} alignItems={"center"}>
                                    期間自
                                    <TextField {...inputProps} id={"durationFrom"} type="date"></TextField>
                                    至
                                    <TextField {...inputProps} id={"durationTo"} type="date"></TextField>
                                </Grid>
                            </Grid>
                            <FormControl style={{width: "100%"}}>
                                <InputLabel>選擇產品原廠</InputLabel>
                                <Select
                                    multiple
                                    label={"選擇產品原廠"}
                                    defaultValue={[]}
                                    onChange={(event) => {
                                        // console.log(event.target.value)
                                        setReportInput({...reportInput, "productOrigins": event.target.value})
                                    }}
                                >
                                    {ProductOriginList.map(({value, label})=> <MenuItem value={value}>{label}</MenuItem>)}
                                </Select>
                            </FormControl>

                        </CardContent>
                        <CardActions style={{justifyItems: "center"}}>
                            <Button
                                variant={"contained"}
                                color={"primary"}
                                style={{marginTop: 10}}
                                onClick={() => downloadReport(reportInput)}
                            >
                                下載產業報表細項
                            </Button>
                            <Button
                                variant={"contained"}
                                color={"primary"}
                                style={{marginTop: 10}}
                                onClick={() => downloadAggregateReport(reportInput)}
                            >
                                下載產業加總表
                            </Button>

                        </CardActions>
                    </Card>
                    <br/>
                    <Card>
                        <CardHeader title={"Forecast報表"}/>
                        <CardContent>
                            <Grid container direction={"column"}>
                                <Grid container direction={"row"} alignItems={"center"}>
                                    期間從
                                    <TextField {...forecastInputProps} inputProps={{style: {textAlign: "center"}}}
                                               id={"startDate"} type="date" style={{margin: 10}} variant="outlined"/>
                                    至
                                    <TextField {...forecastInputProps} inputProps={{style: {textAlign: "center"}}}
                                               id={"endDate"} type="date" style={{margin: 10}} variant="outlined"/>
                                </Grid>
                                <TextField {...forecastInputProps} label={"客戶名稱"}  id={"customerName"}/>
                                <FormControl style={{width: "100%"}}>
                                    <InputLabel>選擇產品</InputLabel>
                                    <Select
                                        multiple
                                        label={"選擇產品"}
                                        defaultValue={[]}
                                        onChange={(event) => {
                                            // console.log(event.target.value)
                                            setForecastInput({...forecastInput, "productIds": event.target.value})
                                        }}
                                    >
                                        {data?.products.map((product)=>{
                                            return <MenuItem value={product.id}>{product.name}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                                <FormControl style={{width: "100%"}}>
                                    <InputLabel>客戶類型</InputLabel>
                                    <Select
                                        label={"客戶類型"}
                                        defaultValue={[]}
                                        onChange={(event) => {
                                            // console.log(event.target.value)
                                            setForecastInput({...forecastInput, "customerType": event.target.value})
                                        }}
                                    >
                                        {CustomerTypeList.map(({value, label}) => {
                                            return <MenuItem value={value}>{label}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>

                                <FormControl style={{width: "100%"}}>
                                    <InputLabel>操作方式</InputLabel>
                                    <Select
                                        label={"操作方式"}
                                        defaultValue={[]}
                                        onChange={(event) => {
                                            setForecastInput({...forecastInput, "orderItemType": event.target.value})
                                        }}
                                    >
                                        {OrderItemTypeList.map(({value, label}) => {
                                            return <MenuItem value={value}>{label}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>

                                <FormControl style={{width: "100%"}}>
                                    <InputLabel>原廠商</InputLabel>
                                    <Select
                                        label={"原廠商"}
                                        defaultValue={[]}
                                        onChange={(event) => {
                                            setForecastInput({...forecastInput, "productOrigin": event.target.value})
                                        }}
                                    >
                                        {ProductOriginList.map(({value, label}) => {
                                            return <MenuItem value={value}>{label}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                                <FormControl style={{width: "100%"}}>
                                    <InputLabel>AE負責人</InputLabel>
                                    <Select
                                        label={"AE負責人"}
                                        defaultValue={[]}
                                        onChange={(event) => {
                                            setForecastInput({...forecastInput, "assignedSalesUserId": event.target.value})
                                        }}
                                    >
                                        {userListQuery.data?.users.map(({id, name}) => {
                                            return <MenuItem value={id}>{name}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                                <FormControl style={{width: "100%"}}>
                                    <InputLabel>AM負責人</InputLabel>
                                    <Select
                                        label={"AM負責人"}
                                        defaultValue={[]}
                                        onChange={(event) => {
                                            setForecastInput({...forecastInput, "assigneeId": event.target.value})
                                        }}
                                    >
                                        {userListQuery.data?.users.map(({id, name}) => {
                                            return <MenuItem value={id}>{name}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>

                            </Grid>
                        </CardContent>
                        <CardActions style={{justifyItems: "center"}}>
                            <Button
                                variant={"contained"}
                                color={"primary"}
                                style={{marginTop: 10}}
                                onClick={() => downloadForecastReport(forecastInput)}
                            >
                                下載Forecast報表
                            </Button>
                            <Button
                                variant={"contained"}
                                color={"primary"}
                                style={{marginTop: 10}}
                                onClick={() => downloadForecastReport(forecastInput, true)}
                            >
                                下載Forecast報表(不計算花費)
                            </Button>

                            {/*<Button*/}
                            {/*    variant={"contained"}*/}
                            {/*    color={"primary"}*/}
                            {/*    style={{marginTop: 10}}*/}
                            {/*    onClick={() => setForecastInput({})}*/}
                            {/*>*/}
                            {/*    重設*/}
                            {/*</Button>*/}
                        </CardActions>
                    </Card>
                </Box>
            </Container>
        </Page>
    )
}
