import { Box, Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import DefaultQueryHandler from "../../../common/DefaultQueryHandler";
import { DataGridLocale } from "../../../common/DataGridLocale";
import {GridSortModel, GridFilterModel, GridValueGetterParams, DataGridPro} from "@mui/x-data-grid-pro";
import {RecordStatus, useGetMetaAdsRecordListQuery, useRemoveMetaAdsRecordMutation} from "../../../generated/graphql";
import { Download } from "react-feather";
import { format } from "date-fns";
import ConfirmButton from "../../../components/ConfirmButton";
import NotificationPopup from "../../../common/NotificationPopup";
import { download } from "../../report/DownloadableReports";
import { useUser } from "../../../Auth";
import { RecordStatusList, RecordStatusMap } from "../../../common/Constant";
import {PasteToClipboard} from "../../../common/Util";

export default () => {
    const [removeRecord] = useRemoveMetaAdsRecordMutation();
    const { loading, data, error } = useGetMetaAdsRecordListQuery();
    const [sortModel, setSortModel] = useState<GridSortModel>([{ field: "id", sort: "desc" }]);
    const user = useUser();
    const [filterModel, setFilterModel] = useState<GridFilterModel | undefined>();

    useEffect(() => {
        setFilterModel({
            items: [{
                columnField: "assignee",
                value: user?.name,
                operatorValue: "equals"
            }],
        });
    }, [user]);

    const onRemove = async (id) => {
        try {
            await removeRecord({
                variables: {
                    id: id,
                },
                refetchQueries: ["getMetaAdsRecordList"],
            });
            NotificationPopup.success(`刪除完成`);
        } catch (e) {
            NotificationPopup.error(`刪除發生問題: ${e.message}`);
            console.error(e);
        }
    };

    const columns = [
        { field: "id", headerName: "id", width: 100, type: "number" },
        { field: "name", headerName: "Meta 報表名稱", width: 200 },
        { field: "createdAt", headerName: "下載時間", width: 160, valueGetter: (params) =>
                `${format(new Date(params.row.createdAt), "yyyy-MM-dd HH:mm:SS")}` },
        { field: "duration", headerName: "走期", width: 200, valueGetter: (params) =>
                `${format(new Date(params.row.startDate), "yyyy-MM-dd")} ~ 
                ${format(new Date(params.row.endDate), "yyyy-MM-dd")}`,
        },
        {
            headerName: "負責人",
            field: "assignee",
            valueGetter: (params) => params.row.assignee?.name,
        },
        {
            headerName: "狀態",
            field: "status",
            valueGetter: (params: GridValueGetterParams) => {
                return RecordStatusMap[params.row.status as string];
            },
            valueOptions: RecordStatusList,
            type: "singleSelect",
        },
        {
            type: "actions",
            headerName: "下載",
            field: "actions1",
            width: 90,
            disableColumnMenu: true,
            disableColumnSelector: false,
            sortable: false,
            getActions: (params) => [
                <Button
                    disabled={params.row.status != RecordStatus.Success}
                    onClick={async () => {
                        await download(() => { },
                            `meta-ads/${params.row.id}`,
                            `${params.row.name}_${format(new Date(params.row.startDate), "yyyyMMdd")}` +
                            `-${format(new Date(params.row.endDate), "yyyyMMdd")}`,
                            false);
                    }}>
                    <Download />
                </Button>,
            ]
        },
        {
            type: "actions",
            headerName: "刪除",
            field: "actions2",
            width: 90,
            disableColumnMenu: true,
            disableColumnSelector: false,
            sortable: false,
            getActions: (params) => [
                <ConfirmButton
                    onConfirm={async () => {
                        await onRemove(params.row.id);
                    }}
                    queryEntity={"record"}
                    queryAction={"刪除報表紀錄"}
                    entityId={params.row.id}
                    queryMessage={`名稱: ${params.row.name}`}
                >
                    刪除
                </ConfirmButton>
            ]
        },
    ];

    return (
        <DefaultQueryHandler error={error} loading={loading}>
            <Box width={"100%"} style={{ marginTop: "30px", height: "80vh" }}>
                <DataGridPro
                    columns={columns}
                    sortModel={sortModel}
                    onSortModelChange={(model) => setSortModel(model)}
                    rows={data?.metaAdsRecords!}
                    hideFooter={true}
                    autoHeight={true}
                    localeText={DataGridLocale}
                    onCellClick={PasteToClipboard}
                    filterModel={filterModel}
                    onFilterModelChange={(filterModel) => {
                        setFilterModel(filterModel);
                    }}
                />
            </Box>
        </DefaultQueryHandler>
    );
};
