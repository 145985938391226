import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Page from "../../components/Page";
import {
    useCreateLogMutation,
    useGetProductQuery,
    useGetSupplierListQuery,
    useRemoveProductMutation,
    useUpdateProductMutation,
} from "../../generated/graphql";
import DefaultQueryHandler from "../../common/DefaultQueryHandler";
import EditView, {getModifiedValues} from "../custom/EditView";
import NotificationPopup from "../../common/NotificationPopup";
import { selectMapFn } from "../../common/Util";
import { InputType } from "../../common/FormRenderer";
import {ProductOriginList} from "../../common/Constant";
import {map} from 'lodash';
import {getLogQueryMessage} from "../log/LogUtils";
import {useUser} from "../../Auth";


const getProductColumns = (product, supplierList) => {
    return [
        {
            name: "productOrigin",
            label: "原廠",
            type: InputType.select,
            value: product?.productOrigin,
            options: ProductOriginList,
        },
        { name: "name", label: "產品名稱", value: product?.name },
        {
            name: "supplierIds",
            label: "供應商",
            type: InputType.select,
            options: supplierList ? supplierList.map(selectMapFn) : [],
            isMulti: true,
            value: product
                ? product.suppliers.map((supplier) => {
                      return supplier.id;
                  })
                : [],
        },
        { name: "website", label: "網站", value: product?.website },
        // {field: 'createdAt', headerName: '建立時間', value: product.createdAt },
        { name: "description", label: "描述", value: product?.description },
    ];
};
export default () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const { data, loading, error } = useGetProductQuery({
        variables: {
            id,
        },
    });
    const supplierListQuery = useGetSupplierListQuery();
    const [updateProduct] = useUpdateProductMutation();
    const [removeProduct] = useRemoveProductMutation();
    const user = useUser();
    const [createLog] = useCreateLogMutation();
    const onSubmit = useCallback(
        async (product, { resetForm }) => {
            // console.log(product)
            try {
                await updateProduct({
                    variables: {
                        product,
                        id,
                    },
                    refetchQueries: ['getProduct'],
                });
                NotificationPopup.success(`更新完成`);
                await createLog({
                    variables: {
                        log: {
                            entityId: id,
                            userId: user!.id as string,
                            queryAction: "修改產品",
                            queryEntity: "product",
                            queryMessage: `${getLogQueryMessage(getModifiedValues(product, data?.product, ''), getProductColumns(
                                data?.product,
                                supplierListQuery.data?.suppliers
                            ))}`,
                        }
                    }
                });
            } catch (e) {
                NotificationPopup.error(`更新產品發生問題: ${e.message}`);
                console.error(e);
            } finally {
                // resetForm();
            }
        },
        [updateProduct, data, supplierListQuery]
    );
    const onRemove = async () => {
        try {
            await removeProduct({
                variables: {
                    id: id,
                },
                refetchQueries: ["getProductList"],
            });
            NotificationPopup.success(`刪除完成`);
            navigate(`/app/product`);
        } catch (e) {
            NotificationPopup.error(`刪除產品發生問題: ${e.message}`);
            console.error(e);
        }
    };
    return (
        <Page title={"product detail page"}>
            <DefaultQueryHandler
                loading={loading || supplierListQuery.loading}
                error={error}
            >
                <EditView
                    columns={getProductColumns(
                        data?.product,
                        supplierListQuery.data?.suppliers
                    )}
                    onSubmit={onSubmit}
                    onRemove={onRemove}
                />
                {/* <DetailView data={data?.product} columns={productColumns}/> */}
            </DefaultQueryHandler>
        </Page>
    );
};
