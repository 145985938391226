import React, { useCallback, useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from "@material-ui/core";
import { Formik } from "formik";
import FormRenderer, { InputType } from "../../common/FormRenderer";
import { format } from "date-fns";
import {
    CustomerTypeEnum,
    LocaleEnum, Maybe,
    OrderStatus,
    RoleIdEnum, Scalars, useCreateLogMutation,
    useGetUserListQuery, User,
    useUpdateOrderMutation,
} from "../../generated/graphql";
import DefaultQueryHandler from "../../common/DefaultQueryHandler";
import { omit } from "lodash";
import NotificationPopup from "../../common/NotificationPopup";
import CreateView from "../custom/CreateView";
import {hasRole, selectMapFn} from "../../common/Util";
import {
    CurrencyList,
    InvoiceMethodList,
    PaymentDaysList, PaymentDaysMap,
} from "../../common/Constant";
import CreateContactView from "../contact/CreateContactView";
import CreateAdvertiserView from "../advertiser/CreateAdvertiserView";
import { useUser } from "../../Auth";
import {getLogQueryMessage} from "../log/LogUtils";

export default ({ order }) => {
    const user = useUser();
    const [advertiserId, setAdvertiserId] = useState<string | null>(
        order.advertiser?.id
    );
    const userListQuery = useGetUserListQuery();
    const [updateOrder] = useUpdateOrderMutation({
        refetchQueries: ["getOrder"],
    });
    const [createLog] = useCreateLogMutation();
    const createAdvertiserObject = {
        name: "createAdvertiserView",
        label: "廣告主",
        type: InputType.custom,
        renderCell:
            order.customer?.type == CustomerTypeEnum.Agent ? (
                <div style={{ marginTop: 10 }}>
                    <CreateAdvertiserView customer={order.customer} />
                    <FormControl style={{ width: "100%" }}>
                        <InputLabel>廣告主</InputLabel>
                        <Select
                            onChange={(
                                event: React.ChangeEvent<{
                                    value: unknown;
                                }>
                            ) => {
                                setAdvertiserId(event.target.value as string);
                            }}
                        >
                            {order.customer?.advertisers.map(({ id, name }) => {
                                return (
                                    <MenuItem value={id} key={id}>
                                        {name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </div>
            ) : (
                <div />
            ),
    };
    const columns = order.status == OrderStatus.Draft
        ? [
            {
                name: "name",
                label: "專案名稱",
                value: order.name,
            },
            {
                name: "invoiceMethod",
                label: "發票開立方法",
                type: InputType.select,
                options: InvoiceMethodList,
                value: order.invoiceMethod,
            },
            createAdvertiserObject,
            {
                name: "createContactView",
                label: "新增聯絡人",
                type: InputType.custom,
                renderCell: order.customer ? (
                    <div
                        style={{
                            marginTop: 10,
                            marginBottom: 10,
                        }}
                    >
                        <CreateContactView
                            customer={order.customer}
                        />
                    </div>
                ) : (
                    <div />
                ),
            },
            {
                name: "contactId",
                label: "主要聯絡人",
                type: InputType.select,
                options: order.customer?.contacts.map(selectMapFn),
                value: order.contact?.id,
            },
            {
                name: "invoiceContactId",
                label: "發票聯絡人",
                type: InputType.select,
                options: order.customer?.contacts.map(selectMapFn),
                value: order.invoiceContact?.id,
            },
            {
                name: "currency",
                label: "幣別",
                type: InputType.select,
                value: order.currency,
                options: CurrencyList,
            },
            {
                name: "renewal",
                label: "續約",
                type: InputType.checkbox,
                value: order.renewal,
            },
            {
                name: "assignedSalesUserId",
                label: "負責人",
                type: InputType.select,
                value: order.assignedSalesUser?.id,
                options: userListQuery.data?.users.map(selectMapFn),
            },
            {
                name: "remark",
                label: "合約備註",
                value: order.remark,
            },
        ]
        : [
            createAdvertiserObject,
            {
                name: "createContactView",
                label: "新增聯絡人",
                type: InputType.custom,
                renderCell: order.customer ? (
                    <div
                        style={{
                            marginTop: 10,
                            marginBottom: 10,
                        }}
                    >
                        <CreateContactView
                            customer={order.customer}
                        />
                    </div>
                ) : (
                    <div />
                ),
            },
            {
                name: "invoiceContactId",
                label: "發票聯絡人",
                type: InputType.select,
                options:
                    order.customer?.contacts.map(selectMapFn),
                value: order.invoiceContact?.id,
            },
        ]
    const onSubmit = useCallback(
        async (updatedOrder, { resetForm }, setOpen) => {
            try {
                if (updatedOrder.currency) {
                    updatedOrder = {...updatedOrder, locale: updatedOrder.currency == "TWD" ?
                            LocaleEnum.ZhTw : LocaleEnum.EnUs}
                }
                if (advertiserId) {
                    updatedOrder = {...updatedOrder, advertiserId}
                }
                const newOrder = await updateOrder({
                    variables: {
                        order: updatedOrder,
                        id: order.id,
                    },
                });
                NotificationPopup.success(`修改完成`);
                setOpen(false);
                return newOrder.data?.updateOrder;
            } catch (e) {
                NotificationPopup.error(`修改委刊單發生問題: ${e.message}`);
                console.error(e);
            }
        },
        [updateOrder, advertiserId, user]
    );

    return (
        <CreateView
            title={"修改委刊單"}
            columns={columns}
            onSubmit={onSubmit}
            submitTitle={"修改"}
            queryEntity={"order"}
        />
    );
};
