export const ReportList = [
    {
        title: "帳戶",
        name: "customer",
        fields: ["daily"]
    },
    {
        title: "行銷活動",
        name: "campaign",
        fields: ["total", "daily"]
    },
    {
        title: "廣告組合",
        name: "adset",
        fields: ["total", "daily"]
    },
    {
        title: "廣告",
        name: "ad",
        fields: ["total", "daily"]
    },
    {
        title: "年齡",
        name: "age",
        fields: ["total", "campaign-total", "adset-total", "daily", "campaign-daily", "adset-daily"]
    },
    {
        title: "性別",
        name: "gender",
        fields: ["total", "campaign-total", "adset-total", "daily", "campaign-daily", "adset-daily"]
    },
    {
        title: "裝置",
        name: "impression_device",
        fields: ["total", "campaign-total", "adset-total", "daily", "campaign-daily", "adset-daily"]
    },
    {
        title: "平台",
        name: "publisher_platform",
        fields: ["total", "campaign-total", "adset-total", "daily", "campaign-daily", "adset-daily"]
    },
    {
        title: "區域",
        name: "region",
        fields: ["total", "campaign-total", "adset-total", "daily", "campaign-daily", "adset-daily"]
    },
    {
        title: "國家/地區",
        name: "country",
        fields: ["total", "campaign-total", "adset-total", "daily", "campaign-daily", "adset-daily"]
    },
    {
        title: "時段",
        name: "hourly_stats_aggregated_by_audience_time_zone",
        fields: ["total", "campaign-total", "adset-total"]
    }
];