import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import { DataGridLocale } from "../../../../common/DataGridLocale";
import { GridColDef, DataGridPro, GridSortModel, GridFilterModel } from "@mui/x-data-grid-pro";
import { useUser } from "../../../../Auth";
import NotificationPopup from "../../../../common/NotificationPopup";
import {
    useCreateMetaAdsRecordMutation,
    useGetMetaTemplatesQuery, useRemoveMetaAdsTemplateMutation,
} from "../../../../generated/graphql";
import ConfirmButton from "../../../../components/ConfirmButton";
import CreateTemplateView, { AdTypes } from "../CreateTemplateView";
import {find} from "lodash";
import CreateMetaTemplateView from "./CreateMetaTemplateView";
import CreateView from "../../../custom/CreateView";
import {InputType} from "../../../../common/FormRenderer";

const MetaTemplateTable = () => {
    const user = useUser();
    const { loading, data, error } = useGetMetaTemplatesQuery();
    const [removeTemplate] = useRemoveMetaAdsTemplateMutation();
    const [createRecord] = useCreateMetaAdsRecordMutation();
    const [sortModel, setSortModel] = useState<GridSortModel>([{ field: "id", sort: "desc" }]);
    const [filterModel, setFilterModel] = useState<GridFilterModel | undefined>();

    useEffect(() => {
        setFilterModel({
            items: [
                {
                    columnField: "assignee",
                    value: user?.name,
                    operatorValue: "equals"
                }
            ]
        });
    }, [user]);

    const onRemove = async (id) => {
        try {
            await removeTemplate({
                variables: { id },
                refetchQueries: ["getMetaTemplates"]
            });
            NotificationPopup.success(`刪除完成`);
        } catch (e) {
            NotificationPopup.error(`刪除發生問題: ${e.message}`);
            console.error(e);
        }
    };

    const columns = [
        { field: "id", headerName: "id", width: 100, type: "number", hide: true },
        { field: "name", headerName: "Meta Ads 報表名稱", width: 200 },
        {
            field: "adTypes",
            headerName: "廣告類型/欄位",
            width: 200,
            renderCell: (params) => (
                <div style={{ whiteSpace: 'normal', lineHeight: '1.5', overflow: 'hidden', maxHeight: '100%', display: 'flex', alignItems: 'center' }}>
                    {params.row.adTypes?.map((type) => find(AdTypes, (adType) => adType.key === type)?.label ?? '').join(',')}
                </div>
            )
        },
        {
            headerName: "負責人",
            field: "assignee",
            valueGetter: (params) => params.row.assignee?.name
        },
        {
            type: "actions",
            headerName: "",
            field: "actions1",
            width: 90,
            disableColumnMenu: true,
            disableColumnSelector: false,
            sortable: false,
            getActions: (params) => [
                <CreateView submitTitle={"儲存"}  title={"產生報表"}
                            columns={[
                                { name: "startDate", label: "開始日期",type:InputType.date },
                                { name: "endDate", label: "結束日期",type:InputType.date },
                            ]}
                            onSubmit={async(res,helpers,setOpen)=>
                            {
                                await createRecord({
                                    variables: {
                                        metaAdsRecordInput: {
                                            templateId: parseInt(params.row.id),
                                            startDate: res.startDate,
                                            endDate: res.endDate,
                                            name: params.row.name,
                                            assigneeId: user?.id,
                                        }
                                    },
                                    refetchQueries: ["getMetaAdsRecordList"],
                                });
                                setOpen(false);
                                NotificationPopup.success(`正在產生報表，稍後請至"報表紀錄"下載報表。`);

                            }}
                            queryEntity={"metaAds"}
                />
            ]
        },
        {
            type: "actions",
            headerName: "",
            field: "actions2",
            width: 90,
            disableColumnMenu: true,
            disableColumnSelector: false,
            sortable: false,
            getActions: (params) => [
                <CreateMetaTemplateView template={params.row} />
            ]
        },
        {
            type: "actions",
            headerName: "",
            field: "actions3",
            width: 90,
            disableColumnMenu: true,
            disableColumnSelector: false,
            sortable: false,
            getActions: (params) => [
                <ConfirmButton
                    onConfirm={async () => {
                        await onRemove(params.row.id);
                    }}
                    queryEntity={"template"}
                    queryAction={"刪除廣告模板"}
                    entityId={params.row.id}
                    queryMessage={`名稱: ${params.row.name}`}
                >
                    刪除
                </ConfirmButton>
            ]
        }
    ];

    return (
        <Box width={"100%"} style={{ marginTop: "30px", height: "80vh" }}>
            <DataGridPro
                columns={columns}
                sortModel={sortModel}
                onSortModelChange={(model) => setSortModel(model)}
                rows={data?.metaAdsTemplates!}
                hideFooter={true}
                autoHeight={true}
                localeText={DataGridLocale}
                filterModel={filterModel}
                onFilterModelChange={(filterModel) => setFilterModel(filterModel)}
            />
        </Box>
    );
};

export default MetaTemplateTable;
