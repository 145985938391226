import {Box} from "@material-ui/core";
import React from "react";
import {DepositStatus, useGetCustomerListQuery} from "../../generated/graphql";
import DefaultQueryHandler from "../../common/DefaultQueryHandler";
import {getLevelName, PasteToClipboard, ViewColumn} from "../../common/Util";
import { DataGridLocale } from "../../common/DataGridLocale";
import {GridColDef, GridValueGetterParams, DataGridPro, GridValueFormatterParams} from "@mui/x-data-grid-pro";
import PaginatedDatagrid from "../../components/PaginatedDatagrid";
import { loader } from "graphql.macro";
import {addDays, format} from "date-fns";
import {findDefaultTag} from "./CustomerDetailView";
import {FieldTagMap, ProductTagMap} from "../../common/IndustryConstant";
const GetCustomerListQuery = loader("./graphql/GetCustomerList.graphql");


export default () => {
    const columns: GridColDef[] = [
        { field: "score", headerName: "等級", width: 120, valueGetter:(params)=>
                getLevelName(params.row.score),
        },
        { field: "id", headerName: "ID", width: 120, type: "number" },
        { field: "name", headerName: "客戶名稱", width: 200 },
        { field: "phone", headerName: "電話", width: 180 },
        { field: "taxId", headerName: "統一編號", width: 150 },
        { field: "disabled", headerName: "隱藏", hide: true},
        {
            field: "industryTag", headerName: "產業標籤", width: 200, valueGetter: (params) =>
            {
                const found = findDefaultTag(params.row.industryTag);
                return `${found['3'] ?? ''} ${found['3name'] ?? ''}`;
            }
        },
        {
            field: "productTag", headerName: "產品標籤", width: 200, valueGetter: (params) =>
                ProductTagMap[params.row.productTag]
        },
        {
            field: "fieldTag", headerName: "場域標籤", width: 200, valueGetter: (params) =>
                FieldTagMap[params.row.fieldTag]
        },
        { field: "contractEndDate", headerName: "合約到期日",
            valueGetter: (params: GridValueGetterParams) => {
                return params.row.contractEndDate
                    ? format(new Date(params.row.contractEndDate as string), "yyyy-MM-dd")
                    : "未指定";
            } },
        ViewColumn('/app/customer'),
    ];
    const targetDate = addDays(new Date(), 31);

    return (
        <Box width={"100%"} style={{ marginTop: "30px", height: "80vh" }}>
            <PaginatedDatagrid
                id={'customer'}
                filterButtons={[{
                    label: "合約即將到期",
                    filter: {
                        items: [
                            {
                                columnField: "disabled",
                                value: "false",
                                operatorValue: "equals",
                                id: 2,
                            },
                            {
                                columnField: "contractEndDate",
                                value: format(targetDate, "yyyy-MM-dd"),
                                id: 1,
                                operatorValue: "<",
                            },
                        ],
                    },
                }]}
                columns={columns}
                query={GetCustomerListQuery}
                ordering={{ field: "id", sort: "desc" }}
                queryParams={true}
            />
        </Box>
    );
};
