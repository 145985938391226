import React, { useState } from "react";
import "./App.css";
import {getAccessToken, logout, setAccessToken} from "./Auth";
import Login from "./Login";
import {ApolloClient, ApolloLink, ApolloProvider, HttpLink, InMemoryCache} from "@apollo/client";
import {useRoutes } from "react-router-dom";
import {ThemeProvider, Box} from "@material-ui/core";
import GlobalStyles from "./components/GlobalStyles";
import theme from "./theme";
import routes from "./routes";
import { onError } from "@apollo/client/link/error";
import {ErrorBoundary} from "./ErrorBoundary";
import {LicenseInfo} from '@mui/x-data-grid-pro';
import {DefaultLayout} from "./layouts/MainLayout";
import Page from "./components/Page";


LicenseInfo.setLicenseKey("57a30118235ee0577bd1bf38d2d551adT1JERVI6MjQ1MTYsRVhQSVJZPTE2NTE4MjgwNjcwMDAsS0VZVkVSU0lPTj0x");

const Inner = ()=>
{
    const routing = useRoutes(routes);
    return routing;
}


const App = () => {
    const [accessToken, setStateAccessToken] = useState(getAccessToken());
    if (accessToken == null) {
        return (
            <DefaultLayout>
                <Page title={'Login'}>
                    <Box width={"100%"} style={{ marginTop: 20, height: '100vh' }}>
                        <Login
                            onSuccess={(accessToken) => {
                                setAccessToken(accessToken);
                                setStateAccessToken(accessToken);
                            }}
                        />
                    </Box>

                </Page>

            </DefaultLayout>
        );
    }
    const errorLink = onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
            graphQLErrors.forEach(({ message, locations, path }) =>
            {
                if(message === "Unauthorized")
                {
                    logout();
                    setStateAccessToken(null);

                }
                console.log(
                    `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
                )
            });
        if (networkError) console.log(`[Network error]: ${networkError}`);
    });
    const client = new ApolloClient({
        cache: new InMemoryCache(),
        link:ApolloLink.from([errorLink,new HttpLink({
            uri:`${process.env.REACT_APP_BACKEND_URI}/graphql`,
            headers:{
                Authorization: `Bearer ${accessToken}`,
            }
        })]),
    });
    return (
    <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
            <GlobalStyles />
            <ErrorBoundary>
                <Inner/>
            </ErrorBoundary>
        </ThemeProvider>
    </ApolloProvider>
    );
};

export default App;
