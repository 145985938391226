import {Box, MenuItem, Select} from "@material-ui/core";
import {DataGridPro, GridColDef, GridRenderCellParams, GridSortModel} from "@mui/x-data-grid-pro";
import {find, omit, reduce} from 'lodash';
import React, {useCallback, useState} from "react";
import {DataGridLocale} from "../../common/DataGridLocale";
import {InputType} from "../../common/FormRenderer";
import NotificationPopup from "../../common/NotificationPopup";
import {PasteToClipboard, selectMapFn} from "../../common/Util";
import ConfirmButton from "../../components/ConfirmButton";
import {
    Advertiser,
    useRemoveAdvertiserMutation,
    useUpdateAdvertiserMutation
} from "../../generated/graphql";
import CreateView from "../custom/CreateView";
import {findDefaultTag, industryTagColumn} from "../customer/CustomerDetailView";
import {FieldTagList, FieldTagMap, IndustryTagList, ProductTagList, ProductTagMap} from "../../common/IndustryConstant";

export default ({advertisers}) =>
{
    const [updateAdvertiser] = useUpdateAdvertiserMutation();
    const [removeAdvertiser] = useRemoveAdvertiserMutation();
    const [sortModel, setSortModel] = useState<GridSortModel>([{field: "id", sort: "desc"}]);
    const IndustryTagOptions = reduce(IndustryTagList, (acc:any, industry)=>{
        return [...acc, ...reduce(industry.list, (acc:any, industry)=>{
            return [...acc, ...industry.list.map((industry)=>
                ({ value: industry.id, label: `${industry.id} ${industry.name}`}))]
        }, [])]
    }, []);
    const onSubmit = useCallback(
        async (advertiser: Advertiser, {resetForm}, setOpen) =>
        {
            try {
                const query = await updateAdvertiser({
                    variables: {
                        advertiser: omit(advertiser, ["id"]),
                        id: advertiser.id,
                    },
                    refetchQueries: ["getCustomer"],
                });
                NotificationPopup.success(`修改完成`);
                return advertiser;
            } catch (e) {
                NotificationPopup.error(
                    `修改發生問題: ${e.message}`
                );
                console.error(e);
            } finally {
                resetForm();
                setOpen(false);
            }
        },
        [updateAdvertiser]
    );
    const onRemove = (id) =>
    {
        try {
            removeAdvertiser({
                variables: {
                    id: id,
                },
                refetchQueries: ["getCustomer"],
            });
            NotificationPopup.success(`刪除完成`);
        } catch (e) {
            NotificationPopup.error(`刪除發生問題: ${e.message}`);
            console.error(e);
        }
    };
    const columns: GridColDef[] = [
        {field: "id", headerName: "ID", width: 90, hide: true, type: "number"},
        {field: "name", headerName: "廣告主名稱", width: 150},
        // {
        //     field: "industry", headerName: "產業別", width: 200, valueGetter: (params) =>
        //         (params.row.industry as any)?.name
        // },
        {
            field: "industryTag", headerName: "產業標籤", width: 200, valueGetter: (params) =>
            {
                const found = findDefaultTag(params.row.industryTag);
                return `${found['3']} ${found['3name']}`;
            }
        },
        {
            field: "productTag", headerName: "產品標籤", width: 200, valueGetter: (params) =>
                ProductTagMap[params.row.productTag]
        },
        {
            field: "fieldTag", headerName: "場域標籤", width: 200, valueGetter: (params) =>
                FieldTagMap[params.row.fieldTag]
        },
        {
            field: "action",
            headerName: "動作",
            width: 200,
            renderCell: (params: GridRenderCellParams) =>
            {
                const industry = find(IndustryTagOptions, (option)=>params.row.industryTag==option.value);
                return (
                    <div style={{display: "flex"}}>
                        <CreateView
                            title={"修改"}
                            columns={[
                                {
                                    name: "id",
                                    label: "ID",
                                    value: params.row.id,
                                    type: InputType.hidden,
                                },
                                {
                                    name: "name",
                                    label: "廣告主名稱",
                                    value: params.row.name,
                                },
                                {
                                    name: "industryTag",
                                    label: "產業標籤",
                                    type: InputType.autoComplete,
                                    options: IndustryTagOptions,
                                    value: {value: params.row.industryTag, label: industry?.label},
                                },
                                {
                                    name: "productTag",
                                    label: "產品標籤",
                                    type: InputType.select,
                                    options: ProductTagList.map((product)=>({value: product.id,label: `${product.id} ${product.name}`
                                    })),
                                    value: params.row.productTag,
                                },
                                {
                                    name: "fieldTag",
                                    label: "場域標籤",
                                    type: InputType.select,
                                    options: FieldTagList.map((field)=>({value: field.id,label: `${field.id} ${field.name}`
                                    })),
                                    value: params.row.fieldTag,
                                },
                            ]}
                            onSubmit={onSubmit}
                            queryEntity={"advertiser"}
                        />
                        &ensp;
                        <ConfirmButton
                            onConfirm={() =>
                            {
                                onRemove(params.row.id);
                            }}
                            queryEntity={"advertiser"}
                            queryAction={"刪除廣告主"}
                            entityId={params.row.id}
                            queryMessage={`名稱: ${params.row.name}`}
                        >
                            刪除
                        </ConfirmButton>
                    </div>
                );
            },
        },
    ];
    return (
        <Box width={"100%"} style={{marginTop: 20, height: 200}}>
            <DataGridPro
                columns={columns}
                rows={advertisers}
                sortModel={sortModel}
                onSortModelChange={(model) => setSortModel(model)}
                hideFooter={true}
                autoHeight={true}
                localeText={DataGridLocale}
                onCellClick={PasteToClipboard}
            />
        </Box>
    );
};
