import { Box } from "@material-ui/core";
import React, { useState } from "react";
import { useGetSupplierListQuery } from "../../generated/graphql";
import DefaultQueryHandler from "../../common/DefaultQueryHandler";
import { PasteToClipboard, ViewColumn } from "../../common/Util";
import { DataGridLocale } from "../../common/DataGridLocale";
import {GridColDef, DataGridPro, GridSortModel} from "@mui/x-data-grid-pro";

export default () => {
    const { loading, data, error } = useGetSupplierListQuery();
    const [sortModel, setSortModel] = useState<GridSortModel>([{field: "id", sort: "desc"}]);

    const columns: GridColDef[] = [
        { field: "id", headerName: "id", width: 100, type: "number" },
        { field: "name", headerName: "供應商名稱", width: 150 },
        { field: "address", headerName: "地址", width: 300 },
        { field: "invoiceTitle", headerName: "發票抬頭", width: 150 },
        { field: "taxId", headerName: "統一編號", width: 150 },
        { field: "bankCode", headerName: "銀行代碼"},
        { field: "bankAccountNumber", headerName: "銀行帳號"},
        ViewColumn('/app/supplier'),
    ];
    return (
        <DefaultQueryHandler error={error} loading={loading}>
            <Box width={"100%"} style={{ marginTop: "30px", height: "80vh" }}>
                <DataGridPro
                    columns={columns}
                    sortModel={sortModel}
                    onSortModelChange={(model)=>setSortModel(model)}
                    rows={data?.suppliers!}
                    hideFooter={true}
                    autoHeight={true}
                    localeText={DataGridLocale}
                    onCellClick={PasteToClipboard}
                />
            </Box>
        </DefaultQueryHandler>
    );
};
