import { Container } from "@material-ui/core";
import React from "react";
import Page from "../../components/Page";
import LogTable from "./LogTable";

export default () => {
    return (
        <Page title={"log page"}>
            <Container maxWidth={false} style={{ padding: "30px" }}>
                <LogTable />
            </Container>
        </Page>
    );
};
