import format from "date-fns/format";
import { useCallback, useState } from "react";
import { InputType } from "../../common/FormRenderer";
import NotificationPopup from "../../common/NotificationPopup";
import { selectMapFn } from "../../common/Util";
import {useCreateReceivedPaymentMutation, useGetCustomerQuery, useGetProductQuery} from "../../generated/graphql";
import CreateView from "../custom/CreateView";
import {useCustomers} from "../../Auth";


export default () => {
    const [createReceivedPayment] = useCreateReceivedPaymentMutation();
    const customers = useCustomers();
    const [customerId, setCustomerId] = useState<string | null>();
    // const { data, loading, error } = useGetCustomerQuery({
    //     variables: {
    //         id: customerId as string,
    //     },
    // });

    const onSubmit = useCallback(
        async (receivedPayment, { resetForm }, setOpen) => {
            if (!receivedPayment.customerId) {
                NotificationPopup.error(`客戶資料未選取`);
                return;
            }
            try {
                await createReceivedPayment({
                    variables: {
                        receivedPayment: {...receivedPayment,
                            amount: parseFloat(receivedPayment.amount),
                            customerId: parseFloat(receivedPayment.customerId)
                        },
                    },
                    refetchQueries: ["getReceivedPaymentList"],
                });
                NotificationPopup.success(`建立完成`);
                resetForm();
            } catch (e) {
                NotificationPopup.error(`新增收款單發生問題: ${e.message}`);
                console.error(e);
            } finally {
                setOpen(false);
            }
        }
        ,[createReceivedPayment]
    );
    return (
        <CreateView
            title={"新增收款單"}
            columns={[
                {
                    name: "customerId",
                    label: "客戶",
                    type: InputType.autoComplete,
                    options: customers?.map(selectMapFn),
                    onChange: (data) => {
                        setCustomerId(data);
                    },
                    required: true,
                },
                { name: "amount", label: "收款單金額", type: InputType.number },
                {
                    name: "date",
                    label: "收款日期",
                    type: InputType.date,
                    value: format(new Date(), "yyyy-MM-dd"),
                },
                {
                    name: "remark",
                    label: "備註",
                },
                {
                    name: "sourceBankCode",
                    label: "匯款銀行代碼",
                },
                {
                    name: "sourceBankAccountNumber",
                    label: "匯款帳號",
                },
                {
                    name: "destinationBankAccountNumber",
                    label: "虛擬帳號",
                },
            ]}
            onSubmit={onSubmit}
            queryEntity={"receivedPayment"}
        />
    );
}
