import Page from "../../../components/Page";
import {Box, Container, Grid, Tab, Tabs} from "@material-ui/core";
import React, {useState} from "react";
import TemplateTable from "./TemplateTable";
import CreateTemplateView from "./CreateTemplateView";
import MetaTemplateTable from "./meta/MetaTemplateTable";
import CreateMetaTemplateView from "./meta/CreateMetaTemplateView";
import RecordTable from "../record/RecordTable";

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
};

export default () => {
    const [tabValue, setTabValue] = useState(0);

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <Page title={"Template Page"}>
            <Container maxWidth={false} style={{ padding: "30px" }}>
                <Tabs value={tabValue} onChange={handleChange} aria-label="Ads Tabs">
                    <Tab label="Google報表模板" />
                    <Tab label="Meta報表模板" />
                </Tabs>
                <TabPanel value={tabValue} index={0}>
                    <Grid container spacing={2}>
                        <CreateTemplateView />
                        <TemplateTable />
                    </Grid>
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    <Grid container spacing={2}>
                        <CreateMetaTemplateView />
                        <MetaTemplateTable />
                    </Grid>
                </TabPanel>
            </Container>
        </Page>
    );
};