import { Container } from "@material-ui/core";
import React from "react";
import Page from "../../components/Page";
import { DepositStatus } from "../../generated/graphql";
import DepositTable from "./DepositTable";

export default () => {
    return (
        <Page title={"deposit page"}>
            <Container maxWidth={false} style={{ padding: "30px" }}>
                <DepositTable filterButtons={[
                    {
                        label: "尚未發稿清單",
                        filter: {
                            items: [
                                {
                                    columnField: "status",
                                    value: DepositStatus.Pending,
                                    operatorValue: "equals",
                                },
                            ],
                        },
                    },
                    {
                        label: "異動中",
                        filter: {
                            items: [
                                {
                                    columnField: "status",
                                    value: DepositStatus.Changed,
                                    operatorValue: "equals",
                                },
                            ],
                        },
                    },
                ]}/>
            </Container>
        </Page>
    );
};
