import React, { useState } from "react";
import {Link as RouterLink, Outlet} from "react-router-dom";
import {Avatar, makeStyles, IconButton} from "@material-ui/core";
import NavBar from "./NavBar";
import TopBar from "./TopBar";
import preval from "preval.macro";
import Logo from "../../components/Logo";
import {Menu} from "react-feather";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        display: "flex",
        height: "100%",
        overflow: "hidden",
        width: "100%",
    },
    wrapper: {
        display: "flex",
        flex: "1 1 auto",
        overflow: "hidden",
    },
    contentContainer: {
        display: "flex",
        flex: "1 1 auto",
        overflow: "hidden",
    },
    content: {
        flex: "1 1 auto",
        height: "100%",
        overflow: "auto",
    },
}));

const DashboardLayout = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            {/*<TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />*/}
            {/*<RouterLink to="/">*/}
            {/*    <Logo />*/}
            {/*</RouterLink>*/}
            <NavBar
                // openDrawer={openDrawer}
            />


            <div className={classes.wrapper}>
                <div className={classes.contentContainer}>
                    <div className={classes.content}>
                        <Outlet />
                    </div>
                </div>
            </div>

            <div style={{position: "absolute", top: "0px", right: "0px", margin: "5px"}}>
                <small>
                    Build Time:&nbsp;
                    {preval`module.exports = new Date().toLocaleString();`}
                </small>
            </div>
        </div>
    );
};

export default DashboardLayout;
