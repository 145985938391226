import CreateView from "../../../custom/CreateView";
import React, { useCallback, useState } from "react";
import {
    useGetMetaAdsAccountListQuery,
    useGetMetaCampaignListQuery,
} from "../../../../generated/graphql";
import { InputType } from "../../../../common/FormRenderer";
import { find } from "lodash";

export default ({ onSelectCampaign }) => {
    const [selectedAccount, setSelectedAccount] = useState<any>();
    const [selectedCampaign, setSelectedCampaign] = useState<any>();
    const { data: accounts, loading, error } = useGetMetaAdsAccountListQuery();
    const { data: campaignsData, loading: campaignsLoading } = useGetMetaCampaignListQuery({
        variables: { cid: selectedAccount?.id },
        skip: !selectedAccount,
    });

    const accountColumns = [
        {
            name: "metaAdsAccount",
            label: "Meta廣告帳戶",
            type: InputType.autoComplete,
            options: accounts?.metaAdsAccounts?.map((account) => {
                return { value: account.id, label: `${account.id}-${account.name}` };
            }),
            onChange: (d) => {
                setSelectedAccount(find(accounts?.metaAdsAccounts ?? [], (c) => {
                    return c.id == d;
                }));
            },
        },
        {
            name: "campaign",
            label: "廣告活動",
            type: InputType.select,
            options: campaignsData?.metaAdsCampaigns?.map(
                (campaign) =>
                    ({ value: campaign.id, label: `${campaign.id}-${campaign.name}` })
            ) ?? [],
            onChange: (d) => {
                setSelectedCampaign(find(campaignsData?.metaAdsCampaigns ?? [], (c) => {
                    return c.id == d;
                }));
            },
        }
    ];

    const onSubmit = useCallback(
        async (account, { resetForm }, setOpen) => {
            onSelectCampaign({ ...selectedCampaign, accountId: selectedAccount.id });
            setOpen(false);
        }, [selectedCampaign, onSelectCampaign]
    );

    return (
        <CreateView
            title={"新增廣告活動"}
            columns={accountColumns}
            onSubmit={onSubmit}
            queryEntity={"accountMetaAds"}
        />
    );
};
