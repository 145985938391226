import {ProductOrigin as Po} from "../generated/graphql";

export const listToMap = (list) => {
    let result = {}
    list.map((item) => {
        result[item.value] = item.label
    })
    return result
}
export const ProductOriginList = [
    { value: 'Baidu', label: '百度' },
    { value: 'Facebook', label: 'Meta' },
    { value: 'Kooshopping', label: '酷百貨' },
    { value: 'LINE', label: 'LINE' },
    { value: 'LINEOA', label: 'LINE OA' },
    { value: 'LiveStreaming', label: '直播工具' },
    { value: 'Other', label: '其他' },
    { value: 'SEO', label: 'SEO' },
    { value: 'Yahoo', label: 'Yahoo' },
    { value: 'Google', label: 'Google' },
    { value: 'Tender', label: '標案' },
    { value: 'Tiktok', label: '抖音' },
    { value: 'Dcard', label: 'Dcard' },
    { value: 'Linkedin', label: 'Linkedin' },
    { value: 'Twitter', label: 'Twitter' },
    { value: 'Koosocial', label: 'Koosocial' },
    { value: 'Criteo', label: 'Criteo'},
    { value: 'NAVER', label: 'NAVER'},
]
export const ProductOriginMap = listToMap(ProductOriginList);

export const OrderStatusList = [
    { value: 'started', label: '執行中' },
    { value: 'draft', label: '草稿' },
    { value: 'orderSigned', label: '已回簽' },
    { value: 'invoiceRequested', label: '已開發票' },
    { value: 'finished', label: '已結案' },
    { value: 'changed', label: '等待異動核准' },
    { value: 'bonusAssigned', label: '已發獎金' },
]
export const OrderStatusMap = listToMap(OrderStatusList);

export const InvoiceStatusList = [
    { value: 'started', label: '等待開立發票' },
    { value: 'posted', label: '已開立' },
    { value: 'cancelRequested', label: '等待作廢發票' },
    { value: 'canceled', label: '已作廢' },
    { value: 'paid', label: '已付款' },
    { value: 'changed', label: '等待異動核准' },
    { value: 'pending', label: '開立發票中' },
]
export const InvoiceStatusMap = listToMap(InvoiceStatusList);

export const DepositStatusList = [
    { value: 'pending', label: '等待發稿'},
    { value: 'rejected', label: '拒絕請求'},
    { value: 'canceled', label: '取消發稿'},
    { value: 'finished', label: '加值完成'},
    { value: 'changed', label: '異動中'},
]
export const DepositStatusMap = listToMap(DepositStatusList);

export const InvoiceMethodList = [
    { value: 'thisMonth', label: '當月' },
    { value: 'monthly', label: '每月' },
    { value: 'afterCompletion', label: '結案後' },
    { value: 'upfront', label: '專案開始' },
]
export const InvoiceMethodMap = listToMap(InvoiceMethodList);

export const PaymentDaysList = [
    { value: 0, label: '預收' },
    { value: 30, label: '發票日後30天內' },
    { value: 45, label: '發票日後45天內' },
    { value: 55, label: '發票日後55天內' },
    { value: 60, label: '發票日後60天內' },
    { value: 90, label: '發票日後90天內' },
    { value: 95, label: '發票日後95天內' },
]
export const PaymentDaysMap = listToMap(PaymentDaysList);

export const CurrencyList = [
    { value: 'TWD', label: '台幣' },
    { value: 'USD', label: '美金' },
]
export const CurrencyMap = listToMap(CurrencyList);

export const CustomerTypeList = [
    { value: 'agent', label: '代理商' },
    { value: 'direct', label: '直客' },
]
export const CustomerTypeMap = listToMap(CustomerTypeList);

export const OrderItemTypeList = [
    { value: 'kpi', label: 'KPI' },
    { value: 'service', label: '服務費' },
    { value: 'intermediary', label: '掛稿' },
    { value: 'bid', label: '標案' },
    { value: 'fixedEdition', label: '媒體定版'},
    { value: 'integratedProject', label: '整合型專案'},
    { value: 'brand', label: '品牌型'},
];

export const OrderItemTypeMap = listToMap(OrderItemTypeList);

export const DisableList = [
    { value: true, label: '隱藏' },
    { value: false, label: '不隱藏' },
]
export const PurchaseOrderStatusList = [
    { value: 'draft', label: '草稿' },
    { value: 'managerReview', label: '待主管審核' },
    { value: 'gmReview', label: '待總經理審核' },
    { value: 'financeReview', label: '待財務審核' },
    { value: 'unpaid', label: '待付款' },
    { value: 'paid', label: '已付款' },
]
export const PurchaseOrderStatusMap = listToMap(PurchaseOrderStatusList);

export const ReceivedPaymentStatusList = [
    { value: "started", label: "未連結" },
    { value: "linked", label: "已連結" },
    { value: "cancelled", label: "已作廢" },
]
export const ReceivedPaymentStatusMap = listToMap(ReceivedPaymentStatusList);

export const UpgradeStatusList = [
    { value: "draft", label: "草稿" },
    { value: "financeReview", label: "財務審核" },
    { value: "managerReview", label: "主管審核" },
    { value: "gmReview", label: "總經理審核" },
    { value: "success", label: "通過審核" },
    { value: "rejected", label: "被拒絕" },
]
export const UpgradeStatusMap = listToMap(UpgradeStatusList);

export const BelongingList = [
    { value: "koodata", label: "酷訊" },
    { value: "adup", label: "想上" },
    { value: "internationalDivision", label: "國際事業部"},
]
export const BelongingMap = listToMap(BelongingList);


export const RecordStatusList = [
    { value: 'success', label: '已完成' },
    { value: 'error', label: '發生錯誤' },
    { value: 'pending', label: '產出中' },
]
export const RecordStatusMap = listToMap(RecordStatusList);