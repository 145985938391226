import React, {useCallback, useState} from "react";
import {
    CustomerTypeEnum,
    RoleIdEnum,
    useCreateCustomerMutation,
    useGetIndustryListQuery,
    useGetUserListQuery,
} from "../../generated/graphql";
import NotificationPopup from "../../common/NotificationPopup";
import DefaultQueryHandler from "../../common/DefaultQueryHandler";
import CreateView from "../custom/CreateView";
import {InputType} from "../../common/FormRenderer";
import {CustomerTypeList, InvoiceMethodList, PaymentDaysList} from "../../common/Constant";
import {HasRole, saveFile, selectMapFn} from "../../common/Util";
import {IndustryDescriptionButton} from "../../components/IndustryDescriptionButton";
import {Button} from "@material-ui/core";
import {format} from "date-fns";
import ConfirmButton from "../../components/ConfirmButton";

export default () => {
    const { loading, data, error } = useGetUserListQuery();
    const industryListQuery = useGetIndustryListQuery();
    const [createCustomer] = useCreateCustomerMutation();
    const [customerType, setCustomerType] = useState<CustomerTypeEnum>();
    const onSubmit = useCallback(
        async (customer, { resetForm }, setOpen) => {
            try {
                const newCustomer = await createCustomer({
                    variables: { customer },
                    refetchQueries: ["getCustomerList","getCustomer"],
                });
                NotificationPopup.success(`建立完成`);
                resetForm();
                return newCustomer.data?.createCustomer;
            } catch (e) {
                NotificationPopup.error(`建立客戶發生問題: ${e.message}`);
                console.error(e);
            } finally {
                setOpen(false);
            }
        },
        [createCustomer]
    );
    return (
        <DefaultQueryHandler
            loading={loading && industryListQuery.loading}
            error={error}>
            <CreateView
                title={"新增客戶"}
                columns={[
                    { name: "name", label: "客戶名稱" },
                    {
                        name: "type",
                        label: "客戶類型",
                        type: InputType.select,
                        options: CustomerTypeList,
                        onChange: (data) => {
                            setCustomerType(data)
                        },
                    },
                    { name: "phone", label: "電話" },
                    { name: "fax", label: "傳真" },
                    { name: "address", label: "地址" },
                    {
                        name: "assigneeId",
                        label: "負責人",
                        type: InputType.select,
                        options: data?.users.map(selectMapFn),
                    },
                    { name: "billingAddress", label: "帳單地址" },
                    { name: "taxId", label: "統一編號", required: true },
                    {
                        name: "industryId",
                        label: "產業別",
                        type: customerType == CustomerTypeEnum.Direct ? InputType.select : InputType.hidden,
                        options: industryListQuery.data
                            ? industryListQuery.data.industries.map(selectMapFn)
                            : [],
                        startAdornment: <IndustryDescriptionButton/>
                    },
                    {
                        name: "invoiceMethod",
                        label: "發票開立方法",
                        type: InputType.select,
                        options: InvoiceMethodList,
                    },
                    {
                        name: "defaultDiscountPercentage",
                        label: "現折%數",
                        type: InputType.number,
                    },
                ]}
                onSubmit={onSubmit}
                queryEntity={"customer"}
            />
            <HasRole roles={[RoleIdEnum.Finance, RoleIdEnum.Op, RoleIdEnum.AeManager, RoleIdEnum.AmManager, RoleIdEnum.Gm]}>
                &nbsp;&nbsp;
                <Button
                    variant={"contained"}
                    color={"primary"}
                    onClick={()=>saveFile('/reports/customer', '客戶資料表.xlsx')}
                >下載客戶資料</Button>
            </HasRole>
            <HasRole roles={[RoleIdEnum.Finance, RoleIdEnum.Gm, RoleIdEnum.AeManager, RoleIdEnum.AmManager]}>
                &nbsp;&nbsp;
                <Button
                    variant={"contained"}
                    color={"primary"}
                    onClick={()=>saveFile('/customer/fetchLevel', '客戶分級表.xlsx')}
                >下載客戶分級表</Button>
            </HasRole>
            <HasRole roles={[RoleIdEnum.Finance, RoleIdEnum.Admin]}>
                &nbsp;&nbsp;
                <ConfirmButton
                    onConfirm={async () =>
                    {
                        await fetch(`${process.env.REACT_APP_BACKEND_URI}/customer/checkInvoicesExpired`);
                    }}
                >檢查逾期發票</ConfirmButton>
            </HasRole>
        </DefaultQueryHandler>
    );
};
