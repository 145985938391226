import { Button, Dialog, DialogTitle } from "@material-ui/core";
import React, { useState } from "react";
import DepositTable from "./OrderItemDepositTable";

export default ({ orderItem }) => {
    const [open, setOpen] = useState(false);
    return (
        <div>
            <Dialog open={open} fullWidth={true} maxWidth={'lg'}>
                <DialogTitle>發稿列表</DialogTitle>
                <DepositTable orderItem={orderItem} />

                <Button
                    variant={"contained"}
                    color={"secondary"}
                    onClick={() => {
                        setOpen(false);
                    }}
                >
                    關閉
                </Button>
            </Dialog>
            <Button
                variant={"contained"}
                color={"primary"}
                onClick={() => setOpen(true)}
            >
                查看發稿
            </Button>
        </div>
    );
};
