import { Button, Card, Grid } from "@material-ui/core";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import {BelongingMap, OrderItemTypeMap} from "../../common/Constant";
import TextInput from "../../components/Form/TextInput";
import DetailView from "../custom/DetailView";
import CreateDeposit from "../deposit/CreateDeposit";
import DepositPopup from "../deposit/DepositPopup";
import { format } from "date-fns";
import ConfirmButton from "../../components/ConfirmButton";
import NotificationPopup from "../../common/NotificationPopup";
import { OrderStatus, useDeleteOrderItemMutation } from "../../generated/graphql";

var orderItemColumns = [
    { field: "id", headerName: "ID" },
    { field: "product", headerName: "產品" },
    {
        field: "type",
        headerName: "操作方式",
        valueFormatter: (type) => (type ? OrderItemTypeMap[type] : "未指定"),
    },
    {
        field: "totalAmount",
        headerName: "購買金額",
        valueFormatter: (value) => value?.toLocaleString(),
    },
    {
        field: "serviceFee",
        headerName: "帳戶執行費",
        valueFormatter: (value) => value?.toLocaleString(),
    },
    {
        field: "budget",
        headerName: "成本預算",
        valueFormatter: (value) => value?.toLocaleString(),
    },
    {
        field: "assignee",
        headerName: "專案負責人",
        valueFormatter: (user) => (user ? user.name : "未指派"),
    },
    {
        field: "supporter",
        headerName: "協助執行人",
        valueFormatter: (user) => (user ? user.name : "未指派"),
    },
    {
        field: "startDate",
        headerName: "開始時間",
        valueFormatter: (value) => format(new Date(value), "yyyy-MM-dd"),
    },
    {
        field: "endDate",
        headerName: "結束時間",
        valueFormatter: (value) => format(new Date(value), "yyyy-MM-dd"),
    },
    {
        field: "description",
        headerName: "備註",
    },
    {
        field: "belonging",
        headerName: "業績歸屬",
        valueFormatter: (value) => BelongingMap[value],
    }
];

export default ({ order, orderItem }) => {
    const navigate = useNavigate();
    const [removeOrderItem] = useDeleteOrderItemMutation();
    const onRemove = (id) => {
        try {
            removeOrderItem({
                variables: {
                    id: id,
                },
                refetchQueries: ["getOrder"],
            });
            NotificationPopup.success(`刪除完成`);
        } catch (e) {
            NotificationPopup.error(`刪除發生問題: ${e.message}`);
            console.error(e);
        }
    };
    return (
        <Card>
            <Grid container style={{ margin: 15 }}>
                <Grid item>
                    <Button
                        variant={"contained"}
                        color={"primary"}
                        onClick={() => {
                            navigate(`/app/orderItem/${orderItem.id}`);
                        }}
                    >
                        {"查看細項"}
                    </Button>
                </Grid>
                <Grid item style={{ marginLeft: 10 }}>
                    <DepositPopup orderItem={orderItem} />
                </Grid>
                
                {order?.status == OrderStatus.Draft &&<Grid item style={{ marginLeft: 10 }}>
                    <ConfirmButton
                        onConfirm={() => {
                            onRemove(orderItem.id);
                        }}
                        queryEntity={"order"}
                        queryAction={"刪除委刊細項"}
                        entityId={order.id}
                        queryMessage={`名稱: ${order.name}`}
                    >
                        刪除
                    </ConfirmButton>
                </Grid>}
            </Grid>
            <DetailView data={orderItem} columns={orderItemColumns} />
        </Card>
    );
};
