import React, { useCallback, useState } from "react";
import {
    useCreateSupplierMutation,
    useCreateUserMutation,
    useGetProductListQuery,
    useGetRolesQuery,
} from "../../generated/graphql";
import NotificationPopup from "../../common/NotificationPopup";
import DefaultQueryHandler from "../../common/DefaultQueryHandler";
import CreateView from "../custom/CreateView";
import { InputType } from "../../common/FormRenderer";

const selectMapFn = (user) => ({ value: user.id, label: user.name });
export default () => {
    const { loading, data, error } = useGetRolesQuery();
    const [createUser] = useCreateUserMutation();
    const onSubmit = useCallback(
        async (user, { resetForm }, setOpen) => {
            try {
                const newUser = await createUser({
                    variables: {
                        user,
                    },
                    refetchQueries:["getUserList"]
                });
                NotificationPopup.success(`建立完成`);
                return newUser.data?.createUser;
            }
            catch (e) {
                NotificationPopup.error(
                    `建立帳號發生問題: ${e.message}`
                );
                console.error(e);
            } 
            finally {
                resetForm();
                setOpen(false);
            }
        },
        [createUser]
    );
    return (
        <DefaultQueryHandler loading={loading} error={error}>
            <CreateView
                title={"建立帳號"}
                columns={[
                    { name: "name", label: "姓名" },
                    { name: "email", label: "Email" },
                    {
                        name: "roleIds",
                        label: "角色",
                        type: InputType.select,
                        options: data?.roles.map(selectMapFn),
                        isMulti: true,
                        value: [],
                    },
                ]}
                onSubmit={onSubmit}
                queryEntity={"user"}
            />
        </DefaultQueryHandler>
    );
};