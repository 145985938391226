import React, {useEffect, useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography} from "@material-ui/core";
import {Formik} from "formik";
import {filter, find, reduce, sumBy} from "lodash";
import FormRenderer from "../../common/FormRenderer";
import {DataGridLocale} from "../../common/DataGridLocale";
import {dateValueFormatter, PasteToClipboard, ViewColumn} from "../../common/Util";
import {
    DataGridPro,
    GridCellParams,
    GridColDef,
    GridLinkOperator,
    GridSelectionModel, GridSortModel, GridToolbar, GridValueFormatterParams,
    GridValueGetterParams
} from "@mui/x-data-grid-pro";
import {InvoiceStatusList, InvoiceStatusMap} from "../../common/Constant";
import {CheckBox} from "@material-ui/icons";
import UpdateDeposit from "../deposit/UpdateDeposit";
import {
    Invoice,
    InvoiceItem, InvoiceStatus,
    useLinkReceivedPaymentMutation,
    useUpdateReceivedPaymentMutation
} from "../../generated/graphql";
import NotificationPopup from "../../common/NotificationPopup";

const invoiceColumns: GridColDef[] = [
    {field: "id", headerName: "ID", width: 100},
    {
        field: "status",
        headerName: "狀態",
        width: 120,
        type: 'singleSelect',
        valueFormatter: ({value}) => InvoiceStatusMap[value as string],
        valueOptions: params => InvoiceStatusList,
    },
    {field: "invoiceNumber", headerName: "發票號碼", width: 150},
    {field: "postDate", type: "date", headerName: "開立發票日期", width: 180, valueFormatter: dateValueFormatter},
    {field: "items", type: "number", headerName: "發票金額", width: 180,
        valueGetter: (params: GridValueGetterParams) => {
            let items = params.row.items as [InvoiceItem]
            let amount = 0;
            items?.map((item)=> {
                amount += item.amount;
            })
            return Math.round(amount*1.05).toLocaleString();
        }
    }
];

const onLink = async (invoiceIds, receivedPaymentId, linkReceivedPayment, setOpen) => {
    try {
        await linkReceivedPayment({
            variables: {
                links: invoiceIds,
                id: receivedPaymentId,
            },
            refetchQueries: ["getReceivedPaymentList", "getReceivedPayment"],
        });
        NotificationPopup.success(`連結完成`);
        setOpen(false)
    } catch (e: any) {
        NotificationPopup.error(`發生問題: ${e.message}`);
        console.error(e);
    }
}
const getInvoicesTotalAmount = (invoices: Invoice[]) => {
    return sumBy(invoices,
        (invoice)=> Math.round(sumBy(invoice.items, (item)=>(item.amount)*1.05)))
}
const AmountProps = {style: {fontSize: 18, fontWeight: 500, margin: 10}}
export default ({receivedPayment}) => {
    const [open, setOpen] = useState(false);
    const [invoiceIds, setInvoiceIds] = useState(receivedPayment.invoices.map((invoice) => invoice.id));
    const [invoices, setInvoices] = useState<Invoice[]>([]);
    // const [updateReceivedPayment] = useUpdateReceivedPaymentMutation();
    const [linkReceivedPayment] = useLinkReceivedPaymentMutation();
    const [sortModel, setSortModel] = useState<GridSortModel>([{field: "id", sort: "desc"}]);


    useEffect(()=>{
        setInvoices(invoiceIds.map((invoiceId)=>{
            return find(receivedPayment.customer.invoices, (invoice)=>invoiceId==invoice.id);
        }))
    }, [invoiceIds])
    const invoicesTotalAmount = getInvoicesTotalAmount(invoices)
    return (
        <div>
            <Dialog disableEnforceFocus open={open} fullWidth={true} maxWidth={"md"}>
                <DialogTitle style={{backgroundColor: "#DDD"}}>
                    <Typography variant="h4" align="center">
                        連結發票
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item {...AmountProps}>
                            匯入金額：{receivedPayment.amount.toLocaleString()}
                        </Grid>
                        <Grid item {...AmountProps}>
                            發票金額：{invoicesTotalAmount.toLocaleString()}
                        </Grid>
                        <Grid item {...AmountProps}>
                            銀行手續費：{(receivedPayment.amount-invoicesTotalAmount).toLocaleString()}
                        </Grid>
                    </Grid>
                    <DataGridPro
                        style={{height: 500}}
                        columns={invoiceColumns}
                        sortModel={sortModel}
                        onSortModelChange={(model)=>{
                            setSortModel(model);
                        }}
                        rows={filter(receivedPayment.customer.invoices!, (invoice)=> {
                            return invoice.status == InvoiceStatus.Posted || invoice.status == InvoiceStatus.Paid
                        })}
                        hideFooter={true}
                        // autoHeight={true}
                        disableColumnPinning={false}
                        localeText={DataGridLocale}
                        checkboxSelection={true}
                        selectionModel={invoiceIds}
                        onSelectionModelChange={(model: GridSelectionModel) => {
                            setInvoiceIds(model as any);
                        }}

                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        variant={"contained"}
                        color={"primary"}
                        type={"submit"}
                        onClick={()=>{
                            onLink(invoiceIds, receivedPayment.id, linkReceivedPayment, setOpen);
                        }}
                    >
                        確定
                    </Button>
                    <Button
                        variant={"contained"}
                        color={"secondary"}
                        onClick={() => {
                            setOpen(false);
                        }}
                    >
                        取消
                    </Button>
                </DialogActions>
            </Dialog>
            <Button
                variant={"contained"}
                color={"primary"}
                onClick={() => setOpen(true)}
            >
                連結發票
            </Button>
        </div>
    );
}