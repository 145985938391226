import React from "react";
import { Container, Grid } from "@material-ui/core";
import Page from "../../components/Page";
import InvoiceTable from "./InvoiceTable";
import {
    Comparator,
    InvoiceStatus,
    OrderStatus, useGetInvoiceNotificationQuery,
    useGetNotificationListQuery,
} from "../../generated/graphql";

export default () => {
    const startedRequestedNotificationQuery = useGetInvoiceNotificationQuery({
        variables: {
            invoicesFiltering: [
                {
                    comparator: Comparator.Equals,
                    key: "status",
                    value: InvoiceStatus.Started,
                },
            ]
        }
    });
    const cancelRequestedNotificationQuery = useGetInvoiceNotificationQuery({
        variables: {
            invoicesFiltering: [
                {
                    comparator: Comparator.Equals,
                    key: "status",
                    value: InvoiceStatus.CancelRequested,
                },
            ]
        }
    });
    const changedNotificationQuery = useGetInvoiceNotificationQuery({
        variables: {
            invoicesFiltering: [
                {
                    comparator: Comparator.Equals,
                    key: "status",
                    value: InvoiceStatus.Changed,
                },
            ],
        },
    });

    const expiredNotificationQuery = useGetInvoiceNotificationQuery({
        variables: {
            invoicesFiltering: [
                {
                    comparator: Comparator.Equals,
                    key: "expired",
                    value: "true",
                },
            ],
        },
    });
    return (
        <Page title={"Invoice List Page"}>
            <Container maxWidth={false} style={{ padding: "30px" }}>
                <Grid container spacing={2}>
                    <InvoiceTable
                        filterButtons={[
                            {
                                label: "尚未開立發票",
                                filter: {
                                    items: [
                                        {
                                            columnField: "status",
                                            value: InvoiceStatus.Started,
                                            operatorValue: "equals",
                                        },
                                    ],
                                },
                                count: startedRequestedNotificationQuery.data?.invoices.total,
                            },
                            {
                                label: "開立發票中",
                                filter: {
                                    items: [
                                        {
                                            columnField: "status",
                                            value: InvoiceStatus.Pending,
                                            operatorValue: "equals",
                                        },
                                    ],
                                },
                            },
                            {
                                label: "請求作廢",
                                filter: {
                                    items: [
                                        {
                                            columnField: "status",
                                            value: InvoiceStatus.CancelRequested,
                                            operatorValue: "equals",
                                        },
                                    ],
                                },
                                count: cancelRequestedNotificationQuery.data?.invoices.total,
                            },
                            {
                                label: "等待異動",
                                filter: {
                                    items: [
                                        {
                                            columnField: "status",
                                            value: InvoiceStatus.Changed,
                                            operatorValue: "equals",
                                        },
                                    ],
                                },
                                count: changedNotificationQuery.data?.invoices.total,
                            },
                            {
                                label: "逾期發票",
                                filter: {
                                    items: [
                                        {
                                            columnField: "expired",
                                            value: "true",
                                            operatorValue: "equals",
                                        },
                                    ],
                                },
                                count: expiredNotificationQuery.data?.invoices.total,
                            }
                        ]}
                    />
                </Grid>
            </Container>
        </Page>
    );
};
