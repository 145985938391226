import CreateView from "../../../custom/CreateView";
import React, {useCallback, useState} from "react";
import {useQuery, gql} from "@apollo/client";
import {InputType} from "../../../../common/FormRenderer";
import {find} from "lodash";
import {useGetMetaAdsAccountListQuery} from "../../../../generated/graphql";

export default ({onSelectAccount}) => {
    const [selectedAccount, setSelectedAccount] = useState<any>();
    const { data: accounts, loading, error } = useGetMetaAdsAccountListQuery();

    const accountColumns = [
        {
            name: "metaAdsAccount",
            label: "Meta廣告帳戶",
            type: InputType.autoComplete,
            options: accounts?.metaAdsAccounts?.map((account)=>{
                return {value: account.id, label: `${account.id}-${account.name}`};
            }),
            onChange: (d) => {
                setSelectedAccount(find(accounts?.metaAdsAccounts ?? [], (c)=>{
                    return c.id == d;
                }));
            },
        },
    ];

    const onSubmit = useCallback(
        async (account, { resetForm }, setOpen) => {
            onSelectAccount(selectedAccount);
            setOpen(false);
        },[selectedAccount, onSelectAccount]
    );

    return (
        <CreateView
            title={"新增Meta廣告帳戶"}
            columns={accountColumns}
            onSubmit={onSubmit}
            queryEntity={"accountMetaAds"}
        />
    );
};
