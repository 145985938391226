import Page from "../../components/Page";
import {
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import OrderTable from "../order/OrderTable";
import DepositTable from "../deposit/DepositTable";
import InvoiceTable from "../invoice/InvoiceTable";
import {
    useGetCurrentUserQuery,
    RoleIdEnum,
    OrderStatus,
    InvoiceStatus,
    DepositStatus,
    useGetUserListQuery,
    PurchaseOrderStatusEnum,
} from "../../generated/graphql";
import { hasRole, HasRole } from "../../common/Util";
import { useUser } from "../../Auth";
import PurchaseOrderTable from "../purchaseOrder/PurchaseOrderTable";

const MyAccordion = ({ title, children }) => {
    return (
        <Accordion defaultExpanded={true}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                {title}
            </AccordionSummary>
            <AccordionDetails>{children}</AccordionDetails>
        </Accordion>
    );
};

export default () => {
    const user = useUser();
    const userListQuery = useGetUserListQuery();
    const [userId, setUserId] = useState<string | undefined>();
    const [userName, setUserName] = useState<string | undefined | null>("我");
    useEffect(() => {
        setUserId(user?.id?.toString());
    }, [user]);

    const purchaseOrderFilterButtons: any = [];
    if (hasRole(user, [RoleIdEnum.Ae, RoleIdEnum.Am])) {
        purchaseOrderFilterButtons.push({
            label: "待送出審核",
            filter: {
                items: [
                    {
                        columnField: "status",
                        value: PurchaseOrderStatusEnum.Draft,
                        operatorValue: "equals",
                    },
                ],
            },
        });
    }
    if (hasRole(user, [RoleIdEnum.AeManager, RoleIdEnum.AmManager])) {
        purchaseOrderFilterButtons.push({
            label: "待主管審核",
            filter: {
                items: [
                    {
                        columnField: "status",
                        value: PurchaseOrderStatusEnum.ManagerReview,
                        operatorValue: "equals",
                    },
                ],
            },
        });
    }
    if (hasRole(user, [RoleIdEnum.Gm])) {
        purchaseOrderFilterButtons.push({
            label: "待總經理審核",
            filter: {
                items: [
                    {
                        columnField: "status",
                        value: PurchaseOrderStatusEnum.GmReview,
                        operatorValue: "equals",
                    },
                ],
            },
        });
    }
    if (hasRole(user, [RoleIdEnum.Finance])) {
        purchaseOrderFilterButtons.push({
            label: "待財務審核",
            filter: {
                items: [
                    {
                        columnField: "status",
                        value: PurchaseOrderStatusEnum.FinanceReview,
                        operatorValue: "equals",
                    },
                ],
            },
        });
        purchaseOrderFilterButtons.push({
            label: "待付款",
            filter: {
                items: [
                    {
                        columnField: "status",
                        value: PurchaseOrderStatusEnum.Unpaid,
                        operatorValue: "equals",
                    },
                ],
            },
        });
    }

    return (
        <Page title="Dashboard">
            <Grid container style={{ padding: 10 }}>
                <HasRole roles={[RoleIdEnum.AeManager, RoleIdEnum.AmManager]}>
                    <div>
                        <FormControl style={{ width: 100 }}>
                            <InputLabel>負責人</InputLabel>
                            <Select
                                onChange={({ target }) => {
                                    const user = userListQuery.data?.users.find(
                                        (user) => {
                                            return user.id === target.value;
                                        }
                                    );
                                    setUserId(user!.id.toString());
                                    setUserName(user!.name);
                                }}
                            >
                                {userListQuery.data?.users.map((user) => {
                                    return (
                                        <MenuItem value={user.id} key={user.id}>
                                            {user.name}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </div>
                </HasRole>
                <HasRole
                    roles={[
                        RoleIdEnum.Ae,
                        RoleIdEnum.Am,
                        RoleIdEnum.AeManager,
                        RoleIdEnum.AmManager,
                    ]}
                >
                    <Grid item sm={12}>
                        <MyAccordion
                            title={`${userName}的委刊單`}
                            children={
                                <OrderTable
                                    refetchState={userId}
                                    defaultFilterItems={[
                                        {
                                            columnField: "assignedSalesUser.id",
                                            value: userId,
                                            operatorValue: "equals",
                                        },
                                    ]}
                                    filterButtons={[
                                        {
                                            label: "已簽回",
                                            filter: {
                                                items: [
                                                    {
                                                        columnField: "status",
                                                        value: OrderStatus.OrderSigned,
                                                        operatorValue: "equals",
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            label: "草稿",
                                            filter: {
                                                items: [
                                                    {
                                                        columnField: "status",
                                                        value: OrderStatus.Draft,
                                                        operatorValue: "equals",
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            label: "結案",
                                            filter: {
                                                items: [
                                                    {
                                                        columnField: "status",
                                                        value: OrderStatus.Finished,
                                                        operatorValue: "equals",
                                                    },
                                                ],
                                            },
                                        },
                                    ]}
                                />
                            }
                        />
                        <MyAccordion
                            title={`${userName}的發票清單`}
                            children={
                                <InvoiceTable
                                    refetchState={userId}
                                    defaultFilterItems={[
                                        {
                                            columnField:
                                                "order.assignedSalesUser.id",
                                            value: userId,
                                            operatorValue: "equals",
                                        },
                                    ]}
                                    filterButtons={[
                                        {
                                            label: "尚未開立發票",
                                            filter: {
                                                items: [
                                                    {
                                                        columnField: "status",
                                                        value: InvoiceStatus.Started,
                                                        operatorValue: "equals",
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            label: "開立發票中",
                                            filter: {
                                                items: [
                                                    {
                                                        columnField: "status",
                                                        value: InvoiceStatus.Pending,
                                                        operatorValue: "equals",
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            label: "等待異動",
                                            filter: {
                                                items: [
                                                    {
                                                        columnField: "status",
                                                        value: InvoiceStatus.Changed,
                                                        operatorValue: "equals",
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            label: "請求作廢",
                                            filter: {
                                                items: [
                                                    {
                                                        columnField: "status",
                                                        value: InvoiceStatus.CancelRequested,
                                                        operatorValue: "equals",
                                                    },
                                                ],
                                            },
                                        },
                                    ]}
                                />
                            }
                        />
                        <MyAccordion
                            title={`${userName}的發稿`}
                            children={
                                <DepositTable
                                    refetchState={userId}
                                    defaultFilterItems={[
                                        {
                                            columnField:
                                                "orderItem.assignee.id",
                                            value: userId,
                                            operatorValue: "equals",
                                        },
                                    ]}
                                    filterButtons={[
                                        {
                                            label: "尚未發稿清單",
                                            filter: {
                                                items: [
                                                    {
                                                        columnField: "status",
                                                        value: DepositStatus.Pending,
                                                        operatorValue: "equals",
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            label: "加值完成",
                                            filter: {
                                                items: [
                                                    {
                                                        columnField: "status",
                                                        value: DepositStatus.Finished,
                                                        operatorValue: "equals",
                                                    },
                                                ],
                                            },
                                        },
                                    ]}
                                />
                            }
                        />
                    </Grid>
                </HasRole>

                <HasRole roles={[RoleIdEnum.Finance, RoleIdEnum.Op]}>
                    <Grid item sm={12}>
                        <MyAccordion
                            title={"財務發票清單"}
                            children={
                                <InvoiceTable
                                    filterButtons={[
                                        {
                                            label: "待開立發票",
                                            filter: {
                                                items: [
                                                    {
                                                        columnField: "status",
                                                        value: InvoiceStatus.Started,
                                                        operatorValue: "equals",
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            label: "待異動發票",
                                            filter: {
                                                items: [
                                                    {
                                                        columnField: "status",
                                                        value: InvoiceStatus.Changed,
                                                        operatorValue: "equals",
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            label: "待作廢發票",
                                            filter: {
                                                items: [
                                                    {
                                                        columnField: "status",
                                                        value: InvoiceStatus.CancelRequested,
                                                        operatorValue: "equals",
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            label: "已開發票(未收款)",
                                            filter: {
                                                items: [
                                                    {
                                                        columnField: "status",
                                                        value: InvoiceStatus.Posted,
                                                        operatorValue: "equals",
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            label: "已開發票(已收款)",
                                            filter: {
                                                items: [
                                                    {
                                                        columnField: "status",
                                                        value: InvoiceStatus.Paid,
                                                        operatorValue: "equals",
                                                    },
                                                ],
                                            },
                                        },
                                    ]}
                                />
                            }
                        />
                        <MyAccordion
                            title={"財務發稿清單"}
                            children={
                                <DepositTable
                                    filterButtons={[
                                        {
                                            label: "待加值",
                                            filter: {
                                                items: [
                                                    {
                                                        columnField: "status",
                                                        value: DepositStatus.Pending,
                                                        operatorValue: "equals",
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            label: "已加值",
                                            filter: {
                                                items: [
                                                    {
                                                        columnField: "status",
                                                        value: DepositStatus.Finished,
                                                        operatorValue: "equals",
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            label: "退回發稿",
                                            filter: {
                                                items: [
                                                    {
                                                        columnField: "status",
                                                        value: DepositStatus.Rejected,
                                                        operatorValue: "equals",
                                                    },
                                                ],
                                            },
                                        },
                                    ]}
                                />
                            }
                        />
                    </Grid>
                </HasRole>

                <HasRole roles={[RoleIdEnum.Am, RoleIdEnum.Ae, RoleIdEnum.AmManager, RoleIdEnum.AeManager, RoleIdEnum.Gm, RoleIdEnum.Finance]}>
                    <Grid item sm={12}>
                        <MyAccordion
                            title={"付款單清單"}
                            children={
                                <PurchaseOrderTable
                                    defaultFilterItems={
                                        purchaseOrderFilterButtons.length == 1
                                            ? purchaseOrderFilterButtons[0]
                                                  .filter.items
                                            : []
                                    }
                                    filterButtons={
                                        purchaseOrderFilterButtons.length == 1
                                            ? []
                                            : purchaseOrderFilterButtons
                                    }
                                />
                            }
                        />
                    </Grid>
                </HasRole>
            </Grid>
        </Page>
    );
};
