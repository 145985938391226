import {Grid} from "@material-ui/core";
import {Check, X} from "react-feather";
import {PurchaseOrderStatusEnum} from "../../generated/graphql";
import {format} from "date-fns";

const TextGrid = ({children, color}) => {
    return <Grid container item justifyContent={"center"} style={{color: color, minHeight: 22}}>
        {children}
    </Grid>
}
export const StatusCell = ({name, state, time, isFinished, isRejected=false}:
                        {name?: string, state?: string, time?: string, isFinished: boolean, isRejected?: boolean}) => {
    // let isFinished = name==undefined;
    const color = isFinished ? "green" : "red"
    return <Grid container direction={"column"} justifyContent={"center"} style={{width: 210}}>
        <Grid container item justifyContent={"center"} alignItems={"center"} direction={"row"}>
            <div style={{width: 80, height: 1, backgroundColor: "gray"}}/>
            <Grid container justifyContent={"center"} alignItems={"center"} style={{width: 50, height: 50, backgroundColor: color, borderRadius: 25}}>
                { isFinished && <Check color="#ffffff"/> }
                { isRejected && <X color="#ffffff"/> }
            </Grid>
            <div style={{width: 80, height: 1, backgroundColor: "gray"}}/>
        </Grid>
        {[name, state, time ? format(new Date(time as string), "yyyy-MM-dd") : null].map((content, index)=> <TextGrid color={color} key={index}>{content}</TextGrid>)}
    </Grid>
}
export default ({purchaseOrder}) => {
    let statusInt = 0;
    if (purchaseOrder.status == PurchaseOrderStatusEnum.ManagerReview) {
        statusInt = 1;
    }
    else if (purchaseOrder.status == PurchaseOrderStatusEnum.GmReview) {
        statusInt = 2;
    }
    else if (purchaseOrder.status == PurchaseOrderStatusEnum.FinanceReview) {
        statusInt = 3;
    }
    else if (purchaseOrder.status == PurchaseOrderStatusEnum.Unpaid
        || purchaseOrder.status == PurchaseOrderStatusEnum.Paid) {
        statusInt = 4;
    }

    return <Grid container style={{marginTop: 20}}>
        <StatusCell name={purchaseOrder.initiator?.name} state={"送出申請"}
                    time={purchaseOrder.initiateDate} isFinished={statusInt>0}/>
        <StatusCell name={purchaseOrder.managerReviewer?.name} state={"主管審核"}
                    time={purchaseOrder.managerReviewDate}  isFinished={statusInt>1}/>
        <StatusCell name={purchaseOrder.gmReviewer?.name} state={"總經理審核"}
                    time={purchaseOrder.gmReviewDate}  isFinished={statusInt>2}/>
        <StatusCell name={purchaseOrder.financeReviewer?.name} state={"財務審核"}
                    time={purchaseOrder.financeReviewDate}  isFinished={statusInt>3}/>
    </Grid>
}