import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Page from "../../components/Page";
import {
    CustomerTypeEnum,
    InvoiceStatus,
    Order,
    OrderStatus,
    RoleIdEnum,
    useGetCurrentUserQuery,
    useGetOrderQuery,
    useRemoveInvoiceMutation,
    useRemoveOrderMutation,
    useUpdateInvoiceMutation,
    useUpdateOrderStatusMutation,
} from "../../generated/graphql";
import DefaultQueryHandler from "../../common/DefaultQueryHandler";
import { AppBar, Grid, Tab, Tabs } from "@material-ui/core";
import OrderItemBoxView from "../orderItem/OrderItemBoxView";
import DetailView from "../custom/DetailView";
import CreateOrderItem from "../orderItem/CreateOrderItem";
import UploadFile from "./UploadFile";
import {
    defaultLinkCellRenderer,
    PasteToClipboard,
    useTabIndex, ViewColumn,
} from "../../common/Util";
import CreateInvoice from "../invoice/CreateInvoice";
import ConfirmButton from "../../components/ConfirmButton";
import {
    InvoiceMethodMap, InvoiceStatusList,
    InvoiceStatusMap,
    OrderStatusMap, PaymentDaysMap,
} from "../../common/Constant";
import TabPanel from "../custom/TabPanel";
import { format } from "date-fns";
import UpdateOrder from "./UpdateOrder";
import { DataGridLocale } from "../../common/DataGridLocale";
import { InputType } from "../../common/FormRenderer";
import CreateView from "../custom/CreateView";
import DownloadOrderContract from "./DownloadOrderContract";
import ChangeInvoice from "../invoice/ChangeInvoice";
import ChangeInvoiceDate from "../invoice/ChangeInvoiceDate";
import NotificationPopup from "../../common/NotificationPopup";
import {
    GridValueFormatterParams,
    GridValueGetterParams,
    DataGridPro, GridColumnResizeParams, GridRenderCellParams,
} from "@mui/x-data-grid-pro";

export default () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const { data, loading, error } = useGetOrderQuery({
        variables: {
            id,
        },
    });
    const [tabIndex, setTabIndex] = useTabIndex();
    const [updateOrderStatus] = useUpdateOrderStatusMutation();
    const [updateInvoice] = useUpdateInvoiceMutation();
    const [removeOrder] = useRemoveOrderMutation();
    const [removeInvoice] = useRemoveInvoiceMutation();
    const currentUserQuery = useGetCurrentUserQuery();
    const order = data?.order;

    const changeStatus = async (status, changeReason = null) => {
        await updateOrderStatus({
            variables: {
                id,
                status: {
                    status,
                    changeReason,
                },
            },
            refetchQueries: ["getOrder"],
        });
    };
    const cancelInvoice = async (invoiceId, note) => {
        await updateInvoice({
            variables: {
                id: invoiceId,
                invoice: {
                    status: InvoiceStatus.CancelRequested,
                    note: note,
                },
            },
            refetchQueries: ["getOrder", "getInvoiceNotification"],
        });
    }
    const updateInvoiceStatus = async (invoiceId) => {
        await updateInvoice({
            variables: {
                id: invoiceId,
                invoice: {
                    status: InvoiceStatus.CancelRequested,
                },
            },
            refetchQueries: ["getOrder"],
        });
    };
    const onRemoveOrder = async (orderId) => {
        try {
            await removeOrder({
                variables: {
                    id: orderId,
                },
                refetchQueries: ["GetOrders"],
            });
            NotificationPopup.success(`刪除完成`);
            navigate("/app/order");
        } catch (e) {
            NotificationPopup.error(`刪除發生問題: ${e.message}`);
            console.error(e);
        }
    };
    const onRemoveInvoice = async (invoiceId) => {
        try {
            await removeInvoice({
                variables: {
                    id: invoiceId,
                },
                refetchQueries: ["getOrder"],
            });
            NotificationPopup.success(`刪除完成`);
        } catch (e) {
            NotificationPopup.error(`刪除發生問題: ${e.message}`);
            console.error(e);
        }
    };
    const invoiceColumns = [
        {
            field: "id",
            headerName: "ID",
            type: "number",
        },
        {
            field: "createdAt",
            headerName: "申請時間",
            valueGetter: (params: GridValueGetterParams) => {
                return params.row.createdAt
                    ? format(
                          new Date(params.row.createdAt as string),
                          "yyyy-MM-dd HH:mm"
                      )
                    : "";
            },
            type: "datetime",
        },
        { field: "invoiceNumber", headerName: "發票號碼" },
        {
            field: "postDate",
            headerName: "開立發票日期",
            valueGetter: (params: GridValueGetterParams) => {
                return params.row.postDate
                    ? format(new Date(params.row.postDate as string), "yyyy-MM-dd")
                    : "";
            },
            type: "date",
        },
        // {
        //     field: "postDate",
        //     headerName: "回填日期",
        //     width: 150,
        //     valueGetter: (params: GridValueGetterParams) => {
        //         return params.value
        //             ? format(new Date(params.value as string), "yyyy-MM-dd")
        //             : "";
        //     },
        // },
        {
            field: "status",
            headerName: "狀態",
            valueGetter: (params: GridValueGetterParams) => {
                return InvoiceStatusMap[params.row.status as string];
            },
            valueOptions: InvoiceStatusList,
            type: "singleSelect",
        },
        ViewColumn("/app/invoice"),
        {
            field: "action2",
            headerName: "操作",
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <>
                        {params.row.status == InvoiceStatus.Started ? (
                            <>
                                <ConfirmButton
                                    onConfirm={async () => {
                                        await onRemoveInvoice(params.row.id);
                                    }}
                                    queryEntity={"order"}
                                    queryAction={"取消發票"}
                                    entityId={params.row.id}
                                    queryMessage={`名稱: ${params.row.name}`}
                                >
                                    取消發票
                                </ConfirmButton>
                                &nbsp;
                                <ChangeInvoiceDate invoice={params.row} />
                            </>
                        ) : null}
                        &nbsp;
                        {params.row.status == InvoiceStatus.Posted ? (
                            <CreateView
                                title={"請求作廢"}
                                columns={[
                                    {
                                        name: "note",
                                        label: "作廢原因",
                                        type: InputType.text,
                                    },
                                ]}
                                onSubmit={(o) => {
                                    cancelInvoice(
                                        params.row.id,
                                        o.note,
                                    );
                                    return params.row
                                }}
                                submitTitle={"申請"}
                                queryEntity={"invoice"}
                            />
                        ) : null}
                        &nbsp;
                        <ChangeInvoice
                            invoice={params.row}
                            orderItems={order?.items}
                        ></ChangeInvoice>
                    </>
                );
            },
        },
    ];
    let total = 0;
    let remainder = 0;
    order?.items?.map((item) => {
        total += item.totalAmount! + item.serviceFee!;
        remainder += item.totalAmount! + item.serviceFee!;
    });
    order?.invoices?.map((invoice) => {
        invoice.items.map((item) => {
            if (invoice.status != InvoiceStatus.Canceled) {
                total -= item.amount!;
            }
            if (invoice.status == InvoiceStatus.Paid) {
                remainder -= item.amount!;
            }
        });
    });
    const orderColumns = [
        {
            field: "status",
            headerName: "狀態",
            valueFormatter: (value) => {
                const statusString = value == 'draft' ? OrderStatusMap[value] : '進行中';
                if (value == OrderStatus.Changed) {
                    return `${statusString} -  異動原因: ${order?.changeReason}`;
                } else if (value != OrderStatus.InvoiceRequested)
                    return statusString;
                else
                    return `${statusString} / ${
                        remainder == 0 ? "已付款" : "尚未完成付款"
                    }`;
            },
        },
        { field: "name", headerName: "專案名稱" },
        { field: "number", headerName: "委刊單號" },
        {
            field: "customer",
            headerName: "客戶名稱",
            valueFormatter: (value) => {
                if (order?.customer?.type==CustomerTypeEnum.Agent ) {
                    return `${value.name} - (廣告主: ${order?.advertiser ? order?.advertiser.name : '未指定'})`;
                } else {
                    return value.name;
                }
            },
        },
        { field: "contact", headerName: "聯絡人" },
        { field: "invoiceContact", headerName: "發票聯絡人" },
        { field: "createdAt", headerName: "建立時間" },
        { field: "files", headerName: "回簽檔" },
        {
            field: "invoiceMethod",
            headerName: "發票開立方法",
            valueFormatter: (value) => {
                return InvoiceMethodMap[value];
            },
        },
        {
            field: "currency",
            headerName: "幣別",
            valueFormatter: (value) => {
                return value == "TWD" ? "台幣" : "美金";
            },
        },
        { field: "paymentDays", headerName: "付款天數", valueFormatter: (value) => {return PaymentDaysMap[value]} },
        {
            field: "renewal",
            headerName: "新約/續約",
            valueFormatter: (value) => {
                return value ? "續約" : "新約";
            },
        },
        {
            field: "assignedSalesUser",
            headerName: "簽約負責人",
            valueFormatter: (user) => (user ? user.name : "無"),
        },
        {
            field: "remark",
            headerName: "合約備註",
        },
    ];

    let isManager = currentUserQuery.data?.me.roles.some(
        (role) =>
            role.id == RoleIdEnum.AeManager || role.id == RoleIdEnum.AmManager
    );

    return (
        <Page title={"Order detail page"}>
            <AppBar position="static" color="default">
                <Tabs
                    value={tabIndex}
                    indicatorColor="secondary"
                    textColor="secondary"
                    onChange={(
                        event: React.ChangeEvent<{}>,
                        newValue: number
                    ) => {
                        setTabIndex(newValue);
                    }}
                >
                    <Tab label="委刊單" />
                    <Tab label="委刊細項" />
                    <Tab label="發票" />
                </Tabs>
            </AppBar>
            <DefaultQueryHandler loading={loading} error={error}>
                <TabPanel value={tabIndex} index={0}>
                    <Grid container spacing={3}>
                        {order?.status == OrderStatus.Draft && (
                            <Grid item>
                                <ConfirmButton
                                    onConfirm={() => {
                                        onRemoveOrder(data?.order?.id);
                                    }}
                                    queryEntity={"order"}
                                    queryAction={"刪除委刊單"}
                                    entityId={data?.order?.id}
                                    queryMessage={`名稱: ${data?.order?.name}`}
                                >
                                    刪除
                                </ConfirmButton>
                            </Grid>
                        )}
                        {order?.status != OrderStatus.Finished && (
                            <Grid item>
                                <UpdateOrder order={data?.order}></UpdateOrder>
                            </Grid>
                        )}
                        {order?.status == OrderStatus.Draft && (
                            <Grid item>
                                <DownloadOrderContract order={order} />
                            </Grid>
                        )}
                        {order?.status == OrderStatus.Draft && (
                            <Grid item>
                                <UploadFile
                                    uriPart={`/orders/upload/${id}`}
                                    uploadTitle={"上傳回簽檔"}
                                    onSuccess={async () => {
                                        await changeStatus(order?.invoices?.length > 0 ? OrderStatus.InvoiceRequested : OrderStatus.OrderSigned);
                                    }}
                                    queryEntity={"order"}
                                    entityId={order.id}
                                />
                            </Grid>
                        )}
                        {order?.status != OrderStatus.Draft &&
                            order?.status != OrderStatus.Changed && (
                                <Grid item>
                                    <CreateView
                                        title={"異動委刊單"}
                                        columns={[
                                            {
                                                name: "changeReason",
                                                label: "異動原因",
                                                type: InputType.text,
                                            },
                                        ]}
                                        onSubmit={async (o) => {
                                            await changeStatus(
                                                OrderStatus.Changed,
                                                o.changeReason
                                            );
                                            return order;
                                        }}
                                        submitTitle={"申請"}
                                        queryEntity={"order"}
                                    />
                                </Grid>
                            )}
                        {order?.status == OrderStatus.Changed && isManager && (
                            <Grid item>

                                <CreateView
                                    title={"同意異動"}
                                    columns={[
                                        {
                                            name: "id",
                                            label: "",
                                            type: InputType.custom,
                                            renderCell: (
                                                <div
                                                    style={{
                                                        marginBottom: 10,
                                                    }}
                                                >
                                                    異動原因：
                                                    {order?.changeReason}
                                                </div>
                                            ),
                                        },
                                    ]}
                                    onSubmit={async () => {
                                        await changeStatus(OrderStatus.Draft);
                                        return order;
                                    }}
                                    submitTitle={"同意"}
                                    queryEntity={"order"}
                                />
                            </Grid>
                        )}
                        {/* {order?.status == OrderStatus.InvoiceRequested &&
                            remainder == 0 && (
                                <Grid item>
                                    <ConfirmButton
                                        onConfirm={() => {
                                            changeStatus(OrderStatus.Finished);
                                        }}
                                    >
                                        結案
                                    </ConfirmButton>
                                </Grid>
                            )} */}
                    </Grid>
                    {/*TODO:needs some styling and layout. Upload needs to trigger a refetch for getOrder
                if they are in the same view*/}
                    <DetailView data={data?.order} columns={orderColumns} />
                </TabPanel>
                <TabPanel value={tabIndex} index={1}>
                    {order?.status != OrderStatus.Finished && (
                        <CreateOrderItem order={order} />
                    )}
                    <Grid container style={{ padding: 20 }} spacing={5}>
                        {order?.items.map((orderItem) => (
                            <Grid item xs={12} sm={6} key={orderItem.id}>
                                <OrderItemBoxView
                                    order={order}
                                    orderItem={orderItem}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </TabPanel>
                <TabPanel value={tabIndex} index={2}>
                    <div>
                        {(order?.status == OrderStatus.OrderSigned ||
                            order?.status == OrderStatus.InvoiceRequested) && (
                            <CreateInvoice
                                order={order as Order}
                                total={total}
                            />
                        )}
                        <div style={{ marginTop: 10, marginBottom: 10 }}>
                            總剩餘可開發票的金額：{total.toLocaleString()}
                        </div>
                        <DataGridPro
                            rows={data?.order?.invoices!}
                            hideFooter={true}
                            autoHeight={true}
                            localeText={DataGridLocale}
                            onCellClick={PasteToClipboard}
                            columns={invoiceColumns.map((c) => ({
                                width: parseInt(window.localStorage.getItem(c.field) ?? '200'),
                                ...c,
                            }))}
                            onColumnWidthChange={(param: GridColumnResizeParams) => {
                                window.localStorage.setItem(param.colDef.field, param.width.toString())
                            }}
                        />
                    </div>
                </TabPanel>
            </DefaultQueryHandler>
        </Page>
    );
};
