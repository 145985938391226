import { useQuery } from "@apollo/client";
import { Grid } from "@material-ui/core";
import { format } from "date-fns";
import React, { useCallback, useState } from "react";
import { InputType } from "../../common/FormRenderer";
import NotificationPopup from "../../common/NotificationPopup";
import {hasRole, HasRole, selectMapFn} from "../../common/Util";
import ConfirmButton from "../../components/ConfirmButton";
import {
    PurchaseOrderStatusEnum,
    RoleIdEnum,
    useGetProductQuery,
    useNoopMutation, usePushMessageMutation, useRemovePurchaseOrderMutation, useRemoveReceivedPaymentMutation,
    useUpdatePurchaseOrderMutation,
} from "../../generated/graphql";
import CreateView from "../custom/CreateView";
import UploadFile from "../order/UploadFile";
import {useUser} from "../../Auth";
import {PurchaseOrderStatusList} from "../../common/Constant";
import {useNavigate} from "react-router-dom";
import {omit} from "lodash";

export default ({ purchaseOrder }) => {
    const navigate = useNavigate();
    const [updatePurchaseOrder] = useUpdatePurchaseOrderMutation();
    const [pushMessage] = usePushMessageMutation();
    const [noop] = useNoopMutation();
    const { data, loading, error } = useGetProductQuery({
        variables: {
            id: purchaseOrder.orderItem.product.id,
        },
    });
    const [contacts, setContacts] = useState(purchaseOrder.supplier.contacts);
    const user = useUser();
    const [removePurchaseOrder] = useRemovePurchaseOrderMutation();

    const onRemovePurchaseOrder = async () =>
    {
        try {
            await removePurchaseOrder({
                variables: {
                    id: purchaseOrder.id,
                },
                refetchQueries: ["getPurchaseOrderList", "getOrderItem"],
            });
            NotificationPopup.success(`刪除完成`);
            navigate("/app/purchaseOrder");
        } catch (e) {
            NotificationPopup.error(`刪除發生問題: ${e.message}`);
            console.error(e);
        }
    };

    const updateStatus = async (purchaseOrderDto) => {
        await updatePurchaseOrder({
            variables: {
                purchaseOrder: purchaseOrderDto,
                id: purchaseOrder.id,
            },
            refetchQueries: ["getPurchaseOrder", "getPurchaseOrderList"],
        });
    };

    const onSubmit = useCallback(
        async (updatedPurchaseOrder, { resetForm }, setOpen) => {
            if (updatedPurchaseOrder.amount != null) {
                updatedPurchaseOrder.amount = parseFloat(
                    updatedPurchaseOrder.amount
                );
            }
            try {
                const query = await updatePurchaseOrder({
                    variables: {
                        purchaseOrder: omit({...updatedPurchaseOrder, supplierId: updatedPurchaseOrder.supplierId}, ['supplier']),
                        id: purchaseOrder.id,
                    },
                    refetchQueries: ["getPurchaseOrder"],
                });
                if (updatedPurchaseOrder.status == PurchaseOrderStatusEnum.Draft) {
                    const message = `${purchaseOrder.orderItem.order.assignedSalesUser.name} 委刊細項ID:${purchaseOrder.orderItem.id} ` +
                        `付款單被退回, 退回原因:${updatedPurchaseOrder.description}\n${window.location.origin}/app/orderItem/${purchaseOrder.orderItem.id}`;
                    await pushMessage({variables: {message}});
                }
                NotificationPopup.success(`成功送出`);
                return query.data?.updatePurchaseOrder;
            } catch (e) {
                NotificationPopup.error(`發生問題: ${e.message}`);
                console.error(e);
            } finally {
                resetForm();
                setOpen(false);
            }
        },
        [updatePurchaseOrder]
    );

    return (
        <div style={{ margin: 10 }}>
            <Grid container spacing={2}>
                {((purchaseOrder?.status == PurchaseOrderStatusEnum.Draft)
                || hasRole(user, [RoleIdEnum.Admin])) && (
                    <Grid item>
                        <CreateView
                            title={"修改付款單"}
                            columns={[
                                {
                                    name: "supplierId",
                                    label: "供應商",
                                    type: InputType.autoComplete,
                                    onChange: (supplierId) => {
                                        data?.product.suppliers!.map(
                                            (supplier: any) => {
                                                if (supplierId == supplier.id) {
                                                    setContacts(
                                                        supplier!.contacts
                                                    );
                                                }
                                            }
                                        );
                                    },
                                    options: data
                                        ? data.product.suppliers!.map(
                                              selectMapFn
                                          )
                                        : [],
                                    value: {value: purchaseOrder.supplier.id, label: purchaseOrder.supplier.name},
                                    required: true,
                                },
                                {
                                    name: "contactId",
                                    label: "聯絡人",
                                    type: InputType.select,
                                    options: contacts.map(selectMapFn),
                                    value: purchaseOrder.contact.id,
                                },
                                {
                                    name: "amount",
                                    label: "付款單金額",
                                    type: InputType.number,
                                    value: purchaseOrder.amount,
                                },
                                {
                                    name: "note",
                                    label: "備註",
                                    value: purchaseOrder.note,
                                },
                                {
                                    name: "signedDate",
                                    label: "簽約日期",
                                    type: InputType.date,
                                    value: format(
                                        new Date(purchaseOrder.signedDate),
                                        "yyyy-MM-dd"
                                    ),
                                },
                                {
                                    name: "paidDate",
                                    label: "付款日期",
                                    type: InputType.date,
                                    value: format(
                                        new Date(purchaseOrder.paidDate),
                                        "yyyy-MM-dd"
                                    ),
                                },
                            ]}
                            onSubmit={onSubmit}
                            queryEntity={"purchaseOrder"}
                            submitTitle={"修改"}
                        />
                    </Grid>
                )}
                {purchaseOrder?.status == PurchaseOrderStatusEnum.Draft && (
                    <Grid item>
                        <UploadFile
                            uriPart={`/purchase-orders/upload/${purchaseOrder?.id}`}
                            uploadTitle={"上傳付款單"}
                            onSuccess={() => {
                                noop({
                                    variables: {},
                                    refetchQueries: ["getPurchaseOrder"],
                                });
                            }}
                            queryEntity={"purchaseOrder"}
                            entityId={purchaseOrder.id}
                        />
                    </Grid>
                )}
                {purchaseOrder?.status == PurchaseOrderStatusEnum.Draft && (
                    <Grid item>
                        <UploadFile
                            uriPart={`/purchase-orders/upload-invoice/${purchaseOrder?.id}`}
                            uploadTitle={"上傳發票"}
                            onSuccess={() => {
                                noop({
                                    variables: {},
                                    refetchQueries: ["getPurchaseOrder"],
                                });
                            }}
                            queryEntity={"purchaseOrder"}
                            entityId={purchaseOrder.id}
                        />
                    </Grid>
                )}
                {purchaseOrder?.status == PurchaseOrderStatusEnum.Draft &&
                purchaseOrder.files.length!=0 && purchaseOrder.invoiceFiles.length !=0 && (
                    <Grid item>
                        <ConfirmButton
                            onConfirm={() => {
                                updateStatus(
                                    {
                                        status: PurchaseOrderStatusEnum.ManagerReview,
                                        initiatorId: user?.id,
                                        initiateDate: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
                                    }
                                );
                            }}
                            queryEntity={"purchaseOrder"}
                            queryAction={"送出審核"}
                            entityId={purchaseOrder.id}
                        >
                            送出審核
                        </ConfirmButton>
                    </Grid>
                )}
                {purchaseOrder.status ==
                    PurchaseOrderStatusEnum.ManagerReview && (
                    <HasRole
                        roles={[RoleIdEnum.AmManager, RoleIdEnum.AeManager]}
                    >
                        <Grid item>
                            <CreateView
                                title={"退回申請"}
                                columns={[
                                    {
                                        name: "description",
                                        label: "退回原因",
                                        value: purchaseOrder.description,
                                    },
                                    {
                                        name: "status",
                                        label: "狀態",
                                        value: PurchaseOrderStatusEnum.Draft,
                                        options: PurchaseOrderStatusList,
                                        type: InputType.hidden,
                                    },
                                ]}
                                onSubmit={onSubmit}
                                queryEntity={"purchaseOrder"}
                            />
                        </Grid>
                        <Grid item>
                            <ConfirmButton
                                onConfirm={() => {
                                    updateStatus(
                                        {
                                            status: PurchaseOrderStatusEnum.GmReview,
                                            managerReviewerId: user?.id,
                                            managerReviewDate: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
                                        },
                                    );
                                }}
                                queryEntity={"purchaseOrder"}
                                queryAction={"審核通過"}
                                entityId={purchaseOrder.id}
                            >
                                審核通過
                            </ConfirmButton>
                        </Grid>
                    </HasRole>
                )}
                {purchaseOrder.status ==
                    PurchaseOrderStatusEnum.GmReview && (
                    <HasRole
                        roles={[RoleIdEnum.Gm]}
                    >
                        <Grid item>
                            <CreateView
                                title={"退回申請"}
                                columns={[
                                    {
                                        name: "description",
                                        label: "退回原因",
                                        value: purchaseOrder.description,
                                    },
                                    {
                                        name: "status",
                                        label: "狀態",
                                        value: PurchaseOrderStatusEnum.Draft,
                                        options: PurchaseOrderStatusList,
                                        type: InputType.hidden,
                                    },
                                ]}
                                onSubmit={onSubmit}
                                queryEntity={"purchaseOrder"}
                            />
                        </Grid>
                        <Grid item>
                            <ConfirmButton
                                onConfirm={() => {
                                    updateStatus({
                                            status: PurchaseOrderStatusEnum.FinanceReview,
                                            gmReviewerId: user?.id,
                                            gmReviewDate: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
                                        }
                                    );
                                }}
                                queryEntity={"purchaseOrder"}
                                queryAction={"審核通過"}
                                entityId={purchaseOrder.id}
                            >
                                審核通過
                            </ConfirmButton>
                        </Grid>
                    </HasRole>
                )}
                {purchaseOrder.status ==
                    PurchaseOrderStatusEnum.FinanceReview && (
                    <HasRole roles={[RoleIdEnum.Finance]}>
                        <Grid item>
                            <CreateView
                                title={"退回申請"}
                                columns={[
                                    {
                                        name: "description",
                                        label: "退回原因",
                                        value: purchaseOrder.description,
                                    },
                                    {
                                        name: "status",
                                        label: "狀態",
                                        value: PurchaseOrderStatusEnum.Draft,
                                        options: PurchaseOrderStatusList,
                                        type: InputType.hidden,
                                    },
                                ]}
                                onSubmit={onSubmit}
                                queryEntity={"purchaseOrder"}
                            />
                        </Grid>
                        <Grid item>
                            <ConfirmButton
                                onConfirm={() => {
                                    updateStatus({
                                            status: PurchaseOrderStatusEnum.Unpaid,
                                            financeReviewerId: user?.id,
                                            financeReviewDate: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
                                        }
                                    );
                                }}
                                queryEntity={"purchaseOrder"}
                                queryAction={"審核通過"}
                                entityId={purchaseOrder.id}
                            >
                                審核通過
                            </ConfirmButton>
                        </Grid>
                    </HasRole>
                )}
                {purchaseOrder.status == PurchaseOrderStatusEnum.Unpaid && (
                    <HasRole roles={[RoleIdEnum.Finance]}>
                        <Grid item>
                            <CreateView
                                title={"回填付款"}
                                columns={[
                                    {
                                        name: "paidDate",
                                        label: "實際付款日期",
                                        type: InputType.date,
                                        value: format(
                                            new Date(purchaseOrder.paidDate),
                                            "yyyy-MM-dd"
                                        ),
                                    },
                                    {
                                        name: "status",
                                        label: "狀態",
                                        value: PurchaseOrderStatusEnum.Paid,
                                        options: PurchaseOrderStatusList,
                                        type: InputType.hidden,
                                    },
                                ]}
                                onSubmit={onSubmit}
                                queryEntity={"purchaseOrder"}
                            />
                        </Grid>
                    </HasRole>
                )}
                <HasRole roles={[RoleIdEnum.Finance]}>
                    <Grid item>
                        <ConfirmButton
                            onConfirm={async () =>
                            {
                                await onRemovePurchaseOrder();
                            }}
                            queryEntity={"purchaseOrder"}
                            queryAction={"刪除付款單"}
                            entityId={purchaseOrder.id}
                            queryMessage={`委刊細項ID：${purchaseOrder.orderItem.id}`}>刪除付款單
                        </ConfirmButton>
                    </Grid>
                </HasRole>
            </Grid>
        </div>
    );
};
