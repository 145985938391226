import Page from "../../components/Page";
import {useParams} from "react-router-dom";
import {
    CreditClassEnum,
    RoleIdEnum,
    UpgradeStatus,
    useCreateLogMutation,
    useGetUpgradeQuery,
    useRemoveUpgradeMutation,
    useUpdateUpgradeMutation
} from "../../generated/graphql";
import {Card, FormControl, FormLabel, Grid, TextField, Box} from "@material-ui/core";
import ConfirmButton from "../../components/ConfirmButton";
import DetailView from "../custom/DetailView";
import DefaultQueryHandler from "../../common/DefaultQueryHandler";
import {Question} from "../customer/CustomerCreditView";
import React, {useCallback, useEffect, useState} from "react";
import {filter, find, pick, reduce} from "lodash";
import NotificationPopup from "../../common/NotificationPopup";
import {getModifiedValues} from "../custom/EditView";
import {useUser} from "../../Auth";
import {UpgradeStatusMap} from "../../common/Constant";
import {useNavigate} from "react-router";
import UpgradeStatusView from "./UpgradeStatusView";
import {NumberFormatCustom} from "../../common/FormRenderer";
import {hasRole} from "../../common/Util";


const TextQuestion = ({question, answer, setAnswer, type, editable=false}) => {
    return <Grid item xs={"auto"}>
        <Card style={{margin: 20, padding: 20}}>
            <FormControl style={{width: "100%"}}>
                <FormLabel style={{fontSize: 20, marginBottom: 20}}>{question.title}</FormLabel>
                <TextField
                    // type={type}
                    value={answer[question.field]}
                    disabled={!editable}
                    onChange={(e) => {
                        const value = type == "number" ? parseInt(e.target.value) : e.target.value;
                        setAnswer({...answer, [question.field]:value});
                    }}
                    multiline={type == "text"}
                    InputProps={type == "number" ? {
                        inputComponent:
                            NumberFormatCustom as any,
                    } : {}}
                ></TextField>
            </FormControl>
        </Card>
    </Grid>
}
export default () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const { data, loading, error } = useGetUpgradeQuery({
        variables: {
            id,
        },
    });
    const [updateUpgrade] = useUpdateUpgradeMutation();
    const [removeUpgrade] = useRemoveUpgradeMutation();
    const [answer, setAnswer] = useState<any>(null);
    const stages:any = [{
        questions: [{
            title: "申請原因說明",
            field: "description",
            type: "text",
        },{
            title: "近三個月訂單總金額",
            field: "lastThreeMonthAmount",
            type: "number",
        },{
            title: "欲調整信用評等等級",
            field: "targetCreditClass",
            answers: filter([
                {value: "S", label: "S級"},
                {value: "A", label: "A級"},
                {value: "B", label: "B級"},
                {value: "C", label: "C級"},
            ],(o)=> data?.upgrade.status == UpgradeStatus.Success ? true
                : (o.value!=data?.upgrade?.customer?.creditClass)),
        }],
        status: UpgradeStatus.Draft,
        next: UpgradeStatus.FinanceReview,
        userTitle: "申請人",
        user: data?.upgrade.ae,
        idField: "aeId",
        rejectButton: false,
        roles: Object.values(RoleIdEnum),
    },{
        questions: [{
            title: "帳列應收帳款餘額",
            field: "receivableAmount",
            type: "number",
        },{
            title: "暫收款",
            field: "unrecordedAmount",
            type: "number",
        },{
            title: "是否有逾期款",
            field: "overdue",
            answers: filter([
                {value: true, label: "是"},
                {value: false, label: "否"},
            ]),
        },{
            title: "財務部門意見回饋",
            field: "financeComment",
            type: "text",
        }],
        status: UpgradeStatus.FinanceReview,
        next: UpgradeStatus.ManagerReview,
        userTitle: "財務",
        user: data?.upgrade.finance,
        idField: "financeId",
        rejectButton: false,
        roles: [RoleIdEnum.Finance],
    },{
        questions: [{
            title: "業務主管針對同仁申請客戶信用評等調整之意見回饋",
            field: "managerComment",
            type: "text",
        }],
        status: UpgradeStatus.ManagerReview,
        next: [CreditClassEnum.A, CreditClassEnum.S].includes(data?.upgrade.targetCreditClass as CreditClassEnum) ? UpgradeStatus.GmReview : UpgradeStatus.Success,
        userTitle: "主管",
        user: data?.upgrade.manager,
        idField: "managerId",
        rejectButton: true,
        roles: [RoleIdEnum.AeManager, RoleIdEnum.AmManager],
    },{
        questions: [{
            title: "總經理意見回饋",
            field: "gmComment",
            type: "text",
        }],
        status: UpgradeStatus.GmReview,
        next: UpgradeStatus.Success,
        userTitle: "總經理",
        user: data?.upgrade.gm,
        idField: "gmId",
        rejectButton: true,
        roles: [RoleIdEnum.Gm],
    }];
    const answerFields = reduce(stages,(acc, stage)=>acc.concat(stage.questions.map((question)=>question.field)), []);
    useEffect(()=>{
        if (data) {
            setAnswer(pick(data?.upgrade, answerFields));
        }
    }, [data]);
    const [createLog] = useCreateLogMutation();
    const user = useUser();
    const onRemove = useCallback(async () => {
        try {
            await removeUpgrade({
                variables: {id},
                refetchQueries: ["getUpgrades"],
            });
            NotificationPopup.success(`刪除成功`);
            navigate("/app/upgrade");
        } catch (e) {
            NotificationPopup.error(`刪除發生問題: ${e.message}`);
            console.error(e);
        }
    }, []);
    const onUpdate = useCallback(async (updatedFields) => {
        try {
            await updateUpgrade({
                variables: {
                    id: data?.upgrade.id as string,
                    upgrade: {...answer, ...updatedFields},
                },
                refetchQueries: ["getUpgrade", "getUpgrades", "getCustomer"],
            });
            NotificationPopup.success(`更新完成`);
            await createLog({
                variables: {
                    log: {
                        entityId: data?.upgrade.id as string,
                        userId: user!.id as string,
                        queryAction: "編輯信用升等",
                        queryEntity: "customer",
                        queryMessage: `${getModifiedValues(pick(data?.upgrade, answerFields), answer, '編輯')}`,
                    }
                }
            });
        } catch (e) {
            NotificationPopup.error(`編輯信用升等發生問題: ${e.message}`);
            console.error(e);
        }
    }, [answer]);

    return (
        <Page title={"upgrade detail page"}>
            {answer!=null && <DefaultQueryHandler loading={loading} error={error}>
                {(data?.upgrade.status==UpgradeStatus.Draft || hasRole(user, [RoleIdEnum.Admin]))
                && <Box margin={2}><ConfirmButton  onConfirm={async ()=>{await onRemove()}}>刪除</ConfirmButton></Box>}
                <UpgradeStatusView upgrade={data?.upgrade}/>
                <DetailView data={data?.upgrade} columns={[
                    {field: "customer", headerName: "客戶名稱", valueFormatter: (value) =>
                        {
                            return value?.name;
                        }},
                    { field: "createdAt", headerName: "建立時間" },
                    { field: "updatedAt", headerName: "更新時間" },
                    { field: "status", headerName: "狀態",
                        valueFormatter: (value) => {
                            return UpgradeStatusMap[value];
                        }}
                ]}/>
                {stages.map((stage, index)=>{
                    const enabled:any = stage.status==data?.upgrade.status && find(user?.roles, (role)=>{return stage.roles.includes(role.id)});
                    if (stage.status!=data?.upgrade.status && stage.user == null) {
                        return;
                    }
                    return (<Card style={{margin: 20, padding: 20, width: "80%"}}>
                        {`${stage.userTitle}：${stage.user?.name ?? ''}`}
                        {stage.questions.map((question)=>{
                            if (question.answers != null ) {
                                return <Question question={{
                                    title: question.title,
                                    field: question.field,
                                    answers: question.answers,
                                }} answer={answer} setAnswer={setAnswer} editable={stage.status==data?.upgrade.status}/>
                            }
                            else {
                                return <TextQuestion question={{title: question.title, field: question.field}}
                                                     answer={answer}
                                                     setAnswer={setAnswer}
                                                     editable={enabled}
                                                     type={question.type}
                                />
                            }
                        })}
                        <Grid container spacing={3}>
                            {enabled && <Grid item><ConfirmButton onConfirm={async ()=>{
                                await onUpdate({[stage.idField]: user?.id, status: stage.next});
                            }}>{stage.status==UpgradeStatus.Draft ? "提交" : "同意"}
                            </ConfirmButton></Grid>}

                            {enabled && stage.rejectButton &&
                            <Grid item><ConfirmButton onConfirm={async ()=>{await onUpdate(
                                {[stage.idField]: user?.id, status: UpgradeStatus.Rejected})}}>不同意</ConfirmButton></Grid>}
                        </Grid>
                    </Card>);
                })}

            </DefaultQueryHandler>}
        </Page>
    );
}