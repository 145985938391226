import {
    Button,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Input,
    InputLabel, MenuItem,
    Select,
    Typography
} from "@material-ui/core";
import React, {useState} from "react";
import {ProductOrigin} from "../../generated/graphql";
import UploadFile from "../order/UploadFile";
import {format} from "date-fns";

export default () => {
    const [open, setOpen] = useState(false);
    const [productOrigin, setProductOrigin] = useState<ProductOrigin>(ProductOrigin.Google)
    const [missingAccountCosts, setMissingAccountCosts] = useState([])
    return <div>
        <Dialog open={open} fullWidth={true}>
            <DialogTitle style={{backgroundColor: "#DDD"}}>
                <Typography variant="h4" align="center">
                    上傳每日花費
                </Typography>
            </DialogTitle>
            <DialogContent>
                <FormControl required style={{width: "100%", marginBottom: 10}}>
                    <InputLabel htmlFor="select-native">媒體</InputLabel>
                    <Select defaultValue={productOrigin} input={<Input />} onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                        setProductOrigin(event.target.value as ProductOrigin);
                    }}>
                        <MenuItem value={"google"} key={"google"}>Google</MenuItem>
                        <MenuItem value={"facebook"} key={"facebook"}>Meta</MenuItem>
                        <MenuItem value={"line"} key={"line"}>LINE</MenuItem>
                        <MenuItem value={"yahoo"} key={"yahoo"}>Yahoo</MenuItem>
                    </Select>
                </FormControl>
                <UploadFile
                    uploadTitle={"上傳花費Excel"}
                    uriPart={`/account-costs/import-product-origin-costs?productOrigin=${productOrigin}`}
                    onSuccess={(result)=>{
                        setMissingAccountCosts(result.missingAccountCosts);
                    }}
                />
                {(missingAccountCosts.length>0) && <>無發稿CID:<br/><div>
                {missingAccountCosts.map((accountCost: any)=>{
                    return <div>{`${accountCost.name} ${format(new Date(accountCost.date), "yyyy-MM-dd")} ${accountCost.accountId} ${accountCost.cost}`}</div>;
                })}</div></>}

            </DialogContent>
            <DialogActions>
                <Button
                    variant={"contained"}
                    color={"secondary"}
                    onClick={()=>{
                        setMissingAccountCosts([]);
                        setOpen(false);
                    }}>
                    取消
                </Button>
            </DialogActions>
        </Dialog>
        <Button
            variant={"contained"}
            color={"primary"}
            onClick={() => setOpen(true)}
        >
            上傳每日花費
        </Button>
    </div>
}