import {AppBar, FormControl, Grid, InputLabel, MenuItem, Select, Tab, Tabs} from "@material-ui/core";
import React, {useCallback, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {CustomerTypeList, DisableList, InvoiceMethodList, PaymentDaysList,} from "../../common/Constant";
import DefaultQueryHandler from "../../common/DefaultQueryHandler";
import {InputType} from "../../common/FormRenderer";
import NotificationPopup from "../../common/NotificationPopup";
import {dateValueFormatter, hasRole, selectMapFn, useTabIndex} from "../../common/Util";
import Page from "../../components/Page";
import {
    CreditClassEnum,
    CustomerTypeEnum,
    RoleIdEnum,
    useCreateLogMutation,
    useGetCustomerQuery,
    useGetUserListQuery,
    useNoopMutation,
    useRemoveCustomerMutation,
    useUpdateCustomerMutation,
} from "../../generated/graphql";
import AdvertiserTable from "../advertiser/AdvertiserTable";
import CreateAdvertiserView from "../advertiser/CreateAdvertiserView";
import ContactTable from "../contact/ContactTable";
import CreateContactView from "../contact/CreateContactView";
import EditView, {getModifiedValues} from "../custom/EditView";
import TabPanel from "../custom/TabPanel";
import {getLogQueryMessage} from "../log/LogUtils";
import {useUser} from "../../Auth";
import DownloadContractButton from "./DownloadContractButton";
import UploadFile from "../order/UploadFile";
import DetailView from "../custom/DetailView";
import {format} from "date-fns";
import CustomerCreditView from "./CustomerCreditView";
import {filter, find} from "lodash";
import {FieldTagList, IndustryTagList, ProductTagList} from "../../common/IndustryConstant";
import CreateUpgradeButton from "../upgrade/CreateUpgradeButton";
import RebateTable from "../rebate/RebateTable";
import CreateRebateButton from "../rebate/CreateRebateButton";
import CreateView from "../custom/CreateView";
import {download} from "../report/DownloadableReports";

/*
[
    {name: "product",label:"商品",type:InputType.select,options:data?.products.map(selectMapFn)},
    {name: "supplier",label:"廠商",type:InputType.select,options:data?.suppliers.map(selectMapFn)},
    {name: 'totalAmount', label: '購買金額', value: 0},
    {name: 'serviceFee', label: '帳戶執行費'},
    {name: 'budget', label: '成本預算'},
    {name: 'startDate', label: '開始時間',type:InputType.date},
    {name: 'endDate',label: '結束時間',type:InputType.date, value: Date()},
]
*/

export const findDefaultTag = (industryTag) => {
    const result = {};
    const found:any = find(IndustryTagList,(industry:any)=>{
        const found = find(industry.list, (industry)=>{
            const found = find(industry.list, (industry)=>{
                return industry.id == industryTag;
            });
            result['3name'] = found?.name;
            result['3'] = found?.id;
            return found;
        });
        result['secondLevel'] = found;
        result['2name'] = found?.name;
        result['2'] = found?.id;
        return found;
    });
    result['firstLevel'] = found;
    result['1name'] = found?.name;
    result['1'] = found?.id;
    return result;
}
const dateFormatter = (value) =>
    value ? format(new Date(value), "yyyy-MM-dd") : "未設定";

export const industryTagColumn = (industryTag)=>
{
    return {
        name: "industryTag",
        label: "產業別大類",
        type: InputType.custom,
        options: ProductTagList.map((product) => ({value: product.id, label: product.name})),
        RenderCell: ({form, field}) =>
        {
            const defaultObject: any = findDefaultTag(industryTag);
            const [firstLevel, setFirstLevel] = useState<any>(defaultObject.firstLevel);
            const [secondLevel, setSecondLevel] = useState<any>(defaultObject.secondLevel);
            return <Grid container spacing={1}>
                <Grid item xs={3}>
                    <FormControl style={{width: "100%"}}>
                        <InputLabel htmlFor="select-native">產業別大類</InputLabel>
                        <Select defaultValue={defaultObject['1']} onChange={(event) =>
                        {
                            setFirstLevel(find(IndustryTagList, (industry) => industry.id == event.target.value));
                            setSecondLevel(null);
                        }}>
                            {IndustryTagList.map((industry) =>
                            {
                                return (
                                    <MenuItem value={industry.id} key={industry.id}>
                                        {industry.id} {industry.name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl style={{width: "100%"}}>
                        <InputLabel htmlFor="select-native">產業別中類</InputLabel>
                        <Select defaultValue={defaultObject['2']} onChange={(event) =>
                        {
                            setSecondLevel(find(firstLevel?.list, (industry) => industry.id == event.target.value))
                        }}>
                            {firstLevel?.list.map((industry) =>
                            {
                                return (
                                    <MenuItem value={industry.id} key={industry.id}>
                                        {industry.id} {industry.name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl style={{width: "100%"}}>
                        <InputLabel htmlFor="select-native">產業別小類</InputLabel>
                        <Select defaultValue={defaultObject['3']} onChange={(event) =>
                        {
                            form.setFieldValue(
                                field.name,
                                (event.target.value as string).toString(),
                            );
                        }}>
                            {secondLevel?.list.map((industry) =>
                            {
                                return (
                                    <MenuItem value={industry.id} key={industry.id}>
                                        {industry.id} {industry.name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        }
    };
}
export default () => {
    const navigate = useNavigate();
    const {id} = useParams();
    const {data, loading, error} = useGetCustomerQuery({
        variables: {id},
    });
    const userListQuery = useGetUserListQuery();
    const [updateCustomer] = useUpdateCustomerMutation();
    const [removeCustomer] = useRemoveCustomerMutation();
    const [tabIndex, setTabIndex] = useTabIndex();
    const [customerType, setCustomerType] = useState<CustomerTypeEnum>();
    const user = useUser();
    const [createLog] = useCreateLogMutation();
    const [noop] = useNoopMutation();
    const onSubmit = useCallback(
        async (customer, {resetForm}) => {
            try {
                await updateCustomer({
                    variables: {
                        customer: customer,
                        id,
                    },
                    refetchQueries: ["getCustomer", "getCustomerList"],
                });
                NotificationPopup.success(`更新完成`);
                await createLog({
                    variables: {
                        log: {
                            entityId: id,
                            userId: user!.id as string,
                            queryAction: "修改客戶",
                            queryEntity: "customer",
                            queryMessage: `${getLogQueryMessage(getModifiedValues(customer, data?.customer, ''), getColumns(
                                userListQuery.data,
                                data?.customer
                            ))}`,
                        }
                    }
                });
            } catch (e) {
                NotificationPopup.error(`編輯客戶發生問題: ${e.message}`);
                console.error(e);
            }
        },
        [updateCustomer, user, data, userListQuery]
    );
    const onRemove = async () => {
        try {
            await removeCustomer({
                variables: {
                    id: id,
                },
                refetchQueries: ["getCustomerList"],
            });
            NotificationPopup.success(`刪除完成`);
            navigate(`/app/customer`);
        } catch (e) {
            NotificationPopup.error(`刪除發生問題: ${e.message}`);
            console.error(e);
        }
    };
    const defaultPaymentDays = filter(PaymentDaysList, ({value, label})=>{
        switch (data?.customer?.creditClass) {
            case CreditClassEnum.S:
                return [0, 30, 45, 55, 60, 90, 95, data?.customer?.defaultPaymentDays].includes(value);
            case CreditClassEnum.A:
                return [0, 30, 45, 55, 60, data?.customer?.defaultPaymentDays].includes(value);
            case CreditClassEnum.B:
                return [0, 30, 45, data?.customer?.defaultPaymentDays].includes(value);
            case CreditClassEnum.C:
                return [0, data?.customer?.defaultPaymentDays].includes(value);
        }
    });
    const getColumns = (userData, customer) => {
        const customerColumns = [
            {name: "name", label: "客戶名稱", value: customer.name},
            {
                name: "type",
                label: "客戶類型",
                type: InputType.select,
                value: customer.type,
                options: CustomerTypeList,
                onChange: (data) => {
                    setCustomerType(data)
                },
            },
            {name: "phone", label: "電話", value: customer.phone},
            {name: "fax", label: "傳真", value: customer.fax},
            {name: "address", label: "地址", value: customer.address},
            {
                name: "assigneeId",
                label: "負責人",
                type: InputType.select,
                options: userData ? userData.users.map(selectMapFn) : [],
                value: customer.assignee?.id,
            },
            {
                name: "billingAddress",
                label: "帳單地址",
                value: customer.billingAddress,
            },
            {name: "taxId", label: "統一編號", value: customer.taxId, required: true},
            industryTagColumn(data?.customer?.industryTag),
            {
                name: "productTag",
                label: "產品標籤",
                type: InputType.select,
                options: ProductTagList.map((product)=>({value: product.id,label: `${product.id} ${product.name}`
                })),
                value: customer.productTag,
            },
            {
                name: "fieldTag",
                label: "場域標籤",
                type: InputType.select,
                options: FieldTagList.map((field)=>({value: field.id,label: `${field.id} ${field.name}`
            })),
                value: customer.fieldTag,
            },
            {
                name: "invoiceMethod",
                label: "發票開立方法",
                type: InputType.select,
                options: InvoiceMethodList,
                value: customer.invoiceMethod,
            },
            {
                name: "defaultPaymentDays",
                label: "付款天數",
                type: InputType.select,
                options: defaultPaymentDays,
                value: customer.defaultPaymentDays,
            },
            {
                name: "creditClass",
                label: "信用評等",
                value: customer.creditClass ? `${customer.creditClass}等級` : null,
                disabled: true,
            },
            {
                name: "virtualAccountNumber",
                label: "虛擬付款帳號",
                value: customer.virtualAccountNumber,
                disabled: true,
            },
            {
                name: "defaultDiscountPercentage",
                label: "現折%數",
                type: InputType.number,
                value: customer.defaultDiscountPercentage,
            },
            {
                name: "disabled",
                label: "是否隱藏",
                type: InputType.select,
                options: DisableList,
                value: customer.disabled,
            },
            {name: "bankCode", label: "銀行代碼", value: customer.bankCode},
            {name: "bankAccountNumber", label: "銀行帳號", value: customer.bankAccountNumber},
            {
                name: "registerDate",
                label: "公司登記日期",
                type: InputType.date,
                value: format(new Date(customer.registerDate), "yyyy-MM-dd"),
            },
            {
                name: "capital",
                label: "資本額",
                type: InputType.number,
                value: customer.capital,
            },
            {
                name: "answerEditable",
                label: "業務可編輯信用評等",
                type: hasRole(user, [RoleIdEnum.Finance]) ? InputType.checkbox : InputType.hidden,
                value: customer.answerEditable,
            },
            {
                name: "invoiceExpired",
                label: "已逾期",
                type: hasRole(user, [RoleIdEnum.Admin, RoleIdEnum.Finance]) ? InputType.checkbox : InputType.hidden,
                value: customer.invoiceExpired,
            },
        ];
        return customerColumns;
    };

    return (
        <Page title={"customer detail page"}>
            <AppBar position="static" color="default">
                <Tabs
                    value={tabIndex}
                    indicatorColor="secondary"
                    textColor="secondary"
                    onChange={(
                        event: React.ChangeEvent<{}>,
                        newValue: number
                    ) => {
                        setTabIndex(newValue);
                    }}
                >
                    <Tab label="客戶"/>
                    <Tab label="聯絡人"/>
                    <Tab label="合約"/>
                    <Tab label="信用評等"/>

                    {hasRole(user, [RoleIdEnum.Op, RoleIdEnum.Finance, RoleIdEnum.AeManager, RoleIdEnum.AmManager]) && <Tab label="退佣"/>}
                    {data?.customer?.type == CustomerTypeEnum.Agent && <Tab label="廣告主"/>}
                </Tabs>
            </AppBar>
            <DefaultQueryHandler
                error={error}
                loading={
                    loading &&
                    userListQuery.loading
                }
            >
                <TabPanel value={tabIndex} index={0}>
                    {data?.customer && (
                        <EditView
                            columns={getColumns(
                                userListQuery.data,
                                data?.customer,
                            )}
                            onSubmit={onSubmit}
                            onRemove={onRemove}
                        />
                    )}
                </TabPanel>
                <TabPanel value={tabIndex} index={1}>
                    <CreateContactView customer={data?.customer}/>
                    <ContactTable contacts={data?.customer?.contacts}/>
                </TabPanel>
                <TabPanel index={tabIndex} value={2}>
                    <Grid container spacing={3}>
                        <Grid item>
                            <DownloadContractButton customer={data?.customer}/>
                        </Grid>
                        <Grid item>
                            <UploadFile
                                uriPart={`/customer/upload/${data?.customer?.id}`}
                                uploadTitle={"上傳合約書"}
                                onSuccess={async () => {
                                    await noop({refetchQueries: ["getCustomer"]});
                                }}
                                queryEntity={"customer"}
                                entityId={data?.customer?.id}
                            />
                        </Grid>
                    </Grid>
                    <DetailView data={data?.customer} columns={[
                        {field: "contractStartDate",headerName: "合約開始日期", valueFormatter: dateFormatter},
                        {field: "contractEndDate",headerName: "合約結束日期", valueFormatter: dateFormatter},
                        {field: "contractFiles", headerName: "合約書"},
                    ]}/>
                </TabPanel>
                <TabPanel value={tabIndex} index={3}>
                    {data?.customer && <div>
                        <CreateUpgradeButton customers={[data?.customer]}></CreateUpgradeButton>
                        <CustomerCreditView customer={data?.customer}/>
                    </div>}
                </TabPanel>
                {hasRole(user, [RoleIdEnum.Op, RoleIdEnum.Finance, RoleIdEnum.AeManager, RoleIdEnum.AmManager]) &&
                    <TabPanel value={tabIndex} index={4}>
                        {data?.customer && <div>
                            <Grid container spacing={3}>
                                <Grid item>
                                    <CreateRebateButton customer={data?.customer}/>
                                </Grid>
                                <Grid item>
                                    <CreateView submitTitle={"下載退佣"}  title={"下載退佣"} columns={[
                                        { name: "startDate", label: "開始日期",type:InputType.date },
                                        { name: "endDate", label: "結束日期",type:InputType.date },
                                    ]}
                                                onSubmit={async(res,helpers,setOpen)=>
                                                {
                                                    await download(()=>setOpen(false),
                                                        `customer/fetchRebates?startDate=${res.startDate}&endDate=${res.endDate}&customerId=${id}`,
                                                        `${data?.customer?.name} 退佣 ${res.startDate}-${res.endDate}`);
                                                    setOpen(false);
                                                }}
                                                queryEntity={"report"}
                                    />
                                </Grid>
                            </Grid>
                            <RebateTable customer={data?.customer}/>
                        </div>}
                    </TabPanel>}
                {data?.customer?.type == CustomerTypeEnum.Agent && (
                    <TabPanel value={tabIndex} index={hasRole(user, [RoleIdEnum.Op, RoleIdEnum.Finance, RoleIdEnum.AeManager, RoleIdEnum.AmManager]) ? 5 : 4}>
                        <CreateAdvertiserView customer={data?.customer}/>
                        <AdvertiserTable advertisers={data?.customer?.advertisers}/>
                    </TabPanel>
                )}

            </DefaultQueryHandler>
        </Page>
    );
};
