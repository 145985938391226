import CreateView from "../custom/CreateView";
import {InputType} from "../../common/FormRenderer";
import {useCallback, useState} from "react";
import NotificationPopup from "../../common/NotificationPopup";
import {useCreateUpgradeMutation} from "../../generated/graphql";
import {selectMapFn} from "../../common/Util";
import {useNavigate} from "react-router-dom";


export default ({customers}) => {
    const navigate = useNavigate();
    const [createUpgrade] = useCreateUpgradeMutation();
    const [selectId, setSelectId] = useState(customers?.length == 1 ? customers[0].id : null);
    const onSubmit = useCallback(
        async (upgrade, { resetForm }, setOpen) => {
            try {
                const newUpgrade = await createUpgrade({
                    variables: {upgrade: {customerId: selectId}},
                    refetchQueries: ["getUpgrades"],
                });
                NotificationPopup.success(`申請完成`);
                navigate(`/app/upgrade/${newUpgrade.data?.createUpgrade.id}`);
                return newUpgrade.data?.createUpgrade;
            } catch (e) {
                NotificationPopup.error(
                    `申請信用升等發生問題: ${e.message}`
                );
                console.error(e);
            } finally {
                resetForm();
                setOpen(false);
            }
        },
        [createUpgrade, selectId]
    );
    return (<CreateView
            title={"申請信用升等"}
            columns={[
                {
                    name: "customerId",
                    label: "客戶名稱",
                    type: InputType.autoComplete,
                    options: customers?.map(selectMapFn),
                    required: true,
                    value: customers?.length == 1 ? {value: customers[0].id, label: customers[0].name} : null,
                    onChange: (selectId) => {
                        setSelectId(selectId?.value ?? selectId);
                    },
                }
            ]}
            onSubmit={onSubmit}
            queryEntity={"upgrade"}
        />
    )
}