import React from 'react';
import {Button} from "@material-ui/core";
import {DefaultLayout} from "./layouts/MainLayout";


export class ErrorBoundary extends React.Component<{},{hasError:boolean,error:any}> {
    constructor(props) {
        super(props);
        this.state = { hasError: false,error:null };
        window.onerror = (error)=>
        {
            console.error(`uncaught error`,error);
            this.setState({hasError:true,error});
        }
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        //logErrorToMyService(error, errorInfo);
        //TODO: log this to google logging??
        console.error(error);
        console.error(errorInfo);
        this.setState({error});
    }

    render() {
        if (this.state.hasError) {
            return <DefaultLayout>
                <h1>Something went wrong.</h1>
                <pre>
                    {this.state.error && this.state.error.message}
                </pre>
                <Button variant={"contained"} color={"primary"} onClick={()=>this.setState({hasError:false})}>
                        Back
                </Button>

            </DefaultLayout>;
        }

        return this.props.children;
    }
}
