import {
    Button,
    Card, Dialog, DialogActions, DialogContent, DialogTitle,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Radio,
    RadioGroup,
    TextField,
    Typography,
} from "@material-ui/core";
import React, {useCallback, useEffect, useState} from "react";
import {CreditClassEnum, useCreateLogMutation, useUpdateCustomerMutation} from "../../generated/graphql";
import NotificationPopup from "../../common/NotificationPopup";
import {getLogQueryMessage} from "../log/LogUtils";
import {getModifiedValues} from "../custom/EditView";
import {useUser} from "../../Auth";
import {find, pick, reduce} from "lodash";


export const Question = ({
                             question,
                             answer,
                             setAnswer,
                             editable = false
                         }: {
    question: { title: string, field: string, answers: { value: any, label: string }[] },
    answer: any,
    setAnswer: any,
    editable?: boolean,
}) =>
{
    // let defaultValue = "";
    // if (answer && answer[question.field] != null) {
    //     defaultValue = answer[question.field];
    // }
    return <Grid item xs={6}>
        <Card style={{margin: 20, padding: 20}}>
            <FormControl>
                <FormLabel style={{fontSize: 20, marginBottom: 20}}>{question.title}</FormLabel>
                <RadioGroup value={answer[question.field]} style={{marginTop: 10}} onChange={(e, value) =>
                {
                    if (value=="true") {
                        setAnswer({...answer, [question.field]: true});
                    }
                    else if (value=="false") {
                        setAnswer({...answer, [question.field]: false});
                    }
                    else {
                        setAnswer({...answer, [question.field]: value});
                    }
                }}>
                    {question.answers.map((answer) =>
                    {
                        return <FormControlLabel value={answer.value} control={<Radio disabled={!editable}/>}
                                                 label={answer.label}/>
                    })}
                </RadioGroup>
            </FormControl>
        </Card>
    </Grid>
}
const getScore = (answer, questions) =>
{
    const score = reduce(questions, (acc, question) =>
    {
        if (question.field in answer) {
            const value = answer[question.field];
            return acc + find(question.answers, (answer) => answer.value == value)?.score ?? 0;
        } else {
            return acc;
        }
    }, 0);
    return score;
}
const getCreditClass = (score) =>
{
    const credit = score > 50 ? CreditClassEnum.A : score > 40 ? CreditClassEnum.B : CreditClassEnum.C;
    return credit;
}
const getResultMessage = (answer, questions) =>
{
    const score = getScore(answer, questions);
    const credit = getCreditClass(score);
    return `本次的信用評等分數為 ${score}，評等為 ${credit}。\n若資料有誤需要修改請通知財務部。`;
}
export default ({customer}) =>
{
    const [open, setOpen] = useState(false);
    const [answer, setAnswer] = useState<any>(customer?.answer ?? {});
    const [updateCustomer] = useUpdateCustomerMutation();
    const [createLog] = useCreateLogMutation();
    const user = useUser();
    const onUpdate = useCallback(
        async () =>
        {
            try {
                const creditClass = getCreditClass(getScore(answer, questions));
                await updateCustomer({
                    variables: {
                        customer: {
                            answerEditable: false,
                            answer: pick(answer, questions.map((question) => question.field)),
                            creditClass,
                            defaultPaymentDays: 0,
                        },
                        id: customer.id,
                    },
                    refetchQueries: ["getCustomer", "getCustomerList"],
                });
                NotificationPopup.success(`更新完成`);
                await createLog({
                    variables: {
                        log: {
                            entityId: customer.id,
                            userId: user!.id as string,
                            queryAction: "編輯信用評等",
                            queryEntity: "customer",
                            queryMessage: `${getModifiedValues(answer, customer?.answer, '新增')}`,
                        }
                    }
                });
            } catch (e) {
                NotificationPopup.error(`編輯信用評等發生問題: ${e.message}`);
                console.error(e);
            }
        },
        [updateCustomer, user, answer]
    );
    const questions = [
        {
            title: "1. 公司經營型態?",
            field: "operationType",
            answers: [
                {value: "1", label: "上市櫃公司", score: 10},
                {value: "2", label: "非上市櫃公司–知名品牌 / 全球百大企業 / 產業領頭", score: 8},
                {value: "3", label: "非上市櫃公司–中小型企業 / 傳統產業", score: 6},
                {value: "4", label: "非上市櫃公司–其他", score: 3},
            ]
        },
        {

            title: "2. 公司經營年數?",
            field: "years",
            answers: [
                {value: "1", label: "5年以上", score: 10},
                {value: "2", label: "3年以上", score: 5},
                {value: "3", label: "未滿3年", score: 2},
            ]
        },
        {
            title: "3. 公司資本額?",
            field: "capital",
            answers: [
                {value: "1", label: "1千萬以上", score: 10},
                {value: "2", label: "五百萬(含)以上", score: 5},
                {value: "3", label: "五百萬以下", score: 3},
            ]
        },
        {
            title: "4. 上一年度與Koodata之交易金額?",
            field: "lastYearTransaction",
            answers: [
                {value: "1", label: "100萬以上", score: 10},
                {value: "2", label: "20萬(含)以上", score: 5},
                {value: "3", label: "20萬(含)以下", score: 3},
                {value: "4", label: "首次交易", score: 2},
            ]
        },
        {
            title: "5. 以往付款情況?",
            field: "paidCredit",
            answers: [
                {value: "1", label: "準時付款", score: 10},
                {value: "2", label: "偶爾拖延", score: 5},
                {value: "3", label: "經常拖延", score: 3},
                {value: "4", label: "首次交易", score: 2},
            ]
        },
        {
            title: "6. 公司或負責人是否有負面記錄?",
            field: "negativeRecord",
            answers: [
                {value: "1", label: "有，請提供並列印查詢頁面給財務部", score: 0},
                {value: "2", label: "無", score: 5},
            ]
        },
        {
            title: "7. 公司是否有跳票記錄",
            field: "bouncedRecord",
            answers: [
                {value: "1", label: "有，請提供並列印查詢頁面給財務部", score: 0},
                {value: "2", label: "無", score: 5},
            ]
        }
    ]
    return <div>
        <Dialog maxWidth={"sm"} open={open} fullWidth={true}>
            <DialogTitle>
                <Typography variant="h4" align="center">
                    信用評等結果
                </Typography>
            </DialogTitle>
            <DialogContent>
                {getResultMessage(answer, questions)}
            </DialogContent>
            <DialogActions>
                <Button
                    variant={"contained"}
                    color={"primary"}
                    onClick={() =>
                    {
                        setOpen(false);
                    }}
                >
                    確認
                </Button>
            </DialogActions>
        </Dialog>
        <Grid container>
            {questions.map((question) =>
            {
                return <Question question={question}
                                 answer={answer}
                                 setAnswer={setAnswer}
                                 editable={customer.answerEditable}/>
            })}
            <Grid container item xs={12} justifyContent={"center"}>
                <Button
                    variant={"contained"}
                    color={"primary"}
                    disabled={!customer.answerEditable}
                    onClick={async () =>
                    {
                        await onUpdate();
                        setOpen(true);
                    }}
                >
                    提交
                </Button>
            </Grid>
        </Grid>
    </div>
}