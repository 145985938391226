export const ReportList = [
    {
        title: "帳戶",
        name: "customer",
        fields:[
            "total",
            "daily",
            "daily_total",
        ]
    },
    {
        title: "廣告活動",
        name: "campaign",
        fields:[
            "total",
            "daily",
        ]
    },
    {
        title: "廣告群組",
        name: "ad_group",
        fields:[
            "total",
            "daily",
        ]
    },
    {
        title: "廣告",
        name: "ad_group_ad",
        fields:[
            "total",
            "daily",
        ]
    },
    {
        title: "關鍵字",
        name: "keyword",
        fields:[
            "total",
            "daily",
            "no_match-total",
            "no_match-daily",
            "search_term",
        ]
    },
    {
        title: "性別",
        name: "gender",
        fields:[
            "total",
            "daily",
            "customer",
            "customer-daily",
            "campaign",
            "campaign-daily",
            "ad_group",
            "ad_group-daily",
        ]
    },
    {
        title: "年齡層",
        name: "age_range",
        fields:[
            "total",
            "daily",
            "customer",
            "customer-daily",
            "campaign",
            "campaign-daily",
            "ad_group",
            "ad_group-daily",
        ]
    },
    {
        title: "裝置",
        name: "device",
        fields:[
            "total",
            "daily",
            "customer",
            "customer-daily",
            "campaign",
            "campaign-daily",
            "ad_group",
        ]
    },
    {
        title: "時段",
        name: "ad_schedule",
        fields:[
            "customer",
            "customer-daily",
            "campaign",
            "campaign-daily",
        ]
    },
    // {
    //     title: "廣告活動標籤",
    //     name: "campaign-label",
    //     fields:[
    //         "total",
    //         "daily",
    //     ]
    // },
    // {
    //     title: "廣告群組標籤",
    //     name: "ad_group-label",
    //     fields:[
    //         "total",
    //         "daily",
    //     ]
    // },
    {
        title: "廣告標籤",
        name: "label",
        fields:[
            "customer",
            "customer-daily",
            "campaign",
            "ad_group",
        ]
    },
    {
        title: "轉換動作", /* 未分別列與欄  */
        name: "conversion_action",
        fields:[
            "customer",
            "campaign",
            "ad_group",
            "keyword",
            // "ad_group_ad-label",
            // "ad_group_ad-keyword",
        ]
    },
    {
        title: "所有轉換動作", /* 未分別列與欄  */
        name: "all_conversion_action",
        fields:[
            "customer",
            "campaign",
            "ad_group",
            "keyword",
            // "ad_group_ad-label",
            // "ad_group_ad-keyword",
        ]
    },
    {
        title: "額外資訊", /* 未列於postman api中  */
        name: "asset",
        fields:[
            "total",
            "daily",
            "campaign",
            "campaign-daily",
        ]
    },
    {
        title: "目標對象",
        name: "audience",
        fields:[
            "customer",
            "customer-daily",
            "campaign",
            "campaign-daily",
            "ad_group",
            "ad_group-daily",
        ]
    },
    // {
    //     title: "主題",
    //     name: "Topic",
    //     fields:[
    //         "customer",
    //         "customer-daily",
    //         "campaign",
    //         "campaign-daily",
    //         "ad_group",
    //         "ad_group-daily",
    //     ]
    // },
    {
        title: "指定地區",
        name: "location",
        fields:[
            "customer",
            "campaign",
            "campaign-daily",
        ]
    },
    {
        title: "地理位置",
        name: "geographic",
        fields:[
            "county",
            "city",
            "region",
        ]
    },
    {
        title: "使用者地區",
        name: "user_location",
        fields:[
            "county",
            "city",
            "region",
        ]
    },
];