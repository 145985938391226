import React, { useState } from "react";
import { Container, Grid } from "@material-ui/core";
import Page from "../../components/Page";
import { useUser } from "../../Auth";
import {
    Comparator,
    InvoiceStatus,
    OrderItemStatus,
    OrderStatus,
    useGetNotificationListQuery,
} from "../../generated/graphql";
import OrderItemTable from "./OrderItemTable";

export default () => {
    return (
        <Page title={"order page"}>
            <Container maxWidth={false} style={{ padding: "30px" }}>
                <OrderItemTable
                    filterButtons={[
                        {
                            label: "已結案",
                            filter: {
                                items: [
                                    {
                                        columnField: "status",
                                        value: OrderItemStatus.Finished,
                                        operatorValue: "equals",
                                    },
                                ],
                            },
                        },
                    ]}
                />
            </Container>
        </Page>
    );
};
