import { Box } from "@material-ui/core";
import React from "react";
import PaginatedDatagrid, {
    FilterButton,
} from "../../components/PaginatedDatagrid";
import {InvoiceStatusList, ReceivedPaymentStatusList, ReceivedPaymentStatusMap} from "../../common/Constant";
import { loader } from "graphql.macro";
import { format } from "date-fns";
import {
    GridCellParams,
    GridFilterItem,
    GridValueFormatterParams,
    GridValueGetterParams,
} from "@mui/x-data-grid-pro";
import ContactView from "../../layouts/PopupLayout/ContactView";
import { ViewColumn } from "../../common/Util";
const GetReceivedPaymentListQuery = loader(
    "./graphql/GetReceivedPaymentList.graphql"
);

export default ({
                    defaultFilterItems,
                    filterButtons,
                    refetchState,
                }: {
    defaultFilterItems?: GridFilterItem[];
    filterButtons?: FilterButton[];
    refetchState?: any;
}) => {
    return (
        <Box width={"100%"} style={{marginTop: 20}}>
            <PaginatedDatagrid
                id={'received-payment'}
                refetchState={refetchState}
                defaultFilterItems={defaultFilterItems}
                filterButtons={filterButtons}
                query={GetReceivedPaymentListQuery}
                ordering={{ field: "id", sort: "desc" }}
                columns={[
                    {
                        headerName: "ID",
                        field: "id",
                        type: "number",
                    },
                    {
                        headerName: "狀態",
                        type: "singleSelect",
                        field: "status",
                        valueGetter: (params: GridValueGetterParams) => {
                            return ReceivedPaymentStatusMap[params.row.status as string];
                        },
                        valueOptions: params => ReceivedPaymentStatusList,
                    },
                    {
                        headerName: "收款日期",
                        field: "date",
                        type: "date",
                        valueGetter: (params: GridValueGetterParams) =>
                            params.row.date
                                ? format(
                                    new Date(params.row.date as string),
                                    "yyyy-MM-dd"
                                )
                                : "未指定",
                    },
                    {
                        field: "amount",
                        headerName: "收款金額",
                        type: "number",
                        valueGetter: (params) => {
                            return params.row.amount?.toLocaleString();
                        },
                    },
                    {
                        field: "customer.name",
                        headerName: "客戶",
                    },
                    ViewColumn('/app/receivedPayment'),
                ]}
            />
        </Box>
    );
};
