import React, { useState } from "react";
import { Container, Grid } from "@material-ui/core";
import Page from "../../components/Page";
import CreateSupplierView from "./CreateSupplierView";
import SupplierTable from "./SupplierTable";

export default () => {
    return (
        <Page title={"supplier page"}>
            <Container maxWidth={false} style={{ padding: "30px"}}>
                <Grid container spacing={2}>
                    <CreateSupplierView/>
                    <SupplierTable/>
                </Grid>
            </Container>
        </Page>
    )
}