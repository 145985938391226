import { Box } from "@material-ui/core";
import React from "react";
import { loader } from "graphql.macro";
import PaginatedDatagrid, { FilterButton } from "../../components/PaginatedDatagrid";
import { ViewColumn } from "../../common/Util";
import {InvoiceStatusList, InvoiceStatusMap} from "../../common/Constant";
import { format } from "date-fns";
import { Order, Comparator, InvoiceStatus, OrderStatus, useGetNotificationListQuery } from "../../generated/graphql";
import {GridFilterItem, GridValueFormatterParams, GridValueGetterParams} from "@mui/x-data-grid-pro";
const GetInvoiceListQuery = loader("./graphql/GetInvoiceList.graphql");

export default ({defaultFilterItems, filterButtons, refetchState}: 
    {defaultFilterItems?: GridFilterItem[], filterButtons?: FilterButton[], refetchState?: any}) => {
    return (
        <Box width={"100%"}>
            <PaginatedDatagrid
                id={'invoice'}
                defaultFilterItems={defaultFilterItems}
                refetchState={refetchState}
                queryParams={true}
                filterButtons={filterButtons}
                query={GetInvoiceListQuery}
                columns={[
                    {
                        headerName: "ID",
                        field: "id",
                        type: "number",
                    },
                    {
                        headerName: "發票號碼",
                        field: "invoiceNumber",
                        valueGetter: (params: GridValueGetterParams) => {
                            return params.row.invoiceNumber ? params.row.invoiceNumber as string : "尚未開立"
                        },
                    },
                    {
                        headerName: "委刊單",
                        field: "order.number",
                        // valueFormatter: (params: ValueFormatterParams) => {
                        //     const { name, number } = params.row.order as Order;
                        //     return `${number} - ${name}`;
                        // },
                        renderCell: (params) => {
                            const { name, number, id } = params.row.order as Order;
                            return <a href={`/app/order/${id}`}>{number} - ${name}</a>
                        }
                    },
                    {
                        field: "date",
                        headerName: "預期開立發票日期",
                        valueGetter: (params: GridValueGetterParams) =>
                            format(new Date(params.row.date as string), "yyyy-MM-dd"),
                        type: "date",
                    },
                    {
                        headerName: "狀態",
                        field: "status",
                        valueGetter: (params: GridValueGetterParams) => {
                            return InvoiceStatusMap[params.row.status as string];
                        },
                        valueOptions: InvoiceStatusList,
                        type: "singleSelect",
                    },
                    {
                        headerName: "逾期",
                        field: "expired",
                        valueFormatter: (params: GridValueFormatterParams) => {
                            return params.value ? "已逾期" : "";
                        },
                        valueOptions: [{value: true, label: "已逾期"}, {value: false, label: "未逾期"}],
                        type: "singleSelect",
                    },
                    ViewColumn('/app/invoice'),
                ]}
            />
        </Box>
    );
};
