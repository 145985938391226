import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    AppBar,
    Card,
    Grid,
    Tab,
    Tabs,
    withStyles,
} from "@material-ui/core";
import { format } from "date-fns";
import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import DefaultQueryHandler from "../../common/DefaultQueryHandler";
import {
    Clickable,
    defaultLinkCellRenderer,
    HasRole,
    useTabIndex,
} from "../../common/Util";
import TextInput from "../../components/Form/TextInput";
import Page from "../../components/Page";
import {
    DepositStatus,
    InvoiceStatus, OrderItem,
    OrderItemStatus,
    OrderStatus,
    RoleIdEnum,
    useGetCurrentUserQuery,
    useGetOrderItemQuery, useRemoveAllDepositMutation,
    useUpdateOrderItemMutation,
} from "../../generated/graphql";
import DetailView from "../custom/DetailView";
import TabPanel from "../custom/TabPanel";
import CreateDeposit from "../deposit/CreateDeposit";
import DepositPopup from "../deposit/DepositPopup";
import DepositTable from "../deposit/OrderItemDepositTable";
import UpdateOrderItem from "./UpdateOrderItem";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CloseIcon from "@material-ui/icons/Close";
import ConfirmButton from "../../components/ConfirmButton";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import NotificationPopup from "../../common/NotificationPopup";
import {BelongingMap, OrderItemTypeMap, OrderStatusMap} from "../../common/Constant";
import UploadDepositButton from "../deposit/UploadDepositButton";
import OrderItemPurchaseOrderTable from "../purchaseOrder/OrderItemPurchaseOrderTable";
import CreatePurchaseOrder from "../purchaseOrder/CreatePurchaseOrder";
import {FieldTagMap, ProductTagMap} from "../../common/IndustryConstant";

var orderItemColumns = [
    {
        field: "order",
        headerName: "專案名稱",
    },
    { field: "id", headerName: "ID" },
    {
        field: "status",
        headerName: "狀態",
        valueFormatter: (value) => {
            return OrderStatusMap[value];
        },
    },
    { field: "product", headerName: "產品" },
    {
        field: "type",
        headerName: "操作方式",
        valueFormatter: (type) => (type ? OrderItemTypeMap[type] : "未指定"),
    },
    {
        field: "totalAmount",
        headerName: "購買金額",
        valueFormatter: (value) => value?.toLocaleString(),
    },
    {
        field: "serviceFee",
        headerName: "帳戶執行費",
        valueFormatter: (value) => value?.toLocaleString(),
    },
    {
        field: "budget",
        headerName: "成本預算",
        valueFormatter: (value) => value?.toLocaleString(),
    },
    {
        field: "assignee",
        headerName: "專案負責人",
        valueFormatter: (value) => (value ? value.name : "未指派"),
    },
    {
        field: "supporter",
        headerName: "協助執行人",
        valueFormatter: (value) => (value ? value.name : "未指派"),
    },
    {
        field: "startDate",
        headerName: "開始時間",
        valueFormatter: (value) => format(new Date(value), "yyyy-MM-dd"),
    },
    {
        field: "endDate",
        headerName: "結束時間",
        valueFormatter: (value) => format(new Date(value), "yyyy-MM-dd"),
    },
    {
        field: "description",
        headerName: "備註",
    },
    {
        field: "productTag",
        headerName: "產品標籤",
        valueFormatter: (value) => ProductTagMap[value],
    },
    {
        field: "fieldTag",
        headerName: "場域標籤",
        valueFormatter: (value) => FieldTagMap[value],
    },
    {
        field: "belonging",
        headerName: "業績歸屬",
        valueFormatter: (value) => BelongingMap[value],
    }
];

const canFinish = (orderItem, depositTotal, priviledge) => {
    if (orderItem.status == OrderItemStatus.Started) {
        if (priviledge) {
            return true;
        }
        // check endDate
        if (new Date(orderItem.endDate) < new Date()) {
            // check deposits
            if (depositTotal <= 0) {
                // check invoices
                let invoiceAmount = 0;
                orderItem.order.invoices.map((invoice) => {
                    invoice.items.map((invoiceItem) => {
                        if (invoice.status == InvoiceStatus.Posted || invoice.status == InvoiceStatus.Paid) {
                            if (invoiceItem.orderItem.id == orderItem.id) {
                                invoiceAmount += invoiceItem.amount;
                            }
                        }
                    });
                });
                if (
                    invoiceAmount >= orderItem.totalAmount + orderItem.serviceFee
                ) {
                    return true;
                }
            }
        }
    }
    return false;
};
const canGetBonus = (orderItem) => {
    if (orderItem.status == OrderItemStatus.Finished) {
        let invoiceAmount = 0;
        orderItem.order.invoices.map((invoice) => {
            invoice.items.map((invoiceItem) => {
                if (invoice.status == InvoiceStatus.Paid) {
                    if (invoiceItem.orderItem.id == orderItem.id) {
                        invoiceAmount += invoiceItem.amount;
                    }
                }
            });
        });
        if ( invoiceAmount >= orderItem.totalAmount + orderItem.serviceFee ) {
            return true;
        }
    }
    return false;
};

export default () => {
    const meQuery = useGetCurrentUserQuery();
    const { id } = useParams();
    const { data, loading, error } = useGetOrderItemQuery({
        variables: {
            id,
        },
    });
    const [tabIndex, setTabIndex] = useTabIndex();
    const [updateOrderItem] = useUpdateOrderItemMutation({
        refetchQueries: ["getOrderItem"],
    });
    const [removeAllDeposits] = useRemoveAllDepositMutation();

    let total = data?.orderItem?.budget ?? 0;
    data?.orderItem?.deposits.map((deposit) => {
        if (
            deposit.status != DepositStatus.Rejected &&
            deposit.status != DepositStatus.Canceled
        ) {
            total -= (deposit.amountSpent != null) ? deposit.amountSpent : deposit.amount;
        }
    });

    const AccordionSummary = withStyles({
        content: {
            alignItems: "center",
        },
    })(MuiAccordionSummary);

    const changeStatus = async (status) => {
        await updateOrderItem({
            variables: {
                id,
                orderItem: {
                    status,
                },
            },
            refetchQueries: ["getOrderItem"],
        });
        // check all order items status
    };
    const removeAllDeposit = async () => {
        await removeAllDeposits(
            {
                variables: {id},
                refetchQueries: ["getOrderItem", "getDepositList"]
            });
    }

    let priviledge = false;
    meQuery.data?.me.roles.map((role) => {
        console.log(role);
        if (["admin", "am_manager", "ae_manager"].indexOf(role.id) != -1) {
            console.log(role.id);
            priviledge = true;
        }
    });


    return (
        <Page title={"Order item detail page"}>
            <AppBar position="static" color="default">
                <Tabs
                    value={tabIndex}
                    indicatorColor="secondary"
                    textColor="secondary"
                    onChange={(
                        event: React.ChangeEvent<{}>,
                        newValue: number
                    ) => {
                        setTabIndex(newValue);
                    }}
                >
                    <Tab label="委刊細項" />
                    <Tab label="發稿" />
                    <Tab label="付款單" />
                </Tabs>
            </AppBar>
            <DefaultQueryHandler loading={loading} error={error}>
                <TabPanel value={tabIndex} index={0}>
                    <Grid container spacing={3}>
                        <Grid item>
                            <UpdateOrderItem orderItem={data?.orderItem} />
                        </Grid>
                        {data && canFinish(data?.orderItem, total, priviledge) && (
                            <Grid item>
                                <ConfirmButton
                                    onConfirm={async () => {
                                        await changeStatus(OrderItemStatus.Finished);
                                    }}
                                    queryEntity={"orderItem"}
                                    queryAction={"結案"}
                                    entityId={id}
                                >
                                    結案
                                </ConfirmButton>
                            </Grid>
                        )}
                        {priviledge && (data?.orderItem?.status == OrderItemStatus.Finished) && (
                            <Grid item>
                                <ConfirmButton
                                    onConfirm={async () => {
                                        await changeStatus(OrderItemStatus.Started);
                                    }}
                                    queryEntity={"orderItem"}
                                    queryAction={"取消結案"}
                                    entityId={id}
                                >
                                    取消結案
                                </ConfirmButton>
                            </Grid>
                        )}
                        <HasRole
                            roles={[
                                RoleIdEnum.Admin,
                                RoleIdEnum.AeManager,
                                RoleIdEnum.AmManager,
                            ]}
                        >
                            <Grid item>
                                {data && canGetBonus(data?.orderItem) && (
                                        <ConfirmButton
                                            onConfirm={() => {
                                                changeStatus(
                                                    OrderItemStatus.BonusAssigned
                                                );
                                            }}
                                            queryEntity={"orderItem"}
                                            queryAction={"發獎金"}
                                            entityId={id}
                                        >
                                            發獎金
                                        </ConfirmButton>
                                    )}
                            </Grid>
                        </HasRole>
                    </Grid>
                    <DetailView
                        data={data?.orderItem}
                        columns={orderItemColumns}
                    />
                </TabPanel>
                <TabPanel value={tabIndex} index={1}>
                    {data?.orderItem?.order.customer.invoiceExpired
                        ? <b style={{color:"red"}}>此客戶有逾期發票，因此無法發稿，請聯絡財務。</b>
                    : <Grid container spacing={3}>
                        {data?.orderItem?.status != OrderItemStatus.Finished && <Grid item>
                            <CreateDeposit orderItem={data?.orderItem} />
                        </Grid>}
                        {data?.orderItem?.status != OrderItemStatus.Finished &&<Grid item>
                            <UploadDepositButton orderItem={data?.orderItem} />
                        </Grid>}
                        <Grid item>
                            <HasRole roles={[RoleIdEnum.Admin]}>
                                <ConfirmButton
                                    onConfirm={()=>removeAllDeposit()}
                                    queryEntity={"orderItem"}
                                    queryAction={"刪除所有發稿"}
                                    entityId={id}
                                >刪除所有發稿</ConfirmButton>
                            </HasRole>
                        </Grid>
                    </Grid>}
                    <div style={{ marginTop: 10, marginBottom: 10 }}>
                        剩餘可發稿的金額：{total.toLocaleString()}
                    </div>
                    <DepositTable orderItem={data?.orderItem} />
                </TabPanel>
                <TabPanel value={tabIndex} index={2}>
                    <CreatePurchaseOrder orderItem={data?.orderItem} />
                    <br/>
                    <OrderItemPurchaseOrderTable orderItem={data?.orderItem} />
                </TabPanel>
            </DefaultQueryHandler>
        </Page>
    );
};
