import React, {useCallback} from "react";
import {useCreateProductMutation, useGetSupplierListQuery,} from "../../generated/graphql";
import NotificationPopup from "../../common/NotificationPopup";
import {InputType} from "../../common/FormRenderer";
import DefaultQueryHandler from "../../common/DefaultQueryHandler";
import CreateView from "../custom/CreateView";
import {selectMapFn} from "../../common/Util";
import {map} from 'lodash';
import {ProductOriginList} from "../../common/Constant";

export default () => {
    const { loading, data, error } = useGetSupplierListQuery();
    const [createProduct] = useCreateProductMutation();
    const onSubmit = useCallback(
        async (product, { resetForm }, setOpen) => {
            try {
                const newProduct = await createProduct({
                    variables: {
                        product,
                    },
                    refetchQueries:["getProductList"]
                });
                NotificationPopup.success(`新增完成`);
                return newProduct.data?.createProduct;
            } catch (e) {
                NotificationPopup.error(
                    `新增產品發生問題: ${e.message}`
                );
                console.error(e);
            } finally {
                resetForm();
                setOpen(false);
            }
        },
        [createProduct]
    );
    return (
        <DefaultQueryHandler loading={loading} error={error}>
            <CreateView
                title={"新增產品"}
                columns={[
                    {
                        name: "productOrigin",
                        label: "媒體原廠",
                        type:InputType.select,
                        options:ProductOriginList,
                    },
                    { name: "name", label: "產品名稱" },
                    {
                        name: "supplierIds",
                        label: "媒體供應商",
                        type: InputType.select,
                        isMulti: true,
                        options: data?.suppliers.map(selectMapFn),
                        value: [],
                    },
                    { name: "website", label: "網站" },
                    { name: "description", label: "說明" },
                ]}
                onSubmit={onSubmit}
                queryEntity={"product"}
            />
        </DefaultQueryHandler>
    );
};
