import CreateView from "../../custom/CreateView";
import React, {useCallback, useState} from "react";
import {useCampaignsQuery, useGetAdsAccountsQuery} from "../../../generated/graphql";
import {InputType} from "../../../common/FormRenderer";
import {find} from "lodash";

export default ({onSelectCampaign}) => {
    const [selectedAccount, setSelectedAccount] = useState<any>();
    const [selectedCampaign, setSelectedCampaign] = useState<any>();
    const { data:accounts, loading, error } = useGetAdsAccountsQuery();
    const { data: campaignsData, loading: campaignsLoading } = useCampaignsQuery({
        variables: { cid: selectedAccount?.id },
        skip: !selectedAccount,
    });
    const accountColumns = [
        {
            name: "adsAccount",
            label: "廣告帳戶",
            type: InputType.autoComplete,
            options: accounts?.adsAccounts?.map((account)=>{
                return {value: account.id, label: `${account.id}-${account.name}`};
            }),
            onChange: (d) => {
                setSelectedAccount(find(accounts?.adsAccounts ?? [], (c)=>{
                    return c.id == d;
                }));
            },
        },
        {
            name: "campaign",
            label: "廣告活動",
            type: InputType.select,
            options: campaignsData?.campaigns?.map(
                (campaign)=>
                    ({value: campaign.id, label: `${campaign.id}-${campaign.name}`})
            ) ?? [],
            onChange: (d) => {
                setSelectedCampaign(find(campaignsData?.campaigns ?? [], (c)=>{
                    return c.id == d;
                }));
            },
        }
    ];
    const onSubmit = useCallback(
        async (account, { resetForm }, setOpen) => {
            onSelectCampaign({...selectedCampaign, accountId: selectedAccount.id});
            setOpen(false);
        },[selectedCampaign, onSelectCampaign]
    );

    return <CreateView
        title={"新增廣告活動"}
        columns={accountColumns}
        onSubmit={onSubmit}
        queryEntity={"accountAds"}
    />
};