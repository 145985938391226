import {Box} from "@material-ui/core";
import PaginatedDatagrid from "../../components/PaginatedDatagrid";
import React, {useState} from "react";
import {GridColDef, GridSortModel, GridValueGetterParams} from "@mui/x-data-grid-pro";
import {hasRole, ViewColumn} from "../../common/Util";
import {RoleIdEnum, UpgradeStatus, useGetUpgradesBriefQuery} from "../../generated/graphql";
import {UpgradeStatusMap} from "../../common/Constant";
import {loader} from "graphql.macro";
import {filter, reduce} from "lodash";
import {useUser} from "../../Auth";

const GetUpgradesQuery= loader("./graphql/GetUpgrades.graphql");

export default () => {
    const columns: GridColDef[] = [
        { field: "id", headerName: "id", width: 100, type: "number" },
        { field: "customer", headerName: "客戶名稱", width: 200, valueGetter:
                (params: GridValueGetterParams) => params.row.customer?.name},
        { field: "status", headerName: "狀態", width: 120, valueGetter:
                (params: GridValueGetterParams) => UpgradeStatusMap[params.row.status]},
        { field: "ae", headerName: "申請人", width: 120, valueGetter:
                (params: GridValueGetterParams) => params.row.ae?.name},
        ViewColumn('/app/upgrade'),
    ];
    const {data, loading, error} = useGetUpgradesBriefQuery();
    const user = useUser();
    const filterButtons:any = filter([{
        label: "財務審核",
        filter: {
            items: [
                {
                    columnField: "status",
                    value: UpgradeStatus.FinanceReview,
                    operatorValue: "equals",
                },
            ],
        },
        count: reduce(data?.upgradeList, (acc, upgrade)=>acc + (upgrade.status==UpgradeStatus.FinanceReview ? 1 : 0), 0),
    },{
        label: "主管審核",
        filter: {
            items: [
                {
                    columnField: "status",
                    value: UpgradeStatus.ManagerReview,
                    operatorValue: "equals",
                },
            ],
        },
        count: reduce(data?.upgradeList, (acc, upgrade)=>acc + (upgrade.status==UpgradeStatus.ManagerReview ? 1 : 0), 0),
    },{
        label: "總經理審核",
        filter: {
            items: [
                {
                    columnField: "status",
                    value: UpgradeStatus.GmReview,
                    operatorValue: "equals",
                },
            ],
        },
        count: reduce(data?.upgradeList, (acc, upgrade)=>acc + (upgrade.status==UpgradeStatus.GmReview ? 1 : 0), 0),
    }], (filterButton, index)=>{
        switch (index) {
            case 0:
                return hasRole(user, [RoleIdEnum.Finance]);
            case 1:
                return hasRole(user, [RoleIdEnum.AeManager, RoleIdEnum.AmManager]);
            case 2:
                return hasRole(user, [RoleIdEnum.Gm]);
        }
    });
    return (
        <Box width={"100%"} style={{ marginTop: "30px", height: "80vh" }}>
            <PaginatedDatagrid
                id={'upgrade'}
                filterButtons={filterButtons}
                columns={columns}
                query={GetUpgradesQuery}
                ordering={{ field: "id", sort: "desc" }}
                queryParams={true}
            />
        </Box>
    );
}