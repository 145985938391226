import React from "react";
import GoogleLogin, {GoogleLoginResponse} from "react-google-login";
import NotificationPopup from "./common/NotificationPopup";


export default ({ onSuccess }: { onSuccess: (a: string) => void }) => {
    return (
        <GoogleLogin
            clientId={`887835872105-4cokj8qcqjo4hvlv3j1b06eil59e0onr.apps.googleusercontent.com`}
            buttonText={"Login"}
            style={{width:"150px"}}
            onSuccess={async (response) => {
                const res = await fetch(
                    `${process.env.REACT_APP_BACKEND_URI}/auth/login`,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            id_token: (response as GoogleLoginResponse).tokenId,
                        }),
                    }
                );
                const json = await res.json();
                if(json.access_token)
                {
                    onSuccess(json.access_token);
                }
                else
                {
                    NotificationPopup.error(<div>
                        <p>Failed to login.</p>
                        <p><strong>Error:</strong> {json.error}</p>
                        <p><strong>Message:</strong> {json.message}</p>
                    </div>);
                }

            }}
            cookiePolicy={"single_host_origin"}
        />
    );
};
