import React, {useCallback, useState} from "react";
import {Button, Container, Grid} from "@material-ui/core";
import Page from "../../components/Page";
import ReceivedPaymentTable from "./ReceivedPaymentTable";
import { ReceivedPaymentStatusEnum } from "../../generated/graphql";
import CreateReceivedPayment from "./CreateReceivedPayment";
import {getAccessToken} from "../../Auth";
import { saveAs } from "file-saver";
import {InputType} from "../../common/FormRenderer";
import {format} from "date-fns";
import {download as downloadReport} from "../report/DownloadableReports";
import CreateView from "../custom/CreateView";

export default () => {
    const download = useCallback(async () => {
        const res = await fetch(
            `${process.env.REACT_APP_BACKEND_URI}/reports/linked-received-payments`,
            {
                headers: {
                    Authorization: `Bearer ${getAccessToken()}`,
                },
            }
        );
        const blob = await res.blob();
        saveAs(blob, `連結發票收款單.xlsx`);
    }, []);
    return (
        <Page title={"purchase order page"}>
            <Container maxWidth={false} style={{ padding: "30px" }}>
                <Grid container spacing={2}>
                    <CreateReceivedPayment/>
                    &nbsp;
                    <CreateView
                        submitTitle={"下載已連結報表"}
                        title={"下載已連結報表"}
                        columns={[
                            {
                                name: "startDate",
                                label: "走期(起)",
                                type: InputType.date,
                            },
                            {
                                name: "endDate",
                                label: "走期(迄)",
                                type: InputType.date,
                            },
                        ]}
                        onSubmit={async (res, helpers, setOpen) => {
                            await downloadReport(() => setOpen(false),
                                `reports/linked-received-payments?startDate=${
                                    res.startDate
                                }&endDate=${res.endDate}`,
                                "linked-received-payments"
                            );
                            setOpen(false)
                        }}
                        queryEntity={""}
                    />
                    <ReceivedPaymentTable
                        filterButtons={[
                            {
                                label: "尚未連結發票",
                                filter: {
                                    items: [
                                        {
                                            columnField: "status",
                                            value: ReceivedPaymentStatusEnum.Started,
                                            operatorValue: "equals",
                                        },
                                    ],
                                },
                            },
                        ]}
                    />
                </Grid>
            </Container>
        </Page>
    );
};
