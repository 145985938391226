import {Box, Button} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import DefaultQueryHandler from "../../../common/DefaultQueryHandler";
import { DataGridLocale } from "../../../common/DataGridLocale";
import {GridColDef, DataGridPro, GridSortModel, GridFilterModel, GridValueGetterParams} from "@mui/x-data-grid-pro";
import {getLevelName, PasteToClipboard, ViewColumn} from "../../../common/Util";
import {
    RecordStatus,
    useGetGoogleAdsRecordListQuery,
    useGetGoogleAdsTemplatesQuery, useRemoveGoogleAdsRecordMutation,
    useRemoveGoogleAdsTemplateMutation
} from "../../../generated/graphql";
import {BookOpen, Download, Edit, Eye} from "react-feather";
import {Delete, DeleteForever} from "@material-ui/icons";
import {format} from "date-fns";
import ConfirmButton from "../../../components/ConfirmButton";
import NotificationPopup from "../../../common/NotificationPopup";
import {download} from "../../report/DownloadableReports";
import {useUser} from "../../../Auth";
import {InvoiceStatusList, InvoiceStatusMap, RecordStatusList, RecordStatusMap} from "../../../common/Constant";

export default () => {
    const [removeRecord] = useRemoveGoogleAdsRecordMutation();
    const { loading, data, error, refetch } = useGetGoogleAdsRecordListQuery();
    const [sortModel, setSortModel] = useState<GridSortModel>([{field: "id", sort: "desc"}]);
    const user = useUser();
    const [filterModel, setFilterModel] = useState<
        GridFilterModel | undefined
        >();
    useEffect(() => {
        refetch();
    }, []);
    useEffect(()=>{
            setFilterModel({
                items:[{
                    columnField:"assignee",
                    value:user?.name,
                    operatorValue:"equals"
                }],
            });
        },[user]
    );
    const onRemove = async (id) =>
    {
        try {
            await removeRecord({
                variables: {
                    id: id,
                },
                refetchQueries: ["getGoogleAdsRecordList"],
            });
            NotificationPopup.success(`刪除完成`);
        } catch (e) {
            NotificationPopup.error(`刪除發生問題: ${e.message}`);
            console.error(e);
        }
    };
    const columns = [
        { field: "id", headerName: "id", width: 100, type: "number" },
        { field: "name", headerName: "Google Ads 報表名稱", width: 200},
        // { field: "managerId", headerName: "Google Ads 帳戶", width: 150  },
        { field: "createdAt", headerName: "下載時間", width: 160, valueGetter:(params)=>
                `${format(new Date(params.row.createdAt), "yyyy-MM-dd HH:mm:SS")}` },

        { field: "duration", headerName: "走期", width: 200, valueGetter:(params)=>
                `${format(new Date(params.row.startDate), "yyyy-MM-dd")} ~ 
                ${format(new Date(params.row.endDate), "yyyy-MM-dd")}`,
        },
        {
            headerName: "負責人",
            field: "assignee",
            valueGetter:(params)=>params.row.assignee?.name,
        },
        {
            headerName: "狀態",
            field: "status",
            valueGetter: (params: GridValueGetterParams) => {
                return RecordStatusMap[params.row.status as string];
            },
            valueOptions: RecordStatusList,
            type: "singleSelect",
        },
        {
            type: "actions",
            headerName: "下載",
            field: "actions1",
            width: 90,
            disableColumnMenu: true,
            disableColumnSelector: false,
            sortable: false,
            getActions: (params) => [
                <Button
                    disabled={params.row.status != RecordStatus.Success}
                    onClick={async ()=>{
                    await download(()=>{},
                        `google-ads/${params.row.id}`,
                        `${params.row.name}_${format(new Date(params.row.startDate), "yyyyMMdd")}`+
                        `-${format(new Date(params.row.endDate), "yyyyMMdd")}`,
                        false);
                }}>
                    <Download/>
                </Button>,
            ]
        },
        {
            type: "actions",
            headerName: "刪除",
            field: "actions2",
            width: 90,
            disableColumnMenu: true,
            disableColumnSelector: false,
            sortable: false,
            getActions: (params) => [
                <ConfirmButton
                    onConfirm={async () =>
                    {
                        await onRemove(params.row.id);
                    }}
                    queryEntity={"record"}
                    queryAction={"刪除報表紀錄"}
                    entityId={params.row.id}
                    queryMessage={`名稱: ${params.row.name}`}
                >
                    刪除
                </ConfirmButton>
            ]
        },
    ];
    return (
        <DefaultQueryHandler error={error} loading={loading}>
            <Box width={"100%"} style={{ marginTop: "30px", height: "80vh" }}>
                <DataGridPro
                    columns={columns}
                    sortModel={sortModel}
                    onSortModelChange={(model)=>setSortModel(model)}
                    rows={data?.googleAdsRecords || []}
                    hideFooter={true}
                    autoHeight={true}
                    localeText={DataGridLocale}
                    onCellClick={PasteToClipboard}
                    filterModel={filterModel}
                    onFilterModelChange={(filterModel) => {
                        setFilterModel(filterModel);
                    }}
                />
            </Box>
        </DefaultQueryHandler>
    );
};
