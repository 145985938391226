import { Box } from "@material-ui/core";
import React from "react";
import PaginatedDatagrid, {
    FilterButton,
} from "../../components/PaginatedDatagrid";
import {PurchaseOrderStatusList, PurchaseOrderStatusMap} from "../../common/Constant";
import { loader } from "graphql.macro";
import { format } from "date-fns";
import {
    GridCellParams,
    GridFilterItem,
    GridValueFormatterParams,
    GridValueGetterParams,
} from "@mui/x-data-grid-pro";
import ContactView from "../../layouts/PopupLayout/ContactView";
import { ViewColumn } from "../../common/Util";
const GetPurchaseOrderListQuery = loader(
    "./graphql/GetPurchaseOrderList.graphql"
);

export default ({
    defaultFilterItems,
    filterButtons,
    refetchState,
}: {
    defaultFilterItems?: GridFilterItem[];
    filterButtons?: FilterButton[];
    refetchState?: any;
}) => {
    return (
        <Box width={"100%"}>
            <PaginatedDatagrid
                id={'purchase'}
                refetchState={refetchState}
                defaultFilterItems={defaultFilterItems}
                filterButtons={filterButtons}
                query={GetPurchaseOrderListQuery}
                ordering={{ field: "id", sort: "desc" }}
                columns={[
                    {
                        headerName: "ID",
                        field: "id",
                    },
                    {
                        headerName: "委刊單號",
                        field: "orderItem.order.number",
                    },
                    {
                        headerName: "供應商",
                        field: "supplier.name",
                    },
                    {
                        headerName: "申請日期",
                        field: "createdAt",
                        type: "date",
                        valueGetter: (params: GridValueGetterParams) =>
                            params.row.createdAt
                                ? format(
                                    new Date(params.row.createdAt as string),
                                    "yyyy-MM-dd"
                                )
                                : "未指定",
                    },
                    {
                        headerName: "付款日期",
                        field: "paidDate",
                        type: "date",
                        valueGetter: (params: GridValueGetterParams) =>
                            params.row.paidDate
                                ? format(
                                    new Date(params.row.paidDate as string),
                                    "yyyy-MM-dd"
                                )
                                : "未指定",
                    },
                    {
                        field: "initiator.name",
                        headerName: "申請人",
                    },
                    {
                        headerName: "狀態",
                        field: "status",
                        valueGetter: (params: GridValueGetterParams) => {
                            return PurchaseOrderStatusMap[
                                params.row.status as string
                            ];
                        },
                        valueOptions: PurchaseOrderStatusList,
                        type: "singleSelect",
                    },
                    ViewColumn('/app/purchaseOrder'),
                ]}
            />
        </Box>
    );
};
