import React, { useCallback, useState } from "react";
import {
    useCreateSupplierMutation,
    useGetProductListQuery,
} from "../../generated/graphql";
import NotificationPopup from "../../common/NotificationPopup";
import DefaultQueryHandler from "../../common/DefaultQueryHandler";
import CreateView from "../custom/CreateView";
import { InputType } from "../../common/FormRenderer";
import {omit} from "lodash";

const selectMapFn = (user) => ({ value: user.id, label: user.name });
export default () => {
    const { loading, data, error } = useGetProductListQuery();
    const [createSupplier] = useCreateSupplierMutation();
    const onSubmit = useCallback(
        async (supplier, { resetForm }, setOpen) => {
            try {
                const newSupplier = await createSupplier({
                    variables: {
                        supplier: omit({...supplier, productIds: supplier.products.map((product)=>product.value)}, ['products']),
                    },
                    refetchQueries:["getSupplierList"]
                });
                NotificationPopup.success(`新增完成`);
                return newSupplier.data?.createSupplier;
            } catch (e) {
                NotificationPopup.error(
                    `新增供應商發生問題: ${e.message}`
                );
                console.error(e);
            } finally {
                resetForm();
                setOpen(false);
            }
        },
        [createSupplier]
    );
    return (
        <DefaultQueryHandler loading={loading} error={error}>
            <CreateView
                title={"新增供應商"}
                columns={[
                    { name: "name", label: "供應商名稱" },
                    {
                        name: "products",
                        label: "產品",
                        type: InputType.autoComplete,
                        options: data?.products.map(selectMapFn),
                        isMulti: true,
                        value: [],
                    },
                    { name: "phone", label: "電話" },
                    { name: "address", label: "地址" },
                    { name: "invoiceTitle", label: "發票抬頭" },
                    { name: "taxId", label: "統編" },
                    { name: "bankCode", label: "銀行代碼" },
                    { name: "bankAccountNumber", label: "銀行帳號" },
                ]}
                onSubmit={onSubmit}
                queryEntity={"supplier"}
            />
        </DefaultQueryHandler>
    );
};

