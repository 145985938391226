import {format} from "date-fns";
import React, {useCallback} from "react";
import {DepositStatusList} from "../../common/Constant";
import {InputType} from "../../common/FormRenderer";
import NotificationPopup from "../../common/NotificationPopup";
import {DepositStatus, useUpdateDepositMutation,} from "../../generated/graphql";
import CreateView from "../custom/CreateView";
import {omit} from "lodash";

export default ({ deposit, amMode = true , orderItem}) => {
    const [updateDeposit] = useUpdateDepositMutation();
    const onSubmit = useCallback(
        async (
            updatedDeposit,
            { resetForm },
            setOpen
        ) => {
            try {
                const newDeposit = await updateDeposit({
                    variables: {
                        deposit: amMode
                            ? {...omit(updatedDeposit, ['id']), status: DepositStatus.Changed}
                            : {
                                  status: updatedDeposit.status,
                              },
                        id: updatedDeposit.id,
                    },
                    refetchQueries: ["getDepositList", "getOrderItem"],
                });
                NotificationPopup.success(`修改完成`);
                return newDeposit.data?.updateDeposit;
            } catch (e) {
                NotificationPopup.error(
                    `Failed to update deposit ${e.messaeg}`
                );
                console.error(e);
            } finally {
                resetForm();
                setOpen(false);
            }
        },
        [updateDeposit]
    );
    return (
        <CreateView
            title={amMode ? "異動發稿" : "修改發稿"}
            columns={
                amMode
                    ? [
                          {
                              name: "id",
                              label: "ID",
                              value: deposit.id,
                              type: InputType.hidden,
                          },
                          {
                              name: "startDate",
                              label: "開始時間",
                              type: InputType.date,
                              value: format(
                                  new Date(deposit.startDate),
                                  "yyyy-MM-dd"
                              ),
                              min: orderItem.startDate,
                              max: orderItem.endDate,
                          },
                          {
                              name: "endDate",
                              label: "結束時間",
                              type: InputType.date,
                              value: format(
                                  new Date(deposit.endDate),
                                  "yyyy-MM-dd"
                              ),
                              min: orderItem.startDate,
                              max: orderItem.endDate,
                          },
                          {
                              name: "amountSpent",
                              label: "實際金額",
                              type: InputType.number,
                              value: deposit.amountSpent,
                          },
                          {
                              name: "description",
                              label: "異動說明",
                              value: deposit.description,
                          },
                      ]
                    : [
                          {
                              name: "id",
                              label: "ID",
                              value: deposit.id,
                              type: InputType.hidden,
                          },
                          {
                              name: "description",
                              label: "異動說明",
                              type: InputType.custom,
                              renderCell: (
                                <div
                                    style={{
                                        marginBottom: 10,
                                    }}
                                >
                                    異動原因：
                                    {deposit.description}
                                </div>
                            ),

                          },
                          {
                              name: "status",
                              label: "狀態",
                              type: InputType.select,
                              options: DepositStatusList,
                              value: deposit.status,
                          },
                      ]
            }
            onSubmit={onSubmit}
            submitTitle={"儲存"}
            queryEntity={"deposit"}
        />
    );
};
