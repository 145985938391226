import {map} from "lodash";
import {useCallback, useState} from "react";
import {
    Box,
    Button,
    ButtonGroup,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from "@material-ui/core";
import {ProductOrigin, useRealAccountCostsLazyQuery, useRemoveRealAccountCostsMutation,} from "../../generated/graphql";
import DefaultQueryHandler from "../../common/DefaultQueryHandler";
import NotificationPopup from "../../common/NotificationPopup";
import UploadFile from "../order/UploadFile";
import ReportDateInput, {useReportDate} from "./ReportDateInput";
import {ProductOriginList} from "../../common/Constant";

const UploadCosts = () =>
{
    const {reportDate,onChange} = useReportDate();
    const [productOrigin,setProductOrigin] = useState(ProductOrigin.Google as string);
    return <Box>
        <h4>
            上傳實際花費
        </h4>
        <div style={{margin:"0 15px"}}>
            <ReportDateInput value={reportDate} onChange={onChange} />
            <FormControl>
                <InputLabel>
                    媒體別
                </InputLabel>
                <Select onChange={e => setProductOrigin(e.target.value as string)} value={productOrigin}>
                    {ProductOriginList.map(({value, label}) => <MenuItem value={value} >
                        {label}
                    </MenuItem>)}
                </Select>
            </FormControl>
        </div>

        <UploadFile onSuccess={()=>{

        }}  uploadTitle={"上傳花費列表"}
        uriPart={`/account-costs/import-costs?year=${reportDate.year}&month=${reportDate.month}&productOrigin=${productOrigin}`} />
    </Box>
}



const now = new Date();
export default()=>
{
    const {reportDate,onChange} = useReportDate();
    const [productOrigin,setProductOrigin] = useState<string>(ProductOrigin.Google);
    const [getItemCosts,{data,loading,error}] = useRealAccountCostsLazyQuery();
    const [deleteCosts] = useRemoveRealAccountCostsMutation();
    const [open,setOpen] = useState(false);
    const removeCosts = useCallback(async(month,year,productOrigin)=>
    {
        if(window.confirm(`確定要刪除所選擇之花費項目？${month}月/${year} 媒體別：${ProductOrigin[productOrigin]}`))
        {
            try{
                await deleteCosts({
                    refetchQueries:["realAccountCosts"],
                    variables:{month:parseInt(month),year:parseInt(year),productOrigin}
                });
                NotificationPopup.success(`Delete costs for month:${month} year:${year}`);
            }
            catch(e)
            {
                NotificationPopup.error(`Failed to delete costs.. ${e.message}`);
            }
        }
    },[deleteCosts])
    return <div>
        <Dialog open={open} fullWidth={true} maxWidth={"lg"} onClose={()=>setOpen(false)}>
            <DialogTitle disableTypography={true}>
                <Typography variant={"h4"}>
                    管理實際花費
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid item sm={8}>
                        <Box style={{marginBottom:"15px"}}>
                            <ReportDateInput value={reportDate} onChange={onChange}/>
                            <FormControl>
                                <InputLabel>
                                    媒體別
                                </InputLabel>
                                <Select onChange={e => setProductOrigin(e.target.value as string)} value={productOrigin}>
                                    {ProductOriginList.map(({value, label}) => <MenuItem value={value} >
                                        {label}
                                    </MenuItem>)}
                                </Select>
                            </FormControl>

                        </Box>
                        <ButtonGroup orientation={"horizontal"}>
                            <Button variant={"contained"} color={"primary"}
                                    onClick={()=>getItemCosts({variables:{
                                            ...reportDate,
                                            productOrigin:productOrigin as ProductOrigin
                                        }})}>
                                顯示
                            </Button>
                            <Button variant={"contained"} style={{backgroundColor:"#c62828",color:"white"}} onClick={()=>
                            {
                              removeCosts(reportDate.month,reportDate.year,productOrigin)
                            }}>
                                刪除
                            </Button>
                        </ButtonGroup>
                        <DefaultQueryHandler error={error} loading={loading}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            CID
                                        </TableCell>
                                        <TableCell>
                                            Cost
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {loading && <CircularProgress/>}
                                    {data && map(data.realAccountCosts,({id,accountId,cost})=><TableRow key={id}>
                                        <TableCell>
                                            {accountId}
                                        </TableCell>
                                        <TableCell>
                                            {cost}
                                        </TableCell>
                                    </TableRow>)}
                                </TableBody>
                            </Table>
                        </DefaultQueryHandler>
                    </Grid>
                    <Grid item sm={4}>
                        <UploadCosts/>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant={"contained"} color={"secondary"} onClick={()=>setOpen(false)}>
                    取消
                </Button>
            </DialogActions>
        </Dialog>
        <Button variant={"contained"} color={"primary"} onClick={()=>setOpen(true)}>
            管理實際花費
        </Button>
        </div>
}
