import {
    TextField,
    Card,
    Container,
    Grid,
    Box,
    Button,
} from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import React from "react";
import { Link } from "react-router-dom";
import ContactView from "../../layouts/PopupLayout/ContactView";
import { OrderStatusMap } from "../../common/Constant";
import {hasRole, HasRole, previewPdf, saveFile} from "../../common/Util";
import { format } from "date-fns";
import {RoleIdEnum, useRemoveFileMutation} from "../../generated/graphql";
import ConfirmButton from "../../components/ConfirmButton";
import NotificationPopup from "../../common/NotificationPopup";
import {useUser} from "../../Auth";

export default ({ data, columns }) => {
    const [removeFile] = useRemoveFileMutation({refetchQueries:["getOrder","getCustomer"]});
    const user = useUser();
    /*
    const columns = [
        { field: "id", headerName: "id", width: 80 },
        { field: "name", headerName: "供應商名稱", width: 150 },
        { field: "address", headerName: "地址", width: 300 },
        { field: "invoiceTitle", headerName: "發票抬頭", width: 150 },
        { field: "taxId", headerName: "統一編號", width: 150 },
    ];
    */
    return (
        <div>
            {/* <Grid container> */}
            {data != null &&
                columns.map((column) => {
                    let title = column.headerName;
                    let field = column.field;
                    let valueFormatter = column.valueFormatter;
                    var value = data[field];
                    if (valueFormatter) {
                        value = valueFormatter(data[field]);
                    }
                    return (
                        <Grid
                            key={title + field}
                            container
                            style={{
                                borderBottom: "1px solid #dce0e0",
                                paddingTop: 15,
                                paddingBottom: 15,
                                color: column.color ? column.color : "black",
                            }}
                            alignItems={"center"}
                        >
                            <Grid
                                item
                                xs={12}
                                sm={4}
                                style={{
                                    paddingLeft: 30,
                                    color: "#666",
                                }}
                            >
                                {title}
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                {field == "customer" ? (
                                    <Link
                                        to={`/app/customer/${data[field]?.id}`}
                                    >
                                        {value}
                                    </Link>
                                ) : field == "contact" ||
                                  field == "invoiceContact" ? (
                                    <ContactView contact={data[field]} />
                                ) : field == "createdAt" || field == 'updatedAt' ? (
                                    format(
                                        new Date(data[field]),
                                        "yyyy-MM-dd HH:mm"
                                    )
                                ) : field == "product" ||
                                  field == "supplier" ||
                                  field == "order" ? (
                                    <Link
                                        to={`/app/${field}/${data[field].id}`}
                                    >
                                        {valueFormatter ? value : data[field].name}
                                    </Link>
                                ): field == "orderItem" ? (
                                  <Link
                                      to={`/app/orderItem/${data[field].id}`}
                                  >
                                      {data[field].id}
                                  </Link>
                              ) : field == "files" || field == "invoiceFiles" || field == "contractFiles" ? (
                                    data[field].map((file,index) => {
                                        return (
                                            <div key={index}>
                                                <a
                                                    href={"#"}
                                                    onClick={() =>
                                                        saveFile(
                                                            `/files/${file.id}`,
                                                            file.name
                                                        )
                                                    }
                                                    style={{ marginRight: 10 }}
                                                >
                                                    {file.name}
                                                </a>
                                                <Button
                                                    variant={"contained"}
                                                    color={"secondary"}
                                                    onClick={() => {
                                                        previewPdf(
                                                            `/files/${file.id}`,
                                                            file.name
                                                        );
                                                    }}
                                                >
                                                    預覽
                                                </Button>
                                                {((data?.status == "draft") || hasRole(user, [RoleIdEnum.Admin,RoleIdEnum.AeManager,RoleIdEnum.AmManager])) && <ConfirmButton onConfirm={async()=>
                                                    {
                                                        try
                                                        {
                                                            await removeFile({variables:{id:file.id}})
                                                            NotificationPopup.success(`刪除成功`);
                                                        }
                                                        catch(e)
                                                        {
                                                            console.error(e);
                                                           NotificationPopup.success(`刪除檔案發生問題: ${e.message}`);
                                                        }
                                                    }}>
                                                        刪除
                                                    </ConfirmButton>}
                                            </div>
                                        );
                                    })
                                ) : (
                                    value
                                )}
                            </Grid>
                        </Grid>
                    );
                })}

            {/* </Grid> */}
        </div>
    );
};
