import {GridLocaleText} from '@mui/x-data-grid-pro';

export const DataGridLocale:Partial<GridLocaleText> = {
    // booleanCellFalseLabel: "",
    // booleanCellTrueLabel: "",
    // checkboxSelectionHeaderName: "",
    // filterValueAny: "",
    // filterValueFalse: "",
    // filterValueTrue: "",
    // footerRowSelected(count: number): React.ReactNode
    // {
    //     return undefined;
    // },
    // footerTotalRows: undefined,
    // noResultsOverlayLabel: "",
    // toolbarColumns: undefined,
    // toolbarColumnsLabel: "",
    // toolbarDensity: undefined,
    // toolbarDensityComfortable: "",
    // toolbarDensityCompact: "",
    // toolbarDensityLabel: "",
    // toolbarDensityStandard: "",
    toolbarExport: "輸出報表",
    toolbarExportCSV: "下載CSV檔",
    // Root
    // rootGridLabel: "表單",
    noRowsLabel: "沒有任何內容",
    errorOverlayDefaultLabel: "錯誤發生",
    // Column menu text
    columnMenuLabel: "Menu",
    columnMenuShowColumns: "顯示",
    columnMenuFilter: "篩選",
    columnMenuHideColumn: "隱藏",
    columnMenuUnsort: "不指定排序",
    columnMenuSortAsc: "由小到大",
    columnMenuSortDesc: "由大到小",
    // Columns panel text
    columnsPanelTextFieldLabel: "尋找欄位",
    columnsPanelTextFieldPlaceholder: "欄位名稱",
    columnsPanelDragIconLabel: "重新排序欄位",
    columnsPanelShowAllButton: "顯示全部",
    columnsPanelHideAllButton: "隱藏全部",
    // Filter panel text
    filterPanelAddFilter: "增加篩選",
    filterPanelDeleteIconLabel: "刪除",
    filterPanelOperators: "篩選條件",
    filterPanelOperatorAnd: "And",
    filterPanelOperatorOr: "Or",
    filterPanelColumns: "欄位",
    filterPanelInputLabel: "值",
    filterPanelInputPlaceholder: "輸入值",
    // Filter operators text
    filterOperatorContains: "包含",
    filterOperatorEquals: "等於",
    filterOperatorStartsWith: "開頭是",
    filterOperatorEndsWith: "結尾是",
    filterOperatorIs: "等於",
    filterOperatorNot: "不等於",
    filterOperatorAfter: "晚於",
    filterOperatorOnOrAfter: "晚於(包含)",
    filterOperatorBefore: "早於",
    filterOperatorOnOrBefore: "早於(包含)",
    filterOperatorIsEmpty: "等於空值",
    filterOperatorIsNotEmpty: "不是空值",

    // Filters toolbar button text
    toolbarFilters: "篩選",
    toolbarFiltersLabel: "顯示篩選",
    toolbarFiltersTooltipHide: "隱藏篩選",
    toolbarFiltersTooltipShow: "顯示篩選",
    toolbarFiltersTooltipActive: (count) => `${count}個篩選條件`,
    // Column header text
    columnHeaderFiltersTooltipActive: (count) => `${count}個篩選條件`,
    columnHeaderFiltersLabel: "顯示篩選",
    columnHeaderSortIconLabel: "排序",
    pinToLeft: "釘選至左邊",
    pinToRight: "釘選至右邊",
    unpin: "取消釘選",

    noResultsOverlayLabel:"沒有資料",
};
