import React, { useState } from "react";
import { Container, Grid } from "@material-ui/core";
import Page from "../../components/Page";
import CreateUserView from "./CreateUserView";
import UserTable from "./UserTable";

export default () => {
    return (
        <Page title={"user page"}>
            <Container maxWidth={false} style={{ padding: "30px"}}>
                <Grid container spacing={2}>
                    <CreateUserView/>
                    <UserTable/>
                </Grid>
            </Container>
        </Page>
    )
}