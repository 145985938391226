import React from 'react';

const Logo = (props) => {
  const logoUrl = window.location.href.indexOf("adup") !== -1?"/static/logo-adup.png":"/static/logo.png";
  return (
    <img
      alt="Logo"
      src={logoUrl}
      {...props}
      width={140}
    />
  );
};

export default Logo;
