import { Box } from "@material-ui/core";
import React from "react";
import {
    Deposit,
    DepositStatus, OrderItemStatus,
    RoleIdEnum,
    useRemoveDepositMutation,
} from "../../generated/graphql";
import { DepositStatusMap } from "../../common/Constant";
import { DataGridLocale } from "../../common/DataGridLocale";
import { format } from "date-fns";
import { HasRole, PasteToClipboard } from "../../common/Util";
import UpdateDeposit from "./UpdateDeposit";
import {
    GridCellParams,
    GridColumns,
    GridValueGetterParams,
    DataGridPro, GridValueFormatterParams,
} from "@mui/x-data-grid-pro";
import ConfirmButton from "../../components/ConfirmButton";
import NotificationPopup from "../../common/NotificationPopup";
import {filter, reduce} from "lodash";
import DepositCostPopup from "./DepositCostPopup";


export default ({ orderItem }) => {
    const [removeDeposit] = useRemoveDepositMutation();
    const onRemove = (deposit) => {
        try {
            removeDeposit({
                variables: {
                    id: deposit.id,
                },
                refetchQueries: ["getOrderItem"],
            });
            NotificationPopup.success(`刪除完成`);
        } catch (e) {
            NotificationPopup.error(`刪除發生問題: ${e.message}`);
            console.error(e);
        }
    };
    const columns: (fn)=>GridColumns = (onRemove)=>([
        { field: "id", headerName: "ID", width: 50 },
        {
            field: "amount",
            headerName: "發稿金額(實際金額)",
            width: 220,
            valueGetter: (params: GridValueGetterParams) => {
                return (
                    params.row.amount!.toLocaleString() +
                    (params.row.amountSpent == null
                        ? ""
                        : `(${params.row.amountSpent})`)
                );
            },
        },
        {
            field: "supplier",
            headerName: "供應商",
            width: 150,
            valueGetter: (params: GridValueGetterParams) => {
                return params.row.supplier ? params.row.supplier!["name"] : "";
            },
        },
        {
            field: "status",
            headerName: "狀態",
            width: 120,
            valueGetter: (params: GridValueGetterParams) => {
                return DepositStatusMap[params.row.status as string];
            },
        },
        { headerName: "CID", field: "accountId", width: 150 },
        {
            headerName: "開始日期",
            field: "startDate",
            width: 150,
            valueGetter: (params: GridValueGetterParams) => {
                return params.row.startDate
                    ? format(new Date(params.row.startDate as string), "yyyy-MM-dd")
                    : "未指定";
            },
        },
        {
            headerName: "結束日期",
            field: "endDate",
            width: 150,
            valueGetter: (params: GridValueGetterParams) =>
                params.row.endDate
                    ? format(new Date(params.row.endDate as string), "yyyy-MM-dd")
                    : "未指定",
        },
        {
            headerName: "後台花費",
            field: "accountCosts",
            width: 150,
            valueGetter: (params: GridValueGetterParams) => params.row.accountCosts.reduce((a, c) => a + c.cost, 0).toString(),
            renderCell: (params: GridCellParams) => {
                const depositAmount = params.row.amountSpent == null ? params.row.amount : params.row.amountSpent;
                const totalCost = params.row.accountCosts.reduce((a, c) => a + c.cost, 0)
                return <><div style={{color: depositAmount < totalCost ? "red" : ""}}>{totalCost.toLocaleString()}</div>
                    &nbsp;&nbsp;
                    <DepositCostPopup deposit={params.row}/>
                </>
            }
        },
        {
            headerName: "動作",
            field: "action",
            width: 160,
            disableColumnMenu: true,
            sortable: false,
            renderCell: (params: GridCellParams) => {
                return (
                    <>
                        {((params.row as Deposit).status ==
                            DepositStatus.Pending ||
                            (params.row as Deposit).status ==
                            DepositStatus.Finished) &&
                        orderItem.status != OrderItemStatus.Finished && (
                            <UpdateDeposit deposit={params.row} amMode={true} orderItem={orderItem} />
                        )}
                        &ensp;
                        <HasRole roles={[RoleIdEnum.Admin]}>
                            <ConfirmButton
                                onConfirm={() => onRemove(params.row)}
                                queryEntity={"deposit"}
                                queryAction={"刪除發稿"}
                                entityId={params.row.id}
                                queryMessage={`金額: ${params.row.amount}`}
                            >
                                刪除
                            </ConfirmButton>
                        </HasRole>
                    </>
                );
            },
        },
    ]);

    return (
        <Box width={"100%"} style={{ height: "60vh" }}>
            <DataGridPro
                columns={columns(onRemove)}
                rows={orderItem.deposits}
                hideFooter={true}
                autoHeight={true}
                localeText={DataGridLocale}
                onCellClick={PasteToClipboard}
            />
        </Box>
    );
};
