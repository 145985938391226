import DetailView from "../custom/DetailView";
import {
    OrderItem,
    RoleIdEnum,
    useGetPurchaseOrderQuery,
    useRemoveReceivedPaymentMutation
} from "../../generated/graphql";
import {useParams} from "react-router";
import DefaultQueryHandler from "../../common/DefaultQueryHandler";
import {Box, Button, Grid} from "@material-ui/core";
import {CurrencyMap, PurchaseOrderStatusMap} from "../../common/Constant";
import React from "react";
import {format} from "date-fns";
import {DataGridLocale} from "../../common/DataGridLocale";
import {HasRole, PasteToClipboard, saveFile} from "../../common/Util";
import {DataGridPro} from "@mui/x-data-grid-pro";
import NotificationPopup from "../../common/NotificationPopup";
import {getAccessToken} from "../../Auth";
import UpdatePurchaseOrderButton from "./UpdatePurchaseOrderButton";
import PurchaseOrderStatusView from "./PurchaseOrderStatusView";
import ConfirmButton from "../../components/ConfirmButton";
import {useNavigate} from "react-router-dom";

const columns = [
    {field: "id", headerName: "ID"},
    {
        field: "status",
        headerName: "狀態",
        valueFormatter: (value) =>
        {
            return PurchaseOrderStatusMap[value];
        },
    },
    {
        field: "signedDate",
        headerName: "簽約日期",
        valueFormatter: (value) => format(new Date(value), "yyyy-MM-dd"),
    },
    {
        field: "paidDate",
        headerName: "付款日期",
        valueFormatter: (value) => format(new Date(value), "yyyy-MM-dd"),
    },
    {field: "supplier", headerName: "供應商"},
    {field: "contact", headerName: "聯絡人"},
    {
        field: "amount",
        headerName: "金額",
        valueFormatter: (value) =>
        {
            return value?.toLocaleString();
        },
    },
    {field: "note", headerName: "備註"},
    {field: "description", headerName: "退件原因"},
    {field: "files", headerName: "報價單"},
    {field: "invoiceFiles", headerName: "請款單據"},
    {field: "updatedAt", headerName: "更新時間"},
    {
        field: "orderItem", headerName: "委刊細項", valueFormatter: (value) =>
        {
            return value.id
        }
    },
];
export default () =>
{
    const {id} = useParams();
    const {loading, data, error} = useGetPurchaseOrderQuery({variables: {id}});
    return (
        <DefaultQueryHandler error={error} loading={loading}>
            <Grid container spacing={3}>
                <Grid item>
                    <UpdatePurchaseOrderButton purchaseOrder={data?.purchaseOrder}/>
                </Grid>
                <Grid item>
                </Grid>
            </Grid>
            <PurchaseOrderStatusView purchaseOrder={data?.purchaseOrder}/>
            <DetailView columns={columns} data={data?.purchaseOrder}/>
        </DefaultQueryHandler>
    );
};
