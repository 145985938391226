import {Box, Button, Chip} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import DefaultQueryHandler from "../../../common/DefaultQueryHandler";
import { DataGridLocale } from "../../../common/DataGridLocale";
import {GridColDef, DataGridPro, GridSortModel, GridFilterModel} from "@mui/x-data-grid-pro";
import {PasteToClipboard, ViewColumn} from "../../../common/Util";
import {
    useCreateGoogleAdsRecordMutation,
    useGetGoogleAdsTemplatesQuery,
    useRemoveGoogleAdsTemplateMutation
} from "../../../generated/graphql";
import {BookOpen, Edit, Eye} from "react-feather";
import {Delete, DeleteForever} from "@material-ui/icons";
import CreateTemplateView, {AdTypes, formatField} from "./CreateTemplateView";
import CreateView from "../../custom/CreateView";
import {InputType} from "../../../common/FormRenderer";
import {download} from "../../report/DownloadableReports";
import ConfirmButton from "../../../components/ConfirmButton";
import NotificationPopup from "../../../common/NotificationPopup";
import {useUser} from "../../../Auth";
import {find} from "lodash";

export default () => {
    const user = useUser();
    const { loading, data, error } = useGetGoogleAdsTemplatesQuery();
    const [removeTemplate] = useRemoveGoogleAdsTemplateMutation();
    const [createRecord] = useCreateGoogleAdsRecordMutation();
    const [sortModel, setSortModel] = useState<GridSortModel>([{field: "id", sort: "desc"}]);
    const [filterModel, setFilterModel] = useState<
        GridFilterModel | undefined
        >();
    useEffect(()=>{
        setFilterModel({
            items:[{
                columnField:"assignee",
                value:user?.name,
                operatorValue:"equals"
            }],
        });
        },[user]
    );
    const onRemove = async (id) =>
    {
        try {
            await removeTemplate({
                variables: {
                    id: id,
                },
                refetchQueries: ["getGoogleAdsTemplates"],
            });
            NotificationPopup.success(`刪除完成`);
        } catch (e) {
            NotificationPopup.error(`刪除發生問題: ${e.message}`);
            console.error(e);
        }
    };
    const columns = [
        { field: "id", headerName: "id", width: 100, type: "number", hide: true },
        { field: "name", headerName: "Google Ads 報表名稱", width: 200  },
        {
            field: "adTypes",
            headerName: "廣告類型/欄位",
            width: 500,
            renderCell: (params) => {
                return (
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '4px' }}>
                            {params.row.adTypes?.map((type) => (
                                <Chip
                                    size={"small"}
                                    key={type}
                                    label={find(AdTypes, (adType) => adType.key === type)?.label ?? ''}
                                    style={{ backgroundColor: '#A5D6A7', color: 'white' }}
                                />
                            ))}
                        </div>
                        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '4px' }}>
                            {params.row.reportNames?.map((name) => (
                                <Chip size="small" key={name} label={name.split('-').map((item)=>formatField(item))} style={{ backgroundColor: '#81D4FA', color: 'white' }} />
                            ))}
                        </div>
                        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '4px' }}>
                            {params.row.metricsFields?.map((field) => (
                                <Chip size="small" key={field} label={formatField(field)} style={{ backgroundColor: '#FFAB91', color: 'white' }} />
                            ))}
                        </div>
                    </div>
                );
            }
        },
        {
            headerName: "負責人",
            field: "assignee",
            valueGetter:(params)=>params.row.assignee?.name,
        },
        {
            type: "actions",
            headerName: "",
            field: "actions1",
            width: 90,
            disableColumnMenu: true,
            disableColumnSelector: false,
            sortable: false,
            getActions: (params) => [
                <CreateView submitTitle={"儲存"}  title={"產生報表"}
                            columns={[
                                { name: "startDate", label: "開始日期",type:InputType.date },
                                { name: "endDate", label: "結束日期",type:InputType.date },
                            ]}
                            onSubmit={async(res,helpers,setOpen)=>
                            {
                                await createRecord({
                                    variables: {
                                        googleAdsRecordInput: {
                                            templateId: parseInt(params.row.id),
                                            startDate: res.startDate,
                                            endDate: res.endDate,
                                            name: params.row.name,
                                            assigneeId: user?.id,
                                        }
                                    },
                                    refetchQueries: ["getGoogleAdsRecordList"],
                                });
                                setOpen(false);
                                NotificationPopup.success(`正在產生報表，稍後請至"報表紀錄"下載報表。`);

                            }}
                            queryEntity={"googleAds"}
                />
            ]
        },
        {
            type: "actions",
            headerName: "",
            field: "actions2",
            width: 90,
            disableColumnMenu: true,
            disableColumnSelector: false,
            sortable: false,
            getActions: (params) => [
                <CreateTemplateView template={params.row} />
            ]
        },
        {
            type: "actions",
            headerName: "",
            field: "actions3",
            width: 90,
            disableColumnMenu: true,
            disableColumnSelector: false,
            sortable: false,
            getActions: (params) => [
                <ConfirmButton
                    onConfirm={async () =>
                    {
                        await onRemove(params.row.id);
                    }}
                    queryEntity={"template"}
                    queryAction={"刪除廣告模板"}
                    entityId={params.row.id}
                    queryMessage={`名稱: ${params.row.name}`}
                >
                    刪除
                </ConfirmButton>
            ]
        },
    ];
    return (
        <DefaultQueryHandler error={error} loading={loading}>
            <Box width={"100%"} style={{ marginTop: "30px", height: "80vh" }}>
                <DataGridPro
                    columns={columns}
                    sortModel={sortModel}
                    onSortModelChange={(model)=>setSortModel(model)}
                    rows={data?.googleAdsTemplates!}
                    hideFooter={true}
                    autoHeight={true}
                    localeText={DataGridLocale}
                    onCellClick={PasteToClipboard}
                    filterModel={filterModel}
                    onFilterModelChange={(filterModel) => {
                        setFilterModel(filterModel);
                    }}
                    rowHeight={150}
                />
            </Box>
        </DefaultQueryHandler>
    );
};
