import { Box, Container } from "@material-ui/core";
import { Link } from "react-router-dom";
import React from "react";
import { loader } from "graphql.macro";
import PaginatedDatagrid, {FilterButton} from "../../components/PaginatedDatagrid";
import CreateOrderView from "./CreateOrderView";
import {
    dateValueFormatter,
    defaultLinkCellRenderer,
    ViewColumn,
} from "../../common/Util";
import {
    Comparator,
    InvoiceStatus,
    OrderStatus,
    useGetNotificationListQuery,
} from "../../generated/graphql";
import {CustomerTypeMap, OrderStatusList, OrderStatusMap} from "../../common/Constant";
import { GridFilterItem, GridValueGetterParams } from "@mui/x-data-grid-pro";
const GetOrdersQuery = loader("./graphql/GetOrders.graphql");

export default ({defaultFilterItems, filterButtons, refetchState}:
    {defaultFilterItems?: GridFilterItem[], filterButtons?: FilterButton[], refetchState?: any}) => {
    return (
        <Box width={"100%"}>
            <PaginatedDatagrid
                id={'order'}
                defaultFilterItems={defaultFilterItems}
                refetchState={refetchState}
                queryParams={true}
                filterButtons={filterButtons}
                query={GetOrdersQuery}
                ordering={{ field: "number", sort: "desc" }}
                columns={[
                    { headerName: "客戶類型", field: "customer.type", valueGetter: (params: GridValueGetterParams)=>CustomerTypeMap[params.row.customer.type as string]},
                    { headerName: "委刊單號", field: "number" },
                    {
                        headerName: "專案名稱",
                        field: "name",
                    },
                    { headerName: "簽約負責人", field: "assignedSalesUser.name" },
                    { headerName: "聯絡人", field: "contact.name" },
                    { headerName: "客戶", field: "customer.name" },
                    {
                        headerName: "狀態",
                        type: "singleSelect",
                        field: "status",
                        valueGetter: (params: GridValueGetterParams) => {
                            return params.row.status == 'draft' ? OrderStatusMap[params.row.status] : '進行中';
                        },
                        valueOptions: params => OrderStatusList,
                    },
                    ViewColumn("/app/order"),
                ]}
            />
        </Box>
    );
};
