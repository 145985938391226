import {Field, FieldAttributes} from "formik";
import React from "react";
import { chunk } from "lodash";
import {
    FormControl,
    FormGroup,
    Grid,
    TextField,
    GridSize,
    MenuItem,
    Select,
    InputLabel,
    Input,
    Chip,
    Button,
    Checkbox,
    FormControlLabel,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import NumberFormat from "react-number-format";
import {format} from "date-fns";

export enum InputType {
    textarea = "textarea",
    number = "formatednumber",
    text = "text",
    select = "select",
    email = "email",
    date = "date",
    hidden = "hidden",
    autoComplete = "autocomplete",
    button = "button",
    custom = "custom",
    checkbox = "checkbox",
    switch = "switch",
}
interface FieldDef {
    required?: boolean;
    name: string;
    type?: InputType;
    label?: string;
    options?: any[];
    isMulti?: boolean;
    form?: any;
    component?: any;
    renderCell:any;
    RenderCell:any;
    freeSolo?: boolean;
    min: Date,
    max: Date,
}
type OnChangeFn = (name: string, value: any) => void;

interface Params {
    def: FieldDef[];
    values: any;
    onChange: OnChangeFn;
    columns?: number; //number of columns
}
const style = {
    marginTop: 10,
};

const handleChangeMultiple = (event: React.ChangeEvent<{ value: unknown }>) => {
    const { options } = event.target as HTMLSelectElement;
    const value = [];
    // for (let i = 0, l = options.length; i < l; i += 1) {
    //   if (options[i].selected) {
    //     value.push(options[i].value);
    //   }
    // }
};

export interface SelectFieldParams
{
    options: { value:any,label:string }[];
    field:any;
    form:any;
    disabled?:boolean;
    required?:boolean;
    isMulti?:boolean;
    label?:string;
    startAdornment?:any;
}

export const SelectField = ({
    options,
    field,
    form,
    disabled,
    required=true,
    isMulti,
    label,
    startAdornment,
}:SelectFieldParams) => {
    return (
        <FormControl required={required}>
            <InputLabel htmlFor="select-native">{label}</InputLabel>
            <Select
                disabled={disabled}
                multiple={isMulti}
                value={field.value}
                name={field.name}
                inputProps={{
                    id: "select-native",
                }}
                input={<Input />}
                startAdornment={startAdornment}
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    const { options } = event.target as HTMLSelectElement;
                    form.setFieldValue(field.name, event.target.value);
                    event.stopPropagation();
                }}
            >
                {options.map(({ value, label }) => {
                    return (
                        <MenuItem value={value} key={value}>
                            {label}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
};
export const AutoCompleteField = ({
    options,
    field,
    form,
    disabled,
    required,
    isMulti,
    label,
    freeSolo,
    value,
}) => {

    return (
        <Autocomplete<string, boolean, boolean, boolean>
            multiple={isMulti}
            freeSolo={freeSolo}
            options={options ? options : []}
            getOptionLabel={(option: any) => {
                return option.label || "";
            }}
            defaultValue={value}
            // value={value}
            renderTags={(value: any, getTagProps) => {
                if (isMulti) {
                    return value.map((option, index: number) => (
                        <Chip
                            variant="outlined"
                            label={option.label}
                            {...getTagProps({ index })}
                        />
                    ));
                }
                else {
                    return value.map((option: string, index: number) => (
                        <Chip
                            variant="outlined"
                            label={option}
                            {...getTagProps({ index })}
                        />
                    ));
                }
            }}
            renderInput={(params) => (
                <TextField {...params} required={required}  label={label} placeholder={label} />
            )}
            onChange={(_, option: any) => {
                console.log(option);
                if (option instanceof Array) {
                    form.setFieldValue(field.name, option);
                }
                else if (typeof option === "object") {
                    form.setFieldValue(field.name, option?.value);
                }
                else {
                    form.setFieldValue(field.name, option);
                }
            }}
        />
    );
};
interface NumberFormatCustomProps {
    inputRef: (instance: NumberFormat | null) => void;
    onChange: (event: { target: { name: string; value: string|number|undefined } }) => void;
    name: string;
}
export function NumberFormatCustom(props: NumberFormatCustomProps) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.floatValue,
                    },
                });
            }}
            thousandSeparator
            isNumericString
        />
    );
}
const Number = ({ field, form, ...props }) => {
    return (
        <TextField
            {...field}
            {...props}
            name={field.name}
            InputProps={{
                inputComponent: NumberFormatCustom as any,
            }}
        />
    );
};
const Text = ({ field, form, pattern, ...props }) => {
    return <TextField {...field} {...props} name={field.name} inputProps={{ pattern: pattern }} />;
};

const FormButton = ({ field, label, hidden, ...props }) => {
    return hidden ? (
        <Button
            variant={"contained"}
            color={"secondary"}
            {...field}
            {...props}
            style={{ width: "fit-content", marginTop: 10 }}
        >
            {" "}
            {label}{" "}
        </Button>
    ) : (
        <></>
    );
};
const CustomView = ({ renderCell,RenderCell,form,field }) => {
    if(RenderCell)
    {
        return <RenderCell form={form} field={field}/>;
    }
    else {
        return renderCell;
    }

};

const Check = ({ field, form, label, ...props }) => {
    return (
        <FormControlLabel
            control={
                <Checkbox
                    checked={field.value}
                    {...field}
                    {...props}
                    name={field.name}
                />
            }
            label={label}
        />
    );
};

const inputRender = (fieldDef: FieldDef, onChange, value) => {
    switch (fieldDef.type) {
        case InputType.select:
            return (
                <Field
                    component={SelectField}
                    {...fieldDef}
                    onChange={onChange}
                    value={value}
                />
            );
        case InputType.textarea:
            return (
                <Field
                    component={Text}
                    {...fieldDef}
                    value={value}
                    onChange={onChange}
                    multiline
                    style={style}
                />
            );
        case InputType.autoComplete:
            return (
                <Field
                    component={AutoCompleteField}
                    {...fieldDef}
                    onChange={onChange}
                    value={value}
                />
            );
        case InputType.hidden:
            return <div></div>;
        case InputType.button:
            return (
                <Field
                    component={FormButton}
                    {...fieldDef}
                    onChange={onChange}
                />
            );
        case InputType.custom:
            return (
                <Field
                    component={CustomView}
                    {...fieldDef}
                    onChange={onChange}
                />
            );
        case InputType.checkbox:
            return (
                <Field
                    component={Check}
                    label={fieldDef.label}
                    name={fieldDef.name}
                    onChange={onChange}
                />
            );
        case InputType.number:
            return (
                <Field
                    component={Number}
                    {...fieldDef}
                    value={value}
                    onChange={onChange}
                />
            );
        case InputType.date:
            console.log(fieldDef);
            return (
                <TextField
                    type="date"
                    {...fieldDef}
                    value={value}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{
                        min: fieldDef.min ? format(new Date(fieldDef.min), 'yyyy-MM-dd') : null,
                        max: fieldDef.max ? format(new Date(fieldDef.max), 'yyyy-MM-dd') : null,
                    }}
                    required
                    onChange={onChange}
                />
            );
        case InputType.text:
        case InputType.email:

        default:
            return (
                <Field
                    component={Text}
                    {...fieldDef}
                    value={value}
                />
            );
    }
};

const formPart = (def: FieldDef[], onChange, values) => {
    return def.map((fieldDef) => {
        return (
            <FormGroup key={fieldDef.name}>
                {/*<FormLabel>{fieldDef.label || labelize(fieldDef.name)} {fieldDef.required && "*"} </FormLabel>*/}
                <FormControl style={{width: "100%"}}>
                    {inputRender(fieldDef, onChange, values[fieldDef.name])}
                </FormControl>
            </FormGroup>
        );
    });
};

const formContent = (columns, def, onChange, values) => {
    if (columns) {
        const chunked = chunk(def, columns);
        return chunked.map((chunk:any[], index) => (
            <Grid container key={index}>
                {chunk.map((col, index) => (
                    <Grid item key={index} md={(12 / columns) as GridSize}>
                        {formPart([col], onChange, values)}
                    </Grid>
                ))}
            </Grid>
        ));
    } else {
        return formPart(def, onChange, values);
    }
};

export default ({ def, onChange, columns = 1, values }: Params) => {
    //TODO: add YUP validation?
    return <>{formContent(columns, def, onChange, values)}</>
};
