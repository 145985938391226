import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from "@material-ui/core";
import FormRenderer  from "../../common/FormRenderer";
import { Formik } from "formik";
import React, { useState} from "react";
import {omit, reduce} from "lodash";
import {getModifiedValues} from "./EditView";
import {getLogQueryMessage} from "../log/LogUtils";
import {useUser} from "../../Auth";
import {useCreateLogMutation} from "../../generated/graphql";

export default ({ title, columns, onSubmit, submitTitle='新增' , queryEntity}) => {
    const [open, setOpen] = useState(false);
    const [disable, setDisable] = useState(false);
    const user = useUser();
    const [createLog] = useCreateLogMutation();
    const initialValues = reduce(
        columns,
        (acc, column) => {
            return { ...acc, [column.name]: column.value };
        },
        {}
    )
    // console.log(initialValues);

    return (
        <div>
            <Dialog open={open} fullWidth={true}>
                <DialogTitle style={{ backgroundColor: "#DDD" }}>
                    <Typography variant="h4" align="center">
                        {title}
                    </Typography>
                </DialogTitle>
                <Formik
                    initialValues={initialValues}
                    onSubmit={async (values, bla) => {
                        setDisable(true);
                        const newEntity = await onSubmit(getModifiedValues(values, initialValues, submitTitle), bla, setOpen);
                        if (newEntity) {
                            await createLog({
                                variables: {
                                    log: {
                                        entityId: (newEntity.id) as string,
                                        userId: user!.id as string,
                                        queryAction: title,
                                        queryEntity: queryEntity,
                                        queryMessage: `${getLogQueryMessage(omit(getModifiedValues(values, initialValues, submitTitle), ['id']), columns )}`,
                                    }
                                }
                            });
                        }
                        await setTimeout(function() { //Start the timer
                            setDisable(false);
                        }.bind(this), 500);
                    }}
                >
                    {({ handleChange, resetForm, handleSubmit, values }) => {
                        columns.map((column)=> {
                            if ( 'onChange' in column) {
                                column.onChange(values[column.name])
                            }
                        })
                        return (
                            <form onSubmit={handleSubmit}>
                                <DialogContent>
                                    <FormRenderer
                                        values={values}
                                        def={columns}
                                        onChange={handleChange}
                                    />
                                </DialogContent>
                                <DialogActions style={{paddingLeft: 24, paddingRight: 24}}>
                                    <Button
                                        variant={"contained"}
                                        color={"primary"}
                                        type={"submit"}
                                        disabled={disable}
                                    >
                                        {submitTitle}
                                    </Button>
                                    <Button
                                        variant={"contained"}
                                        color={"secondary"}
                                        onClick={() => {
                                            setOpen(false);
                                            resetForm();
                                        }}
                                    >
                                        取消
                                    </Button>
                                </DialogActions>
                            </form>
                        );
                    }}
                </Formik>
            </Dialog>
            <Button
                variant={"contained"}
                color={"primary"}
                onClick={() => setOpen(true)}
            >
                {title}
            </Button>
        </div>
    );
};
