import {add,parseISO} from 'date-fns';
import {useEffect, useState} from "react";
import {
    Customer,
    useGetCustomerBriefListQuery,
    useGetCustomerListQuery,
    useMeQueryQuery,
    User,
} from "./generated/graphql";

const ACCESS_TOKEN = "ACCESS_TOKEN2";
const ACCESS_TOKEN_TIMESTAMP = "ACCESS_TOKEN_TIMESTAMP2";

export const getAccessToken = ():string|null|undefined=>
{
    const timestamp = window.localStorage.getItem(ACCESS_TOKEN_TIMESTAMP);
    const expireTimeStamp = add(parseISO(timestamp!), {weeks:1})
    if(new Date() > expireTimeStamp)
    {
        window.localStorage.removeItem(ACCESS_TOKEN);
    }
    return window.localStorage.getItem(ACCESS_TOKEN);

}

export const setAccessToken = (token:string):void=>
{
    window.localStorage.setItem(ACCESS_TOKEN,token);
    window.localStorage.setItem(ACCESS_TOKEN_TIMESTAMP,new Date().toISOString());
}
export const logout = ()=>
{
    window.localStorage.removeItem(ACCESS_TOKEN)
    window.localStorage.removeItem(ACCESS_TOKEN_TIMESTAMP);
}


export const useUser = ():Partial<User>|undefined=>
{
    const [user,setUser] = useState<Partial<User>|undefined>(undefined);
    const {data,error} = useMeQueryQuery();
    useEffect(()=>
    {
        if(error)
        {
            throw new Error(`Error setting user in hook useUser ${error.message}`);
        }
        if(data)
        {
            setUser(data.me);
        }
    },[data,setUser,error])

    return user;
}

export const useCustomers = ():any=>
{
    const [customers,setCustomers] = useState<Array<Partial<any>|undefined>|undefined>(undefined);
    const {data,error} = useGetCustomerBriefListQuery();
    useEffect(()=>
    {
        if(error)
        {
            throw new Error(`Error setting user in hook useUser ${error.message}`);
        }
        if(data)
        {
            setCustomers(data?.result?.list);
        }
    },[data,setCustomers,error])

    return customers;
}


