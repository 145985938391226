import {listToMap, ReceivedPaymentStatusList} from "./Constant";

export const IndustryTagList = [
    {
        "id": "A",
        "name": "農、林、漁、牧業",
        "def": "從事農作物栽培、畜牧、農事及畜牧服務、造林、伐木及採集、漁撈及水產養殖等之行業。",
        "list": [
            {
                "id": "01",
                "name": "農、牧業",
                "def": "從事農作物栽培、畜禽飼育及放牧、農事及畜牧服務等之行業。",
                "list": [
                    {
                        "id": "011",
                        "name": "農作物栽培業",
                        "def": "從事稻作、雜糧、特用作物、蔬菜、果樹、食用菇蕈、花卉、秧苗、種苗及食用菇蕈之菌種等農作物栽培之行業。"
                    },
                    {
                        "id": "012",
                        "name": "畜牧業",
                        "def": "從事畜、禽等之飼育、放牧，以生產肉、乳、皮、毛、蛋等之行業；昆蟲、爬蟲、鳥類及觀賞動物等之飼育亦歸入本類。"
                    },
                    {
                        "id": "013",
                        "name": "農事及畜牧服務業",
                        "def": "從事對他人提供作物栽培服務、作物採收後處理、畜牧服務等農事服務之行業。"
                    }
                ]
            },
            {
                "id": "02",
                "name": "林業",
                "def": "從事造林、伐木、林間生物採捕、林業輔助服務如森林資源估測等之行業；在林地以簡易炭窯燒製木（竹）炭亦歸入本類。",
                "list": [
                    {
                        "id": "020",
                        "name": "林業",
                        "def": "從事造林、伐木、林間生物採捕、林業輔助服務如森林資源估測等之行業；在林地以簡易炭窯燒製木（竹）炭亦歸入本類。"
                    }
                ]
            },
            {
                "id": "03",
                "name": "漁業",
                "def": "從事水產生物採捕或養殖之行業。",
                "list": [
                    {
                        "id": "031",
                        "name": "漁撈業",
                        "def": "在海洋及內陸水域從事水產生物採捕之行業。"
                    },
                    {
                        "id": "032",
                        "name": "水產養殖業",
                        "def": "從事水產生物繁殖或飼養之行業。"
                    }
                ]
            }
        ]
    },
    {
        "id": "B",
        "name": "礦業及土石採取業",
        "def": "從事石油、天然氣、砂、石及黏土等礦物及土石之探勘、採取、初步處理（如碎解、洗選等處理作業）及準備作業（如除土、開坑、掘鑿等礦場工程）等之行業。",
        "list": [
            {
                "id": "05",
                "name": "石油及天然氣礦業",
                "def": "從事石油及天然氣之探勘、採取、初步處理及準備作業等之行業；從事頁岩油或瀝青砂開採、油田或天然氣田生產之碳氫化合物中分離提取液態烴，以及油氣井設備安裝亦歸入本類。",
                "list": [
                    {
                        "id": "050",
                        "name": "石油及天然氣礦業",
                        "def": "從事石油及天然氣之探勘、採取、初步處理及準備作業等之行業；從事頁岩油或瀝青砂開採、油田或天然氣田生產之碳氫化合物中分離提取液態烴，以及油氣井設備安裝亦歸入本類。"
                    }
                ]
            },
            {
                "id": "06",
                "name": "砂、石採取及其他礦業",
                "def": "從事砂、石、黏土及其他礦物之探勘、採取、初步處理及準備作業等之行業，如砂（含矽砂）、礫、大理石、石灰石、蛇紋石、花崗岩、砂岩、石膏、黏土、瓷土、金屬礦、硫磺礦、玉礦及水晶礦等採取。",
                "list": [
                    {
                        "id": "060",
                        "name": "砂、石採取及其他礦業",
                        "def": "從事砂、石、黏土及其他礦物之探勘、採取、初步處理及準備作業等之行業，如砂（含矽砂）、礫、大理石、石灰石、蛇紋石、花崗岩、砂岩、石膏、黏土、瓷土、金屬礦、硫磺礦、玉礦及水晶礦等採取。"
                    }
                ]
            }
        ]
    },
    {
        "id": "C",
        "name": "製造業",
        "def": "從事以物理或化學方法，將材料、物質或零組件轉變成新產品，不論使用動力機械或人力，在工廠內或在家中作業，均歸入製造業；例外情形列舉如負面表列第1至第7項。此外，產品實質改造、翻新、重製作業、組件組裝、產業機械及設備之維修與安裝亦歸入本類；而機械設備之專用零組件製造與其所屬機械設備主體製造原則上歸入同一類別；非專用零組件如原動機、活塞、電動機、電器配件、活閥、齒輪、軸承等製造，則依性質分別歸入適當類別；惟以鑄模、擠壓等方法製造之塑膠零配件則歸入2203細類「塑膠外殼及配件製造業」。",
        "list": [
            {
                "id": "08",
                "name": "食品及飼品製造業",
                "def": "從事將農、林、漁、牧業產品處理成食品及飼品之行業，如肉類、水產及蔬果之加工及保藏、動植物油脂、乳品、碾穀、磨粉及澱粉製品與動物飼品等製造。",
                "list": [
                    {
                        "id": "081",
                        "name": "肉類加工及保藏業",
                        "def": "從事禽畜肉類加工及保藏之行業。"
                    },
                    {
                        "id": "082",
                        "name": "水產加工及保藏業",
                        "def": "從事水產加工及保藏之行業，如生鮮水產處理、保藏、乾製、醃製、燻製及鹽漬等加工處理。"
                    },
                    {
                        "id": "083",
                        "name": "蔬果加工及保藏業",
                        "def": "從事蔬果加工及保藏之行業，如生鮮蔬果處理、保藏、乾製、油漬、酸漬、糖漬、鹽漬及烘製等加工處理。"
                    },
                    {
                        "id": "084",
                        "name": "動植物油脂製造業",
                        "def": "從事粗製及精煉動植物食用油脂之行業，如橄欖油、大豆油、人造奶油、烹飪用油、食用動物油脂等製造。"
                    },
                    {
                        "id": "085",
                        "name": "乳品製造業",
                        "def": "從事乳品製造之行業，如鮮乳、調味乳、鮮奶油、優格、乳酪、冰淇淋等製造；奶精、奶油球等類乳製品製造亦歸入本類。"
                    },
                    {
                        "id": "086",
                        "name": "碾穀、磨粉及澱粉製品製造業",
                        "def": "從事穀物碾磨、磨粉製品、澱粉及其製品製造之行業。"
                    },
                    {
                        "id": "087",
                        "name": "動物飼品製造業",
                        "def": "從事將榖物、肉類、水產類、油籽製品等處理或調製成動物飼品之行業。"
                    },
                    {
                        "id": "089",
                        "name": "其他食品製造業",
                        "def": "從事081至086s以外食品製造之行業，如烘焙炊蒸食品、麵條、粉條類食品、糖、巧克力及糖果、茶、調味品、膳食及菜餚等製造。"
                    }
                ]
            },
            {
                "id": "09",
                "name": "飲料製造業",
                "def": "從事飲料製造之行業。",
                "list": [
                    {
                        "id": "091",
                        "name": "酒精飲料製造業",
                        "def": "從事啤酒及含酒精成分超過0.5％飲料製造之行業。"
                    },
                    {
                        "id": "092",
                        "name": "非酒精飲料製造業",
                        "def": "從事含酒精成分不超過0.5％飲料製造之行業，如茶類飲料、碳酸飲料、咖啡飲料、醋飲料、運動飲料、包裝飲用水及蔬果汁飲料等製造。"
                    }
                ]
            },
            {
                "id": "10",
                "name": "菸草製造業",
                "def": "從事以菸草或菸草代用品作為原料，製造可供吸用、嚼用、含用或聞用等菸草製品之行業。",
                "list": [
                    {
                        "id": "100",
                        "name": "菸草製造業",
                        "def": "從事以菸草或菸草代用品作為原料，製造可供吸用、嚼用、含用或聞用等菸草製品之行業。"
                    }
                ]
            },
            {
                "id": "11",
                "name": "紡織業",
                "def": "從事紡織之行業，如紡紗、織布、染整及紡織品製造等。",
                "list": [
                    {
                        "id": "111",
                        "name": "紡紗業",
                        "def": "從事纖維紡前處理及紗（線）紡製之行業；紙紗紡製及包覆彈性絲之紡紗亦歸入本類。"
                    },
                    {
                        "id": "112",
                        "name": "織布業",
                        "def": "從事以各種材質之紗（絲）為原料織造布疋之行業。"
                    },
                    {
                        "id": "113",
                        "name": "不織布業",
                        "def": "從事以天然纖維及人造纖維經膠合、針軋、水軋、熱熔、紡黏及熔噴等方法製成織品之行業。"
                    },
                    {
                        "id": "114",
                        "name": "染整業",
                        "def": "從事纖維、紗（線）、布疋、成衣等紡織品漂白、染色、整理及塗佈之行業；同時從事紡織品染整及印花亦歸入本類。"
                    },
                    {
                        "id": "115",
                        "name": "紡織品製造業",
                        "def": "從事紡織品製造之行業，如毛毯、床單、桌巾、毛巾、地毯、繩索、紡織標籤、紡織徽章等製造。"
                    }
                ]
            },
            {
                "id": "12",
                "name": "成衣及服飾品製造業",
                "def": "從事成衣及服飾品製造之行業；以皮革、毛皮製造成衣及服飾品亦歸入本類。",
                "list": [
                    {
                        "id": "121",
                        "name": "成衣製造業",
                        "def": "從事成衣製造之行業，如紡織成衣及皮衣等製造；服裝訂製及雨衣縫製亦歸入本類。"
                    },
                    {
                        "id": "123",
                        "name": "服飾品製造業",
                        "def": "從事服飾品製造之行業，如襪類、紡織手套、紡織帽、圍巾、領帶等製造；皮帶、非運動用皮製手套、毛皮帽、氈帽及髮網製造亦歸入本類。"
                    }
                ]
            },
            {
                "id": "13",
                "name": "皮革、毛皮及其製品製造業",
                "def": "從事皮革與毛皮整製，以及鞋類、行李箱、手提袋等皮革、毛皮製品製造之行業；以仿皮革或皮革替代品為原料製造者亦歸入本類。",
                "list": [
                    {
                        "id": "130",
                        "name": "皮革、毛皮及其製品製造業",
                        "def": "從事皮革與毛皮整製，以及鞋類、行李箱、手提袋等皮革、毛皮製品製造之行業；以仿皮革或皮革替代品為原料製造者亦歸入本類。"
                    }
                ]
            },
            {
                "id": "14",
                "name": "木竹製品製造業",
                "def": "從事以木、竹、藤、柳條、軟木、草等製成材料、半成品或成品之行業。",
                "list": [
                    {
                        "id": "140",
                        "name": "木竹製品製造業",
                        "def": "從事以木、竹、藤、柳條、軟木、草等製成材料、半成品或成品之行業。"
                    }
                ]
            },
            {
                "id": "15",
                "name": "紙漿、紙及紙製品製造業",
                "def": "從事紙漿、紙張、紙板及其製品製造之行業。",
                "list": [
                    {
                        "id": "151",
                        "name": "紙漿、紙及紙板製造業",
                        "def": "從事以木材、竹、草、蔗渣、廢布、廢紙、下腳棉花及其他植物纖維製造紙漿，及以紙漿為原料製造紙張及紙板之行業。"
                    },
                    {
                        "id": "152",
                        "name": "瓦楞紙板及紙容器製造業",
                        "def": "從事瓦楞紙板製造，及以紙板、瓦楞紙板、浸蠟紙、強力紙等製造之容器、紙袋或紙棧板之行業，如紙杯、紙盒、紙袋、紙板箱、瓦楞紙箱等製造。"
                    },
                    {
                        "id": "159",
                        "name": "其他紙製品製造業",
                        "def": "從事151及152s以外紙製品製造之行業。"
                    }
                ]
            },
            {
                "id": "16",
                "name": "印刷業",
                "def": "從事報紙、書籍、期刊等印刷之行業。印刷製程包括使用各種方法將影像從印刷版、網版或電腦檔案轉印到紙張、塑膠、金屬、紡織製品或木材等媒介。",
                "list": [
                    {
                        "id": "160",
                        "name": "印刷及資料儲存媒體複製業",
                        "def": "從事製版、印刷、裝訂、其他印刷輔助及資料儲存媒體複製之行業。"
                    }
                ]
            },
            {
                "id": "17",
                "name": "石油及煤製品製造業",
                "def": "從事將原油及煤製成可用產品之行業，包括以裂解、蒸餾等技術將原油分離出燃料氣、汽油、輕油（石油腦）、煤油、柴油等石油精煉產品，以及生產半焦炭、焦炭、煤焦油等產品；製造生質汽（柴）油之行業亦歸入本類。",
                "list": [
                    {
                        "id": "170",
                        "name": "石油及煤製品製造業",
                        "def": "從事將原油及煤製成可用產品之行業，包括以裂解、蒸餾等技術將原油分離出燃料氣、汽油、輕油（石油腦）、煤油、柴油等石油精煉產品，以及生產半焦炭、焦炭、煤焦油等產品；製造生質汽（柴）油之行業亦歸入本類。"
                    }
                ]
            },
            {
                "id": "18",
                "name": "化學材料及肥料製造業",
                "def": "從事化學原材料、肥料及氮化合物、塑膠及合成橡膠原料、人造纖維等製造之行業。",
                "list": [
                    {
                        "id": "181",
                        "name": "化學原材料製造業",
                        "def": "從事以熱解、蒸餾等基本化學程序製造化學元素及化合物之行業，如工業或醫療用之液化或壓縮無機氣體、無機酸、無機鹼及其他無機化合物等製造；乙烯、丙烯、芳香烴等石油化工原料製造亦歸入本類。"
                    },
                    {
                        "id": "183",
                        "name": "肥料及氮化合物製造業",
                        "def": "從事化學肥料及氮化合物製造之行業，如氮肥、磷肥、鉀肥、尿素、天然磷酸鹽、天然鉀鹽及氨水、硝酸鹽、硝酸鉀等氮相關產品之製造；土壤改良劑及有機肥料（堆肥除外）製造亦歸入本類。"
                    },
                    {
                        "id": "184",
                        "name": "塑膠及合成橡膠原料製造業",
                        "def": "從事塑膠及合成橡膠原料製造之行業。"
                    },
                    {
                        "id": "185",
                        "name": "人造纖維製造業",
                        "def": "從事以化學方法製造合成或再生纖維之行業，如醋酸纖維、聚酯纖維、嫘縈纖維、硝化纖維、銅銨纖維、尼龍纖維、酪素纖維、聚丙烯纖維、聚丙烯腈（亞克力）纖維及聚氨基甲酸酯纖維等製造。"
                    }
                ]
            },
            {
                "id": "19",
                "name": "其他化學製品製造業",
                "def": "從事農藥及環境用藥、塗料染料及顏料、清潔用品及化粧品等製造之行業。",
                "list": [
                    {
                        "id": "191",
                        "name": "農藥及環境用藥製造業",
                        "def": "從事農業及環境用藥製造之行業，如殺蟲劑、殺蟎劑、殺鼠劑、殺菌劑、除草劑、發芽抑制劑、植物生長調節劑、消毒劑、污染防治用藥、環境用藥微生物製劑等製造。"
                    },
                    {
                        "id": "192",
                        "name": "塗料、染料及顏料製造業",
                        "def": "從事塗料、染料及顏料製造之行業；瓷釉及印刷油墨製造亦歸入本類。"
                    },
                    {
                        "id": "193",
                        "name": "清潔用品及化粧品製造業",
                        "def": "從事清潔用品及化粧品製造之行業。"
                    },
                    {
                        "id": "199",
                        "name": "未分類其他化學製品製造業",
                        "def": "從事191至193s以外其他化學製品製造之行業，如火柴、火藥、炸藥、膠水、抗震劑、閃光劑、墨水及墨汁等製造；橡膠生膠之混煉亦歸入本類。"
                    }
                ]
            },
            {
                "id": "20",
                "name": "藥品及醫用化學製品製造業",
                "def": "從事原料藥、西藥、醫用生物製品、中藥及醫用化學製品等製造之行業。",
                "list": [
                    {
                        "id": "200",
                        "name": "藥品及醫用化學製品製造業",
                        "def": "從事原料藥、西藥、醫用生物製品、中藥及醫用化學製品等製造之行業。"
                    }
                ]
            },
            {
                "id": "21",
                "name": "橡膠製品製造業",
                "def": "從事橡膠製品製造之行業。",
                "list": [
                    {
                        "id": "210",
                        "name": "橡膠製品製造業",
                        "def": "從事橡膠製品製造之行業。"
                    }
                ]
            },
            {
                "id": "22",
                "name": "塑膠製品製造業",
                "def": "從事塑膠製品製造之行業。",
                "list": [
                    {
                        "id": "220",
                        "name": "塑膠製品製造業",
                        "def": "從事塑膠製品製造之行業。"
                    }
                ]
            },
            {
                "id": "23",
                "name": "非金屬礦物製品製造業",
                "def": "從事石油及煤以外非金屬礦物製品製造之行業。",
                "list": [
                    {
                        "id": "231",
                        "name": "玻璃及其製品製造業",
                        "def": "從事玻璃、玻璃纖維及玻璃製品製造之行業；科學或工業用玻璃器皿及玻璃熟料之製造亦歸入本類。"
                    },
                    {
                        "id": "232",
                        "name": "耐火、黏土建材及其他陶瓷製品製造業",
                        "def": "從事耐火材料、黏土建築材料及其他陶瓷製品製造之行業。"
                    },
                    {
                        "id": "233",
                        "name": "水泥及其製品製造業",
                        "def": "從事水泥、預拌混凝土及其製品製造之行業。"
                    },
                    {
                        "id": "234",
                        "name": "石材製品製造業",
                        "def": "從事將石材切割、成型及修飾為石材製品之行業，如石碑、石材建築材料、鋪地石板、石材家具等製造。"
                    },
                    {
                        "id": "239",
                        "name": "其他非金屬礦物製品製造業",
                        "def": "從事231至234s以外非金屬礦物製品製造之行業，如研磨材料、石灰、石膏及其他非金屬礦物製品製造。"
                    }
                ]
            },
            {
                "id": "24",
                "name": "基本金屬製造業",
                "def": "從事以冶鍊、鑄造、軋延、擠型、伸線等方式製造金屬板、條、棒、管、線等基本金屬製品之行業。",
                "list": [
                    {
                        "id": "241",
                        "name": "鋼鐵製造業",
                        "def": "從事鋼鐵冶鍊、鑄造、軋延、擠型及伸線等之行業。"
                    },
                    {
                        "id": "242",
                        "name": "鋁製造業",
                        "def": "從事鋁金屬冶鍊、鑄造、軋延、擠型及伸線等之行業。"
                    },
                    {
                        "id": "243",
                        "name": "銅製造業",
                        "def": "從事銅金屬冶鍊、鑄造、軋延、擠型及伸線等之行業。"
                    },
                    {
                        "id": "249",
                        "name": "其他基本金屬製造業",
                        "def": "從事241至243s以外基本金屬冶鍊、鑄造、軋延、擠型及伸線等之行業。"
                    }
                ]
            },
            {
                "id": "25",
                "name": "金屬製品製造業",
                "def": "從事金屬刀具、手工具、金屬模具、金屬結構及建築組件、金屬容器、金屬加工處理及其他金屬製品製造之行業。",
                "list": [
                    {
                        "id": "251",
                        "name": "金屬刀具、手工具及模具製造業",
                        "def": "從事金屬刀具、手工具及模具製造之行業。"
                    },
                    {
                        "id": "252",
                        "name": "金屬結構及建築組件製造業",
                        "def": "從事金屬結構及建築組件製造之行業。"
                    },
                    {
                        "id": "253",
                        "name": "金屬容器製造業",
                        "def": "從事鍋爐、金屬貯槽、壓力容器及其他金屬容器製造之行業。"
                    },
                    {
                        "id": "254",
                        "name": "金屬加工處理業",
                        "def": "從事金屬鍛造、粉末冶金、金屬熱處理、金屬表面處理及其他金屬加工處理之行業。"
                    },
                    {
                        "id": "259",
                        "name": "其他金屬製品製造業",
                        "def": "從事251至254s以外金屬製品製造之行業，如螺絲、螺帽、鉚釘、金屬彈簧、金屬線製品及其他金屬製品製造。"
                    }
                ]
            },
            {
                "id": "26",
                "name": "電子零組件製造業",
                "def": "從事半導體、被動電子元件、印刷電路板、光電材料及元件等電子零組件製造之行業。",
                "list": [
                    {
                        "id": "261",
                        "name": "半導體製造業",
                        "def": "從事半導體製造之行業，如積體電路（IC）及分離式元件製造；半導體封裝及測試亦歸入本類。"
                    },
                    {
                        "id": "262",
                        "name": "被動電子元件製造業",
                        "def": "從事被動電子元件製造之行業，如電子用之電容器、繼電器、電感器、電阻裝置等製造。"
                    },
                    {
                        "id": "263",
                        "name": "印刷電路板製造業",
                        "def": "從事印刷電路板製造之行業；印刷電路銅箔基板製造及積體電路（IC）載板製造亦歸入本類。"
                    },
                    {
                        "id": "264",
                        "name": "光電材料及元件製造業",
                        "def": "從事光電材料及元件製造之行業，如液晶面板及其組件、發光二極體、太陽能電池、電漿面板及其組件等製造。"
                    },
                    {
                        "id": "269",
                        "name": "其他電子零組件製造業",
                        "def": "從事261至264s以外電子零組件製造之行業。"
                    }
                ]
            },
            {
                "id": "27",
                "name": "電腦、電子產品及光學製品製造業",
                "def": "從事電腦及其週邊設備、通訊傳播設備、視聽電子產品、資料儲存媒體、量測設備、導航設備、控制設備、鐘錶、輻射設備、電子醫學設備、光學儀器及設備等製造之行業。",
                "list": [
                    {
                        "id": "271",
                        "name": "電腦及其週邊設備製造業",
                        "def": "從事電腦及其週邊設備製造或組裝之行業。"
                    },
                    {
                        "id": "272",
                        "name": "通訊傳播設備製造業",
                        "def": "從事電話、有線通訊傳播設備、無線通訊傳播設備、廣播及電視傳播設備等製造之行業。"
                    },
                    {
                        "id": "273",
                        "name": "視聽電子產品製造業",
                        "def": "從事供家庭娛樂、汽車等視聽電子產品製造之行業，如電視機、錄放影機、家庭劇院視聽設備、光碟片播放機、點唱機、揚聲器、擴音器、麥克風、耳機、家用攝影機等製造。"
                    },
                    {
                        "id": "274",
                        "name": "資料儲存媒體製造業",
                        "def": "從事磁性及光學之空白資料儲存媒體製造之行業，如空白光碟片製造。"
                    },
                    {
                        "id": "275",
                        "name": "量測、導航、控制設備及鐘錶製造業",
                        "def": "從事量測、導航及控制設備製造之行業；計時功能之量測裝置（如鐘錶及相關裝置）製造亦歸入本類。"
                    },
                    {
                        "id": "276",
                        "name": "輻射及電子醫學設備製造業",
                        "def": "從事輻射及電子醫學設備製造之行業，如醫學用超音波設備、助聽器、電子醫學內視鏡設備等製造；食品殺菌輻射設備製造亦歸入本類。"
                    },
                    {
                        "id": "277",
                        "name": "光學儀器及設備製造業",
                        "def": "從事照相機及其他光學儀器與設備製造之行業。"
                    }
                ]
            },
            {
                "id": "28",
                "name": "電力設備及配備製造業",
                "def": "從事發電、輸電、配電機械、電池、電線及配線器材、照明設備及配備、家用電器等電力設備及配備製造之行業。",
                "list": [
                    {
                        "id": "281",
                        "name": "發電、輸電及配電機械製造業",
                        "def": "從事發電、輸電、配電機械製造之行業，如發電、配電設備及其專用變壓器、電動機、發電機、大電流控制開關及配電盤設備、電力繼電器及工業用電力控制設備等製造。"
                    },
                    {
                        "id": "282",
                        "name": "電池製造業",
                        "def": "從事電池製造之行業，如原電池（內含二氧化錳、氧化汞、氧化銀等）、電力用蓄電池及其零件（隔離板、容器、蓋子）、鉛酸電池、鎳鎘電池、鋰電池、乾（濕）電池等製造。"
                    },
                    {
                        "id": "283",
                        "name": "電線及配線器材製造業",
                        "def": "從事電線、電纜及配線器材製造之行業。"
                    },
                    {
                        "id": "284",
                        "name": "照明設備及配備製造業",
                        "def": "從事電燈泡、燈管及照明器具等製造之行業。"
                    },
                    {
                        "id": "285",
                        "name": "家用電器製造業",
                        "def": "從事家用空調器具、電冰箱及其他電器製造之行業。"
                    },
                    {
                        "id": "289",
                        "name": "其他電力設備及配備製造業",
                        "def": "從事281至285s以外電力設備及配備製造之行業，如固態電池充電器、電力自動門裝置、電鈴、超音波洗滌機、不斷電設備（UPS）、電子計分板、電力交通號誌設備、燃料電池、具連接頭之延長線等製造；電力用之電容器、電阻器、換流器、整流裝置等製造亦歸入本類。"
                    }
                ]
            },
            {
                "id": "29",
                "name": "機械設備製造業",
                "def": "從事產業專用及通用機械設備製造之行業；機械設備專用之主要零組件製造原則上亦歸入本類。",
                "list": [
                    {
                        "id": "291",
                        "name": "金屬加工用機械設備製造業",
                        "def": "從事冶金機械、金屬切削工具機及其他金屬加工用機械設備製造之行業；用於硬橡膠、硬塑膠、冷玻璃等材料之加工機械設備製造亦歸入本類。"
                    },
                    {
                        "id": "292",
                        "name": "其他專用機械設備製造業",
                        "def": "從事農業、林業、礦業、土石採取業、營建工程業、製造業等專用機械設備製造之行業。"
                    },
                    {
                        "id": "293",
                        "name": "通用機械設備製造業",
                        "def": "從事原動機、流體傳動設備、泵、壓縮機、活栓、活閥、機械傳動設備、輸送機械設備、事務機械設備、污染防治設備、動力手工具等廣泛用於產業之通用機械設備製造之行業；機械設備通用零件製造亦歸入本類。"
                    }
                ]
            },
            {
                "id": "30",
                "name": "汽車及其零件製造業",
                "def": "從事汽車及其專用零配件製造之行業。",
                "list": [
                    {
                        "id": "301",
                        "name": "汽車製造業",
                        "def": "從事汽車製造之行業，如客車、客貨兩用車、卡車、貨車、曳引車、越野車及高爾夫球車等製造；汽車引擎及裝有引擎之車身底盤製造等亦歸入本類。"
                    },
                    {
                        "id": "302",
                        "name": "車體製造業",
                        "def": "從事汽車車體、拖車、半拖車、貨櫃等製造之行業。"
                    },
                    {
                        "id": "303",
                        "name": "汽車零件製造業",
                        "def": "從事汽車專用零配件製造之行業，如煞車器、齒輪箱、輪圈、懸吊避震器、散熱器、消音器、排氣管、離合器、方向盤、安全帶、安全氣囊、車門、保險桿、車用電力設備等製造；汽車座椅製造亦歸入本類。"
                    }
                ]
            },
            {
                "id": "31",
                "name": "其他運輸工具及其零件製造業",
                "def": "從事汽車以外其他運輸工具及其專用零配件製造之行業。",
                "list": [
                    {
                        "id": "311",
                        "name": "船舶及浮動設施製造業",
                        "def": "從事船舶與海上浮動設施建造製造之行業，如客船、貨輪、漁船、帆船、水上摩托車、浮塢、浮碼頭、浮筒、橡皮艇等製造。"
                    },
                    {
                        "id": "312",
                        "name": "機車及其零件製造業",
                        "def": "從事機車及其專用零配件製造之行業。"
                    },
                    {
                        "id": "313",
                        "name": "自行車及其零件製造業",
                        "def": "從事自行車及其專用零配件製造之行業。"
                    },
                    {
                        "id": "319",
                        "name": "未分類其他運輸工具及其零件製造業",
                        "def": "從事311至313s以外其他運輸工具及其專用零配件製造之行業，如軌道車輛、航空器、軍用戰鬥車輛、手推車、行李推車、購物車、畜力車、電動代步車、輪椅、嬰兒車、無人飛行載具（無人機）等製造。"
                    }
                ]
            },
            {
                "id": "32",
                "name": "家具製造業",
                "def": "從事各種材質（陶瓷、水泥及石材除外）之家具及裝設品製造之行業。本類家具可用於家庭、辦公室、學校、實驗室、旅館、餐廳、電影院等場所；家具之表面塗裝亦歸入本類。",
                "list": [
                    {
                        "id": "321",
                        "name": "非金屬家具製造業",
                        "def": "從事非金屬（陶瓷、水泥及石材除外）家具及裝設品製造之行業；非金屬家具之表面塗裝亦歸入本類。"
                    },
                    {
                        "id": "322",
                        "name": "金屬家具製造業",
                        "def": "從事以金屬為主之家具及裝設品製造之行業，如金屬櫃、金屬桌及彈簧床墊等製造；金屬家具之表面塗裝亦歸入本類。"
                    }
                ]
            },
            {
                "id": "33",
                "name": "其他製造業",
                "def": "從事08至32m以外製品製造之行業；本m製品差異較大，無法依其主要材料或製程歸屬，如育樂用品、醫療器材及用品、珠寶等製造。",
                "list": [
                    {
                        "id": "331",
                        "name": "育樂用品製造業",
                        "def": "從事育樂用品製造之行業，如體育用品、玩具、樂器、文具等製造。"
                    },
                    {
                        "id": "332",
                        "name": "醫療器材及用品製造業",
                        "def": "從事眼鏡及內科、外科、牙科、矯治、獸醫等用途之醫療器材與用品製造之行業。"
                    },
                    {
                        "id": "339",
                        "name": "未分類其他製造業",
                        "def": "從事331及332s以外之其他製品製造之行業，如珠寶及金工製品、拉鍊、鈕扣等製造。"
                    }
                ]
            },
            {
                "id": "34",
                "name": "產業用機械設備維修及安裝業",
                "def": "從事產業用機械設備維修（以恢復機械設備正常運作為目的，含例行性保養維護）及安裝之行業，如機械、電子及光學設備、度量衡儀器、電力設備、船舶、航空器、軌道車輛、投幣式電動遊戲機、商用空調及冷凍冷藏設備等產業用機械設備之維修，以及廠房機械與保齡球道設備等安裝服務；大規模機械拆除服務亦歸入本類。",
                "list": [
                    {
                        "id": "340",
                        "name": "產業用機械設備維修及安裝業",
                        "def": "從事產業用機械設備維修（以恢復機械設備正常運作為目的，含例行性保養維護）及安裝之行業，如機械、電子及光學設備、度量衡儀器、電力設備、船舶、航空器、軌道車輛、投幣式電動遊戲機、商用空調及冷凍冷藏設備等產業用機械設備之維修，以及廠房機械與保齡球道設備等安裝服務；大規模機械拆除服務亦歸入本類。"
                    }
                ]
            }
        ]
    },
    {
        "id": "D",
        "name": "電力及燃氣供應業",
        "def": "從事電力、氣體燃料及蒸汽供應之行業。",
        "list": [
            {
                "id": "35",
                "name": "電力及燃氣供應業",
                "def": "從事電力、氣體燃料及蒸汽供應之行業。",
                "list": [
                    {
                        "id": "351",
                        "name": "電力供應業",
                        "def": "從事發電、輸配電及售電之行業。"
                    },
                    {
                        "id": "352",
                        "name": "氣體燃料供應業",
                        "def": "從事以管線輸送天然氣供應用戶之行業；液化石油氣之分裝亦歸入本類。"
                    },
                    {
                        "id": "353",
                        "name": "蒸汽供應業",
                        "def": "從事蒸汽、冷暖氣等製造及配送之行業；熱水（含溫泉）及冰水供應亦歸入本類。"
                    }
                ]
            }
        ]
    },
    {
        "id": "E",
        "name": "用水供應及污染整治業",
        "def": "從事用水供應、廢水及污水處理、廢棄物清除及處理、污染整治之行業；資源物回收分類及處理成再生原料亦歸入本類。",
        "list": [
            {
                "id": "36",
                "name": "用水供應業",
                "def": "從事用水之集取、淨化及配送之行業，如海水、河水、湖水、雨水及地下水等之集取、淡化、淨化，並以管線或貨車配送用水。",
                "list": [
                    {
                        "id": "360",
                        "name": "用水供應業",
                        "def": "從事用水之集取、淨化及配送之行業，如海水、河水、湖水、雨水及地下水等之集取、淡化、淨化，並以管線或貨車配送用水。"
                    }
                ]
            },
            {
                "id": "37",
                "name": "廢水及污水處理業",
                "def": "從事廢水及污水處理之行業，如下水道系統及污水處理設施場所之營運管理、家庭污水之收集及清運，水溝、廢（污）水坑及化糞池之清理，流動廁所之清理，以物理、化學及生物等方法處理廢（污）水，下水道及排水管之清理等。",
                "list": [
                    {
                        "id": "370",
                        "name": "廢水及污水處理業",
                        "def": "從事廢水及污水處理之行業，如下水道系統及污水處理設施場所之營運管理、家庭污水之收集及清運，水溝、廢（污）水坑及化糞池之清理，流動廁所之清理，以物理、化學及生物等方法處理廢（污）水，下水道及排水管之清理等。"
                    }
                ]
            },
            {
                "id": "38",
                "name": "廢棄物清除、處理及資源物回收處理業",
                "def": "從事廢棄物清除、廢棄物處理及資源物回收處理之行業。",
                "list": [
                    {
                        "id": "381",
                        "name": "廢棄物清除業",
                        "def": "從事非有害及有害廢棄物清除之行業。"
                    },
                    {
                        "id": "382",
                        "name": "廢棄物處理業",
                        "def": "從事非有害及有害廢棄物處理之行業。"
                    },
                    {
                        "id": "383",
                        "name": "資源物回收處理業",
                        "def": "從事資源物回收分類、拆解、粉碎、減積等業務或處理成再生原料之行業，如廢車船拆解粉碎、車殼粉碎、廢輪胎粉碎、廢家電（電腦）拆解粉碎、廢照明光源粉碎及分離、廢玻璃處理成碎玻璃砂等；使用過之烹飪油及油脂處理為再生原料亦歸入本類。"
                    }
                ]
            },
            {
                "id": "39",
                "name": "污染整治業",
                "def": "從事污染整治之行業，如受污染土地及地下水消毒、受污染水面消毒及清理、油溢清理、減少有毒物質（如石棉、鉛顏料等）之危害等。",
                "list": [
                    {
                        "id": "390",
                        "name": "污染整治業",
                        "def": "從事污染整治之行業，如受污染土地及地下水消毒、受污染水面消毒及清理、油溢清理、減少有毒物質（如石棉、鉛顏料等）之危害等。"
                    }
                ]
            }
        ]
    },
    {
        "id": "F",
        "name": "營建工程業",
        "def": "從事建築及土木工程之興建、改建、修繕等及其專門營造之行業；附操作員之營造設備租賃亦歸入本類。",
        "list": [
            {
                "id": "41",
                "name": "建築工程業",
                "def": "從事住宅及非住宅建物興建、改建、修繕等建築工程之行業；焚化廠、廢棄物處理廠、工業廠房、預鑄房屋、室內運動場館、機械式停車塔及靈骨塔之營建亦歸入本類。",
                "list": [
                    {
                        "id": "410",
                        "name": "建築工程業",
                        "def": "從事住宅及非住宅建物興建、改建、修繕等建築工程之行業；焚化廠、廢棄物處理廠、工業廠房、預鑄房屋、室內運動場館、機械式停車塔及靈骨塔之營建亦歸入本類。"
                    }
                ]
            },
            {
                "id": "42",
                "name": "土木工程業",
                "def": "從事道路、橋樑、公用事業設施、港埠等土木工程興建、改建、修繕等之行業；預鑄結構體營建亦歸入本類。",
                "list": [
                    {
                        "id": "421",
                        "name": "道路工程業",
                        "def": "從事鐵路、道路及其附屬設施、隧道、地下道、橋樑等工程興建、改建、修繕等之行業；道路標示工程亦歸入本類。"
                    },
                    {
                        "id": "422",
                        "name": "公用事業設施工程業",
                        "def": "從事水、電、燃氣及電信等公用事業之配送線路、管道及相關建物與結構體之工程興建、改建、修繕等之行業；水井鑽鑿亦歸入本類。"
                    },
                    {
                        "id": "429",
                        "name": "其他土木工程業",
                        "def": "從事421及422s以外土木工程興建、改建、修繕等之行業，如運河、堤壩、港埠、煉油廠、港灣疏濬等工程；土地重劃工程亦歸入本類。"
                    }
                ]
            },
            {
                "id": "43",
                "name": "專門營造業",
                "def": "從事建築及土木特定部分工程之行業，如整地、基礎、結構、庭園景觀、建築設備安裝、建物完工裝修等工程；附操作員之營造設備租賃亦歸入本類。",
                "list": [
                    {
                        "id": "431",
                        "name": "整地、基礎及結構工程業",
                        "def": "從事營造工程之事前準備，如建物及結構體拆除、岩石鑿除、土方挖填等整地工程，以及基礎與結構有關之預拌混凝土、鋼構、帷幕牆、擋土支撐、外部石作等工程之行業；石油及天然氣以外之礦場非自辦準備作業亦歸入本類。"
                    },
                    {
                        "id": "432",
                        "name": "庭園景觀工程業",
                        "def": "從事公園、庭園景觀工程興建、改建、修繕等之行業，如設置人造草皮或公園與庭園步道、圍籬、噴泉、假山及池沼開鑿等景觀工程。"
                    },
                    {
                        "id": "433",
                        "name": "機電、管道及其他建築設備安裝業",
                        "def": "從事建物及土木工程結構體基本設備安裝、維修等之行業，如機電設備、電信線路、電力線路、冷凍系統、通風系統、空調設備、管道等工程。"
                    },
                    {
                        "id": "434",
                        "name": "建物完工裝修工程業",
                        "def": "從事建物及土木工程結構體之室內及室外完工裝修工程之行業，如防水、隔熱、隔音等隔離工程、門窗安裝、玻璃鑲嵌、油漆粉刷、壁紙黏貼、瓷磚黏貼、地板安裝、系統櫥櫃安裝等工程。"
                    },
                    {
                        "id": "439",
                        "name": "其他專門營造業",
                        "def": "從事431至434s以外專門營造之行業，如鷹架、模板、起重、房屋遷移、工作平台架設等工程；附操作員之營造設備租賃亦歸入本類。"
                    }
                ]
            }
        ]
    },
    {
        "id": "G",
        "name": "批發及零售業",
        "def": "從事有形商品之批發、零售、經紀及代理之行業；銷售商品所附帶不改變商品本質之簡單處理，如包裝、清洗、分級、摻混、運送、安裝、修理等亦歸入本類。",
        "list": [
            {
                "id": "45",
                "name": "批發業",
                "def": "從事有形商品批發、仲介批發買賣或代理批發拍賣之行業，其銷售對象為機構或產業（如中盤批發商、零售商、工廠、公司行號、進出口商等）",
                "list": [
                    {
                        "id": "451",
                        "name": "商品批發經紀業",
                        "def": "以按次計費或依合約計酬方式，從事有形商品之仲介批發買賣或代理批發拍賣之行業，如商品批發掮客及代理毛豬、魚貨、花卉、蔬果等批發拍賣活動。"
                    },
                    {
                        "id": "452",
                        "name": "綜合商品批發業",
                        "def": "以非特定專賣形式從事多種系列商品批發之行業。"
                    },
                    {
                        "id": "453",
                        "name": "農產原料及活動物批發業",
                        "def": "從事未經加工處理之農業初級產品及活動物批發之行業，如穀類、種子、含油子實、花卉、植物、菸葉、生皮、生毛皮、農產原料之廢料、殘渣與副產品等農業初級產品，以及禽、畜、寵物、魚苗、貝介苗及觀賞水生動物等活動物批發。"
                    },
                    {
                        "id": "454",
                        "name": "食品、飲料及菸草製品批發業",
                        "def": "從事食品、飲料及菸草製品批發之行業，如蔬果、肉品、水產品等不須加工處理即可販售給零售商轉賣之農產品及冷凍調理食品、食用油脂、菸酒、非酒精飲料、茶葉等加工食品批發；動物飼品批發亦歸入本類。"
                    },
                    {
                        "id": "455",
                        "name": "布疋及服飾品批發業",
                        "def": "從事布疋及服飾品批發之行業，如成衣、鞋類、服飾配件等批發；行李箱（袋）及縫紉用品批發亦歸入本類。"
                    },
                    {
                        "id": "456",
                        "name": "家用器具及用品批發業",
                        "def": "從事家用器具及用品批發之行業，如家用電器、家具、家飾品、家用攝影器材與光學產品、鐘錶、眼鏡、珠寶、清潔用品等批發。"
                    },
                    {
                        "id": "457",
                        "name": "藥品、醫療用品及化粧品批發業",
                        "def": "從事藥品、醫療用品及化粧品批發之行業。"
                    },
                    {
                        "id": "458",
                        "name": "文教育樂用品批發業",
                        "def": "從事文教、育樂用品批發之行業，如書籍、文具、運動用品、玩具及娛樂用品等批發。"
                    }
                ]
            },
            {
                "id": "46",
                "name": "批發業",
                "def": "從事有形商品批發、仲介批發買賣或代理批發拍賣之行業，其銷售對象為機構或產業（如中盤批發商、零售商、工廠、公司行號、進出口商等）",
                "list": [
                    {
                        "id": "461",
                        "name": "建材批發業",
                        "def": "從事建材批發之行業。"
                    },
                    {
                        "id": "462",
                        "name": "化學原材料及其製品批發業",
                        "def": "從事藥品、化粧品、清潔用品、漆料、塗料以外之化學原材料及其製品批發之行業，如化學原材料、肥料、塑膠及合成橡膠原料、人造纖維、農藥、顏料、染料、著色劑、化學溶劑、界面活性劑、工業添加劑、油墨、非食用動植物油脂等批發。"
                    },
                    {
                        "id": "463",
                        "name": "燃料及相關產品批發業",
                        "def": "從事燃料及相關產品批發之行業。"
                    },
                    {
                        "id": "464",
                        "name": "機械器具批發業",
                        "def": "從事電腦、電子、通訊與電力設備、產業與辦公用機械及其零配件、用品批發之行業。"
                    },
                    {
                        "id": "465",
                        "name": "汽機車及其零配件、用品批發業",
                        "def": "從事汽機車及其零件、配備、用品批發之行業。"
                    },
                    {
                        "id": "469",
                        "name": "其他專賣批發業",
                        "def": "從事453至465s以外單一系列商品專賣批發之行業。"
                    }
                ]
            },
            {
                "id": "47",
                "name": "零售業",
                "def": "從事透過商店、攤販及其他非店面如網際網路等向家庭或民眾銷售全新及中古有形商品之行業。",
                "list": [
                    {
                        "id": "471",
                        "name": "綜合商品零售業",
                        "def": "從事以非特定專賣形式銷售多種系列商品之零售店，如連鎖便利商店、百貨公司及超級市場等。"
                    },
                    {
                        "id": "472",
                        "name": "食品、飲料及菸草製品零售業",
                        "def": "從事食品、飲料、菸草製品專賣之零售店，如蔬果、肉品、水產品、米糧、蛋類、飲料、酒類、麵包、糖果、茶葉等零售店。"
                    },
                    {
                        "id": "473",
                        "name": "布疋及服飾品零售業",
                        "def": "從事布疋及服飾品專賣之零售店，如成衣、鞋類、服飾配件等零售店；行李箱（袋）及縫紉用品零售店亦歸入本類。"
                    },
                    {
                        "id": "474",
                        "name": "家用器具及用品零售業",
                        "def": "從事家用器具及用品專賣之零售店，如家用電器、家具、家飾品、鐘錶、眼鏡、珠寶、家用攝影器材與光學產品、清潔用品等零售店。"
                    },
                    {
                        "id": "475",
                        "name": "藥品、醫療用品及化粧品零售業",
                        "def": "從事藥品、醫療用品及化粧品專賣之零售店。"
                    },
                    {
                        "id": "476",
                        "name": "文教育樂用品零售業",
                        "def": "從事文教、育樂用品專賣之零售店，如書籍、文具、運動用品、玩具及娛樂用品、樂器等零售店。"
                    }
                ]
            },
            {
                "id": "48",
                "name": "零售業",
                "def": "從事透過商店、攤販及其他非店面如網際網路等向家庭或民眾銷售全新及中古有形商品之行業。",
                "list": [
                    {
                        "id": "481",
                        "name": "建材零售業",
                        "def": "從事漆料、塗料及居家修繕等建材、工具、用品專賣之零售店。"
                    },
                    {
                        "id": "482",
                        "name": "燃料及相關產品零售業",
                        "def": "從事汽油、柴油、液化石油氣、木炭、桶裝瓦斯、機油等燃料及相關產品專賣之零售店。"
                    },
                    {
                        "id": "483",
                        "name": "資訊及通訊設備零售業",
                        "def": "從事資訊及通訊設備專賣之零售店，如電腦及其週邊設備、通訊設備、視聽設備等零售店。"
                    },
                    {
                        "id": "484",
                        "name": "汽機車及其零配件、用品零售業",
                        "def": "從事全新與中古汽機車及其零件、配備、用品專賣之零售店。"
                    },
                    {
                        "id": "485",
                        "name": "其他專賣零售業",
                        "def": "從事472至484s以外單一系列商品專賣之零售店。"
                    },
                    {
                        "id": "486",
                        "name": "零售攤販",
                        "def": "從事商品零售之固定或流動攤販。"
                    },
                    {
                        "id": "487",
                        "name": "其他非店面零售業",
                        "def": "從事486s以外非店面零售之行業，如透過網際網路、郵購、逐戶拜訪及自動販賣機等方式零售商品。"
                    }
                ]
            }
        ]
    },
    {
        "id": "H",
        "name": "運輸及倉儲業",
        "def": "從事以運輸工具提供客貨運輸及其運輸輔助、倉儲、郵政及遞送服務之行業。",
        "list": [
            {
                "id": "49",
                "name": "陸上運輸業",
                "def": "從事鐵路、捷運、汽車等客貨運輸之行業；管道運輸亦歸入本類。",
                "list": [
                    {
                        "id": "491",
                        "name": "鐵路運輸業",
                        "def": "從事鐵路客貨運輸之行業。"
                    },
                    {
                        "id": "492",
                        "name": "捷運運輸業",
                        "def": "從事捷運運輸之行業。"
                    },
                    {
                        "id": "493",
                        "name": "汽車客運業",
                        "def": "從事以汽車運輸旅客之行業。"
                    },
                    {
                        "id": "494",
                        "name": "汽車貨運業",
                        "def": "從事以汽車或聯結車運送貨物或貨櫃之行業；搬家運送服務亦歸入本類。"
                    },
                    {
                        "id": "499",
                        "name": "其他陸上運輸業",
                        "def": "從事491至494s以外陸上運輸之行業，如管道、人力車、畜力車、空中纜車等運輸服務。"
                    }
                ]
            },
            {
                "id": "50",
                "name": "水上運輸業",
                "def": "從事海洋、內河及湖泊等船舶客貨運輸之行業；觀光客船之經營亦歸入本類。",
                "list": [
                    {
                        "id": "501",
                        "name": "海洋水運業",
                        "def": "從事海洋船舶客貨運輸之行業。"
                    },
                    {
                        "id": "502",
                        "name": "內河及湖泊水運業",
                        "def": "從事內陸河川、湖泊之船舶客貨運輸之行業。"
                    }
                ]
            },
            {
                "id": "51",
                "name": "航空運輸業",
                "def": "從事航空運輸服務之行業，如民用航空客貨運輸、附駕駛商務專機租賃等運輸服務；以熱氣球載客飛行服務亦歸入本類。",
                "list": [
                    {
                        "id": "510",
                        "name": "航空運輸業",
                        "def": "從事航空運輸服務之行業，如民用航空客貨運輸、附駕駛商務專機租賃等運輸服務；以熱氣球載客飛行服務亦歸入本類。"
                    }
                ]
            },
            {
                "id": "52",
                "name": "運輸輔助業",
                "def": "從事報關、船務代理、貨運承攬、運輸輔助之行業；停車場之經營亦歸入本類。",
                "list": [
                    {
                        "id": "521",
                        "name": "報關業",
                        "def": "受貨主委託，從事貨物進出口報關相關服務之行業。"
                    },
                    {
                        "id": "522",
                        "name": "船務代理業",
                        "def": "從事以委託人名義，在約定授權範圍內代為處理船舶客貨運送及其相關業務之行業，如代辦商港、航政、船舶檢修手續等服務。"
                    },
                    {
                        "id": "523",
                        "name": "貨運承攬業",
                        "def": "從事陸上、海洋及航空貨運承攬之行業。"
                    },
                    {
                        "id": "524",
                        "name": "陸上運輸輔助業",
                        "def": "從事停車場、汽車拖吊、陸上貨物裝卸及車站、道路、橋樑、隧道管理等陸上運輸輔助之行業。"
                    },
                    {
                        "id": "525",
                        "name": "水上運輸輔助業",
                        "def": "從事港埠管理、港區內工作船及拖駁船經營、引水、船上貨物裝卸、海難救助等水上運輸輔助之行業。"
                    },
                    {
                        "id": "526",
                        "name": "航空運輸輔助業",
                        "def": "從事航空站站務管理、機坪內航空器拖曳、導引及航空行李、貨物、餐點裝卸及飛航管制服務等航空運輸輔助之行業。"
                    },
                    {
                        "id": "529",
                        "name": "其他運輸輔助業",
                        "def": "從事521至526s以外運輸輔助之行業，如貨櫃及貨物集散站經營、與運輸有關之公證服務等。"
                    }
                ]
            },
            {
                "id": "53",
                "name": "倉儲業",
                "def": "從事提供倉儲設備及低溫裝置，經營普通倉儲及冷凍冷藏倉儲之行業；以倉儲服務為主並結合簡單處理如揀取、分類、分裝、包裝等亦歸入本類。",
                "list": [
                    {
                        "id": "530",
                        "name": "倉儲業",
                        "def": "從事提供倉儲設備及低溫裝置，經營普通倉儲及冷凍冷藏倉儲之行業；以倉儲服務為主並結合簡單處理如揀取、分類、分裝、包裝等亦歸入本類。"
                    }
                ]
            },
            {
                "id": "54",
                "name": "郵政及遞送服務業",
                "def": "從事文件或物品等收取及遞送服務之行業。",
                "list": [
                    {
                        "id": "541",
                        "name": "郵政業",
                        "def": "從事文件或物品等收取及遞送服務之郵政公司。"
                    },
                    {
                        "id": "542",
                        "name": "遞送服務業",
                        "def": "郵政公司以外從事文件或物品等收取及遞送服務之行業；到宅遞送及餐飲遞送服務亦歸入本類。"
                    }
                ]
            }
        ]
    },
    {
        "id": "I",
        "name": "住宿及餐飲業",
        "def": "從事短期或臨時性住宿服務及餐飲服務之行業。",
        "list": [
            {
                "id": "55",
                "name": "住宿業",
                "def": "從事短期或臨時性住宿服務之行業，如旅館、旅社、民宿及露營區等。",
                "list": [
                    {
                        "id": "551",
                        "name": "短期住宿業",
                        "def": "從事以日或週為基礎，提供客房服務或渡假住宿服務之行業，如旅館、旅社、民宿等；本類可附帶提供餐飲、洗衣、會議室、休閒設施、停車等服務。"
                    },
                    {
                        "id": "559",
                        "name": "其他住宿業",
                        "def": "從事551s以外住宿服務之行業，如露營區、休旅車營地及僅對特定對象提供臨時性住宿服務之招待所。"
                    }
                ]
            },
            {
                "id": "56",
                "name": "餐飲業",
                "def": "從事調理餐食或飲料供立即食用或飲用，不論以點餐或自助方式，內用、外帶或外送方式，亦不論以餐車、外燴及團膳等形式，均歸入本類。",
                "list": [
                    {
                        "id": "561",
                        "name": "餐食業",
                        "def": "從事調理餐食供立即食用之商店及攤販。"
                    },
                    {
                        "id": "562",
                        "name": "外燴及團膳承包業",
                        "def": "從事承包客户於指定地點辦理運動會、會議及婚宴等類似活動之外燴餐飲服務；或專為學校、醫院、工廠、公司企業等團體提供餐飲服務之行業；承包飛機或火車等運輸工具上之餐飲服務亦歸入本類。"
                    },
                    {
                        "id": "563",
                        "name": "飲料業",
                        "def": "從事調理飲料供立即飲用之商店及攤販。"
                    }
                ]
            }
        ]
    },
    {
        "id": "J",
        "name": "出版影音及資通訊業",
        "def": "從事出版、影片及電視節目製作、後製、發行與影片放映，聲音錄製及音樂發行，廣播及電視節目編排與傳播，電信、電腦程式設計、諮詢及相關服務、資訊服務等之行業。",
        "list": [
            {
                "id": "58",
                "name": "出版業",
                "def": "從事新聞、雜誌、期刊、書籍及其他出版品、軟體等具有著作權商品出版之行業。",
                "list": [
                    {
                        "id": "581",
                        "name": "新聞、雜誌、期刊、書籍及其他出版業",
                        "def": "從事新聞、雜誌、期刊、書籍及其他出版品出版之行業。"
                    },
                    {
                        "id": "582",
                        "name": "軟體出版業",
                        "def": "從事軟體出版之行業，如作業系統軟體、應用軟體、套裝軟體、遊戲軟體等出版；線上遊戲網站經營亦歸入本類。"
                    }
                ]
            },
            {
                "id": "59",
                "name": "影片及電視節目業；聲音錄製及音樂發行業",
                "def": "從事影片及電視節目製作、後製、發行與影片放映，以及聲音錄製與音樂發行之行業。",
                "list": [
                    {
                        "id": "591",
                        "name": "影片及電視節目業",
                        "def": "從事影片及電視節目之製作、後製、發行及影片放映之行業；影片版權之買賣亦歸入本類。"
                    },
                    {
                        "id": "592",
                        "name": "聲音錄製及音樂發行業",
                        "def": "從事聲音錄製及音樂發行之行業，如原創有聲母帶（片）之製作、擁有版權並向批發商、零售商或直接對大眾發行有聲產品；同時從事有聲產品製作及發行或僅從事其中一項活動，以及在錄音室或其他地方從事聲音錄製服務，包括廣播節目預錄帶（非現場播出）之製作及廣播節目發行，亦歸入本類。"
                    }
                ]
            },
            {
                "id": "60",
                "name": "廣播、電視節目編排及傳播業",
                "def": "從事廣播、電視節目編排及傳播之行業。",
                "list": [
                    {
                        "id": "601",
                        "name": "廣播業",
                        "def": "從事以無線電、有線電、衛星或網路傳播聲音，供公眾收聽之行業。"
                    },
                    {
                        "id": "602",
                        "name": "電視節目編排及傳播業",
                        "def": "從事電視頻道節目編排並透過公共電波或第三者（電信業者）傳播影像及聲音，供公眾收視之行業。電視頻道節目可採外購影片或自製影片（如地方新聞、現場報導）之方式取得；從事取得完整電視頻道節目並授權他人播送亦歸入本類。"
                    }
                ]
            },
            {
                "id": "61",
                "name": "電信業",
                "def": "從事有線電信、無線電信及其他電信相關服務之行業；提供網際網路接取服務（IASP），以及透過提供有線電信傳輸服務，將電視頻道節目有系統地整合並傳送至收視戶亦歸入本類。",
                "list": [
                    {
                        "id": "610",
                        "name": "電信業",
                        "def": "從事有線電信、無線電信及其他電信相關服務之行業；提供網際網路接取服務（IASP），以及透過提供有線電信傳輸服務，將電視頻道節目有系統地整合並傳送至收視戶亦歸入本類。"
                    }
                ]
            },
            {
                "id": "62",
                "name": "電腦程式設計、諮詢及相關服務業",
                "def": "從事電腦程式設計、系統整合、諮詢及設備管理等相關服務之行業。",
                "list": [
                    {
                        "id": "620",
                        "name": "電腦程式設計、諮詢及相關服務業",
                        "def": "從事電腦程式設計、系統整合、諮詢及設備管理等相關服務之行業。"
                    }
                ]
            },
            {
                "id": "63",
                "name": "資訊服務業",
                "def": "從事入口網站經營、資料處理、主機及網站代管等資訊服務之行業。",
                "list": [
                    {
                        "id": "631",
                        "name": "入口網站經營、資料處理、主機及網站代管服務業",
                        "def": "從事入口網站經營、資料處理、主機及網站代管，以及相關服務之行業。"
                    },
                    {
                        "id": "639",
                        "name": "其他資訊服務業",
                        "def": "從事631s以外資訊服務之行業，如新聞供應、剪報及提供電話預錄資訊等服務。"
                    }
                ]
            }
        ]
    },
    {
        "id": "K",
        "name": "金融及保險業",
        "def": "從事金融服務、保險、證券期貨及金融輔助等活動之行業。",
        "list": [
            {
                "id": "64",
                "name": "金融服務業",
                "def": "從事資金取得及再分配之行業；以自有資金從事放款、融資、創業投資或投資證券與票券亦歸入本類。",
                "list": [
                    {
                        "id": "641",
                        "name": "貨幣中介業",
                        "def": "從事以取得非金融機構存款為常川業務之行業；中央銀行亦歸入本類。"
                    },
                    {
                        "id": "642",
                        "name": "控股業",
                        "def": "從事金融控股或其他控股活動之行業。"
                    },
                    {
                        "id": "643",
                        "name": "信託、基金及類似金融實體",
                        "def": "代表股東、職工福利或其他信託財產之受益人，從事聚集股票或資產以管理運用所設置之資產組合或法律實體，如共同基金、信託資金集合管理運用帳戶、金融資產證券化商品、員工持股信託、員工福利儲蓄信託等。這些實體賺取利息、股利及其他財產收入，而非服務收入。"
                    },
                    {
                        "id": "649",
                        "name": "其他金融服務業",
                        "def": "從事641至643s以外金融服務之行業。"
                    }
                ]
            },
            {
                "id": "65",
                "name": "保險業",
                "def": "從事人身保險、財產保險、再保險、退休基金及其輔助業務之行業。",
                "list": [
                    {
                        "id": "651",
                        "name": "人身保險業",
                        "def": "從事人身保險之行業。"
                    },
                    {
                        "id": "652",
                        "name": "財產保險業",
                        "def": "從事財產保險之行業。"
                    },
                    {
                        "id": "653",
                        "name": "再保險業",
                        "def": "從事再保險業務之行業。"
                    },
                    {
                        "id": "654",
                        "name": "退休基金",
                        "def": "專門為員工或會員提供退休所得津貼之法律實體，包括依規定提撥之退休基金，及經由員工或會員攤提之個人退休基金等。"
                    },
                    {
                        "id": "655",
                        "name": "保險輔助業",
                        "def": "從事保險代理、經紀及其他保險輔助業務之行業。"
                    }
                ]
            },
            {
                "id": "66",
                "name": "證券期貨及金融輔助業",
                "def": "從事證券、期貨、基金管理及其他金融輔助業務之行業。",
                "list": [
                    {
                        "id": "661",
                        "name": "證券業",
                        "def": "從事有價證券之承銷、自行買賣、買賣行紀、居間或代理以及輔助業務之行業。"
                    },
                    {
                        "id": "662",
                        "name": "期貨業",
                        "def": "從事衍生性金融商品之期貨契約、選擇權契約、期貨選擇權契約及槓桿保證金契約買賣業務，以及期貨輔助業務之行業。"
                    },
                    {
                        "id": "664",
                        "name": "基金管理業",
                        "def": "承作投資組合及基金管理之行業，如證券投資信託基金、期貨信託基金等管理。"
                    },
                    {
                        "id": "669",
                        "name": "其他金融輔助業",
                        "def": "從事664s以外金融輔助業務之行業。"
                    }
                ]
            }
        ]
    },
    {
        "id": "L",
        "name": "不動產業",
        "def": "從事不動產開發、經營及相關服務之行業。",
        "list": [
            {
                "id": "67",
                "name": "不動產開發業",
                "def": "以銷售為目的，從事土地、建物及其他建設等不動產投資興建之行業。",
                "list": [
                    {
                        "id": "670",
                        "name": "不動產開發業",
                        "def": "以銷售為目的，從事土地、建物及其他建設等不動產投資興建之行業。"
                    }
                ]
            },
            {
                "id": "68",
                "name": "不動產經營及相關服務業",
                "def": "從事不動產經營及相關服務之行業。",
                "list": [
                    {
                        "id": "681",
                        "name": "不動產經營業",
                        "def": "從事不動產租售及經紀之行業。"
                    },
                    {
                        "id": "689",
                        "name": "其他不動產業",
                        "def": "從事681s以外不動產服務之行業。"
                    }
                ]
            }
        ]
    },
    {
        "id": "M",
        "name": "專業、科學及技術服務業",
        "def": "從事專業、科學及技術服務之行業，如法律及會計、企業管理及管理顧問、建築及工程服務、技術檢測及分析、研究發展、廣告及市場研究、專門設計及獸醫服務等。",
        "list": [
            {
                "id": "69",
                "name": "法律及會計服務業",
                "def": "從事提供法律及會計服務之行業。",
                "list": [
                    {
                        "id": "691",
                        "name": "法律服務業",
                        "def": "從事提供法律服務之行業。"
                    },
                    {
                        "id": "692",
                        "name": "會計服務業",
                        "def": "從事帳務與財務報表之整理及查核、財務報表簽證、稅務代理及諮詢等服務之行業。"
                    }
                ]
            },
            {
                "id": "70",
                "name": "企業總管理機構及管理顧問業",
                "def": "從事企業總管理機構活動及管理顧問之行業。",
                "list": [
                    {
                        "id": "701",
                        "name": "企業總管理機構",
                        "def": "從事對非控股企業之所屬單位進行管理及監督，或制定決策及規劃策略之行業。"
                    },
                    {
                        "id": "702",
                        "name": "管理顧問業",
                        "def": "從事提供企業或其他組織有關管理問題諮詢及輔導之行業，如財務決策、行銷策略、人力資源規劃、生產管理等顧問；提供公共關係服務亦歸入本類。"
                    }
                ]
            },
            {
                "id": "71",
                "name": "建築、工程服務及技術檢測、分析服務業",
                "def": "從事建築與工程服務，或物質、材料及產品之物理性、化學性及其他分析檢測之行業。",
                "list": [
                    {
                        "id": "711",
                        "name": "建築、工程服務及相關技術顧問業",
                        "def": "從事建築、工程服務及其相關技術顧問之行業。"
                    },
                    {
                        "id": "712",
                        "name": "技術檢測及分析服務業",
                        "def": "從事物質、材料及產品之物理性、化學性及其他分析檢測之行業。"
                    }
                ]
            },
            {
                "id": "72",
                "name": "研究發展服務業",
                "def": "從事自然、工程、社會及人文科學為基礎之研究、試驗、分析及規劃，而不授予學位之研究發展服務之行業。",
                "list": [
                    {
                        "id": "721",
                        "name": "自然及工程科學研究發展服務業",
                        "def": "從事自然及工程科學為基礎之研究、試驗，而不授予學位之研究發展服務之行業。"
                    },
                    {
                        "id": "722",
                        "name": "社會及人文科學研究發展服務業",
                        "def": "從事社會及人文科學為基礎之研究、分析及規劃，而不授予學位之研究發展服務之行業。"
                    },
                    {
                        "id": "723",
                        "name": "綜合研究發展服務業",
                        "def": "從事綜合自然及工程科學、社會及人文科學等為基礎之研究、試驗、分析及規劃，而不授予學位之研究發展服務之行業。"
                    }
                ]
            },
            {
                "id": "73",
                "name": "廣告業及市場研究業",
                "def": "從事廣告、市場研究及民意調查等行業。",
                "list": [
                    {
                        "id": "731",
                        "name": "廣告業",
                        "def": "從事廣告企劃、設計、製作及安排宣傳媒體等一系列服務之行業；媒體播放時段或刊登空間之買賣或代理，及廣告傳單與樣品分送、為吸引顧客而從事之行銷活動亦歸入本類。"
                    },
                    {
                        "id": "732",
                        "name": "市場研究及民意調查業",
                        "def": "從事市場研究及民意調查之設計、資料蒐集、整理及分析等服務之行業。"
                    }
                ]
            },
            {
                "id": "74",
                "name": "專門設計業",
                "def": "從事室內、工業、視覺傳達等設計之行業。",
                "list": [
                    {
                        "id": "740",
                        "name": "專門設計業",
                        "def": "從事室內、工業、視覺傳達等設計之行業。"
                    }
                ]
            },
            {
                "id": "75",
                "name": "獸醫業",
                "def": "從事動物醫療保健及動物醫事檢驗之行業。",
                "list": [
                    {
                        "id": "750",
                        "name": "獸醫業",
                        "def": "從事動物醫療保健及動物醫事檢驗之行業。"
                    }
                ]
            },
            {
                "id": "76",
                "name": "其他專業、科學及技術服務業",
                "def": "從事69至75m以外專業、科學及技術服務之行業，如攝影、翻譯、藝人及模特兒等經紀業。",
                "list": [
                    {
                        "id": "760",
                        "name": "其他專業、科學及技術服務業",
                        "def": "從事69至75m以外專業、科學及技術服務之行業，如攝影、翻譯、藝人及模特兒等經紀業。"
                    }
                ]
            }
        ]
    },
    {
        "id": "N",
        "name": "支援服務業",
        "def": "從事支援企業或組織營運之例行性活動（少部分服務家庭）之行業，如租賃、人力仲介及供應、旅行及其他相關服務、保全及偵探、建築物及綠化服務、行政支援服務等。",
        "list": [
            {
                "id": "77",
                "name": "租賃業",
                "def": "從事不含操作人員之機械設備及運輸工具、個人及家庭用品等有形資產及智慧財產之營業性租賃，供他人自行使用，並以收取租金、衍生利益金或權利金作為報酬之行業。",
                "list": [
                    {
                        "id": "771",
                        "name": "機械設備租賃業",
                        "def": "從事機械設備租賃，以收取租金作為報酬之行業。"
                    },
                    {
                        "id": "772",
                        "name": "運輸工具租賃業",
                        "def": "從事運輸工具租賃，以收取租金作為報酬之行業。"
                    },
                    {
                        "id": "773",
                        "name": "個人及家庭用品租賃業",
                        "def": "從事個人及家庭用品租賃，以收取租金作為報酬之行業，如運動及娛樂用品、影音光碟等出租。"
                    },
                    {
                        "id": "774",
                        "name": "智慧財產租賃業",
                        "def": "從事授權他人使用專利權、商標、採礦權、專營權及其他智慧財產，以收取衍生利益金或權利金作為報酬之行業；授權使用之形式包括允許複製、或將製程專利用於生產產品。"
                    }
                ]
            },
            {
                "id": "78",
                "name": "人力仲介及供應業",
                "def": "從事人力仲介及人力供應服務之行業。",
                "list": [
                    {
                        "id": "781",
                        "name": "人力仲介業",
                        "def": "從事人才推薦等人力仲介服務之行業；透過網路提供職業介紹服務亦歸入本類。"
                    },
                    {
                        "id": "782",
                        "name": "人力供應業",
                        "def": "從事於特定期間內派遣所屬員工至客戶場所，以支援其人力，且對所派遣之人員無直接監督權責之行業，如人力派遣服務；提供人力資源管理事務工作服務，而不指揮及監督客戶員工者亦歸入本類。"
                    }
                ]
            },
            {
                "id": "79",
                "name": "旅行及其他相關服務業",
                "def": "從事旅行及其他相關服務之行業，如安排及販售旅遊行程（食宿、交通、參觀活動等）、提供導遊及領隊服務、提供旅遊諮詢及相關票券代訂代售等服務。",
                "list": [
                    {
                        "id": "790",
                        "name": "旅行及其他相關服務業",
                        "def": "從事旅行及其他相關服務之行業，如安排及販售旅遊行程（食宿、交通、參觀活動等）、提供導遊及領隊服務、提供旅遊諮詢及相關票券代訂代售等服務。"
                    }
                ]
            },
            {
                "id": "80",
                "name": "保全及偵探業",
                "def": "從事保全及偵探服務之行業。",
                "list": [
                    {
                        "id": "800",
                        "name": "保全及偵探業",
                        "def": "從事保全及偵探服務之行業。"
                    }
                ]
            },
            {
                "id": "81",
                "name": "建築物及綠化服務業",
                "def": "從事建築物複合支援、清潔、綠化等服務之行業。",
                "list": [
                    {
                        "id": "811",
                        "name": "複合支援服務業",
                        "def": "從事在客戶場所提供作業人員以執行複合性且非客戶核心業務之多種支援活動之行業；包括環境清潔、垃圾處理、設施安全管理、接待、代收郵件等。"
                    },
                    {
                        "id": "812",
                        "name": "清潔服務業",
                        "def": "從事建築物、工業、道路及運輸工具（汽車除外）等清潔服務之行業；病媒防治亦歸入本類。"
                    },
                    {
                        "id": "813",
                        "name": "綠化服務業",
                        "def": "從事公園、庭園花草樹木之種植、照顧、維護及建築物、運動場與其他休閒場所週邊綠地維護之行業；行道樹之移植、修剪、維護亦歸入本類。"
                    }
                ]
            },
            {
                "id": "82",
                "name": "行政支援服務業",
                "def": "從事提供代收帳款及信用評等、會議及工商展覽、影印、包裝承攬等行政支援服務之行業。",
                "list": [
                    {
                        "id": "820",
                        "name": "行政支援服務業",
                        "def": "從事提供代收帳款及信用評等、會議及工商展覽、影印、包裝承攬等行政支援服務之行業。"
                    }
                ]
            }
        ]
    },
    {
        "id": "O",
        "name": "公共行政及國防；強制性社會安全",
        "def": "從事公共行政管理與服務之政府機關、民意機關及國防事務等；強制性社會安全事務、享有特權及豁免權之國際組織及外國機構亦歸入本類。",
        "list": [
            {
                "id": "83",
                "name": "公共行政及國防；強制性社會安全",
                "def": "從事公共行政管理與服務之政府機關、民意機關及國防事務等；強制性社會安全事務亦歸入本類。",
                "list": [
                    {
                        "id": "831",
                        "name": "公共行政",
                        "def": "從事公共行政管理與服務之政府機關及民意機關。"
                    },
                    {
                        "id": "832",
                        "name": "國防事務",
                        "def": "從事國家防衛及相關事務之軍事機關、部隊及軍事訓練機構。"
                    },
                    {
                        "id": "833",
                        "name": "強制性社會安全事務",
                        "def": "政府所從事社會安全計畫之資金運用與行政作業。"
                    }
                ]
            },
            {
                "id": "84",
                "name": "國際組織及外國機構",
                "def": "在國內享有特權及豁免權之政治、經濟、金融、社會、文化、科學、技術、救助等性質之國際組織及外國駐國內之使領館、辦事處與代表處等機構。",
                "list": [
                    {
                        "id": "840",
                        "name": "國際組織及外國機構",
                        "def": "在國內享有特權及豁免權之政治、經濟、金融、社會、文化、科學、技術、救助等性質之國際組織及外國駐國內之使領館、辦事處與代表處等機構。"
                    }
                ]
            }
        ]
    },
    {
        "id": "P",
        "name": "教育業",
        "def": "從事正規教育體制內之各級學校與體制外之教育服務，以及教育輔助服務之行業；軍事學校及法務機構附設學校亦歸入本類。",
        "list": [
            {
                "id": "85",
                "name": "教育業",
                "def": "從事正規教育體制內之各級學校與體制外之教育服務，以及教育輔助服務之行業；軍事學校及法務機構附設學校亦歸入本類。",
                "list": [
                    {
                        "id": "851",
                        "name": "學前教育",
                        "def": "從事小學前幼兒教育服務之行業。"
                    },
                    {
                        "id": "852",
                        "name": "小學教育",
                        "def": "從事提供小學教育程度之教育服務並授予畢業證書之公私立學校。"
                    },
                    {
                        "id": "853",
                        "name": "國民中學教育",
                        "def": "從事提供國民中學教育程度之教育服務並授予畢業證書之公私立學校。"
                    },
                    {
                        "id": "854",
                        "name": "高級中等教育",
                        "def": "從事提供高級中等教育程度之教育服務並授予畢業證書之公私立學校。"
                    },
                    {
                        "id": "855",
                        "name": "大專校院",
                        "def": "從事提供大專校院教育程度之教育服務並授予畢業證書之公私立學校。"
                    },
                    {
                        "id": "856",
                        "name": "特殊教育學校",
                        "def": "從事專門提供身心障礙者各級教育服務並授予畢業證書之公私立學校。"
                    },
                    {
                        "id": "858",
                        "name": "教育輔助業",
                        "def": "從事提供教育輔助服務之行業，如教育諮詢、教育相關檢定或評估、交換學生安排服務、課程開發（設計）等不具教學性質之教育輔助服務。"
                    },
                    {
                        "id": "859",
                        "name": "其他教育業",
                        "def": "從事正規教育體制外教育服務之行業，如提供外語、藝術、運動及休閒、商業、資訊及專業管理、課業、升學及就業補習或其他教育服務與專業訓練。"
                    }
                ]
            }
        ]
    },
    {
        "id": "Q",
        "name": "醫療保健及社會工作服務業",
        "def": "從事醫療保健及社會工作服務之行業。",
        "list": [
            {
                "id": "86",
                "name": "醫療保健業",
                "def": "從事醫療保健服務之行業，如醫院、診所、醫學檢驗及其他醫療保健服務等。",
                "list": [
                    {
                        "id": "861",
                        "name": "醫院",
                        "def": "設有病房收治病人之醫院；醫學校院、宗教組織、國防機構、監獄等附設之醫院亦歸入本類。"
                    },
                    {
                        "id": "862",
                        "name": "診所",
                        "def": "從事門診服務之診所；機關、學校、事業單位附設之醫務室、診療所以及衛生機關所設性病防治所、慢性病防治所等亦歸入本類。"
                    },
                    {
                        "id": "869",
                        "name": "其他醫療保健業",
                        "def": "從事861及862s以外醫療保健服務之行業，如醫學檢驗服務及其他醫療保健服務。"
                    }
                ]
            },
            {
                "id": "87",
                "name": "居住型照顧服務業",
                "def": "結合健康照顧及社會服務，從事提供住所並附帶所需之護理、監管或其他形式照顧服務之行業；服務過程中最重要的為提供住所，而健康照顧主要係指護理服務。",
                "list": [
                    {
                        "id": "871",
                        "name": "居住型護理照顧服務業",
                        "def": "從事居住型長期照顧服務及其他居住型護理照顧服務之行業。"
                    },
                    {
                        "id": "879",
                        "name": "其他居住型照顧服務業",
                        "def": "從事871s以外居住型照顧服務之行業。"
                    }
                ]
            },
            {
                "id": "88",
                "name": "其他社會工作服務業",
                "def": "從事居住型照顧服務以外社會工作服務之行業。",
                "list": [
                    {
                        "id": "881",
                        "name": "居家式及社區式長期照顧服務業",
                        "def": "從事居家式及社區式長期照顧服務之行業。"
                    },
                    {
                        "id": "889",
                        "name": "未分類其他社會工作服務業",
                        "def": "從事881s以外其他社會工作服務之行業。"
                    }
                ]
            }
        ]
    },
    {
        "id": "R",
        "name": "藝術、娛樂及休閒服務業",
        "def": "從事創作及藝術表演，經營圖書館、檔案保存、博物館及類似機構，博弈、運動、娛樂及休閒服務等之行業。",
        "list": [
            {
                "id": "90",
                "name": "創作及藝術表演業",
                "def": "從事創作、藝術表演及相關輔助服務之行業。",
                "list": [
                    {
                        "id": "901",
                        "name": "創作業",
                        "def": "從事小說、漫畫、戲劇、詩歌、散文、繪畫、雕刻、塑造等創作之行業；自由撰稿記者及從事藝術品修復服務亦歸入本類。"
                    },
                    {
                        "id": "902",
                        "name": "藝術表演業",
                        "def": "從事戲劇、音樂、舞蹈、雜技及其他表演之行業。"
                    },
                    {
                        "id": "903",
                        "name": "創作及藝術表演輔助業",
                        "def": "從事藝文作品展覽活動籌辦，音樂廳、戲劇院、流行音樂展演空間等藝術表演場所經營，及藝術表演活動籌辦、舞台設計、燈光及服裝指導、藝術表演監製等輔助服務之行業。"
                    }
                ]
            },
            {
                "id": "91",
                "name": "圖書館、檔案保存、博物館及類似機構",
                "def": "從事經營圖書館、檔案保存、動植物園、自然生態保護機構、博物館、歷史遺址及其他類似機構之行業。",
                "list": [
                    {
                        "id": "910",
                        "name": "圖書館、檔案保存、博物館及類似機構",
                        "def": "從事經營圖書館、檔案保存、動植物園、自然生態保護機構、博物館、歷史遺址及其他類似機構之行業。"
                    }
                ]
            },
            {
                "id": "92",
                "name": "博弈業",
                "def": "從事彩券銷售、經營博弈場、投幣式博弈機具、博弈網站及其他博弈服務之行業。",
                "list": [
                    {
                        "id": "920",
                        "name": "博弈業",
                        "def": "從事彩券銷售、經營博弈場、投幣式博弈機具、博弈網站及其他博弈服務之行業。"
                    }
                ]
            },
            {
                "id": "93",
                "name": "運動、娛樂及休閒服務業",
                "def": "從事提供運動、娛樂及休閒服務之行業。",
                "list": [
                    {
                        "id": "931",
                        "name": "運動服務業",
                        "def": "從事職業運動、運動場館經營管理及其他運動服務之行業。"
                    },
                    {
                        "id": "932",
                        "name": "娛樂及休閒服務業",
                        "def": "從事遊樂園及主題樂園、視聽及視唱場所、特殊娛樂場所、遊戲場等經營及其他娛樂及休閒服務之行業。"
                    }
                ]
            }
        ]
    },
    {
        "id": "S",
        "name": "其他服務業",
        "def": "從事G至Rb以外服務之行業，如宗教、職業及類似組織、個人及家庭用品維修、洗衣、美髮及美容美體、殯葬及相關服務、家事服務等。",
        "list": [
            {
                "id": "94",
                "name": "宗教、職業及類似組織",
                "def": "從事推展宗教、教育、學術、文化、環保、醫療、衛生、聯誼、國際交流或基於共同政治理念、相同行業與職業類別而結合之組織等。",
                "list": [
                    {
                        "id": "941",
                        "name": "宗教組織",
                        "def": "從事推展宗教理念，宣揚教義，或設有主持人並備經典、法物供公眾從事宗教儀式及活動之組織。"
                    },
                    {
                        "id": "942",
                        "name": "職業團體",
                        "def": "由工商事業單位、專門職業人員、勞工及農、林、漁、牧業人員組織之團體。"
                    },
                    {
                        "id": "949",
                        "name": "其他組織",
                        "def": "941及942s以外之組織。"
                    }
                ]
            },
            {
                "id": "95",
                "name": "個人及家庭用品維修業",
                "def": "從事汽車、電腦及其週邊設備、通訊傳播設備、視聽電子產品、家用電器與其他個人及家庭用品維修之行業。",
                "list": [
                    {
                        "id": "951",
                        "name": "汽車維修及美容業",
                        "def": "從事汽車維修及汽車美容之行業。"
                    },
                    {
                        "id": "952",
                        "name": "電腦、通訊傳播設備及電子產品維修業",
                        "def": "從事電腦及其週邊設備、通訊傳播設備、視聽電子產品及家用電器維修之行業。"
                    },
                    {
                        "id": "959",
                        "name": "其他個人及家庭用品維修業",
                        "def": "從事951及952s以外個人及家庭用品維修之行業。"
                    }
                ]
            },
            {
                "id": "96",
                "name": "未分類其他服務業",
                "def": "從事洗衣、美髮及美容美體、殯葬及寵物生命紀念相關服務、家事服務與其他個人服務之行業。",
                "list": [
                    {
                        "id": "961",
                        "name": "洗衣業",
                        "def": "從事以機械、手工或提供投幣式機器來洗濯、熨燙衣物、毛巾、床單、地毯、皮衣以及其他紡織製品之行業。"
                    },
                    {
                        "id": "962",
                        "name": "美髮及美容美體業",
                        "def": "從事美髮、美容護膚、彩粧、美甲及未涉及醫療程序之美體雕塑等之行業。"
                    },
                    {
                        "id": "963",
                        "name": "殯葬及寵物生命紀念相關服務業",
                        "def": "從事人之遺體埋葬、火化、殯葬禮儀及殯葬設施經營等相關服務之行業；寵物屍體處理及其生命紀念設施經營亦歸入本類。"
                    },
                    {
                        "id": "964",
                        "name": "家事服務業",
                        "def": "家庭僱用幫傭、看護、司機、廚師等供自用服務之行業。"
                    },
                    {
                        "id": "969",
                        "name": "其他個人服務業",
                        "def": "從事961至964s以外個人服務之行業，如按摩服務、個人造型設計、紋身（眉）服務、算命服務、堪輿服務、字畫裱背、陪侍服務、婚姻介紹、寵物照顧（如提供宿膳、造型、訓練等）、投幣式或電子支付之自助式照相亭及置物櫃、婚禮規劃籌辦服務等。"
                    }
                ]
            }
        ]
    }
]


export const ProductTagList:any = [
    {
        "id": "B001",
        "name": "慈善",
        "def": "與公益團體、非營利單位有關"
    },
    {
        "id": "B002",
        "name": "航空",
        "def": "與飛機、販售機票相關"
    },
    {
        "id": "B003",
        "name": "旅遊",
        "def": "與行程有關，不分國內外，遊樂園"
    },
    {
        "id": "B004",
        "name": "展覽活動",
        "def": "目標為幾月、幾號、在哪裡，具有展示性質。例如卡納赫拉展、數位時代展、線上webinar"
    },
    {
        "id": "B005",
        "name": "政府機關",
        "def": "發稿為政府單位"
    },
    {
        "id": "B006",
        "name": "生命禮儀",
        "def": "商品為骨灰罈、塔位..等"
    },
    {
        "id": "B007",
        "name": "工商服務",
        "def": "其他服務性質商品、直銷、洗衣、環境衛生清潔、廢棄物清除、處理及資源物回收處理業"
    },
    {
        "id": "B008",
        "name": "汽機車",
        "def": "購買汽機車商品"
    },
    {
        "id": "B009",
        "name": "食品飲料",
        "def": "商品為食品或飲料，且包含沒有實體店面的餐飲提供商，例如雲端廚房，食品製造"
    },
    {
        "id": "B010",
        "name": "餐飲業",
        "def": "具有實體店面的餐飲提供商"
    },
    {
        "id": "B011",
        "name": "飯店旅館民宿",
        "def": "短期出租場域給消費者的客戶"
    },
    {
        "id": "B012",
        "name": "教育",
        "def": "發稿單位為教育單位，且商品或服務具有教育性質"
    },
    {
        "id": "B013",
        "name": "文化出版",
        "def": "商品為出版品"
    },
    {
        "id": "B014",
        "name": "營建租屋不動產",
        "def": "長期出租或販售場域的客戶，例如辦公室出租，房屋買賣"
    },
    {
        "id": "B015",
        "name": "裝潢修整室內設計",
        "def": "房屋室內裝修、裝潢、設計，土木工程、鐵門速修"
    },
    {
        "id": "B016",
        "name": "3C家電",
        "def": "與電子產品相關、家用電器"
    },
    {
        "id": "B017",
        "name": "金融",
        "def": "與金融產品相關"
    },
    {
        "id": "B018",
        "name": "遊戲",
        "def": "與遊戲產品相關"
    },
    {
        "id": "B019",
        "name": "租車",
        "def": "商品為出租汽機車"
    },
    {
        "id": "B020",
        "name": "資訊軟體",
        "def": "商品形式為非實體型態，軟體出版、電商、網站代管服務、程式設計"
    },
    {
        "id": "B021",
        "name": "電信通訊",
        "def": "客戶為傳統電信公司，商品通常為方案"
    },
    {
        "id": "B022",
        "name": "綜合零售",
        "def": "客戶通常具有多項商品"
    },
    {
        "id": "B023",
        "name": "代標代購",
        "def": "協助代標或代買海外商品的服務"
    },
    {
        "id": "B024",
        "name": "傢俱寢具",
        "def": "與傢俱寢具相關"
    },
    {
        "id": "B025",
        "name": "百貨購物商場",
        "def": "客戶通常為百貨公司或大型商場"
    },
    {
        "id": "B026",
        "name": "母嬰",
        "def": "通常為媽媽、嬰兒需求相關商品"
    },
    {
        "id": "B027",
        "name": "家庭生活用品",
        "def": "商品通常為日用品"
    },
    {
        "id": "B028",
        "name": "服飾鞋包",
        "def": "商品通常為時尚、服飾、鞋、包包...等，成衣製造"
    },
    {
        "id": "B029",
        "name": "音樂影視",
        "def": "商品為出租或販售音樂影視商品，也包含新歌新電影"
    },
    {
        "id": "B030",
        "name": "運動健身",
        "def": "通常為運動健身需求相關商品或服務，如啞鈴與健身房"
    },
    {
        "id": "B031",
        "name": "保健",
        "def": "商品通常為保健食品"
    },
    {
        "id": "B032",
        "name": "精品珠寶",
        "def": "商品通常為精品或珠寶、鐘錶"
    },
    {
        "id": "B033",
        "name": "藝術品",
        "def": "商品通常為藝術品"
    },
    {
        "id": "B034",
        "name": "美藥妝",
        "def": "商品通常為美妝或藥品"
    },
    {
        "id": "B035",
        "name": "醫美",
        "def": "商品形式通常為美容型醫療"
    },
    {
        "id": "B036",
        "name": "醫療生技",
        "def": "商品與生技、醫療有關，形式不限"
    },
    {
        "id": "B037",
        "name": "成人",
        "def": "成人商品"
    },
    {
        "id": "B040",
        "name": "保險",
        "def": "保險"
    },
    {
        "id": "B041",
        "name": "其他組織",
        "def": "協會、基金會、職業團體"
    },
    {
        "id": "B042",
        "name": "個人工作室",
        "def": "按摩服務、個人造型設計、紋身（眉）服務、寵物照顧（如提供宿膳、造型、訓練等)"
    },
    {
        "id": "B043",
        "name": "其他製造業",
        "def": "設備研發、半導體、電子零件製造、電腦及其週邊設備、生技研發、機械製造"
    },
    {
        "id": "B044",
        "name": "專門設計相關業",
        "def": "台灣在地製造與生產、專門設計相關授權圖像業"
    },
    {
        "id": "B045",
        "name": "寵物相關",
        "def": "飼料製造、寵物相關食用品批發、零售"
    },
    {
        "id": "B046",
        "name": "行銷",
        "def": "廣業整合行銷"
    },
    {
        "id": "B047",
        "name": "傳播",
        "def": "電視廣播、OTT串流平台新媒體傳播產業"
    }
];
export const ProductTagMap = listToMap(ProductTagList.map((product)=>({value: product.id, label: `${product.id} ${product.name}`})));
export const FieldTagList: any = [
    {
        "id": "S001",
        "name": "國內線下",
        "def": "產品或服務銷售方式為線下, 場域為國內"
    },
    {
        "id": "S002",
        "name": "國內線上",
        "def": "產品或服務銷售方式為線上, 場域為國內"
    },
    {
        "id": "S003",
        "name": "國內全線",
        "def": "產品或服務銷售方式為線上與線下並存, 場域為國內"
    },
    {
        "id": "S004",
        "name": "國外線下",
        "def": "產品或服務銷售方式為線下, 場域為國外"
    },
    {
        "id": "S005",
        "name": "國外線上",
        "def": "產品或服務銷售方式為線上, 場域為國外"
    },
    {
        "id": "S006",
        "name": "國外全線",
        "def": "產品或服務銷售方式為線上與線下並存, 場域為國外"
    },
    {
        "id": "S007",
        "name": "跨境線下",
        "def": "產品或服務銷售方式為線下, 場域為國內外皆有"
    },
    {
        "id": "S008",
        "name": "跨境線上",
        "def": "產品或服務銷售方式為線上, 場域為國內外皆有"
    },
    {
        "id": "S009",
        "name": "跨境全線",
        "def": "產品或服務銷售方式為線上與線下並存, 場域為國內外皆有"
    }
]
export const FieldTagMap = listToMap(FieldTagList.map((field)=>({value: field.id, label: `${field.id} ${field.name}`})));