import React, { useCallback, useEffect, useState } from "react";
import DefaultQueryHandler from "../../common/DefaultQueryHandler";
import CreateView from "../custom/CreateView";
import { InputType, SelectField } from "../../common/FormRenderer";
import {
    CustomerTypeEnum,
    LocaleEnum,
    OrderInvoiceMethods,
    useCreateOrderMutation,
    useGetCustomerLazyQuery,
    useGetCustomerBriefListQuery,
    useGetUserListQuery,
    RoleIdEnum, Customer, useNoopMutation,
} from "../../generated/graphql";
import NotificationPopup from "../../common/NotificationPopup";
import { hasRole, selectMapFn } from "../../common/Util";
import {
    CurrencyList,
    InvoiceMethodList,
    PaymentDaysList,
    PaymentDaysMap,
} from "../../common/Constant";
import CreateContactView from "../contact/CreateContactView";
import CreateAdvertiserView from "../advertiser/CreateAdvertiserView";
import {FormControl, InputLabel, MenuItem, Select, TextField} from "@material-ui/core";
import { useUser } from "../../Auth";
import {filter, find} from "lodash";
import {Autocomplete} from "@material-ui/lab";

const useGetCustomerData = (customerId) => {
    const [getCustomer, { data }] = useGetCustomerLazyQuery();
    useEffect(() => {
        const id = customerId;
        if (id) {
            getCustomer({ variables: { id } });
        }
    }, [customerId, getCustomer]);
    return { data };
};

export default () => {
    const [customer, setCustomer] = useState<any>();
    const [advertiserId, setAdvertiserId] = useState<string | null>();
    const { data, loading, error } = useGetCustomerBriefListQuery();
    const user = useUser();
    const userListQuery = useGetUserListQuery();
    const [createOrder] = useCreateOrderMutation({
        refetchQueries: ["GetOrders"],
    });
    const [noop] = useNoopMutation();
    const onSubmit = useCallback(
        async (order, { resetForm }, setOpen) => {
            try {
                if (order.paymentDays == null) {
                    NotificationPopup.error(`請先完成信用評等後再建立委刊單。`);
                    return;
                }
                if (order.currency) {
                    order = {...order, locale: order.currency == "TWD" ?
                            LocaleEnum.ZhTw : LocaleEnum.EnUs}
                }
                if (order.assignedSalesUserId.value) {
                    order = {...order,
                        assignedSalesUserId: order.assignedSalesUserId.value}
                }
                if (advertiserId) {
                    order = {...order, advertiserId}
                }
                const newOrder = await createOrder({
                    variables: { order },
                });
                NotificationPopup.success(`建立完成`);
                setOpen(false);
                resetForm();
                return newOrder.data?.createOrder;
            }
            catch (e) {
                NotificationPopup.error(`建立委刊單發生問題: ${e.message}`);
                console.error(e);
            }
        },
        [createOrder, advertiserId, user]
    );
    const columns = [
        { name: "name", label: "專案名稱", required: true },
        {
            name: "customerId",
            label: "客戶",
            type: InputType.autoComplete,
            options: data?.result?.list.map(selectMapFn),
            onChange: (d) => {
                setCustomer(find(data?.result?.list ?? [], (c)=>{
                    return c.id == d;
                }));
            },
        },
        {
            name: "createAdvertiserView",
            label: "廣告主",
            type: InputType.custom,
            renderCell:
                customer?.type ==
                CustomerTypeEnum.Agent ? (
                    <div style={{ marginTop: 10 }}>
                        <CreateAdvertiserView
                            customer={
                                customer
                            }
                        />
                        <FormControl style={{width: "100%"}}>
                            {/*<InputLabel>廣告主</InputLabel>*/}
                            <Autocomplete
                                options={customer?.advertisers}
                                getOptionLabel={(option:any) => option?.name}
                                renderInput={(params) => <TextField {...params} label="廣告主" />}
                                onChange={(_,value) =>{
                                    setAdvertiserId(value.id as string);
                                }}
                            />
                            {/*<Select onChange={(event: React.ChangeEvent<{ value: unknown }>)=> {*/}
                            {/*    setAdvertiserId(event.target.value as string)*/}
                            {/*}}>*/}
                            {/*    {customer?.advertisers?.map(*/}
                            {/*        ({ id, name }) => {*/}
                            {/*            return (*/}
                            {/*                <MenuItem*/}
                            {/*                    value={id}*/}
                            {/*                    key={id}*/}
                            {/*                >*/}
                            {/*                    {name}*/}
                            {/*                </MenuItem>*/}
                            {/*            );*/}
                            {/*        }*/}
                            {/*    )}*/}
                            {/*</Select>*/}
                        </FormControl>
                    </div>
                ) : (
                    <div />
                ),
        },
        {
            name: "createContactView",
            label: "新增聯絡人",
            type: InputType.custom,
            renderCell: customer ? (
                <div style={{ marginTop: 10 }}>
                    <CreateContactView
                        customer={
                            customer
                        }
                    />
                </div>
            ) : (
                <div />
            ),
        },
        {
            name: "contactId",
            label: "主要聯絡人",
            type: InputType.select,
            options: customer?.contacts?.map(
                    selectMapFn
                )
                ?? [],
            required: true,
        },
        {
            name: "invoiceContactId",
            label: "發票聯絡人",
            type: InputType.select,
            options: customer?.contacts?.map(
                selectMapFn
            ) ?? [],
        },
        {
            name: "invoiceMethod",
            label: "發票開立方法",
            type: InputType.custom,
            value: OrderInvoiceMethods.ThisMonth, //needs default value to work.
            RenderCell: ({form, field}) => {
                const { data } = useGetCustomerData(
                    form?.values?.customerId
                );
                useEffect(() =>
                {
                    const invoiceMethod = customer?.invoiceMethod;
                    if (invoiceMethod) {
                        form.setFieldValue(
                            field.name,
                            invoiceMethod
                        );
                    }
                }, [data, customer]);
                return (
                    <SelectField
                        label={"發票開立方法"}
                        disabled={!data}
                        options={InvoiceMethodList}
                        field={field}
                        form={form}
                    />
                );
            }
        },
        {
            name: "paymentDays",
            label: "付款天數",
            type: InputType.custom,
            value: PaymentDaysMap[customer?.defaultPaymentDays ?? 0],
            RenderCell: ({form, field}) => {
                const { data } = useGetCustomerData(
                    form?.values?.customerId
                );
                useEffect(() =>
                {
                    const paymentDays = data?.customer?.defaultPaymentDays;
                    console.log(`setFieldValue ${field.name} ${paymentDays}`);
                    if (paymentDays!==undefined) {
                        form.setFieldValue(
                            field.name,
                            paymentDays
                        );
                    }
                }, [data, customer]);
                return (
                    <SelectField
                        label={"付款天數"}
                        disabled={!data}
                        options={filter(PaymentDaysList, (paymentDay)=>paymentDay.value==customer?.defaultPaymentDays)}
                        field={field}
                        form={form}
                    />
                );
            }
        },
        {
            name: "currency",
            label: "幣別",
            type: InputType.select,
            value: "TWD",
            options: CurrencyList,
        },
        {
            name: "renewal",
            label: "續約",
            type: InputType.checkbox,
        },
        {
            name: "assignedSalesUserId",
            label: "簽約負責人",
            type: InputType.autoComplete,
            value: userListQuery.data?.users.map(selectMapFn).find((u)=>u.value==user?.id),
            options: userListQuery.data
                ? userListQuery.data?.users.map(selectMapFn)
                : [],
            required: true,
        },
        {
            name: "remark",
            label: "合約備註",
        },
    ];
    return (
        <DefaultQueryHandler
            loading={
                loading && userListQuery.loading
            }
            error={error}
        >
            <CreateView
                title={"建立委刊單"}
                columns={columns}
                onSubmit={onSubmit}
                queryEntity={"order"}
            />
        </DefaultQueryHandler>
    );
};
