import {
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles,
    Box,
    Paper,
    Switch,
    FormControlLabel,
} from "@material-ui/core";
import React, { useCallback, useState } from "react";
import TextInput from "../../components/Form/TextInput";
import SelectInput from "../../components/Form/SelectInput";
import NotificationPopup from "../../common/NotificationPopup";
import { useCreateContactMutation } from "../../generated/graphql";
import CreateView from "../custom/CreateView";
import { InputType } from "../../common/FormRenderer";

const useStyles = makeStyles(() => ({
    margin: {
        marginTop: 20,
        marginBotton: 20,
        minWidth: 200,
    },
    dialogPaper: {
        minHeight: "80vh",
        minWidth: "40vw",
    },
}));

export default ({customer, supplier}: {customer?: any, supplier?: any}) => {
    const [createContact] = useCreateContactMutation();
    const onSubmit = useCallback(
        async (contact, { resetForm }, setOpen) => {
            if (customer) {
                contact.customerId = parseFloat(customer.id)
            }
            else if (supplier) {
                contact.supplierId = parseFloat(supplier.id)
            }
            try {
                const newContact = await createContact({
                    variables: {
                        contact
                    },
                    refetchQueries:["getCustomer", "getOrder", "getSupplier", "getCustomerBriefList"]
                });
                NotificationPopup.success(`建立完成`);
                return newContact.data?.createContact;
            } catch (e) {
                NotificationPopup.error(
                    `新增聯絡人發生錯誤: ${e.message}`
                );
                console.error(e);
            } finally {
                resetForm();
                setOpen(false);
            }
        },
        [createContact]
    );
    return (
        <CreateView
            title={"新增聯絡人"}
            columns={[
                { name: "name", label: "姓名" },
                { name: "phone", label: "電話" },
                { name: "email", label: "Email" },
            ]}
            onSubmit={onSubmit}
            queryEntity={"contact"}
        />
    );
}