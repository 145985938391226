import React, { useCallback } from "react";
import NotificationPopup from "../../common/NotificationPopup";
import {useCreateAdvertiserMutation, useGetIndustryListQuery} from "../../generated/graphql";
import CreateView from "../custom/CreateView";
import {InputType} from "../../common/FormRenderer";
import {selectMapFn} from "../../common/Util";
import {industryTagColumn} from "../customer/CustomerDetailView";
import {FieldTagList, ProductTagList} from "../../common/IndustryConstant";

export default ({customer}) => {
    const [createAdvertiser] = useCreateAdvertiserMutation();
    const industryListQuery = useGetIndustryListQuery();
    const onSubmit = useCallback(
        async (advertiser, { resetForm }, setOpen) => {
            console.log(advertiser)
            if (customer) {
                advertiser.customerId = parseFloat(customer.id)
            }
            try {
                await createAdvertiser({
                    variables: {
                        advertiser
                    },
                    refetchQueries:["getCustomer", "getOrder"]
                });
                NotificationPopup.success(`建立完成`);
            } catch (e) {
                NotificationPopup.error(
                    `新增廣告主發生錯誤: ${e.message}`
                );
                console.error(e);
            } finally {
                resetForm();
                setOpen(false);
            }
        },
        [createAdvertiser]
    );
    return (
        <CreateView
            title={"新增廣告主"}
            columns={[
                { name: "name", label: "廣告主名稱" },
                {
                    name: "industryId",
                    label: "產業別",
                    type: InputType.select,
                    options: !industryListQuery.loading
                        ? industryListQuery.data!.industries.map(selectMapFn)
                        : [],
                    required: true,
                },
                industryTagColumn(null),
                {
                    name: "productTag",
                    label: "產品標籤",
                    type: InputType.select,
                    options: ProductTagList.map((product)=>({value: product.id,label: `${product.id} ${product.name}`
                    })),
                },
                {
                    name: "fieldTag",
                    label: "場域標籤",
                    type: InputType.select,
                    options: FieldTagList.map((field)=>({value: field.id,label: `${field.id} ${field.name}`
                    })),
                },
            ]}
            onSubmit={onSubmit}
            queryEntity={"advertiser"}
        />
    );
}