import React, { useState } from "react";
import { Container, Grid } from "@material-ui/core";
import Page from "../../components/Page";
import ProductTable from "./ProductTable";
import CreateProductView from "./CreateProductView";

export default () => {
    return (
        <Page title={"customer page"}>
            <Container maxWidth={false} style={{ padding: "30px"}}>
                <Grid container spacing={2}>
                    <CreateProductView/>
                    <ProductTable/>
                </Grid>
            </Container>
        </Page>
    )
}